import {
  Box,
  Button,
  capitalize,
  Dialog,
  Grid,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExamAppBar from "../navbar/ExamAppBar";
import { blue } from "@mui/material/colors";
import axios from "axios";
import { Link } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EditRoutineSchedule from "./EditRoutineSchedule";
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RoutineList() {
  const [moduleData, setModuleName] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = (id) => {
      setSelectedId(id); 
      setOpen(true);
    };
  
    // Close the dialog
    const handleClose = () => {
      setOpen(false);
      setSelectedId(null); // Reset selectedId when closing dialog
    };

  const fetchData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${backendUrl}/SubjectExamSchedule`,
        config
      );
      setModuleName(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdate = async () => {
    await fetchData(); 
    setOpen(false); 
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelected = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ExamAppBar />
        </Box>
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        padding={2}
      >
        <Grid item xs>
          <Typography variant="h6" color={blue[700]} textAlign="center">
            These are the Schedules Exams Routines
          </Typography>
        </Grid>
        {/* <Grid item>
          <Button
            startIcon={<EditNoteIcon />}
            variant="contained"
            component={Link}
            to={"/exam-management/exam-routine"}
          >
            Edit Routine
          </Button>
        </Grid> */}
      </Grid>

      <Grid justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <TableContainer sx={{borderRadius:2}}>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Exam Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Subject Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Exam Type
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Exam Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Exam Time
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Full Marks
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Pass Marks
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Status
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "4px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{bgcolor:'white'}}>
                {moduleData.length > 0 &&
                  moduleData.map((data, index) => {
                    // Get today's date
                    const today = new Date();
                    const startDate = new Date(data.examDate);
                    console.log(startDate);
                    console.log(today);

                    let status;
                    if (today < startDate) {
                      status = "Active";
                    } else if (today === startDate) {
                      status = "Running";
                    } else {
                      status = "Finished";
                    }

                    return (
                      <TableRow key={data.id}>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.examSchedule?.examName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.subject?.subjectName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {capitalize(data.examType)}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.examDate.slice(0, 10)}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.examTime}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.fullMark}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.passMark}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.description}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          <span
                            style={{
                              color:
                                status === "Active"
                                  ? "green"
                                  : status === "Running"
                                  ? "orange"
                                  : "gray",
                            }}
                          >
                            {status}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid #ddd",
                            padding: "4px",
                            justifyContent: "right",
                          }}
                        >
                          <Button
                            variant="outlined"
                            component={Link}
                            to={`/exam-management/exam-appear?examroutine=${data.id}`}
                            onClick={() => handleSelected(data.id)}
                          >
                            Exam Attendance
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleClickOpen(data.id)}
                            sx={{ ml: 1 }}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
       open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="lg"
        aria-describedby="alert-dialog-slide-description">
        <EditRoutineSchedule
          Id={selectedId}
          onClose={handleClose}
          onUpdate={fetchData}
        />
      </Dialog>
      
    </>
  );
}
