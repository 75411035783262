import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getTeachingStaffByQualification() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Dashboard/college/teachingStaffQualifications`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getStudentsByProgramLevelAndEthnicity() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `http://apiheims.dibugsoft.com/api/Dashboard/College/GetStudentByProgramLevelAndEthnicity`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}


export async function getProgramEnrollmentReport(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Dashboard/college/GetProgramEnrollmentReport/${id}`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}


export async function getEnrollmentByEcobelts(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Dashboard/college/enrollment-by-ecological-belt?fiscalyearid=${id}`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getProvinceWiseEnrollment(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Dashboard/college/GenerateStudentByProgramProvinceReport/?fiscalyearid=${id}`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getFacultyNamesForTeaching() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Dashboard/college/GenerateStudentByProgramProvinceReport`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function GetStudentByProgramLevelAndEthnicity(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Dashboard/college/GetStudentByProgramLevelAndEthnicity/?fiscalyearid=${id}`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getTeachersByPosition(id) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Dashboard/College/GetTeachingStaffDistribution?fiscalyearid=${id}`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
