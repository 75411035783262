import { Box, Chip, Grid, TextField } from "@mui/material";
import { Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { Typography } from "@mui/material";
import { Paper } from "@mui/material";
import { CardContent } from "@mui/material";
import { InputLabel } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ExamAppBar from "../navbar/ExamAppBar";
import axios from "axios";
import toast from "react-hot-toast";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function ExamSchedule() {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [programsData, setProgramsData] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const programs = await axios.get(
          `${backendUrl}/ProgramMgmt/GetCollegePrograms`,
          config
        );

        const fiscalYears = await axios.get(`${backendUrl}/FiscalYear`, config);

        setProgramsData(programs.data);
        setFiscalYears(fiscalYears.data);

        // Find the active fiscal year
        const activeFiscalYear = fiscalYears.data.find(
          (year) => year.activeFiscalYear === true
        );

        if (activeFiscalYear) {
          setValue("fiscalYearId", activeFiscalYear.id);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const dateFrom = new Date(data.dateFrom).toISOString();
      const dateTo = new Date(data.dateTo).toISOString();

      const payload = {
        fiscalYearId: data.fiscalYearId,
        programMgmtId: data.programId,
        examType: data.examType,
        examName: data.examName,
        dateFrom: dateFrom,
        dateTo: dateTo,
        dateFromNepali: data.dateFromNepali,
        dateToNepali: data.dateToNepali,
        status: data.status,
        description: data.details,
        year: data?.year,
        semester: data?.semester,
      };

      const response = await axios.post(
        `${backendUrl}/ExamSchedule`,
        payload,
        config
      );
      toast.success("Exam Schedule submitted successfully!!");
      console.log("Exam Schedule submitted successfully:", response.data);
      reset();
    } catch (error) {
      console.error("Error submitting exam schedule:", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ExamAppBar />
        </Box>
      </Box>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <Paper elevation={5} sx={{ borderRadius: "20px" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Exam Schedule For Program
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel required>Fiscal Year</InputLabel>
                      <Select
                        required
                        {...register("fiscalYearId")}
                        id="fiscalYearId"
                        size="small"
                        name="fiscalYearId"
                        fullWidth
                        label="Fiscal Year"
                      >
                        {fiscalYears.map((data) => (
                          <MenuItem key={data.id} value={data.id}>
                            {data.yearNepali}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel required>Program</InputLabel>
                      <Select
                        required
                        {...register("programId")}
                        id="programId"
                        size="small"
                        name="programId"
                        fullWidth
                        label="Programs"
                      >
                        {programsData.map((data) => (
                          <MenuItem key={data.id} value={data.id}>
                            {data.programName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel required>Exam Type</InputLabel>
                      <Select
                        required
                        {...register("examType")}
                        id="examType"
                        size="small"
                        name="examType"
                        fullWidth
                        label="Exam Type"
                      >
                        <MenuItem value={"internal"}>Internal Exam</MenuItem>
                        <MenuItem value={"board"}>External Exam</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      {...register("examName", { required: true })}
                      id="examName"
                      size="small"
                      name="examName"
                      label="Exam Name"
                      fullWidth
                      error={!!errors.examName}
                      helpertext={errors.examName ? "Exam name required" : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                      <TextField
                        required
                        {...register("dateFrom", { required: true })}
                        id="dateFrom"
                        type="date"
                        size="small"
                        name="dateFrom"
                        label="Date From"
                        fullWidth
                        error={!!errors.dateFrom}
                        helpertext={errors.dateFrom ? "Date From required" : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                      <TextField
                        required
                        {...register("dateTo", { required: true })}
                        id="dateTo"
                        type="date"
                        size="small"
                        name="dateTo"
                        label="Date To"
                        fullWidth
                        error={!!errors.dateTo}
                        helpertext={errors.dateTo ? "Date From required" : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                      <TextField
                        required
                        {...register("dateFromNepali", { required: true })}
                        id="dateFromNepali"
                        type="date"
                        size="small"
                        name="dateFromNepali"
                        label="Date From Nepali"
                        fullWidth
                        error={!!errors.dateFromNepali}
                        helpertext={
                          errors.dateFromNepali ? "Date From required" : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                      <TextField
                        required
                        {...register("dateToNepali", { required: true })}
                        id="dateToNepali"
                        type="date"
                        size="small"
                        name="dateToNepali"
                        label="Date To Nepali"
                        fullWidth
                        error={!!errors.dateToNepali}
                        helpertext={
                          errors.dateToNepali ? "Date To is required" : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="status" required>
                        Status
                      </InputLabel>
                      <Select
                        required
                        {...register("status")}
                        id="status"
                        size="small"
                        name="status"
                        fullWidth
                        label="Status"
                        error={!!errors.status}
                        helpertext={errors.status ? "Status required" : ""}
                      >
                        <MenuItem value={true}>
                          <Chip label="Active" color="success" size="small" />
                        </MenuItem>
                        <MenuItem value={false}>
                          <Chip label="Inactive" color="error" size="small" />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="details"
                      label="Description"
                      size="small"
                      fullWidth
                      variant="outlined"
                      sx={{
                        borderColor: "#3f51b5",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#007aff", color: "#inherit" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
