import { useForm, Controller } from "react-hook-form";
import{ useEffect, useState } from "react";
import React from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Select,
  MenuItem,
  Button,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Dialog
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EditLandMgmt from "./EditLandMgmt";
import { toast } from "react-toastify";
import axios from "axios";
import InfrastructureAppBar from "../../../modules/navbar/infrastuctureAppBar";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const LandMgmt = () => {
  const { control, handleSubmit, reset, formState: { errors } } = useForm();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedLandId, setSelectedLandId] = useState(null);
  const [landData, setLandData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLandData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await axios.get(`${backendUrl}/Lands`, config);
      setLandData(response.data);
    } catch (error) {
      console.error("Error fetching land data:", error);
    }
  };

  useEffect(() => {
    fetchLandData();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const apiData = {
      totalArea: parseFloat(data.areaCoveredRooms) || 0,
      unit: data.areaUnit || "",
      sheetNo: data.sheetNo || "",
      kittaNo: data.kittaNo || "",
      ownerShip: data.ownership === "yes",
      remarks: data.remarks || "",
    };

    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      await axios.post(`${backendUrl}/Lands`, apiData, config);
      toast.success("Data posted successfully", {
        autoClose: 1500,
      });
      reset();
      fetchLandData();
    } catch (error) {
      toast.error("Error posting data: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleEditClick = (landId) => {
    setSelectedLandId(landId);
    setOpenEditDialog(true);
};



  const handleUpdate = () => {
    fetchLandData();
  };

  return (
    <>
     <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <InfrastructureAppBar/>
      </Box>
     </Box>
    <Grid container spacing={2}>
      <Grid item xs={false} md={1} />
      <Grid item xs={12} md={10} >
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Land Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3} >
                  <Controller
                    name="areaCoveredRooms"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Total Area Covered by Library Rooms is required",
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: "Please enter a valid decimal number",
                      },
                      min: {
                        value: 0.01,
                        message: "Total Area Covered by Library Rooms must be a positive number",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        type="number"
                        size="small"
                        label="Total Area of Land"
                        fullWidth
                        autoComplete="areaCoveredRooms"
                        inputProps={{ step: "0.01", min: "0.01" }} // Allows only positive decimal numbers
                      />
                    )}
                  />
                  {errors.areaCoveredRooms && (
                    <FormHelperText error>{errors.areaCoveredRooms.message}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth size="small" error={!!errors.areaUnit}>
                    <InputLabel required id="area-unit-label">Area Unit</InputLabel>
                    <Controller
                      name="areaUnit"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Area Unit is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                    
                          name="areaUnit"
                          labelId="area-unit-label"
                          label="Area Unit"
                          autoComplete="areaUnit"
                        >
                          <MenuItem value="hector">Hector</MenuItem>
                          <MenuItem value="ropani">Ropani</MenuItem>
                          <MenuItem value="barga meter">barga meter</MenuItem>
                          <MenuItem value="barga feet">barga feet</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.areaUnit && (
                      <FormHelperText>{errors.areaUnit.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Controller
                    name="sheetNo"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        size="small"
                        label="Sheet no."
                        fullWidth
                        autoComplete="sheetNo"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Controller
                    name="kittaNo"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        size="small"
                        label="Kitta no."
                        fullWidth
                        autoComplete="kittaNo"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth size="small" error={!!errors.ownership}>
                    <InputLabel required id="ownership-label">Ownership</InputLabel>
                    <Controller
                      name="ownership"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Ownership is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="ownership-label"
                          label="Ownership"
                          autoComplete="ownership"
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </Select>
                      )}
                    />
                    {errors.ownership && (
                      <FormHelperText>{errors.ownership.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} >
                  <Controller
                    name="remarks"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Remarks"
                        fullWidth
                        autoComplete="remarks"
                        rows={4}
                        InputProps={{ style: { minWidth: '100%' } }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Paper>
        <Grid margin="10px">
           <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Land List
            </Typography> 
            <TableContainer>
              <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
              >
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      S.No
                    </TableCell>


                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Kitta No.
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Total Area (sq. ft) 
                    </TableCell>

                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Area Unit
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Ownership
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Sheet No. of Land
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Remarks
                    </TableCell>

                     
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Action
                    </TableCell>
                    
                  </TableRow>
                </TableHead>

                <TableBody>
                  {landData.length > 0 ? (
                    landData.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{index + 1}</TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.kittaNo}</TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.totalArea}</TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.unit}</TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {data.ownerShip ? "Yes" : "No"}
                        </TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.sheetNo}</TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>{data.remarks}</TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          <Button onClick={() => handleEditClick(data.id)}>
                             <EditNoteIcon /> Edit</Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>


              </Table>
            </TableContainer>

        </Grid>
      </Grid>
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="md">
        <EditLandMgmt id={selectedLandId} onClose={() => setOpenEditDialog(false)} onUpdate={handleUpdate} />
      </Dialog>
    </Grid>
    </>
  );
};

export default LandMgmt;
