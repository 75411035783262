import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="mainfooter">
        <div className="left">
          <div className="othertext">
            <span>About</span>
            <span>Contact</span>
            <span>Terms</span>
            <span>Privacy</span>
          </div>
        </div>
        <div className="right">
          <div className="othertext">
            <span>
              Developed by :{" "}
              <a
                href="www.dibugsoft.com"
                style={{ textDecoration: "underline", color: "white" }}
              >
                DEBUGSOFT
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
