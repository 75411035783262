import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import StudentAppBar from "../../modules/navbar/studentAppBar";

const getDynamicColumns = (ethnicGroups) => {
  const ethnicityColumns = ethnicGroups.map((ethnicity) => ({
    name: ethnicity.name.toLowerCase().replace(/\s+/g, ""), // name -> key (e.g., "Madhesi" -> "madhesi")
    label: ethnicity.name,
    options: { filter: false, sort: false },
  }));

  const staticColumns = [
    { name: "no", label: "S.No.", options: { sort: false, filter: false } },
    {
      name: "district",
      label: "District",
      options: { filter: false, sort: false },
    },
    { name: "male", label: "Male", options: { filter: false, sort: false } },
    {
      name: "female",
      label: "Female",
      options: { filter: false, sort: false },
    },
    { name: "other", label: "Other", options: { filter: false, sort: false } },
    {
      name: "totalGender",
      label: "Total Gender",
      options: { filter: false, sort: false },
    },
    { name: "edj", label: "EDJ", options: { filter: false, sort: false } },
  ];

  return [...staticColumns, ...ethnicityColumns];
};

const CustomHeader = ({ ethnicGroups }) => (
  <thead>
    <tr style={{ backgroundColor: "#2b6eb5", color: "whitesmoke" }}>
      <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>
        S.No.
      </th>
      <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>
        District
      </th>
      <th colSpan="4" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>
        Gender
      </th>
      <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>
        EDJ
      </th>
      <th
        colSpan={ethnicGroups.length}
        style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}
      >
        Caste Ethnicity
      </th>
    </tr>
    <tr style={{ backgroundColor: "#2b6eb5", color: "whitesmoke" }}>
      <th style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Male</th>
      <th style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Female</th>
      <th style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Other</th>
      <th style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Total</th>
      {ethnicGroups.map((ethnicity) => (
        <th
          key={ethnicity.index}
          style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}
        >
          {ethnicity.name}
        </th>
      ))}
    </tr>
  </thead>
);

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const StudentByDistrict = () => {
  const [data, setData] = useState([]);
  const [ethnicGroups, setEthnicGroups] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };

        const response = await axios.get(
          `${backendUrl}/Student/Report/GetStudentByDistrict`,
          config
        );

        if (response.data.length > 0) {
          setEthnicGroups(response.data[0].ethnicGroup);
        }

        const updatedData = response.data.map((student, index) => {
          const ethnicityData = ethnicGroups.reduce((acc, ethnicity) => {
            const ethnicityKey = ethnicity.name
              .toLowerCase()
              .replace(/\s+/g, "");
            acc[ethnicityKey] =
              student.ethnicGroup.find(
                (ethnicityItem) => ethnicityItem.name === ethnicity.name
              )?.count || 0;
            return acc;
          }, {});

          return {
            ...student,
            no: index + 1,
            ...ethnicityData,
          };
        });

        setData(updatedData);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false even on error
      }
    };

    fetchData();
  }, [ethnicGroups]);

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <StudentAppBar />
        </Box>
      </Box>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiTableCell: {
              styleOverrides: {
                head: {
                  backgroundColor: "#2b6eb5",
                  color: "whitesmoke",
                  textTransform: "capitalize",
                  padding: "6px",
                  fontSize: "12px",
                  border: "1px solid #C2C2C2",
                },
                body: {
                  fontSize: "12px",
                  padding: "6px",
                  border: "1px solid #C2C2C2",
                },
              },
            },
          },
        })}
      >
        {loading ? (
          <Typography
            variant="body1"
            style={{ color: "#2b6eb5", padding: "5px", textAlign: "center" }}
          >
            Loading...
          </Typography>
        ) : (
          <MUIDataTable
            title={
              <Typography
                variant="body1"
                style={{ color: "#2b6eb5", padding: "5px", textAlign: "right" }}
              >
                Student By District
              </Typography>
            }
            data={data.length === 0 ? [["No data available"]] : data} // Show "No data available" when no data is fetched
            columns={getDynamicColumns(ethnicGroups)}
            options={{
              filterType: "",
              selectableRows: false,
              responsive: "standard",
              elevation: 0,
              pagination: true,
              search: true,
              searchPlaceholder: "Search...",
              rowsPerPage: 15,
              textLabels: {
                body: {
                  noMatch: "No data available",
                },
              },
            }}
            components={{
              TableHead: () => <CustomHeader ethnicGroups={ethnicGroups} />,
            }}
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default StudentByDistrict;
