import React, { useEffect, useState } from 'react'
import OtherSetupAppBar from '../../../modules/navbar/OtherSetupAppBar'
import { DataGrid, GridOverlay } from '@mui/x-data-grid';

import {
    Grid,
    Typography,
    TextField,
    CardContent,
    Paper,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
} from "@mui/material";
import { getEmployeePosition, postEmployeePosition } from '../../../services/services';
import { toast } from "react-toastify";
import EditPost from './EditPost';


function PostSetup() {
    const [postData, setPostData] = useState([])
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const [formData, setFormData] = useState({
        category: '',
        type: '',
        postName: '',
        index: 0,
        status: true,
        remarks: ''
    });

    useEffect(() => {
        if (formData.category === 'Teaching') {
            setFormData((prev) => ({
                ...prev,
                type: 'Teaching',
            }));
        }
    }, [formData.category]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const fetchData = async () => {
        try {
            const data = await getEmployeePosition()
            setPostData(data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            await postEmployeePosition(formData);
            toast.success("Data added successfully!", {
                autoClose: 2000,
            });
            fetchData();
        } catch (err) {
            toast.error(`Failed to add data${err}`);
        }
    };

    const handleEditClick = (empPostId) => {
        setSelectedItemId(empPostId);
        setOpenEditDialog(true);
    };

    const handleClose = () => {
        setOpenEditDialog(false);
        fetchData()
    };


    const columns = [
        { field: 'sNo', headerName: 'SNo', flex: 0.7 },
        {
            field: 'postTitle',
            headerName: 'Post Title',
            flex: 4,
        },
        {
            field: 'category',
            headerName: 'Category',
            flex: 1.5,
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 3,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
        },
        {
            field: 'index',
            headerName: 'Index',
            flex: 1,
        },
        {
            field: 'Action',
            flex: 2,
            renderHeader: () => (
                <div style={{ textAlign: 'center', fontWeight: '600' }}>
                    Action
                </div>
            ),
            renderCell: (params) => (
                <Box display='flex' justifyContent='space-around' mt='3px'>
                    <Button
                        size='small'
                        color='primary'
                        sx={{ textTransform: 'none' }}
                        onClick={() => handleEditClick(params.row.id)}
                    >
                        Edit
                    </Button>
                </Box>
            )
        }
    ];


    const rows = postData?.sort((a, b) => b.id - a.id).map((item, index) => ({
        sNo: index + 1,
        index:item.index,
        id: item.id,
        postTitle: item.postName,
        type: item.type,
        category: item.category,
        status: item.status === true ? 'Active' : 'Inactive',
    }))
    return (
        <Grid mt={10} container sm={12}>
            <Box mt={8}>
                <OtherSetupAppBar />
            </Box>
            <Grid mx='auto' flex justifyContent='center' item sm={8}>
                <Paper elevation={5} sx={{ borderRadius: "20px" }}>
                    <CardContent>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ textAlign: "center", color: "#2A629A" }}
                        >
                            Employee position Management
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={1}>
                                <Grid item sm={3}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel size='small'>Category</InputLabel>
                                        <Select
                                            size="small"
                                            label="category"
                                            name="category"
                                            value={formData.category}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                        >
                                            <MenuItem value='Teaching'>
                                                Teaching
                                            </MenuItem>
                                            <MenuItem value='Nonteaching'>
                                                Non-teaching
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={3}>
                                    <FormControl fullWidth size="small" >
                                        <InputLabel size="small">Type</InputLabel>
                                        <Select
                                            onChange={handleChange}
                                            value={formData.type}
                                            size="small"
                                            label="Type"
                                            name="type"
                                            fullWidth
                                            defaultValue='Other'
                                            disabled={formData.category === 'Teaching'}
                                        >
                                             <MenuItem value="Teaching" disabled = {formData.category === 'Nonteaching'}>Teaching</MenuItem>
                                            <MenuItem value="Technical">Technical</MenuItem>
                                            <MenuItem value="Administrative">Administrative</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        onChange={handleChange}
                                        required
                                        size="small"
                                        name='index'
                                        type='number'
                                        label="Index"
                                        value={formData.index}
                                        inputProps={{
                                            min:0
                                        }}
                                        fullWidth
                                        autoComplete="given-name"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        name='postName'
                                        value={formData.postName}
                                        required
                                        onChange={handleChange}
                                        size="small"
                                        label="Position Title"
                                        fullWidth

                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            value={formData.status}
                                            size="small"
                                            label="isActive"
                                            name="status"
                                            onChange={handleChange}
                                            fullWidth
                                            defaultValue={true}
                                        >
                                            <MenuItem value={true}>
                                                <span >Active</span>
                                            </MenuItem>
                                            <MenuItem value={false}>
                                                <span style={{ color: "red" }}>InActive</span>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        value={formData.remarks}
                                        size="small"
                                        label="remarks"
                                        name='remarks'
                                        fullWidth
                                        onChange={handleChange}
                                    />
                                </Grid>

                            </Grid>
                            <Grid
                                container
                                style={{
                                    margin: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ backgroundColor: "#007aff", color: "#inherit" }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </form>
                    </CardContent>
                </Paper>

            </Grid>
            <Grid mx='auto' mt={2} justifyContent='center' item sm={10}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    density='compact'
                    pagination
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    disableColumnSelector={true}
                    disableColumnSorting={true}
                    disableDensitySelector={true}
                    columnHeaderHeight={70}
                    showCellVerticalBorder={true}
                    components={{
                        NoRowsOverlay: () => (
                            <GridOverlay>
                                <Typography variant='h5' align='center'>
                                    No rows
                                </Typography>
                            </GridOverlay>
                        ),
                    }}

                    sx={{
                        minHeight: '360px',
                        maxHeight: '464px',
                        '.MuiDataGrid-columnHeader': {
                            backgroundColor: '#0368b0',
                            color: 'white',
                            fontWeight: '40px',
                            borderRight: '1px solid white',

                        },

                        '.MuiDataGrid-footerContainer': {
                            minHeight: '45px',
                        },
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-columnHeader': {
                            fontWeight: '600',
                            fontSize: '14px',
                        },
                        '& .MuiDataGrid-selectedRowCount': {
                            visibility: 'hidden',
                        },
                        width: '100%',
                        marginTop: '0',
                    }}
                />
            </Grid>
            <EditPost id={selectedItemId} open={openEditDialog} handleClose={handleClose} />
        </Grid>
    )
}

export default PostSetup