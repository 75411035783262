import React, { useEffect, useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  FormControl,
  capitalize,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import { blue } from "@mui/material/colors";
import { getVerifiedStudents } from "../../dashboard/services/service";
import { useNavigate } from "react-router-dom";

const ReceiptStudentList = () => {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState([]);
  const [filteredStudentData, setFilteredStudentData] = useState([]);
  const [programType, setProgramType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [levels, setLevels] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYearId] = useState("");
  const [years, setYears] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [semesters, setSemesters] = useState([]);

  const rowsPerPage = 25;
  const fetchData = async () => {
    try {
      const verifiedStudents = await getVerifiedStudents();
      setStudentData(verifiedStudents);
      setFilteredStudentData(verifiedStudents);

      const uniqueLevels = [
        ...new Set(
          verifiedStudents.map((student) => student.levelName).filter(Boolean)
        ),
      ];
      const uniquePrograms = [
        ...new Set(
          verifiedStudents.map((student) => student.programName).filter(Boolean)
        ),
      ];
      const uniqueFiscalYear = [
        ...new Set(
          verifiedStudents.map((student) => student?.fiscalYear).filter(Boolean)
        ),
      ];
      const uniqueSemesters = [
        ...new Set(
          verifiedStudents.map((student) => student.semester).filter(Boolean)
        ),
      ];
      const uniqueYears = [
        ...new Set(
          verifiedStudents.map((student) => student.year).filter(Boolean)
        ),
      ];
      setFiscalYears(uniqueFiscalYear);
      setLevels(uniqueLevels);
      setPrograms(uniquePrograms);
      setSemesters(uniqueSemesters);
      setYears(uniqueYears);
    } catch (err) {
      console.error("Error fetching student data:", err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const filteredData = studentData.filter((student) => {
      const firstName = student.firstName
        ? student.firstName.toLowerCase()
        : "";
      const lastName = student.lastName ? student.lastName.toLowerCase() : "";
      const rollNo = student.rollNo ? student.rollNo.toLowerCase() : "";
      const gender = student.gender ? student.gender.toLowerCase() : "";
      const registeredYear = student.admissionYear ? student.admissionYear : "";
      const semester = student.semester ? student.semester : "";
      const year = student.year ? student.year : "";
      const program = student.programName.toLowerCase();
      const district = student.district ? student.district.toLowerCase() : "";
      const searchTermLower = searchTerm.toLowerCase();

      return (
        (firstName.includes(searchTermLower) ||
          lastName.includes(searchTermLower) ||
          rollNo.includes(searchTermLower) ||
          gender.includes(searchTermLower) ||
          registeredYear.includes(searchTermLower) ||
          semester.includes(searchTermLower) ||
          year.includes(searchTermLower) ||
          program.includes(searchTermLower) ||
          district.includes(searchTermLower)) &&
        (selectedLevel === "" || student.levelName === selectedLevel) &&
        (selectedProgram === "" || student.programName === selectedProgram) &&
        (selectedFiscalYear === "" ||
          student?.fiscalYear === selectedFiscalYear) &&
        (selectedSemester === "" || student.semester === selectedSemester) &&
        (selectedYear === "" || student.year === selectedYear)
      );
    });

    setFilteredStudentData(filteredData);
  }, [
    searchTerm,
    selectedLevel,
    selectedProgram,
    studentData,
    selectedFiscalYear,
    selectedSemester,
    selectedYear,
  ]);
 

  useEffect(() => {
    const relevantPrograms = [
      ...new Set(
        studentData
          .filter(
            (student) =>
              student.levelName === selectedLevel           )
          .map((student) => student.programName)
          .filter(Boolean)
      ),
    ];

    setPrograms(relevantPrograms);
    setSelectedProgram("");
  }, [selectedLevel, studentData]);

  const indexOfLastStudent = page * rowsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - rowsPerPage;
  const currentStudents = filteredStudentData.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleProgramChange = (event) => {
    const selectedProgramName = event.target.value;
    setSelectedProgram(selectedProgramName);
    const selectedProgram = studentData.find(
      (student) => student.programName === selectedProgramName
    );

    if (selectedProgram) {
      setProgramType(selectedProgram.programType);
    }
  };
  const handleExport = (id) => {
    navigate(`/receipt-management/receipt-form/${id}`);
  };

  return (
    <>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Grid
          container
          alignItems="center"
          style={{ marginBottom: "15px" }}
        >
          <Grid item xs={12} sm={6} md={2.3}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search by Students..."
              value={searchTerm}
              sx={{ bgcolor: "whitesmoke" }}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#2b6eb5" }} />
                  </InputAdornment>
                ),
                style: {
                  height: "36px",
                  padding: "0 10px",
                  fontSize: "13px",
                },
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6" style={{ color: blue[700] }} textAlign={'center'}>
              Successfully Enrolled Students
            </Typography>
          </Grid>
        </Grid>
        <TableContainer sx={{ borderRadius: 2 }}>
          <Table>
            <TableHead style={{ backgroundColor: "#2A629A" }}>
              <TableRow>
                <TableCell colSpan={13} style={{ padding: 0 }}>
                  <Grid container spacing={2} padding={1}>
                    <Grid item xs={12} sm={4} md={1.5}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedFiscalYear}
                          onChange={(e) =>
                            setSelectedFiscalYearId(e.target.value)
                          }
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Fiscal Year</MenuItem>
                          {fiscalYears.map((years, index) => (
                            <MenuItem key={index} value={years}>
                              {years}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={1.5}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedLevel}
                          onChange={(e) => setSelectedLevel(e.target.value)}
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Level</MenuItem>
                          {levels.map((level, index) => (
                            <MenuItem key={index} value={level}>
                              {level}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} md={1.5}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedProgram}
                          onChange={handleProgramChange}
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Program</MenuItem>
                          {programs.map((program, index) => (
                            <MenuItem key={index} value={program}>
                              {program}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {programType === "semester" && (
                      <Grid item xs={12} sm={4} md={2}>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={selectedSemester}
                            onChange={(e) =>
                              setSelectedSemester(e.target.value)
                            }
                            displayEmpty
                            sx={{
                              backgroundColor: "whitesmoke",
                              borderColor: "lightgray",
                              borderRadius: 1,
                              width: "150px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "lightgray",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                fontSize: "0.75rem",
                              },
                              "& .MuiSelect-icon": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            <MenuItem value="">Select Semester</MenuItem>
                            {semesters.map((semester, index) => (
                              <MenuItem key={index} value={semester}>
                                {semester} Semester
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {programType === "annual" && (
                      <Grid item xs={12} sm={4} md={2}>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            displayEmpty
                            sx={{
                              backgroundColor: "whitesmoke",
                              borderColor: "lightgray",
                              borderRadius: 1,
                              width: "150px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "lightgray",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                fontSize: "0.75rem",
                              },
                              "& .MuiSelect-icon": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            <MenuItem value="">Select Year</MenuItem>
                            {years.map((year, index) => (
                              <MenuItem key={index} value={year}>
                                {year} Year
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                {[
                  "S.No.",
                  "Full Name",
                  "Gender",
                  "Fiscal Year",
                  "Batch Year",
                  "Semester/Year",
                  "Roll No",
                  "Level",
                  "Faculty",
                  "Program",
                  "Actions",
                ].map((header, index) => (
                  <TableCell
                    key={index}
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                      width:
                        header === "S.No."
                          ? "2%"
                          : header === "Actions"
                          ? "5%"
                          : "auto",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ bgcolor: "white" }}>
              {currentStudents.map((data, index) => (
                <TableRow key={data.id}>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {indexOfFirstStudent + index + 1}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {`${data.firstName ? data.firstName : ""} ${
                      data.middleName ? data.middleName : ""
                    } ${data.lastName ? data.lastName : ""}`}
                  </TableCell>

                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.gender ? capitalize(data.gender) : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.fiscalYear ? data.fiscalYear : ""}
                  </TableCell>

                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.admissionYear ? data.admissionYear : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.year
                      ? `${data.year} year`
                      : `${data.semester} semester`}
                  </TableCell>

                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.rollNo}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.levelName}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.facultyName}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    <Tooltip title={data.programName}>
                      {data.programName}
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    <Button
                                                onClick={() => handleExport(data.id)}

                      // onClick={() =>
                      //   navigate("/receipt-management/receipt-form")
                      // }
                    >
                      Receipt
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
          <Pagination
            count={Math.ceil(filteredStudentData.length / rowsPerPage)}
            page={page}
            shape="rounded"
            onChange={handlePageChange}
          />
        </Grid>
      </div>
    </>
  );
};

export default ReceiptStudentList;
