import React, { useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button, Box, Stack, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { EditCampusInfoContext } from './EditCampusInfo';
import { EditCampusContactContext } from './EditCampusContactDetails';
import axios from 'axios';
import { LoadingOverlay } from "@mantine/core";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const EditCampusReviewDetails = ({ handleBack, id, onClose, handleClose }) => {
  const { campusInfo } = useContext(EditCampusInfoContext);
  const { contactDetails } = useContext(EditCampusContactContext);
  const [loading, setLoading] = useState(false);
  
  const handleRegister = () => {
    setLoading(true);
    setTimeout(async () => {
      const formData = new FormData();
      formData.append("id", campusInfo.id);
      formData.append("universityId", campusInfo.universityId);
      formData.append("typeOfCampus", campusInfo.type);
      formData.append("campusName", campusInfo.name);
      formData.append("code", campusInfo.code);
      formData.append("shortCode", campusInfo.shortCode);
      formData.append("alias", campusInfo.alias);
      formData.append("phone", campusInfo.phone);
      formData.append("email", campusInfo.email);
      formData.append("url", campusInfo.url);
      formData.append("province", campusInfo.province);
      formData.append("district", campusInfo.district);
      formData.append("localLevel", campusInfo.localLevel);
      formData.append("wardNo", campusInfo.wardNo);
      formData.append("Logo", campusInfo.logo || ""); // Append logo as a file
      formData.append("logo", campusInfo.campusLogo || ""); // Append logo as a file
      formData.append("dateOfEstd", contactDetails.dateOfEstd);
      formData.append("yearOfEstd", contactDetails.yearOfEstd);
      formData.append("accreditationStatus", contactDetails.accreditationStatus);
      formData.append("contactName", contactDetails.focalPersonName);
      formData.append("contactPersonPosition", contactDetails.position);
      formData.append("contactPhoneNo", contactDetails.contactNumber);
      formData.append("contactEmail", contactDetails.contactEmail2);
      formData.append("bankName", contactDetails.bankName);
      formData.append("branch", contactDetails.branchName);
      formData.append("accountNo", contactDetails.accountNumber);
      try {
        const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data'
          }
        };
        const response = await axios.patch(`${backendUrl}/Campus/${id}`, formData, config);
        if (response.status === 200) {
          toast.success("University Successfully Updated", {
            autoClose: 2000
          });
        } else {
          throw new Error("Network response was not ok");
        }
        onClose()

      } catch (error) {
        console.error("Failed to Register:", error);
        toast.error("Failed to Register", {
          autoClose: 1500
        });
      } finally {
        setLoading(false);
      }
    }, 1600);

    setTimeout(() => {
      handleClose()
    }, 1500);
  };

  const CampusInfo = campusInfo
    ? [
      { name: "Campus Type:", detail: campusInfo.type },
      { name: "Campus Name:", detail: campusInfo.name },
      { name: "Year of Establishment:", detail: contactDetails.yearOfEstd },
      { name: "Date of Establishment:", detail: contactDetails.dateOfEstd },
      { name: "Affiliated University:", detail: campusInfo.universityId },
      { name: "Campus Code:", detail: campusInfo.code },
      { name: "Phone Number:", detail: campusInfo.phone },
      { name: "Email:", detail: campusInfo.email },
      { name: "url:", detail: campusInfo.url },
      { name: "Short Name:", detail: campusInfo.shortCode },
      { name: "Alias:", detail: campusInfo.alias },
    ]
    : [];

  const contactPerson = contactDetails
    ? [
      {
        name: "Accreditation Status:",
        detail: contactDetails.accreditationStatus,
      },
      { name: "Full Name:", detail: contactDetails.focalPersonName },
      { name: "Contact Number:", detail: contactDetails.contactNumber },
      { name: "Position:", detail: contactDetails.position },
      { name: "Email:", detail: contactDetails.contactEmail },
      { name: "Bank Name:", detail: contactDetails.bankName },
      { name: "Account Number:", detail: contactDetails.accountNumber },
      { name: "Branch:", detail: contactDetails.branchName },
    ]
    : [];

  return (
    <>
      {loading ? (
        <LoadingOverlay
          visible={loading}
          zIndex={100}
          overlayProps={{ radius: "sm", blur: 1 }}
          loaderProps={{ color: "#1976d2", type: "bars" }}
        />
      ) : (
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Box border='1px solid #c2c2c2' padding='0px' borderRadius='10px' position='relative' paddingBottom='5px'>
              <Typography border='1px solid #c2c2c2' borderRadius='10px' fontSize='14px' display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'>
                Campus Details
              </Typography>
              <Grid marginTop='-15px' container paddingLeft='1.5rem' justifyContent="flex-start">
                {CampusInfo.map((details, index) => (
                  <Grid key={index} item xs={3}>
                    <Typography variant="body2" fontSize='14px' gap={1}>{details.name}</Typography>
                    <Typography variant="body2" fontSize='12px' color='#1976d2'>{details.detail}</Typography>
                  </Grid>
                ))}
                <Stack direction='column'>
                  <Typography variant="body2" fontSize='14px' >Address</Typography>
                  <Typography variant="body2" fontSize='12px' color='#1976d2'>
                    {campusInfo &&
                      [
                        campusInfo.province,
                        campusInfo.district,
                        campusInfo.localLevel,
                        campusInfo.wardNo,
                        campusInfo.locality,
                      ].join(" ")}
                  </Typography>
                </Stack>
              </Grid>

            </Box>
            <Grid mt='1.2rem' item xs={12} md={12}>
              <Box border='1px solid #c2c2c2' padding='0px' borderRadius='10px' position='relative' paddingBottom='5px'>
                <Typography border='1px solid #c2c2c2' fontSize='14px' borderRadius='10px' variant="subtitle1" display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'>
                  Contact  Details
                </Typography>
                <Grid marginTop='-15px' container paddingLeft='1.5rem' justifyContent="flex-start">
                  {contactPerson.map((contact, index) => (
                    <Grid item xs={3}>
                      <Typography variant="body2" fontSize='14px' >{contact.name}</Typography>
                      <Typography variant="body2" fontSize='12px' color='#1976d2'>{contact.detail}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box mt={1} mb={2} display="flex" width='100%' justifyContent="flex-end">
            <Button onClick={handleBack} variant='outlined' color='error' type="reset" size="small"
              startIcon={<ChevronLeftRoundedIcon />}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              size="small"
              type="submit"
              sx={{
                marginLeft: "10px",
                position: 'relative',
                pointerEvents: loading ? 'none' : 'auto',
                opacity: loading ? 0.6 : 1,
              }}
              onClick={handleRegister}
              endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ChevronRightRoundedIcon />}

            >
              {loading ? 'Submitting...' : 'Update'}
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default EditCampusReviewDetails;
