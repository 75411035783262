import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Popover,
  Grid,
} from "@mui/material";
import axios from "axios";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  FileDownload,
  InsertDriveFile,
  PictureAsPdf,
} from "@mui/icons-material";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const NumberCampusType = () => {
  const [rangeData, setRangeData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          `${backendUrl}/Dashboard/GenerateCampusTypeReport`,
          config
        );
        const rankWiseData = response.data;
        setRangeData(rankWiseData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // To handle missing or incomplete range data
  const getRangeCount = (rangeCounts, rangeLabel) => {
    const range = rangeCounts.find((item) => item.range === rangeLabel);
    return range ? range.count : 0;
  };

  const exportToExcel = () => {
    const header = [
      [
        "S.No.",
        "University",
        "<=100",
        "101-200",
        "201-500",
        "501-1000",
        "1001-2000",
        ">2000",
      ],
    ];

    const data = rangeData.map((item, index) => [
      index + 1,
      item.type.charAt(0).toUpperCase() + item.type.slice(1), // Capitalizing type (e.g., 'constituent', 'private')
      ...[
        "<=100",
        ">100 and <=200",
        ">200 and <=500",
        ">500 and <=1000",
        ">1000 and <=2000",
        ">2000",
      ].map((range) => getRangeCount(item.rangeCounts, range)),
    ]);
    const grandTotalRow = [
      "Grand Total",
      "",
      ...[
        "<=100",
        ">100 and <=200",
        ">200 and <=500",
        ">500 and <=1000",
        ">1000 and <=2000",
        ">2000",
      ].map((range) => {
        return rangeData.reduce(
          (sum, item) => sum + getRangeCount(item.rangeCounts, range),
          0
        );
      }),
    ];

    const ws = XLSX.utils.aoa_to_sheet([...header, ...data, grandTotalRow]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Campus Type Report");
    XLSX.writeFile(wb, "campus_type_report.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const columns = [
      "S.No.",
      "University",
      "<=100",
      "101-200",
      "201-500",
      "501-1000",
      "1001-2000",
      ">2000",
    ];

    const data = rangeData.map((item, index) => [
      index + 1,
      item.type.charAt(0).toUpperCase() + item.type.slice(1), // Capitalizing type
      ...[
        "<=100",
        ">100 and <=200",
        ">200 and <=500",
        ">500 and <=1000",
        ">1000 and <=2000",
        ">2000",
      ].map((range) => getRangeCount(item.rangeCounts, range)),
    ]);

    const grandTotalRow = [
      "Grand Total",
      "",
      ...[
        "<=100",
        ">100 and <=200",
        ">200 and <=500",
        ">500 and <=1000",
        ">1000 and <=2000",
        ">2000",
      ].map((range) => {
        return rangeData.reduce(
          (sum, item) => sum + getRangeCount(item.rangeCounts, range),
          0
        );
      }),
    ];

    doc.autoTable(columns, [...data, grandTotalRow]);
    doc.save("teachers_by_contract.pdf");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  return (
    <Box sx={{ padding: "5px" }}>
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: "10px" }}
        >
          Export
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#5FAD41" }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: "10px" }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#272727" }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
      </Grid>
      
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table>
          {/* Header */}
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow style={{ color: "white" }}>
              <TableCell
                align="center"
                sx={{
                  fontSize: "0.9rem",
                  color: "#ffffff",
                  border: "1px solid #c2c2c2",
                }}
              >
                Range
              </TableCell>
              {[
                "<=100",
                ">100 and <=200",
                ">200 and <=500",
                ">500 and <=1000",
                ">1000 and <=2000",
                ">2000",
              ].map((range, idx) => (
                <TableCell
                  key={idx}
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    color: "#ffffff",
                    fontSize: "0.9rem",
                    border: "1px solid #c2c2c2",
                  }}
                >
                  {range}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            {rangeData.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell
                  sx={{
                    fontSize: "0.9rem",
                    border: "1px solid #5E686D",
                  }}
                >
                  {item.type.charAt(0).toUpperCase() + item.type.slice(1)}{" "}
                  {/* Capitalize the type */}
                </TableCell>
                {[
                  "<=100",
                  ">100 and <=200",
                  ">200 and <=500",
                  ">500 and <=1000",
                  ">1000 and <=2000",
                  ">2000",
                ].map((range, rangeIdx) => (
                  <TableCell
                    key={rangeIdx}
                    align="center"
                    sx={{
                      border: "1px solid #5E686D",
                      fontSize: "0.9rem",
                    }}
                  >
                    {getRangeCount(item.rangeCounts, range)}
                  </TableCell>
                ))}
              </TableRow>
            ))}

            {/* Total Row */}
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  border: "1px solid #5E686D",
                  backgroundColor: "#c2c2c2",
                }}
              >
                Total
              </TableCell>
              {[
                "<=100",
                ">100 and <=200",
                ">200 and <=500",
                ">500 and <=1000",
                ">1000 and <=2000",
                ">2000",
              ].map((range, idx) => {
                const total = rangeData.reduce(
                  (sum, item) => sum + getRangeCount(item.rangeCounts, range),
                  0
                );
                return (
                  <TableCell
                    key={idx}
                    align="center"
                    sx={{
                      border: "1px solid #5E686D",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                      backgroundColor: "#c2c2c2",
                    }}
                  >
                    {total}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default NumberCampusType;
