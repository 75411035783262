import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Button,
    Popover,
    Grid
} from "@mui/material";
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";

const testData = [
    {
        level: 'Bachelor',
        faculties: [
            {
                faculty: 'Management',
                programs: [
                    {
                        program: 'BBA',
                        appearedMale: 4,
                        appearedFemale: 2,
                        appearedOther: 0,
                        appearedTotal: 6,
                        passedMale: 3,
                        passedFemale: 2,
                        passedOther: 0,
                        passedTotal: 5
                    },
                    {
                        program: 'BIM',
                        appearedMale: 0,
                        appearedFemale: 0,
                        appearedOther: 0,
                        appearedTotal: 0,
                        passedMale: 0,
                        passedFemale: 0,
                        passedOther: 0,
                        passedTotal: 0
                    },
                ]
            },
            {
                faculty: 'Science',
                programs: [
                    {
                        program: 'BSc',
                        appearedMale: 5,
                        appearedFemale: 3,
                        appearedOther: 0,
                        appearedTotal: 8,
                        passedMale: 4,
                        passedFemale: 3,
                        passedOther: 0,
                        passedTotal: 7
                    },
                    {
                        program: 'BSc in Computer Science',
                        appearedMale: 2,
                        appearedFemale: 4,
                        appearedOther: 0,
                        appearedTotal: 6,
                        passedMale: 1,
                        passedFemale: 3,
                        passedOther: 0,
                        passedTotal: 4
                    },
                ]
            },
        ]
    },
    {
        level: 'Masters',
        faculties: [
            {
                faculty: 'Management',
                programs: [
                    {
                        program: 'MBA',
                        appearedMale: 3,
                        appearedFemale: 5,
                        appearedOther: 1,
                        appearedTotal: 9,
                        passedMale: 2,
                        passedFemale: 4,
                        passedOther: 0,
                        passedTotal: 6
                    },
                    {
                        program: 'MBS',
                        appearedMale: 2,
                        appearedFemale: 3,
                        appearedOther: 0,
                        appearedTotal: 5,
                        passedMale: 1,
                        passedFemale: 2,
                        passedOther: 0,
                        passedTotal: 3
                    },
                ]
            },
            {
                faculty: 'Arts',
                programs: [
                    {
                        program: 'MA',
                        appearedMale: 4,
                        appearedFemale: 2,
                        appearedOther: 0,
                        appearedTotal: 6,
                        passedMale: 3,
                        passedFemale: 1,
                        passedOther: 0,
                        passedTotal: 4
                    },
                    {
                        program: 'MA in History',
                        appearedMale: 0,
                        appearedFemale: 0,
                        appearedOther: 0,
                        appearedTotal: 0,
                        passedMale: 0,
                        passedFemale: 0,
                        passedOther: 0,
                        passedTotal: 0
                    },
                ]
            },
        ]
    },
    {
        level: 'PGD',
        faculties: [
            {
                faculty: 'Management',
                programs: [
                    {
                        program: 'PGD in Management',
                        appearedMale: 1,
                        appearedFemale: 1,
                        appearedOther: 0,
                        appearedTotal: 2,
                        passedMale: 1,
                        passedFemale: 1,
                        passedOther: 0,
                        passedTotal: 2
                    },
                ]
            },
        ]
    },
];

const StudentPassRateByProgram = () => {
    const [selectedFaculty, setSelectedFaculty] = useState("All");
    const [selectedLevel, setSelectedLevel] = useState("All");
    const [anchorEl, setAnchorEl] = useState(null);
    const [apiData, setApiData] = useState([]);

    useEffect(() => {
        setApiData(testData)
    }, [])
    const rows = apiData.flatMap((item) => {
        return item.faculties.flatMap((faculty) => {
            return faculty.programs.map((program) => ({
                level: item.level,
                faculty: faculty.faculty,
                program: program.program,

                appearedMale: program.appearedMale,
                appearedFemale: program.appearedFemale,
                appearedOther: program.appearedOther,
                appearedTotal: program.appearedTotal,

                passedMale: program.passedMale,
                passedFemale: program.passedFemale,
                passedOther: program.passedOther,
                passedTotal: program.passedTotal,
                passPercentage: program.appearedTotal > 0 ? ((program.passedTotal/program.appearedTotal)*100).toFixed(2):'0.00'
            }));
        });
    });

    const facultyNames = [...new Set(rows.map(item => item.faculty))];
    const levelNames = [...new Set(rows.map(item => item.level))];
    const filteredRows = rows.filter((row) => {
        return (
            (selectedFaculty === "All" || row.faculty === selectedFaculty) &&
            (selectedLevel === "All" || row.level === selectedLevel)
        );
    });

    const totals = filteredRows.reduce(
        (acc, curr) => {
            // Accumulate the total values for appeared and passed students
            acc.appearedMale += curr.appearedMale;
            acc.appearedFemale += curr.appearedFemale;
            acc.appearedOther += curr.appearedOther;
            acc.appearedTotal += curr.appearedTotal;
    
            acc.passedMale += curr.passedMale;
            acc.passedFemale += curr.passedFemale;
            acc.passedOther += curr.passedOther;
            acc.passedTotal += curr.passedTotal;
    
            return acc;
        },
        {
            appearedMale: 0,
            appearedFemale: 0,
            appearedOther: 0,
            appearedTotal: 0,
    
            passedMale: 0,
            passedFemale: 0,
            passedOther: 0,
            passedTotal: 0,
        }
    );
    
    // Calculate pass percentage after reducing totals
    const passPercentage = totals.appearedTotal > 0 
        ? ((totals.passedTotal / totals.appearedTotal) * 100).toFixed(2)
        : '0.00';
    
    totals.passPercentage = passPercentage;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const header = [
            [
                "S.No.",
                "Faculty",
                "Mountain-Male",
                "Mountain-Female",
                "Mountain-Other",
                "Mountain-Total",
                "Hill-Male",
                "Hill-Female",
                "Hill-Other",
                "Hill-Total",
                "Terai-Male",
                "Terai-Female",
                "Terai-Other",
                "Terai-Total",
                "Total",
            ],
        ];
        XLSX.utils.sheet_add_aoa(worksheet, header, { origin: "A1" });
        rows.forEach((row, index) => {
            const rowData = [
                index + 1,
                row.campusType,
                row.mountainMale,
                row.mountainFemale,
                row.mountainOther,
                row.mountainTotal,
                row.hillMale,
                row.hillFemale,
                row.hillOther,
                row.hillTotal,
                row.teraiMale,
                row.teraiFemale,
                row.teraiOther,
                row.teraiTotal,
                row.mountainTotal + row.hillTotal + row.teraiTotal,
            ];
            XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${index + 2}` });
        });
        const totalsRow = [
            "",
            "Total",
            totals.mountainMale,
            totals.mountainFemale,
            totals.mountainOther,
            totals.mountainTotal,
            totals.hillMale,
            totals.hillFemale,
            totals.hillOther,
            totals.hillTotal,
            totals.teraiMale,
            totals.teraiFemale,
            totals.teraiOther,
            totals.teraiTotal,
            totals.grandTotal,
        ];
        XLSX.utils.sheet_add_aoa(worksheet, [totalsRow], { origin: `A${rows.length + 2}` });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "CampusTypeByFaculty");
        XLSX.writeFile(workbook, "CampusTypeByFaculty.xlsx");
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? "export-popover" : undefined;
    return (
        <div>
            <Box sx={{ marginBottom: "0px", display: "flex", gap: 2 }}>
                <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
                    <InputLabel>Select Level</InputLabel>
                    <Select
                        size="small"
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: 2,
                        }}
                        value={selectedLevel}
                        onChange={(e) => setSelectedLevel(e.target.value)}
                        label="Select Level"
                    >
                        <MenuItem value="All">All Level</MenuItem>
                        {
                            levelNames.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
                    <InputLabel>Select Faculty</InputLabel>
                    <Select
                        size="small"
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: 2,
                        }}
                        value={selectedFaculty}
                        onChange={(e) => setSelectedFaculty(e.target.value)}
                        label="Select Faculty"
                    >
                        <MenuItem value="All">All Faculty</MenuItem>
                        {
                            facultyNames.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))

                        }
                    </Select>
                </FormControl>
                <Grid container justifyContent="right">
                    <Button

                        variant="contained"
                        color="primary"
                        startIcon={<FileDownload />}
                        onClick={handleClick}
                        style={{ marginBottom: '10px' }}
                    >
                        Export
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ padding: '10px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ backgroundColor: '#5FAD41' }}
                                startIcon={<InsertDriveFile />}
                                onClick={exportToExcel}
                                fullWidth
                                style={{ marginBottom: '10px' }}
                            >
                                Excel
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: '#272727' }}
                                startIcon={<PictureAsPdf />}
                                // onClick={exportToPDF}
                                fullWidth
                            >
                                PDF
                            </Button>
                        </Box>
                    </Popover>
                </Grid>
            </Box>
            <TableContainer sx={{ border: "1px solid #ddd" }}>
                <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
                    <TableHead style={{ backgroundColor: "#2A629A" }}>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                rowSpan={2}
                            >
                                S.No.
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                rowSpan={2}
                            >
                                Program
                            </TableCell>

                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                colSpan={4}
                            >
                                Total Appeared students in Exam
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                colSpan={4}
                            >
                                Total passed Students
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                rowSpan={2}
                            >
                                Pass Percent
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Male
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Female
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Others
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Male
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Female
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Others
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredRows.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {index + 1}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "left",
                                        }}
                                    >
                                        {item.program}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {item.appearedMale}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {item.appearedFemale}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {item.appearedOther}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {item.appearedTotal}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {item.passedMale}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {item.passedFemale}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {item.passedOther}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {item.passedTotal}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                       {item.passPercentage}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                          <TableRow>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "center",
                                        }}
                                        colSpan={2}
                                    >
                                        Grand Total
                                    </TableCell>
                                   
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {totals.appearedMale}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {totals.appearedFemale}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {totals.appearedOther}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {totals.appearedTotal}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {totals.passedMale}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {totals.passedFemale}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {totals.passedOther}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {totals.passedTotal}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            border: "1px solid #c2c2c2",
                                            padding: "4px",
                                            textAlign: "right",
                                        }}
                                    >
                                       {totals.passPercentage}
                                    </TableCell>
                                </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default StudentPassRateByProgram;