import React, { useRef, useState } from 'react';
import { DndContext, useDroppable } from '@dnd-kit/core';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';

const ImageEditor = ({ onImageChange, name, placeholder }) => {
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);
  const inputRef = useRef(null);
  const { setNodeRef } = useDroppable({ id: 'droppable' });

  const handleDrop = (event) => {
    event.preventDefault();
    setUploading(true);
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length > 0) {
      const newImage = droppedFiles[0]; 
      setImage(newImage);
      if (onImageChange) onImageChange(newImage);
    }
    setUploading(false);
  };

  const handleInputChange = (event) => {
    setUploading(true);
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length > 0) {
      const newImage = selectedFiles[0]; 
      setImage(newImage);
      const formData = new FormData();
      formData.append(name, newImage); // Append the actual file to FormData
      if (onImageChange) onImageChange(formData);
      // if (onImageChange) onImageChange(newImage);
    }
    setUploading(false);
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleRemoveImage = () => {
    setImage(null);
    if (onImageChange) onImageChange(null);
  };

  return (
    <div>
      <DndContext>
        <div
          ref={setNodeRef}
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
          onClick={handleClick}
          style={{
            border: '1px dashed #ccc',
            padding: '5px',
            textAlign: 'center',
            cursor: 'pointer',
            marginBottom: '5px',
          }}
        >
          {placeholder}
        </div>
      </DndContext>
      <input
        name={name}
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={handleInputChange}
        style={{ display: 'none' }}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {image && (
          <div style={{ position: 'relative', width: '60px', height: '60px' }}>
            <img
              src={URL.createObjectURL(image)}
              alt="preview"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
            <IconButton
              size="small"
              onClick={handleRemoveImage}
              style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        )}
        {uploading && (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageEditor;