// 3.9 Report
import {
    Box,
    Button,
    Grid,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect } from "react";
import React, { useState } from "react";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fetchEnrollmentReportByCampusType } from "../reportApi";

const TypeEcobelt = ({ fiscalId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [apiData, setApiData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchEnrollmentReportByCampusType(fiscalId);
                if (response && typeof response === 'object') {
                    setApiData(response);
                } else {
                    console.error("Invalid response format", response);
                    setApiData({});
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [fiscalId]);


    const rows = Object.keys(apiData)
        .filter(key => key !== "total" && key !== "percentageOfStudent")
        .map((campusType, index) => {
            const campusData = apiData[campusType];
            return {
                id: index + 1,
                campusType: campusType,
                mountainMale: campusData.mountain.male,
                mountainFemale: campusData.mountain.female,
                mountainOther: campusData.mountain.other,
                mountainTotal: campusData.mountain.total,
                hillMale: campusData.hill.male,
                hillFemale: campusData.hill.female,
                hillOther: campusData.hill.other,
                hillTotal: campusData.hill.total,
                teraiMale: campusData.tarai.male,
                teraiFemale: campusData.tarai.female,
                teraiOther: campusData.tarai.other,
                teraiTotal: campusData.tarai.total
            };
        });

    const totals = rows.reduce(
        (acc, row) => {
            acc.mountainMale += row.mountainMale;
            acc.mountainFemale += row.mountainFemale;
            acc.mountainOther += row.mountainOther;
            acc.mountainTotal += row.mountainTotal;
            acc.hillMale += row.hillMale;
            acc.hillFemale += row.hillFemale;
            acc.hillOther += row.hillOther;
            acc.hillTotal += row.hillTotal;
            acc.teraiMale += row.teraiMale;
            acc.teraiFemale += row.teraiFemale;
            acc.teraiOther += row.teraiOther;
            acc.teraiTotal += row.teraiTotal;
            acc.grandTotal += row.mountainTotal + row.hillTotal + row.teraiTotal;

            return acc;
        },
        {
            mountainMale: 0,
            mountainFemale: 0,
            mountainOther: 0,
            mountainTotal: 0,
            hillMale: 0,
            hillFemale: 0,
            hillOther: 0,
            hillTotal: 0,
            teraiMale: 0,
            teraiFemale: 0,
            teraiOther: 0,
            teraiTotal: 0,
            grandTotal: 0,
        }
    );

    function capitalizeFirstLetter(val) {
        return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const header = [
            [
                "S.No.",
                "Faculty",
                "Mountain-Male",
                "Mountain-Female",
                "Mountain-Other",
                "Mountain-Total",
                "Hill-Male",
                "Hill-Female",
                "Hill-Other",
                "Hill-Total",
                "Terai-Male",
                "Terai-Female",
                "Terai-Other",
                "Terai-Total",
                "Grand Total",
            ],
        ];
        XLSX.utils.sheet_add_aoa(worksheet, header, { origin: "A1" });
        rows.forEach((row, index) => {
            const rowData = [
                index + 1,
                row.campusType,
                row.mountainMale,
                row.mountainFemale,
                row.mountainOther,
                row.mountainTotal,
                row.hillMale,
                row.hillFemale,
                row.hillOther,
                row.hillTotal,
                row.teraiMale,
                row.teraiFemale,
                row.teraiOther,
                row.teraiTotal,
                row.mountainTotal + row.hillTotal + row.teraiTotal,
            ];
            XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${index + 2}` });
        });
        const totalsRow = [
            "Total",
            "",
            totals.mountainMale,
            totals.mountainFemale,
            totals.mountainOther,
            totals.mountainTotal,
            totals.hillMale,
            totals.hillFemale,
            totals.hillOther,
            totals.hillTotal,
            totals.teraiMale,
            totals.teraiFemale,
            totals.teraiOther,
            totals.teraiTotal,
            totals.grandTotal,
        ];
        XLSX.utils.sheet_add_aoa(worksheet, [totalsRow], { origin: `A${rows.length + 2}` });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "CampusTypeByFaculty");
        XLSX.writeFile(workbook, "CampusTypeByFaculty.xlsx");
        handleClose();
    };
    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.setFont("helvetica", "normal");
        doc.text("Campus Type By Faculty", 12, 14);
    
        const headers = [
            [
                "S.No.",
                "Faculty",
                "Mountain-M",
                "Mountain-F",
                "Mountain-other",
                "Total",
                "Hill-M",
                "Hill-F",
                "Hill-other",
                "Total",
                "Terai-M",
                "Terai-F",
                "Terai-other",
                "Total",
                "Grand Total",
            ],
        ];
    
        const data = rows.map((row, index) => [
            index + 1,
            row.campusType,
            row.mountainMale,
            row.mountainFemale,
            row.mountainOther,
            row.mountainTotal,
            row.hillMale,
            row.hillFemale,
            row.hillOther,
            row.hillTotal,
            row.teraiMale,
            row.teraiFemale,
            row.teraiOther,
            row.teraiTotal,
            row.mountainTotal + row.hillTotal + row.teraiTotal,
        ]);
        const totalsRow = [
            "",
            "Total",
            totals.mountainMale,
            totals.mountainFemale,
            totals.mountainOther,
            totals.mountainTotal,
            totals.hillMale,
            totals.hillFemale,
            totals.hillOther,
            totals.hillTotal,
            totals.teraiMale,
            totals.teraiFemale,
            totals.teraiOther,
            totals.teraiTotal,
            totals.grandTotal,
        ];
        data.push(totalsRow);
        doc.autoTable({
            head: headers,
            body: data,
            startY: 30,
            styles: {
                lineColor: "#c2c2c2", 
                lineWidth: 0.2,       
                cellPadding: 1,       
            },
            headStyles: {
               fontSize:6
            },
            bodyStyles: {
               fontSize:6
            },
        });
        doc.save("CampusTypeByFaculty.pdf");
        handleClose();
    };
    
    const open = Boolean(anchorEl);
    const id = open ? "export-popover" : undefined;
    return (
        <div>
            <Grid container justifyContent="right">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownload />}
                    onClick={handleClick}
                    style={{ marginBottom: '10px' }}
                >
                    Export
                </Button>

                {/* Popover for Excel and PDF options */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ padding: '10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ backgroundColor: '#5FAD41' }}
                            startIcon={<InsertDriveFile />}
                            onClick={exportToExcel}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        >
                            Excel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: '#272727' }}
                            startIcon={<PictureAsPdf />}
                            onClick={exportToPDF}
                            fullWidth
                        >
                            PDF
                        </Button>
                    </Box>
                </Popover>
            </Grid>
            <TableContainer sx={{ border: "1px solid #ddd" }}>
                <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
                    <TableHead style={{ backgroundColor: "#2A629A" }}>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                rowSpan={3}
                            >
                                S.No.
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                rowSpan={3}
                            >
                                Campus Type
                            </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                colSpan={4}
                            >
                                Mountain
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                colSpan={4}
                            >
                                Hill
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                colSpan={4}
                            >
                                Terai
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                rowSpan={2}
                            >
                                Total
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Male
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Female
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Other
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>

                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Male
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Female
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Other
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Male
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Female
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Other
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "center",
                                    }}
                                >
                                    {index + 1}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "left",
                                    }}
                                >
                                    {capitalizeFirstLetter(row.campusType)}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.mountainMale}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.mountainFemale}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.mountainOther}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.mountainTotal}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.hillMale}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.hillFemale}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.hillOther}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.hillTotal}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.teraiMale}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.teraiFemale}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.teraiOther}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.teraiTotal}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                        textAlign: "right",
                                    }}
                                >
                                    {row.mountainTotal + row.hillTotal + row.teraiTotal}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow sx={{ backgroundColor: "#c2c2c2" }}>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                colSpan={2}
                            >
                                Grand Total
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.mountainMale}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.mountainFemale}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.mountainOther}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.mountainTotal}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.hillMale}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.hillFemale}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.hillOther}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.hillTotal}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.teraiMale}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.teraiFemale}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.teraiOther}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.teraiTotal}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.grandTotal}
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
        </div>
    );
};

export default TypeEcobelt;
