// import React, { useEffect, useState } from "react";
// import MUIDataTable from "mui-datatables";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { Box, Typography } from "@mui/material";
// import axios from "axios";
// import StudentAppBar from "../../modules/navbar/studentAppBar";

// const columns = [
//   { name: "no", label: "S.No.", options: { sort: false, filter: false } },
//   {
//     name: "campus",
//     label: "Campus Name",
//     options: { sort: false, filter: false },
//   },
//   { name: "level", label: "Level", options: { filter: true, sort: false } },
//   { name: "faculty", label: "Faculty", options: { filter: true, sort: false } },
//   { name: "program", label: "Program", options: { filter: true, sort: false } },
//   { name: "male", label: "Male", options: { filter: false, sort: false } },
//   { name: "female", label: "Female", options: { filter: false, sort: false } },
//   { name: "other", label: "Other", options: { filter: false, sort: false } },

//   {
//     name: "totalGender",
//     label: "Total Gender",
//     options: { filter: false, sort: false },
//   },
//   { name: "edj", label: "EDJ", options: { filter: false, sort: false } },
//   {
//     name: "madhesi",
//     label: "Madhesi",
//     options: { filter: false, sort: false },
//   },
//   {
//     name: "janjati",
//     label: "Janajati",
//     options: { filter: false, sort: false },
//   },
//   { name: "tharu", label: "Tharu", options: { filter: false, sort: false } },
//   { name: "dalit", label: "Dalit", options: { filter: false, sort: false } },
//   {
//     name: "totalEthnicity",
//     label: "Total Caste",
//     options: { filter: false, sort: false },
//   },
// ];

// const options = {
//   filterType: "",
//   selectableRows: false,
//   responsive: "standard",
//   elevation: 0,
//   pagination: true,
//   search: true,
//   searchPlaceholder: "Search...",
//   searchProps: {
//     style: {
//       fontSize: 14,
//       padding: 4,
//       borderRadius: 4,
//       // border: '1px solid #ccc',
//       width: 6,
//     },
//     inputProps: {
//       placeholder: "Search...",
//       style: {
//         fontSize: 14,
//         padding: 4,
//       },
//     },
//   },
//   print: true,
//   download: true,
//   viewColumns: false,
//   rowsPerPage: 15,
// };

// // const getMuiTheme = () =>
// //   createTheme({
// //     components: {
// //       MuiTableCell: {
// //         styleOverrides: {
// //           head: {
// //             backgroundColor: '#2b6eb5',
// //             color: 'whitesmoke',
// //             textTransform: 'capitalize',
// //             padding: '6px',
// //             fontSize: '14px',
// //             border: '2px solid #2b6eb5',
// //           },
// //           body: {
// //             fontSize: '14px',
// //             padding: '6px',
// //             border: '1px solid #C2C2C2',
// //           },
// //         },
// //       },
// //     },
// //   });
// const getMuiTheme = () =>
//   createTheme({
//     components: {
//       MuiTableCell: {
//         styleOverrides: {
//           head: {
//             backgroundColor: "#2b6eb5",
//             color: "whitesmoke",
//             textTransform: "capitalize",
//             padding: "6px",
//             fontSize: "12px",
//             border: "1px solid #C2C2C2",
//           },
//           body: {
//             fontSize: "12px",
//             padding: "6px",
//             border: "1px solid #C2C2C2",
//           },
//           root: {
//             justifyContent: "center",
//             "& input": {
//               textAlign: "center",
//             },
//             padding: "0px",
//           },
//         },
//       },
//     },
//   });

// const CustomHeader = () => {
//   return (
//     <thead>
//       <tr style={{ backgroundColor: "#2b6eb5", color: "whitesmoke" }}>
//         <th
//           rowSpan="2"
//           style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}
//         >
//           S.No.
//         </th>
//         <th
//           rowSpan="2"
//           style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}
//         >
//           Campus Name
//         </th>
//         <th
//           rowSpan="2"
//           style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}
//         >
//           Level
//         </th>
//         <th
//           rowSpan="2"
//           style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}
//         >
//           Faculty
//         </th>
//         <th
//           rowSpan="2"
//           style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}
//         >
//           Program
//         </th>
//         <th
//           colSpan="4"
//           style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}
//         >
//           Gender
//         </th>
//         <th
//           rowSpan="2"
//           style={{
//             border: "1px solid #c2c2c2",
//             fontSize: "14px",
//             padding: "5px",
//           }}
//         >
//           EDJ
//         </th>
//         <th
//           colSpan="5"
//           style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}
//         >
//           Caste Ethnicity
//         </th>
//       </tr>
//       <tr style={{ backgroundColor: "#2b6eb5", color: "whitesmoke" }}>
//         <th
//           style={{
//             border: "1px solid #c2c2c2",
//             padding: "0px",
//             backgroundColor: "#2b6eb5",
//             color: "#FFFFFF",
//             fontSize: "14px",
//           }}
//         >
//           Male
//         </th>
//         <th
//           style={{
//             border: "1px solid #c2c2c2",
//             padding: "4px",
//             backgroundColor: "#2b6eb5",
//             color: "#FFFFFF",
//             fontSize: "14px",
//           }}
//         >
//           Female
//         </th>
//         <th
//           style={{
//             border: "1px solid #c2c2c2",
//             padding: "4px",
//             backgroundColor: "#2b6eb5",
//             color: "#FFFFFF",
//             fontSize: "14px",
//           }}
//         >
//           Other
//         </th>
//         <th
//           style={{
//             border: "1px solid #c2c2c2",
//             padding: "4px",
//             backgroundColor: "#2b6eb5",
//             color: "#FFFFFF",
//             fontSize: "14px",
//           }}
//         >
//           Total
//         </th>
//         <th
//           style={{
//             border: "1px solid #c2c2c2",
//             padding: "4px",
//             backgroundColor: "#2b6eb5",
//             color: "#FFFFFF",
//             fontSize: "14px",
//           }}
//         >
//           Madhesi
//         </th>
//         <th
//           style={{
//             border: "1px solid #c2c2c2",
//             padding: "4px",
//             backgroundColor: "#2b6eb5",
//             color: "#FFFFFF",
//             fontSize: "14px",
//           }}
//         >
//           Janajati
//         </th>
//         <th
//           style={{
//             border: "1px solid #c2c2c2",
//             padding: "4px",
//             backgroundColor: "#2b6eb5",
//             color: "#FFFFFF",
//             fontSize: "14px",
//           }}
//         >
//           Tharu
//         </th>
       
//         <th
//           style={{
//             border: "1px solid #c2c2c2",
//             padding: "4px",
//             backgroundColor: "#2b6eb5",
//             color: "#FFFFFF",
//             fontSize: "14px",
//           }}
//         >
//           Dalit
//         </th>
//         <th
//           style={{
//             border: "1px solid #c2c2c2",
//             padding: "4px",
//             backgroundColor: "#2b6eb5",
//             color: "#FFFFFF",
//             fontSize: "14px",
//           }}
//         >
//           Total
//         </th>
//       </tr>
//     </thead>
//   );
// };
// const backendUrl = process.env.REACT_APP_BACKEND_URL;

// const StudentSummary = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const localStorageData = JSON.parse(
//           localStorage.getItem("persist:root")
//         );
//         const userState = JSON.parse(localStorageData.user);
//         const authToken = userState.currentUser.tokenString;
//         const config = {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//             "Content-Type": "application/json",
//           },
//         };
//         const response = await axios.get(
//           `${backendUrl}/Student/Report/GetStudentSummary`,
//           config
//         );
//         const updatedData = response.data.map((student, index) => ({
//           ...student,
//           no: index + 1,

//         }));
//         setData(updatedData);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []);
//   return (
//     <>
//       <Box
//         sx={{
//           margin: 0,
//           padding: 0,
//           height: "100%",
//           boxSizing: "border-box",
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "space-between",
//         }}
//       >
//         <Box mt={8}>
//           <StudentAppBar />
//         </Box>
//       </Box>
//       <ThemeProvider theme={getMuiTheme()}>
//         <MUIDataTable
//           title={
//             <Typography
//               variant="body1"
//               style={{ color: "#2b6eb5", padding: "5px", textAlign: "right" }}
//             >
//               Student Summary
//             </Typography>
//           }
//           data={data}
//           columns={columns}
//           options={options}
//           components={{
//             TableHead: CustomHeader,
//           }}
//         />
//       </ThemeProvider>
//     </>
//   );
// };

// export default StudentSummary;


import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import StudentAppBar from "../../modules/navbar/studentAppBar";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Utility function to get dynamic columns based on ethnic groups
const getDynamicColumns = (ethnicGroups) => {
  const ethnicityColumns = ethnicGroups.map((ethnicity) => ({
    name: ethnicity.name.toLowerCase().replace(/\s+/g, ""), // name -> key (e.g., "Madhesi" -> "madhesi")
    label: ethnicity.name,
    options: { filter: false, sort: false },
  }));

  // Static columns
  const staticColumns = [
    { name: "no", label: "S.No.", options: { sort: false, filter: false } },
    { name: "campus", label: "Campus Name", options: { sort: false, filter: false } },
    { name: "level", label: "Level", options: { filter: true, sort: false } },
    { name: "faculty", label: "Faculty", options: { filter: true, sort: false } },
    { name: "program", label: "Program", options: { filter: true, sort: false } },
    { name: "male", label: "Male", options: { filter: false, sort: false } },
    { name: "female", label: "Female", options: { filter: false, sort: false } },
    { name: "other", label: "Other", options: { filter: false, sort: false } },
    { name: "totalGender", label: "Total Gender", options: { filter: false, sort: false } },
    { name: "edj", label: "EDJ", options: { filter: false, sort: false } },
  ];

  // Return the combined columns (static + dynamic ethnicity columns)
  return [...staticColumns, ...ethnicityColumns];
};

// Custom Header to display dynamic ethnicity columns
const CustomHeader = ({ ethnicGroups }) => {
  return (
    <thead>
      <tr style={{ backgroundColor: "#2b6eb5", color: "whitesmoke" }}>
        <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>S.No.</th>
        <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Campus Name</th>
        <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Level</th>
        <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Faculty</th>
        <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Program</th>
        <th colSpan="4" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Gender</th>
        <th rowSpan="2" style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>EDJ</th>
        <th colSpan={ethnicGroups.length} style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Caste Ethnicity</th>
      </tr>
      <tr style={{ backgroundColor: "#2b6eb5", color: "whitesmoke" }}>
        <th style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Male</th>
        <th style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Female</th>
        <th style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Other</th>
        <th style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>Total</th>
        {ethnicGroups.map((ethnicity) => (
          <th key={ethnicity.index} style={{ border: "1px solid #c2c2c2", fontSize: "14px" }}>
            {ethnicity.name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const StudentSummary = () => {
  const [data, setData] = useState([]);
  const [ethnicGroups, setEthnicGroups] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(`${backendUrl}/Student/Report/GetStudentSummary`, config);

        // Get ethnic groups from the first record's `ethnicGroup` array (assuming all records have same structure)
        if (response.data.length > 0) {
          setEthnicGroups(response.data[0].ethnicGroup);
        }

        const updatedData = response.data.map((student, index) => {
          const ethnicityData = ethnicGroups.reduce((acc, ethnicity) => {
            const ethnicityKey = ethnicity.name.toLowerCase().replace(/\s+/g, "");
            acc[ethnicityKey] = student.ethnicGroup.find(
              (ethnicityItem) => ethnicityItem.name === ethnicity.name
            )?.count || 0;
            return acc;
          }, {});

          return {
            ...student,
            no: index + 1,
            ...ethnicityData, // Add ethnicity fields dynamically
          };
        });

        setData(updatedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [ethnicGroups]);

  return (
    <>
      <Box sx={{ margin: 0, padding: 0, height: "100%", boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        <Box mt={8}>
          <StudentAppBar />
        </Box>
      </Box>
      <ThemeProvider theme={createTheme({
        components: {
          MuiTableCell: {
            styleOverrides: {
              head: {
                backgroundColor: "#2b6eb5",
                color: "whitesmoke",
                textTransform: "capitalize",
                padding: "6px",
                fontSize: "12px",
                border: "1px solid #C2C2C2",
              },
              body: {
                fontSize: "12px",
                padding: "6px",
                border: "1px solid #C2C2C2",
              },
            },
          },
        },
      })}>
        <MUIDataTable
          title={<Typography variant="body1" style={{ color: "#2b6eb5", padding: "5px", textAlign: "right" }}>Student Summary</Typography>}
          data={data}
          columns={getDynamicColumns(ethnicGroups)} // Pass the dynamic columns
          options={{
            filterType: "",
            selectableRows: false,
            responsive: "standard",
            elevation: 0,
            pagination: true,
            search: true,
            searchPlaceholder: "Search...",
            rowsPerPage: 15,
          }}
          components={{
            TableHead: () => <CustomHeader ethnicGroups={ethnicGroups} />, // Pass ethnic groups to header
          }}
        />
      </ThemeProvider>
    </>
  );
};

export default StudentSummary;
