import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ReceiptNavBar from "../../../modules/navbar/ReceiptNavBar";
import { useParams } from "react-router-dom";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ReceiptMgmt = () => {
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const [feeTypes, setFeeTypes] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [receiptNo, setReceiptNo] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [selectedFeeItems, setSelectedFeeItems] = useState([]);
  const [feeAmounts, setFeeAmounts] = useState({});
  const [studentData, setStudentData] = useState({});
  const [defaultFiscal, setDefaultFiscal] = useState(7);

  const campusName = currentUser?.institution?.campusName;
  const campusId = currentUser?.institution?.id;

  const fetchFeeTypes = async () => {
    try {
      const response = await axios.get(`${backendUrl}/FeeType`);
      setFeeTypes(response.data);
    } catch (err) {
      toast.error("Failed to fetch fee types", err);
    }
  };

  const fetchReceiptData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      if (localStorageData) {
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const fiscalYearResponse = await axios.get(
          `${backendUrl}/FiscalYear`,
          config
        );
        const activeFiscalYear = fiscalYears.find(
          (data) => data && data?.activeFiscalYear === true
        );
        if (activeFiscalYear) {
          setDefaultFiscal(activeFiscalYear?.id);
        }
        setFiscalYears(fiscalYearResponse.data);
      }
    } catch (error) {
      console.error("Error fetching fiscal year data:", error);
    }
  };
  useEffect(() => {
    fetchReceiptData();
  }, []);

  useEffect(() => {
    fetchFeeTypes();

    const fetchStudents = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const response = await axios.get(`${backendUrl}/Student/${id}`, config);
        setStudentData(response.data);
      } catch (err) {
        console.error("Error fetching students:", err);
        toast.error("Failed to load students");
      }
    };
    fetchStudents();
  }, [id]);

  useEffect(() => {
    const initialReceiptNo = generateReceiptNo();
    setReceiptNo(initialReceiptNo);
    setValue("receiptNo", initialReceiptNo);
  }, [setValue]);

  const generateReceiptNo = () => {
    const nextReceiptNo = receiptNo + 1;
    setReceiptNo(nextReceiptNo);
    return nextReceiptNo.toString();
  };

  const handleFeeItemChange = (feeId, feeAmount) => {
    setSelectedFeeItems((prev) => {
      if (prev.includes(feeId)) {
        const newSelectedFeeItems = prev.filter((id) => id !== feeId);
        const { [feeId]: _, ...newFeeAmounts } = feeAmounts;
        setFeeAmounts(newFeeAmounts);
        return newSelectedFeeItems;
      } else {
        const newFeeAmounts = { ...feeAmounts, [feeId]: feeAmount };
        setFeeAmounts(newFeeAmounts);
        return [...prev, feeId];
      }
    });
  };

  const onSubmit = async (data) => {
    let receiptItems = [];
    selectedFeeItems.forEach((feeId, index) => {
      const fee = feeTypes.find((fee) => fee.name === feeId);
      receiptItems.push({
        FeeTypeId: feeId,
        Amount: feeAmounts[feeId] || fee.amount || 0,
        Remarks: data.remarks || "",
      });
    });

    const formData = new FormData();
    formData.append("receiptNo", data.receiptNo);
    formData.append("fiscalYearID", data.fiscalYearId);
    formData.append("studentID", id);
    formData.append("campusId", campusId || 0);

    receiptItems.forEach((item, index) => {
      formData.append(`ReceiptItemCreateDto[${index}].name`, item.FeeTypeId);
      formData.append(`ReceiptItemCreateDto[${index}].Amount`, item.Amount);
      formData.append(`ReceiptItemCreateDto[${index}].Remarks`, item.Remarks);
    });

    formData.append("doBNepali", data.dob || "");
    formData.append("dateOfPayment", data.dateOfPayment);

    if (data.uploadApplication[0]) {
      formData.append("uploadApplication", data.uploadApplication[0]);
    }

    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.post(
        `${backendUrl}/Receipt/GenerateReceipt`,
        formData,
        config
      );

      toast.success("Data posted successfully", { autoClose: 1500 });
      reset();
      fetchReceiptData();
    } catch (error) {
      console.error("Error posting receipt data:", error);
      toast.error("Error posting data: " + (error?.message || "Unknown error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ReceiptNavBar />
        </Box>
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={false} md={1} />
        <Grid item xs={12} md={10}>
          <Paper elevation={5} sx={{ borderRadius: "20px" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Creating the student receipt for {campusName}{" "}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  {/* <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      size="small"
                      id="campusName"
                      name="campusName"
                      label="Campus Name"
                      value={campusName || ""}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register("receiptNo", { required: true })}
                      id="receiptNo"
                      value={receiptNo || ""}
                      required
                      size="small"
                      name="receiptNo"
                      label="Receipt No."
                      fullWidth
                      error={!!errors.receiptNo}
                      helperText={
                        errors.receiptNo ? "Receipt No. is required" : ""
                      }
                      onChange={(e) => setReceiptNo(e.target.value)}
                    />
                  </Grid>
                  {console.log(defaultFiscal)}
                  <Grid item xs={12} sm={2}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.fiscalYearId}
                    >
                      <InputLabel required id="fiscalYearId">
                        Fiscal Year
                      </InputLabel>
                      <Select
                        {...register("fiscalYearId", { required: true })}
                        id="fiscalYearId"
                        labelId="fiscalYearId"
                        defaultValue={defaultFiscal}
                        label="Fiscal Year"
                        value={defaultFiscal || 0}
                      >
                        {fiscalYears?.map((fy) => (
                          <MenuItem key={fy.id} value={fy.id}>
                            {fy.yearNepali}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      id="batch"
                      size="small"
                      name="batch"
                      label="Batch"
                      value={studentData?.admissionYear}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  {studentData && studentData?.programType === "semester" ? (
                    <Grid item xs={12} sm={2}>
                      <TextField
                        id="semester"
                        required
                        size="small"
                        name="semester"
                        label="Semester"
                        value={`${studentData?.semester} Semester` || ""}
                        fullWidth
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={2}>
                      <TextField
                        id="year"
                        required
                        size="small"
                        name="year"
                        label="Year"
                        value={`${studentData?.year} Year` || ""}
                        fullWidth
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="studentName"
                      required
                      size="small"
                      name="studentName"
                      label="Student Full Name (English)"
                      value={
                        `${studentData?.firstName} ${studentData?.middleName} ${studentData?.lastName}` ||
                        ""
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="studentNameNepali"
                      required
                      size="small"
                      name="studentNameNepali"
                      label="Student Full Name (Nepali)"
                      value={studentData?.nepaliName || ""}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="programName"
                      required
                      size="small"
                      name="programName"
                      label="Program Name"
                      value={studentData?.programName || ""}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register("dateOfPayment", { required: true })}
                      id="dateOfPayment"
                      required
                      defaultValue={new Date().toISOString().split("T")[0]}
                      size="small"
                      name="dateOfPayment"
                      label="Date of Payment"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Box
                      border="1px solid #8c8d90"
                      borderRadius="10px"
                      position="relative"
                      paddingBottom="5px"
                    >
                      <Typography
                        fontSize="12px"
                        display="inline-block"
                        bgcolor="white"
                        padding="0 5px"
                        position="relative"
                        left="15px"
                        bottom="12px"
                      >
                        Select atleast one or more item as required
                      </Typography>

                      <Grid item xs={12} ml={2}>
                        <FormGroup>
                          <Grid container spacing={1}>
                            {feeTypes.map((fee) => (
                              <Grid item xs={6} sm={6} md={4} key={fee.id}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "2px",
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={() =>
                                          handleFeeItemChange(
                                            fee.name,
                                            fee.amount
                                          )
                                        }
                                        checked={selectedFeeItems.includes(
                                          fee.name
                                        )}
                                        size="small"
                                      />
                                    }
                                    label={`${fee.name}:`}
                                    style={{
                                      marginBottom: 0,
                                      fontSize: "10px",
                                    }}
                                  />
                                  <TextField
                                    id={`amountPaid-${fee.id}`}
                                    required
                                    value={
                                      feeAmounts[fee.id] || fee.amount || 0
                                    }
                                    size="small"
                                    variant="standard"
                                    name={`amountPaid-${fee.id}`}
                                    type="number"
                                    fullWidth
                                    style={{
                                      maxWidth: "10rem",
                                      fontSize: "10px",
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          RS.
                                        </InputAdornment>
                                      ),
                                    }}
                                    onChange={(e) =>
                                      setFeeAmounts((prev) => ({
                                        ...prev,
                                        [fee.id]: parseFloat(e.target.value),
                                      }))
                                    }
                                  />
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </FormGroup>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("uploadApplication", { required: false })}
                      id="uploadApplication"
                      size="small"
                      name="uploadApplication"
                      label="Upload Application"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      {...register("remarks")}
                      id="remarks"
                      size="small"
                      name="remarks"
                      label="Remarks"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ReceiptMgmt;
