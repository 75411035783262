import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  FormControl,
  CardContent,
  Typography,
  InputLabel,
  MenuItem,
  Button,
  Select,
  DialogContent,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function EditRoutineSchedule({ Id, onClose, onUpdate }) {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const [scheduleExam, setScheduleExam] = useState({});
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch exam schedule data based on Id
  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };

        const scheduleExams = await axios.get(
          `${backendUrl}/SubjectExamSchedule/${Id}`,
          config
        );

        setScheduleExam(scheduleExams.data);

        // Populate the form with fetched data
        const examData = scheduleExams.data;
        setValue("examSchedule", examData?.examSchedule?.examName || "");
        setValue("subjectId", examData.subjectID || "");
        setValue(
          "isTheoritical",
          examData.examType === "theoretical" || examData.examType === "Both"
        );
        setValue(
          "isPractical",
          examData.examType === "practical" || examData.examType === "Both"
        );
        setValue("dateFrom", examData?.examDate?.split("T")[0] || "");
        setValue("examTime", examData.examTime || "");
        setValue("fullMark", examData.fullMark || "");
        setValue("passMark", examData.passMark || "");
        setValue("details", examData.description || "");
        setValue("status", examData?.examSchedule?.status || false);
      } catch (err) {
        console.error("Error fetching schedule exam data:", err);
      }
    };
    fetchData();
  }, [Id, setValue]);

  // Fetch all subjects
  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };

        const subjectsResponse = await axios.get(
          `${backendUrl}/Subject/GetAllSubjects`,
          config
        );

        setSubjects(subjectsResponse.data);
      } catch (err) {
        console.error("Error fetching subjects:", err);
      }
    };
    fetchSubjects();
  }, []);

  // Handle form submission
  const onSubmit = async (data) => {
    setLoading(true);

    const updatedExamData = {
      id: Id,
      subjectID: data.subjectId,
      examType:
        data.isTheoritical && data.isPractical
          ? "Both"
          : data.isTheoritical
          ? "theoretical"
          : "practical",
      examDate: data.dateFrom,
      examTime: data.examTime,
      fullMark: data.fullMark,
      passMark: data.passMark,
      description: data.details,
      status: data.status,
    };

    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };

      await axios.put(
        `${backendUrl}/SubjectExamSchedule/${Id}`,
        updatedExamData,
        config
      );

      toast.success("Exam schedule updated successfully!", {
        autoClose: 1500,
      });
      if (onUpdate) onUpdate();
      if (onClose) onClose();
    } catch (err) {
      console.error("Error while updating exam schedule:", err);
      toast.error("Failed to update exam schedule.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogContent fullWidth>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={12}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Edit Exam Schedule For Subject
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                {/* Exam Scheduled */}
                <Grid item xs={12} sm={4}>
                  <TextField
                    size="small"
                    fullWidth
                    disabled
                    label="Exam Scheduled"
                    value={scheduleExam?.examSchedule?.examName || ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                {/* Subject */}
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel required>Subject</InputLabel>
                    <Controller
                      name="subjectId"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select {...field} label="Subject">
                          {subjects.map((subject) => (
                            <MenuItem key={subject.id} value={subject.id}>
                              {subject.subjectName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>

                {/* Exam Type */}
                <Grid item xs={12} sm={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("isTheoritical")}
                        color="primary"
                        checked={watch("isTheoritical")}
                      />
                    }
                    label="Theoretical"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("isPractical")}
                        color="primary"
                        checked={watch("isPractical")}
                      />
                    }
                    label="Practical"
                  />
                </Grid>

                {/* Exam Date */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="dateFrom"
                    control={control}
                     defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Date From"
                        type="date"
                        size="small"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>

                {/* Exam Time */}
                <Grid item xs={12} sm={3}>
                  <Controller
                    name="examTime"
                    control={control}
                     defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Exam Time"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                {/* Full Marks */}
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="fullMark"
                    control={control}
                     defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Full Marks"
                        type="number"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                {/* Pass Marks */}
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="passMark"
                    control={control}
                     defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Pass Marks"
                        type="number"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                {/* Status */}
                <Grid item xs={12} sm={2}>
                  <Controller
                    name="status"
                    control={control}
                     defaultValue=""
                    render={({ field }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel>Status</InputLabel>
                        <Select {...field} label="Status">
                          <MenuItem value={true}>
                            <Chip label="Active" color="success" />
                          </MenuItem>
                          <MenuItem value={false}>
                            <Chip label="Inactive" color="error" />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                  <Controller
                    name="details"
                    control={control}
                     defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Description"
                        size="small"
                        fullWidth
                        multiline
                        rows={2}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* Buttons */}
              <Grid
                container
                justifyContent="center"
                spacing={2}
                style={{ marginTop: "20px" }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Grid>
      </Grid>
    </DialogContent>
  );
}
