// 2.3 Report
import {
  Box,
  Button,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  FileDownload,
  PictureAsPdf,
  InsertDriveFile,
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getStudentByEcobelts } from "./reportApi";

const StudentInLevel = ({ fiscalId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const data = await getStudentByEcobelts(fiscalId);
    if (data) {
      const correctlyStructuredData = data.map((university) => {
        const programLevels = university.levelData.reduce(
          (acc, { levelName, hill, mountain, tarai, total }) => {
            acc[levelName] = { hill, mountain, tarai, total };
            return acc;
          },
          {}
        );

        return {
          universityName: university.universityName,
          programLevels: programLevels,
        };
      });
      const transformedData = transformApiResponse(correctlyStructuredData);
      setData(transformedData);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fiscalId]);

  const transformApiResponse = (apiData) => {
    return apiData.map((item) => ({
      university: item.universityName,
      bachelor: {
        hilly: item.programLevels["Bachelor"].hill,
        mountain: item.programLevels["Bachelor"].mountain,
        terai: item.programLevels["Bachelor"].tarai,
        total: item.programLevels["Bachelor"].total,
      },
      pgd: {
        hilly: item.programLevels["PGD"].hill,
        mountain: item.programLevels["PGD"].mountain,
        terai: item.programLevels["PGD"].tarai,
        total: item.programLevels["PGD"].total,
      },
      masters: {
        hilly: item.programLevels["Master"].hill,
        mountain: item.programLevels["Master"].mountain,
        terai: item.programLevels["Master"].tarai,
        total: item.programLevels["Master"].total,
      },
      mphil: {
        hilly: item.programLevels["MPhil"].hill,
        mountain: item.programLevels["MPhil"].mountain,
        terai: item.programLevels["MPhil"].tarai,
        total: item.programLevels["MPhil"].total,
      },
      phd: {
        hilly: item.programLevels["PhD."].hill,
        mountain: item.programLevels["PhD."].mountain,
        terai: item.programLevels["PhD."].tarai,
        total: item.programLevels["PhD."].total,
      },
      uniTotal:
        item.programLevels["Bachelor"].total +
        item.programLevels["PGD"].total +
        item.programLevels["MPhil"].total +
        item.programLevels["Master"].total +
        item.programLevels["PhD."].total,
    }));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  // Export data to PDF and excel
  const exportToExcel = () => {
    const header = [
      [
        "S.No.",
        "University",
        "Bachelor - Hill",
        "Bachelor - Mountain",
        "Bachelor - Terai",
        "Bachelor - Total",
        "PGD - Hill",
        "PGD - Mountain",
        "PGD - Terai",
        "PGD - Total",
        "Master - Hill",
        "Master - Mountain",
        "Master - Terai",
        "Master - Total",
        "MPhil - Hill",
        "MPhil - Mountain",
        "MPhil - Terai",
        "MPhil - Total",
        "PhD - Hill",
        "PhD - Mountain",
        "PhD - Terai",
        "PhD - Total",
        "University Total",
      ],
    ];
  
    const tableData = data.map((row, index) => [
      index + 1,
      row.university,
      row.bachelor.hilly,
      row.bachelor.mountain,
      row.bachelor.terai,
      row.bachelor.total,
      row.pgd.hilly,
      row.pgd.mountain,
      row.pgd.terai,
      row.pgd.total,
      row.masters.hilly,
      row.masters.mountain,
      row.masters.terai,
      row.masters.total,
      row.mphil.hilly,
      row.mphil.mountain,
      row.mphil.terai,
      row.mphil.total,
      row.phd.hilly,
      row.phd.mountain,
      row.phd.terai,
      row.phd.total,
      row.uniTotal,
    ]);
  
    // Calculate grand total for each column
    const grandTotalRow = [
      "", // Empty cell for S.No.
      "Grand Total",
      ...["bachelor", "pgd", "masters", "mphil", "phd"].flatMap((category) =>
        ["hilly", "mountain", "terai", "total"].map((region) =>
          data.reduce((sum, row) => sum + (row[category][region] || 0), 0)
        )
      ),
      data.reduce((sum, row) => sum + row.uniTotal, 0), 
    ];
  
    const ws = XLSX.utils.aoa_to_sheet([...header, ...tableData, grandTotalRow]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Program Levels");
    XLSX.writeFile(wb, "program_levels.xlsx");
  };
  
  return (
    <div>
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: "10px" }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#5FAD41" }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
            >
              Excel
            </Button>
          </Box>
        </Popover>
      </Grid>
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  textAlign: "center",
                  width: "1rem",
                }}
                rowSpan={2}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                University
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Bachelor
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                PGD
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Masters
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Mphil
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                PHD
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                Total
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.university}>
                {/* University */}
                <TableCell
                  sx={{ border: "1px solid #c2c2c2", textAlign: "center" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #c2c2c2", textAlign: "left" }}
                >
                  {row.university}
                </TableCell>

                {/* Bachelor */}
                {Object.entries(row.bachelor).map(([key, val], i) => (
                  <TableCell
                    key={`bachelor-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}

                {/* PGD */}
                {Object.entries(row.pgd).map(([key, val], i) => (
                  <TableCell
                    key={`pgd-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}

                {/* Masters */}
                {Object.entries(row.masters).map(([key, val], i) => (
                  <TableCell
                    key={`masters-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}

                {/* MPhil */}
                {Object.entries(row.mphil).map(([key, val], i) => (
                  <TableCell
                    key={`mphil-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}

                {/* PhD */}
                {Object.entries(row.phd).map(([key, val], i) => (
                  <TableCell
                    key={`phd-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}

                <TableCell
                  sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                >
                  {row.uniTotal}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid #c2c2c2",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                Grand Total
              </TableCell>

              {/* Bachelor Totals */}
              {["bachelor", "pgd", "masters", "mphil", "phd"].map(
                (category) => {
                  return ["hilly", "mountain", "terai", "total"].map(
                    (region) => {
                      const total = data.reduce((sum, row) => {
                        return sum + (row[category][region] || 0);
                      }, 0);
                      return (
                        <TableCell
                          key={`${category}-${region}`}
                          sx={{
                            border: "1px solid #c2c2c2",
                            textAlign: "right",
                          }}
                        >
                          {total}
                        </TableCell>
                      );
                    }
                  );
                }
              )}
              <TableCell
                sx={{
                  border: "1px solid #c2c2c2",
                  textAlign: "right",
                }}
              >
                {data.reduce((sum, current) => sum + current.uniTotal, 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StudentInLevel;
