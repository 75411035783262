import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  TextField,
  FormControl,
  CardContent,
  Paper,
  FormHelperText,
  Typography,
  InputLabel,
  MenuItem,
  Button,
  Select,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ExamAppBar from "../navbar/ExamAppBar";
import { useLocation } from "react-router-dom";
import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function RoutineSchedule() {
  const location = useLocation();
  const [scheduleExam, setScheduleExam] = useState({});
  const [subjects, setSubjects] = useState([]);

  const queryParams = new URLSearchParams(location.search);
  const Id = queryParams.get("examschedule");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const scheduleExams = await axios.get(`${backendUrl}/ExamSchedule/${Id}`, config);
        setScheduleExam(scheduleExams.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [Id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const subjects = await axios.get(`${backendUrl}/Subject/GetAllSubjects`, config);
        setSubjects(subjects.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const examData = {
      examScheduleId: Id,
      subjectID: data.subjectId,
      examType: data.isTheoritical && data.isPractical
        ? "Both"
        : data.isTheoritical
        ? "Theoretical"
        : "Practical", 
      examDate: `${data.dateFrom}T${data.examTime}:00.000Z`,
      examTime: data.examTime,
      fullMark: data.fullMark,
      passMark: data.passMark,
      description: data.details,
    };

    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(`${backendUrl}/SubjectExamSchedule`, examData, config);
      console.log("Exam Schedule saved successfully:", response.data);
    } catch (err) {
      console.error("Error while saving exam schedule:", err);
    }
  };

  return (
    <div>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ExamAppBar />
        </Box>
      </Box>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <Paper elevation={5} sx={{ borderRadius: "20px" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Exam Schedule For Subject
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      size="small"
                      fullWidth
                      disabled
                      label="Exam Scheduled"
                      value={scheduleExam?.examName || ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel required>Semester</InputLabel>
                      <Select
                        required
                        {...register("semester")}
                        id="semester"
                        size="small"
                        name="semester"
                        fullWidth
                        label="Subject"
                      >
                        <MenuItem value={""}>Select Semester</MenuItem>
                        {subjects.length > 0 &&
                          subjects.map((data) => (
                            <MenuItem key={data.id} value={data.id}>
                              {data.subjectName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel required>Subject</InputLabel>
                      <Select
                        required
                        {...register("subjectId")}
                        id="subjectId"
                        size="small"
                        name="subjectId"
                        fullWidth
                        label="Subject"
                      >
                        <MenuItem value={""}>Select Subject</MenuItem>
                        {subjects.length > 0 &&
                          subjects.map((data) => (
                            <MenuItem key={data.id} value={data.id}>
                              {data.subjectName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...register("isTheoritical")}
                            id="isTheoritical"
                            name="isTheoritical"
                            color="primary"
                            defaultChecked={false}
                          />
                        }
                        label="isTheoritical"
                      />
                      {errors.isTheoritical && (
                        <FormHelperText error>
                          {"isTheoritical required"}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...register("isPractical")}
                            id="isPractical"
                            name="isPractical"
                            color="primary"
                            defaultChecked={false}
                          />
                        }
                        label="isPractical"
                      />
                      {errors.isPractical && (
                        <FormHelperText error>
                          {"isPractical required"}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                      <TextField
                        required
                        {...register("dateFrom", { required: true })}
                        id="dateFrom"
                        type="date"
                        size="small"
                        name="dateFrom"
                        label="Date From"
                        fullWidth
                        error={!!errors.dateFrom}
                        helpertext={errors.dateFrom ? "Date From required" : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                      <TextField
                        required
                        {...register("examTime", { required: true })}
                        id="examTime"
                        type="time"
                        size="small"
                        name="examTime"
                        label="Exam Time"
                        fullWidth
                        error={!!errors.examTime}
                        helpertext={errors.examTime ? "Time From required" : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      required
                      {...register("fullMark", { required: true })}
                      id="fullMark"
                      type="number"
                      size="small"
                      name="fullMark"
                      label="Full Marks"
                      fullWidth
                      error={!!errors.fullMark}
                      helpertext={errors.fullMark ? "Full Mark required" : ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      required
                      {...register("passMark", { required: true })}
                      id="passMark"
                      type="number"
                      size="small"
                      name="passMark"
                      label="Pass Marks"
                      fullWidth
                      error={!!errors.passMark}
                      helpertext={errors.passMark ? "Full Mark required" : ""}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="status" required>
                        Status
                      </InputLabel>
                      <Select
                        required
                        {...register("status")}
                        id="status"
                        size="small"
                        name="status"
                        fullWidth
                        label="Status"
                        error={!!errors.status}
                        helpertext={errors.status ? "Status required" : ""}
                      >
                        <MenuItem value={true}>
                          <Chip label="Active" color="success" size="small" />
                        </MenuItem>
                        <MenuItem value={false}>
                          <Chip label="Inactive" color="error" size="small" />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="details"
                      label="Description"
                      size="small"
                      fullWidth
                      multiline
                      rows={2}
                      variant="outlined"
                      sx={{
                        borderColor: "#3f51b5",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: "#007aff", color: "#inherit" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
