import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  FormControl,
  Box,
  TablePagination,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { getSubject, postSubject } from "../../../services/services";
import ProgramAppBar from "../../../modules/navbar/ProgramSetupAppBar";
import axios from "axios";
import EditSubjectDialog from "./EditSubject";
import toast from "react-hot-toast";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SubjectMgmt = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [getSubjectData, setGetSubjectData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedProgramType, setSelectedProgramType] = useState("");
  const [semester, setSemester] = useState([]);
  const [year, setYear] = useState([]);
  const [programFilter, setProgramFilter] = useState("");
  const [semesterFilter, setSemesterFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        programId: data.programId,
        subjectName: data.subjectName,
        subjectType: data.subjectType,
        shortName: data.shortName,
        code: data.code,
        semester: data.semester,
        year: data.year,
        alias: data.alias || null,
        remarks: data.detail || null,
      };

      await postSubject(formattedData);
      toast.success("Subject Added Successfully!!");
      reset();
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };
  const fetchData = async () => {
    try {
      const data = await getSubject();
      console.log(data)
      const sortedData = data.sort((a, b) => {
        if (
          a.program.programType === "annual" &&
          b.program.programType !== "annual"
        ) {
          return -1;
        }
        if (
          a.program.programType !== "semester" &&
          b.program.programType === "semester"
        ) {
          return 1;
        }
        if (
          a.year === "First" &&
          b.year !== "First"
        ) {
          return 1;
        }
        if (
          a.semester !== "First" &&
          b.semester === "First"
        ) {
          return -1;
        }
        const semesterOrder = [
          "First",
          "Second",
          "Third",
          "Fourth",
        ];
        const aSemesterIndex = semesterOrder.indexOf(a.semester);
        const bSemesterIndex = semesterOrder.indexOf(b.semester);

        if (aSemesterIndex < bSemesterIndex) return -1;
        if (aSemesterIndex > bSemesterIndex) return 1;

        if (a.subjectName < b.subjectName) {
          return -1; 
        }
        if (a.subjectName > b.subjectName) {
          return 1;
        }

        return 0;
      });

      setGetSubjectData(sortedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const programData = await axios.get(
          `${backendUrl}/ProgramMgmt/GetCollegePrograms`,
          config
        );
        setProgramData(programData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const [semesterData, yearData] = await Promise.all([
          axios.get(`${backendUrl}/StudentUpgrade/Semesters`, config),
          axios.get(`${backendUrl}/StudentUpgrade/Years`, config),
        ]);
        setSemester(semesterData.data);
        setYear(yearData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);
  const handleProgramChange = (e) => {
    const selectedProgram = programData.find(
      (program) => program.id === e.target.value
    );
    setSelectedProgramType(selectedProgram?.programType || "");
  };
  const handleEditClick = (subject) => {
    setSelectedSubject(subject);
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedSubject(null);
  };

  const handleUpdate = () => {
    fetchData();
  };
  const filteredData = getSubjectData.filter((subject) => {
    const matchesProgram = programFilter
      ? subject.program.id === programFilter
      : true;
    const matchesSemester = semesterFilter
      ? subject.semester === semesterFilter
      : true;
    const matchesYear = yearFilter ? subject.year === yearFilter : true;

    return matchesProgram && matchesSemester && matchesYear;
  });
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ProgramAppBar />
        </Box>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={false} md={1} />
        <Grid item xs={12} md={12}>
          <>
            <Paper elevation={5} sx={{ borderRadius: "20px" }}>
              <CardContent>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ textAlign: "center", color: "#2A629A" }}
                >
                  Subject Management
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="programId" required>
                          Program Name
                        </InputLabel>
                        <Select
                          required
                          {...register("programId")}
                          id="programId"
                          size="small"
                          name="programId"
                          fullWidth
                          label="Program Name"
                          onChange={handleProgramChange}
                          error={!!errors.programId}
                          helpertext={
                            errors.programId ? "program required" : ""
                          }
                        >
                          {programData.length > 0 &&
                            programData.map((data) => (
                              <MenuItem key={data.id} value={data.id}>
                                {data.programName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel required={selectedProgramType === "annual"}>
                          Year
                        </InputLabel>
                        <Select
                          required={selectedProgramType === "annual"}
                          {...register("year")}
                          id="year"
                          size="small"
                          name="year"
                          label="Year"
                          disabled={selectedProgramType !== "annual"}
                          fullWidth
                        >
                          <MenuItem disabled value="">
                            Select Year
                          </MenuItem>
                          {year &&
                            year.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          required={selectedProgramType === "semester"}
                        >
                          Semester
                        </InputLabel>
                        <Select
                          required={selectedProgramType === "semester"}
                          {...register("semester")}
                          id="semester"
                          size="small"
                          name="semester"
                          label="Semester"
                          fullWidth
                          disabled={selectedProgramType !== "semester"}
                        >
                          <MenuItem disabled value="">
                            Select Semester
                          </MenuItem>
                          {semester &&
                            semester.map((semester) => (
                              <MenuItem key={semester} value={semester}>
                                {semester}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        {...register("subjectName", { required: true })}
                        id="subjectName"
                        size="small"
                        name="subjectName"
                        label="Subject Name"
                        fullWidth
                        error={!!errors.subjectName}
                        helpertext={
                          errors.subjectName ? "Subject name required" : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <TextField
                        required
                        {...register("shortName", { required: true })}
                        id="shortName"
                        size="small"
                        name="shortName"
                        label="Short Name"
                        fullWidth
                        autoComplete="short-name"
                        error={!!errors.shortName}
                        helpertext={
                          errors.shortName ? "short name required" : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        required
                        {...register("code", { required: true })}
                        id="code"
                        size="small"
                        name="code"
                        label="Code"
                        fullWidth
                        error={!!errors.code}
                        helpertext={errors.code ? "code is required" : ""}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="type" required>
                          Subject Type
                        </InputLabel>
                        <Select
                          required
                          {...register("subjectType", { required: true })}
                          id="subjectType"
                          size="small"
                          name="subjectType"
                          label="Subject Type"
                          fullWidth
                          error={!!errors.subjectType}
                          helpertext={
                            errors.subjectType ? "Program type required" : ""
                          }
                        >
                          <MenuItem value="regular">Compulsory</MenuItem>
                          <MenuItem value="optional">Elective</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <TextField
                        id="alias"
                        {...register("alias")}
                        name="alias"
                        size="small"
                        label="Alias"
                        fullWidth
                        error={!!errors.alias}
                        helpertext={errors.alias}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        {...register("detail")}
                        id="detail"
                        name="detail"
                        size="small"
                        label="Details"
                        fullWidth
                        error={!!errors.detail}
                        helpertext={errors.detail}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      margin: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: "#007aff", color: "#inherit" }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </form>
              </CardContent>
            </Paper>
          </>
          <Grid margin="10px">
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              List of Subjects
            </Typography>
            <Grid container spacing={1} mb={1}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Select Program</InputLabel>
                  <Select
                    value={programFilter}
                    onChange={(e) => setProgramFilter(e.target.value)}
                    label="Select Program"
                    sx={{
                      backgroundColor: "whitesmoke",
                      borderColor: "lightgray",
                      borderRadius: 1,
                    }}
                  >
                    <MenuItem value="">All Programs</MenuItem>
                    {programData.map((program) => (
                      <MenuItem key={program.id} value={program.id}>
                        {program.programName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Filter by Semester</InputLabel>
                  <Select
                    value={semesterFilter}
                    onChange={(e) => setSemesterFilter(e.target.value)}
                    label="Select Semester"
                    sx={{
                      backgroundColor: "whitesmoke",
                      borderColor: "lightgray",
                      borderRadius: 1,
                    }}
                  >
                    <MenuItem value="">All Semesters</MenuItem>
                    {semester.map((sem) => (
                      <MenuItem key={sem} value={sem}>
                        {sem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Filter by Year</InputLabel>
                  <Select
                    value={yearFilter}
                    onChange={(e) => setYearFilter(e.target.value)}
                    label="Filter by Year"
                    sx={{
                      backgroundColor: "whitesmoke",
                      borderColor: "lightgray",
                      borderRadius: 1,
                    }}
                  >
                    <MenuItem value="">All Years</MenuItem>
                    {year.map((yr) => (
                      <MenuItem key={yr} value={yr}>
                        {yr}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <TableContainer>
              <Table
                style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
              >
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      S.No:
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      Program Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      Semester/Year
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      Subject Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      Subject Type
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      Short Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      Code
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      Alias
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      Details
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "4px",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "white" }}>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => (
                      <TableRow key={data.id}>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {data.program.programName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {data.year
                            ? `${data.year} year`
                            : `${data.semester} semester`}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {data.subjectName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {data.isRegular ? "Regular" : "optional"}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {data.shortName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {data.code}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {data.alias || "-"}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {data?.remarks || "-"}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          <Button onClick={() => handleEditClick(data.id)}>
                            <EditNoteIcon />
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>

      <EditSubjectDialog
        open={openDialog}
        onClose={handleDialogClose}
        subjectId={selectedSubject}
        programData={programData}
        onUpdate={handleUpdate}
        semester={semester}
        year={year}
      />
    </>
  );
};

export default SubjectMgmt;
