import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogContent,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getVerifiedStudents } from "../../dashboard/services/service";
import { Content } from "rsuite";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const EditGraduationModule = ({ id, onClose, onUpdate }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm();
  
  const { currentUser } = useSelector((state) => state.user);
  console.log(currentUser);
  const [loading, setLoading] = useState(false);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [students, setStudents] = useState([]);
  const divisions = ["First", "Second", "Third"];
 

  useEffect(() => {
    const fetchStudents = async () => {
          try {
            const verifiedStudents = await getVerifiedStudents();
    
            setStudents(verifiedStudents); // This will store only verified students
    
            console.log("Fetched Students:", verifiedStudents);
          } catch (err) {
            console.error("Error fetching students:", err);
    
            toast.error("Failed to load students");
          }
        };
    
        fetchStudents();
    const fetchInitialData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "text/plain",
          },
        };

        // Fetch receipt details by ID
        const graduationResponse = await axios.get(`${backendUrl}/Graduation/${id}`, config);
        const graduationData = graduationResponse.data;

        // Populate form fields with fetched data

        setValue("fiscalYearId", graduationData.fiscalYearID);
        setValue("studentID", graduationData.studentID);
        setValue("applicantNameEng", graduationData.applicantNameEng || "");
        setValue("applicantNameNep", graduationData.applicantNameNep || ""); 
        setValue("dobNep", graduationData.doBNepali || "");
        setValue("dobEng", graduationData.doBEng || "");
        setValue("email", graduationData.email || "");
        setValue("universityIssueNo", graduationData.universityIssueNo || 0);
        setValue("studentRegNo", graduationData.studentRegNo || "");
        setValue("symbolNo", graduationData.symbolNoUniversity || "");
        setValue("campusRollNo", graduationData.campusRolNo || "");
        setValue("enrolledYear", graduationData.enrolledYear || 0);
        setValue("passedYear", graduationData.passedYear || 0);
        setValue("division", graduationData.division || "");
        setValue("gpa", graduationData.gpa || 0);
        setValue("fatherName", graduationData.fatherName || "");
        setValue("motherName", graduationData.motherName || "");
        setValue("contactNumber", graduationData.contactNo || "");
        setValue("uploadSignature", graduationData.uploadSignature || "");
        setValue("uploadPPSizePhoto", graduationData.uploadPPSizePhoto || "");
        setValue("uploadReceipt", graduationData.uploadReceipt || "");
        setValue("uploadTranscript", graduationData.uploadTranscript || "");
        setValue("uploadOtherDocs", graduationData.uploadOtherDoc || "");
        setValue("remarks", graduationData.remarks || "");

        // Fetch fee types and fiscal years for dropdowns
        const [fiscalYearResponse] = await Promise.all([
          axios.get(`${backendUrl}/FiscalYear`, config),
        ]);
        setFiscalYears(fiscalYearResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchInitialData();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("id", id);
    formData.append("fiscalYearID", data.fiscalYearId);
    formData.append("studentID", data.studentID);
    formData.append("applicantNameEng", data.applicantNameEng);
    formData.append("applicantNameNep", data.applicantNameNep);
    formData.append("doBNepali", data.dobNep);
    formData.append("doBEng", data.dobEng);
    formData.append("email", data.email);
    formData.append("universityIssueNo", data.universityIssueNo);
    formData.append("studentRegNo", data.studentRegNo);
    formData.append("symbolNoUniversity", data.symbolNo);
    formData.append("campusRolNo", data.campusRollNo);
    formData.append("enrolledYear", data.enrolledYear);
    formData.append("passedYear", data.passedYear);
    formData.append("division", data.division);
    formData.append("gpa", data.gpa);
    formData.append("fatherName", data.fatherName);
    formData.append("motherName", data.motherName);
    formData.append("contactNo", data.contactNumber);
    if (data.uploadSignature?.[0]) {
        formData.append("uploadSignature", data.uploadSignature[0]);
    }
    if (data.uploadPPSizePhoto?.[0]) {
        formData.append("uploadPPSizePhoto", data.uploadPPSizePhoto[0]);
    }
    if (data.uploadReceipt?.[0]) {
        formData.append("uploadReceipt", data.uploadReceipt[0]);
    }
    if (data.uploadTranscript?.[0]) {
        formData.append("uploadTranscript", data.uploadTranscript[0]);
    }
    if (data.uploadOtherDoc?.[0]) {
        formData.append("uploadOtherDoc", data.uploadOtherDocs[0]);
    }
    formData.append("remarks", data.remarks || "");

    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.put(`${backendUrl}/Graduation/${id}`, formData, config);

      toast.success("Graduation Details updated successfully", {
        autoClose: 1500,
      });
      onUpdate();
      onClose();
    } catch (error) {
      toast.error("Error updating graduation Data: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogContent>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ textAlign: "center", color: "#2A629A", padding: "10px" }}
      >
        Edit Graduation Details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small" error={!!errors.fiscalYearId}>
                        <InputLabel required id="fiscalYearId-label">Fiscal Year</InputLabel>
                        <Controller
                          name="fiscalYearId"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Fiscal Year is required" }}
                          render={({ field }) => (
                          <Select
                          {...field}
                          id="fiscalYearId"
                          labelId="fiscalYearId-label"
                          label="Fiscal Year"
                          
                          >
                          {fiscalYears.map((fy) => (
                              <MenuItem key={fy.id} value={fy.id}>
                              {fy.yearNepali}
                              </MenuItem>
                          ))}
                          </Select>
                          )}
                        />
                  </FormControl>
                </Grid>

                {/* Student Name Dropdown */}
                <Grid item xs={12} sm={5}>
                <Controller
                    name="studentID" 
                    control={control}
                    rules={{ required: "Student Name is required" }} 
                    render={({ field }) => (
                    <Autocomplete
                        {...field} 
                        options={students} 
                        getOptionLabel={(option) =>
                        `${option.firstName} ${option.middleName || ""} ${
                            option.lastName
                        }`
                        } 
                        isOptionEqualToValue={(option, value) =>
                        option.id === value
                        } 
                        onChange={(_, value) => {
                        field.onChange(value ? value.id : null); 
                        }}
                        value={
                        students.find(
                            (student) => student.id === field.value
                        ) || null
                        } 
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Student Name"
                            required
                            size="small"
                            error={!!errors.studentID} 
                            helperText={errors.studentID?.message} 
                        />
                        )}
                    />
                    )}
                />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <Controller
                    name="applicantNameNep"
                    control= {control}
                    defaultValue=""
                    rules={{
                      required: "Applicant Name in Nepali is required",  }}
                  render={({ field }) => (
                  <TextField
                  {...field}
                      fullWidth
                      required
                      size="small"
                      label="Applicant Name (Nepali)"
                      type="text"
                      error={!!errors.applicantNameNep}
                      helperText={
                      errors.applicantNameNep &&
                      "applicant Name (Nepali) is required"
                      }
                  />
                     )}
                  />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <Controller
                    name="applicantNameEng"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Applicant Name in English is required" }}
                    render={({ field }) => (

                    <TextField
                    {...field}
                        fullWidth
                        required                   
                        size="small"
                        label="Applicant Name (English)"                   
                        error={!!errors.applicantNameEng}
                        helperText={
                        errors.applicantNameEng && "Applicant Name is required"
                        }
                    />
                    )}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Controller
                      name="dobEng"
                      control={control}
                      defaultValue=""
                      rules={{ required: "DOB in English is required" }}
                      render={({ field }) => (
                    <TextField
                    {...field}
                        fullWidth
                        required                   
                        size="small"
                        label="DOB (English)"
                        type="date"
                        InputLabelProps={{ shrink: true }}                     
                        error={!!errors.dobEng}
                        helperText={errors.dobEng && "Date of Birth is required"}
                    />
                    )}
                    />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Controller
                      name="dobNep"
                      control={control}
                      defaultValue=""
                      rules={{ required: "DOB in Nepali is required" }}
                      render={({ field }) => (
                <TextField
                {...field}
                    fullWidth
                    required                   
                    size="small"
                    label="DOB (Nepali)"
                    type="date"
                    InputLabelProps={{ shrink: true }}                  
                    error={!!errors.dobNep}
                    helperText={errors.dobNep && "Date of Birth is required"}
                />
                    )}
                 />
                </Grid>

                <Grid item xs={12} sm={5}>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Email is required" }}
                      render={({ field }) => (
                <TextField
                {...field}
                    fullWidth
                    required             
                    size="small"
                    label="Email"
                    type="email"              
                    error={!!errors.email}
                    helperText={errors.email && "Email is required"}
                />
                     )}
                     />
                </Grid>

                <Grid item xs={12} sm={3}>
                    <Controller
                      name="universityIssueNo"
                      control={control}
                      defaultValue=""
                      rules={{ required: "University Issue No is required" }}
                      render={({ field }) => (
                <TextField
                {...field}
                    fullWidth
                    required
                    size="small"               
                    label="University Issue No."
                    error={!!errors.universityIssueNo}
                    helperText={
                    errors.universityIssueNo &&
                    "University Issue No. is required"
                    }
                />
                    )}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Controller
                      name="studentRegNo"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Student Registration No is required" }}
                      render={({ field }) => (
                        <TextField
                        {...field}
                            fullWidth
                            required
                            size="small"                           
                            label="Student Registration Number"                         
                            error={!!errors.studentRegNo}
                            helperText={
                            errors.studentRegNo &&
                            "Student Registration Number is required"
                            }
                       />
                        )}
                        />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Controller
                      name="symbolNo"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Symbol No is required" }}
                      render={({ field }) => (
                <TextField
                {...field}
                    fullWidth
                    required
                    size="small"                
                    label="Symbol Number"              
                    error={!!errors.symbolNo}
                    helperText={
                    errors.symbolNo && "Symbol Number is required"
                    }
                />
                    )}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Controller
                      name="campusRollNo"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Campus Roll No is required" }}
                      render={({ field }) => (
                <TextField
                {...field}
                    fullWidth
                    required
                    size="small"         
                    label="Campus Roll No."                   
                    error={!!errors.campusRollNo}
                    helperText={
                    errors.campusRollNo && "Campus Roll No. is required"
                    }
                />
                    )}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Controller
                      name="enrolledYear"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Enrolled Year is required" }}
                      render={({ field }) => (
                <TextField
                {...field}
                    fullWidth
                    required
                    size="small"                 
                    label="Enrolled Year"
                    type="number"                  
                    error={!!errors.enrolledYear}
                    helperText={
                    errors.enrolledYear && "Enrolled Year is required"
                    }
                />
                    )}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Controller
                      name="passedYear"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Passed Year is required" }}
                      render={({ field }) => (
                <TextField
                {...field}
                    fullWidth
                    required
                    size="small"
                    label="Passed Year"                  
                    type="number"                   
                    error={!!errors.passedYear}
                    helperText={
                    errors.passedYear && "Passed Year is required"
                    }
                />
                     )}
                     />
                </Grid>

                <Grid item xs={12} sm={4}>
                <FormControl fullWidth size="small">
                    <InputLabel>Division</InputLabel>
                    <Controller
                      name="division"
                      control={control}
                      defaultValue=""
                        rules={{ required: "Division is required" }}
                        render={({ field }) => (
                    <Select
                    {...field}                  
                    defaultValue=""
                    label="Division"                   
                    >
                    {divisions.map((division, index) => (
                        <MenuItem key={index} value={division}>
                        {division}
                        </MenuItem>
                    ))}
                    </Select>
                       )}
                       />
                </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Controller
                      name="gpa"
                      control={control}
                      defaultValue=""
                      rules={{ required: "GPA is required" }}
                      render={({ field }) => (
                <TextField
                {...field}
                    fullWidth
                    required
                    size="small"                
                    label="GPA"
                    type="number"
                    step="0.01"                   
                    error={!!errors.gpa}
                    helperText={errors.gpa && "GPA is required"}
                />
                     )}
                     />
                </Grid>

                {/* Father Name */}

                <Grid item xs={12} sm={6}>
                <Controller
                     name="fatherName"
                     control={control}
                     defaultValue=""
                     rules={{ required: "Father Name is required" }}
                     render={({ field }) => (
                <TextField
                {...field}
        
                    fullWidth
                    required                   
                    size="small"
                    label="Father Name"                 
                    error={!!errors.fatherName}
                    helperText={
                    errors.fatherName && "Father's name is required"
                    }
                />
                     )}
                     />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Controller
                      name="motherName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Mother Name is required" }}
                      render={({ field }) => (
                <TextField
                {...field}
                    fullWidth
                    required
                    size="small"                  
                    label="Mother Name"                
                    error={!!errors.motherName}
                    helperText={
                    errors.motherName && "Mother's name is required"
                    }
                />
                    )}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Controller
                      name= "contactNumber"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Contact Number is required" }}
                      render={({ field }) => (             
                <TextField
                {...field}   
                    fullWidth
                    required
                    size="small"                  
                    label="Contact Number"
                    type="tel"              
                    error={!!errors.contactNumber}
                    helperText={
                    errors.contactNumber && "Contact Number is required"
                    }
                />
                    )}
                    />
                </Grid>

                <Grid item xs={4}>
                <TextField
                    {...register("uploadSignature", { required: false })}
                    id="uploadSignature"
                    size="small"
                    name="uploadSignature"
                    label="Upload Signature"
                    type="file"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.uploadSignature}
                    helperText={
                    errors.uploadSignature
                        ? "Upload Signature is required"
                        : ""
                    }
                />
                </Grid>

                <Grid item xs={4}>
                <TextField
                    {...register("uploadPhoto", { required: false })}
                    id="uploadPhoto"
                    size="small"
                    name="uploadPhoto"
                    label="Upload Photo"
                    type="file"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.uploadPhoto}
                    helperText={
                    errors.uploadPhoto ? "Upload Photo is required" : ""
                    }
                />
                </Grid>

                <Grid item xs={4}>
                <TextField
                    {...register("uploadReceipt", { required: false })}
                    id="uploadReceipt"
                    size="small"
                    name="uploadReceipt"
                    label="Upload Receipt"
                    type="file"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.uploadReceipt}
                    helperText={
                    errors.uploadReceipt ? "Upload Receipt is required" : ""
                    }
                />
                </Grid>

                <Grid item xs={4}>
                <TextField
                    {...register("uploadTranscript", { required: false })}
                    id="uploadTranscript"
                    size="small"
                    name="uploadTranscript"
                    label="Upload Transcript"
                    type="file"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.uploadTranscript}
                    helperText={
                    errors.uploadTranscript
                        ? "Upload Transcript is required"
                        : ""
                    }
                />
                </Grid>

                <Grid item xs={4}>
                <TextField
                    {...register("uploadOtherDocs", { required: false })}
                    id="uploadOtherDocs"
                    size="small"
                    name="uploadOtherDocs"
                    label="Upload OtherDocs"
                    type="file"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.uploadOtherDocs}
                    helperText={
                    errors.uploadOtherDocs
                        ? "Upload Other Docs is required"
                        : ""
                    }
                />
                </Grid>

                 <Grid item xs={12}>
                    <Controller
                    name= "remarks"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Remarks is required" }}
                    render={({ field }) => (
                    <TextField
                    {...field}
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        multiline
                        rows={1}
                        size="small"
                    />
                    )}
                    />
                    </Grid>

          <Grid container justifyContent="center" spacing={2} mt={2}>
            <Grid item>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </DialogContent>
  );
};

export default EditGraduationModule;
