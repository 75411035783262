import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Popover,
  Box,
  Grid,
} from "@mui/material";
import {
  FileDownload,
  InsertDriveFile,
  PictureAsPdf,
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  fetchReportLevelWiseUni,
  getCampusInUniByEcoBelts,
} from "../reportApi";

function UniLevelByEcobelts({ fiscalId }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const data = await getCampusInUniByEcoBelts(fiscalId);
    if (data) {
      const transformedData = transformApiResponse(data);
      setData(transformedData);
    } else {
      setData([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fiscalId]);

  const transformApiResponse = (apiData) => {
    return apiData.map((item) => ({
      university: item.universityName,
      bachelor: {
        hilly: item.programLevels["Bachelor"].hill,
        mountain: item.programLevels["Bachelor"].mountain,
        terai: item.programLevels["Bachelor"].tarai,
        total: item.programLevels["Bachelor"].total,
      },
      pgd: {
        hilly: item.programLevels["PGD"].hill,
        mountain: item.programLevels["PGD"].mountain,
        terai: item.programLevels["PGD"].tarai,
        total: item.programLevels["PGD"].total,
      },
      masters: {
        hilly: item.programLevels["Master"].hill,
        mountain: item.programLevels["Master"].mountain,
        terai: item.programLevels["Master"].tarai,
        total: item.programLevels["Master"].total,
      },
      mphil: {
        hilly: item.programLevels["MPhil"].hill,
        mountain: item.programLevels["MPhil"].mountain,
        terai: item.programLevels["MPhil"].tarai,
        total: item.programLevels["MPhil"].total,
      },
      phd: {
        hilly: item.programLevels["PhD."].hill,
        mountain: item.programLevels["PhD."].mountain,
        terai: item.programLevels["PhD."].tarai,
        total: item.programLevels["PhD."].total,
      },
      uniTotal:
        item.programLevels["Bachelor"].total +
        item.programLevels["PGD"].total +
        item.programLevels["MPhil"].total +
        item.programLevels["Master"].total +
        item.programLevels["PhD."].total,
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  const exportToExcel = () => {
    const header = [
      [
        "S.No.",
        "University",
        "Bachelor (Hill)",
        "Bachelor (Mountain)",
        "Bachelor (Terai)",
        "Bachelor (Total)",
        "PGD (Hill)",
        "PGD (Mountain)",
        "PGD (Terai)",
        "PGD (Total)",
        "Master (Hill)",
        "Master (Mountain)",
        "Master (Terai)",
        "Master (Total)",
        "MPhil (Hill)",
        "MPhil (Mountain)",
        "MPhil (Terai)",
        "MPhil (Total)",
        "PhD (Hill)",
        "PhD (Mountain)",
        "PhD (Terai)",
        "PhD (Total)",
        "University Total",
      ],
    ];
  
    const excelData = data.map((row, index) => [
      index + 1, // S.No.
      row.university, // University name
      row.bachelor.hilly, // Bachelor Hill
      row.bachelor.mountain, // Bachelor Mountain
      row.bachelor.terai, // Bachelor Terai
      row.bachelor.total, // Bachelor Total
      row.pgd.hilly, // PGD Hill
      row.pgd.mountain, // PGD Mountain
      row.pgd.terai, // PGD Terai
      row.pgd.total, // PGD Total
      row.masters.hilly, // Master Hill
      row.masters.mountain, // Master Mountain
      row.masters.terai, // Master Terai
      row.masters.total, // Master Total
      row.mphil.hilly, // MPhil Hill
      row.mphil.mountain, // MPhil Mountain
      row.mphil.terai, // MPhil Terai
      row.mphil.total, // MPhil Total
      row.phd.hilly, // PhD Hill
      row.phd.mountain, // PhD Mountain
      row.phd.terai, // PhD Terai
      row.phd.total, // PhD Total
      row.uniTotal, // University Total
    ]);
  
    // Dynamically calculate Grand Total
    const categories = ["bachelor", "pgd", "masters", "mphil", "phd"];
    const regions = ["hilly", "mountain", "terai", "total"];
    
    const grandTotalRow = [
      "", // Empty S.No. for Grand Total
      "Grand Total", // Grand Total label
      ...categories.flatMap((category) =>
        regions.map((region) =>
          data.reduce((sum, row) => sum + (row[category]?.[region] || 0), 0)
        )
      ),
      data.reduce((sum, row) => sum + (row.uniTotal || 0), 0), // University Total
    ];
  
    // Append Grand Total row
    excelData.push(grandTotalRow);
  
    // Create worksheet and workbook
    const ws = XLSX.utils.aoa_to_sheet([...header, ...excelData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Program Levels");
  
    // Export Excel file
    XLSX.writeFile(wb, "program_levels.xlsx");
  };
  return (
    <div>
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: "10px" }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#5FAD41" }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: "10px" }}
            >
              Excel
            </Button>
          </Box>
        </Popover>
      </Grid>

      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  textAlign: "center",
                  width: "1rem",
                }}
                rowSpan={2}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                University
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Bachelor
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                PGD
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Masters
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Mphil
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                PHD
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Hill
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Mountain
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Terai
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.university}>
                {/* University */}
                <TableCell
                  sx={{ border: "1px solid #c2c2c2", textAlign: "center" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #c2c2c2", textAlign: "left" }}
                >
                  {row.university}
                </TableCell>

                {/* Bachelor */}
                {Object.entries(row.bachelor).map(([key, val], i) => (
                  <TableCell
                    key={`bachelor-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}

                {/* PGD */}
                {Object.entries(row.pgd).map(([key, val], i) => (
                  <TableCell
                    key={`pgd-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}

                {/* Masters */}
                {Object.entries(row.masters).map(([key, val], i) => (
                  <TableCell
                    key={`masters-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}

                {/* MPhil */}
                {Object.entries(row.mphil).map(([key, val], i) => (
                  <TableCell
                    key={`mphil-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}

                {/* PhD */}
                {Object.entries(row.phd).map(([key, val], i) => (
                  <TableCell
                    key={`phd-${i}`}
                    sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}
                  >
                    {val}
                  </TableCell>
                ))}


              </TableRow>
            ))}
            <TableRow>
              <TableCell
                colSpan={2}
                sx={{
                  border: "1px solid #c2c2c2",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                Grand Total
              </TableCell>

              {/* Bachelor Totals */}
              {["bachelor", "pgd", "masters", "mphil", "phd"].map(
                (category) => {
                  return ["hilly", "mountain", "terai", "total"].map(
                    (region) => {
                      const total = data.reduce((sum, row) => {
                        return sum + (row[category][region] || 0);
                      }, 0);
                      return (
                        <TableCell
                          key={`${category}-${region}`}
                          sx={{
                            border: "1px solid #c2c2c2",
                            textAlign: "right",
                          }}
                        >
                          {total}
                        </TableCell>
                      );
                    }
                  );
                }
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default UniLevelByEcobelts;
