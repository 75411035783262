import { Divider, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export default function EmployeeReportsMenu({
  anchorElReport,
  handleCloseReportMenu,
}) {
  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElReport}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElReport)}
      onClose={handleCloseReportMenu}
    >
      <Grid>
        <Divider sx={{ color: "#01204E" }}>General</Divider>
      </Grid>

      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/employee-management/teacher-detail"
          color="inherit"
          size="small"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left", // Align text to the left
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          onClick={handleCloseReportMenu}
        >
          Teaching Staff
        </Typography>
      </MenuItem>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/employee-management/non-teaching-details"
          color="inherit"
          size="small"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left", // Align text to the left
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          onClick={handleCloseReportMenu}
        >
          Non-Teaching Staff
        </Typography>
      </MenuItem>
      <Grid>
        <Divider>
          <span style={{ color: "#01204E" }}>Summary</span>
        </Divider>
      </Grid>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/employee-management/teaching-staff-summary"
          color="inherit"
          size="small"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left",
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          onClick={handleCloseReportMenu}
        >
          Teaching Staff Summary  
        </Typography>
      </MenuItem>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/employee-management/non-teaching-staff-summary"
          color="inherit"
          size="small"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left",
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          onClick={handleCloseReportMenu}
        >
          Non-Teaching Staff Summary
        </Typography>
      </MenuItem>
    </Menu>
  );
}
