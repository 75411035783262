import {
  Box,
  Button,
  Dialog,
  Grid,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExamAppBar from "../navbar/ExamAppBar";
import { blue } from "@mui/material/colors";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import EditExamSchedule from "./EditExamSchedule";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExamScheduleList() {
  const [moduleData, setModuleName] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null); // Clear the selected ID
  };



  const fetchData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(`${backendUrl}/ExamSchedule`, config);
      setModuleName(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdate = async () => {
    await fetchData(); 
    setOpen(false); 
  };

  useEffect(() => {
    fetchData();
  }, []);
  

  const handleEditClick = (id) => {
    setSelectedId(id);
    setOpen(true); // Open the dialog
  };

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ExamAppBar />
        </Box>
      </Box>
      <Grid container alignItems="center" justifyContent="space-between" padding={2}>
        <Grid item xs>
          <Typography variant="h6" color={blue[700]} textAlign="center">
            These are the Scheduled Exams
          </Typography>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="primary"
            sx={{
              bgcolor: "#1976d2",
              color: "white",
              "&:hover": {
                bgcolor: "#1565c0",
              },
              padding: "6px 12px",
              borderRadius: 2,
            }}
            startIcon={<AddIcon />}
            component={Link}
            to={"/exam-management/exam-schedule"}
          >
            Schedule Exam
          </Button>
        </Grid>
      </Grid>

      <Grid justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <TableContainer sx={{borderRadius:2}}>
            <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    S.No
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Program Name
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Exam Type
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Exam Name
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Exam Start Date
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Exam End Date
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Nepali From Date
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Nepali To Date
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Description
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Status
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF", border: "1px solid #ddd", padding: "8px" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{bgcolor:'white'}}>
                {moduleData.length > 0 &&
                  moduleData.map((data, index) => {
                    const today = new Date();
                    const startDate = new Date(data.dateFrom);
                    const endDate = new Date(data.dateTo);

                    let status;
                    if (today < startDate) {
                      status = "Active";
                    } else if (today >= startDate && today <= endDate) {
                      status = "Running";
                    } else {
                      status = "Finished";
                    }

                    return (
                      <TableRow key={data.id}>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {index + 1}
                        </TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {data.programMgmt.programName}
                        </TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {data.examType}
                        </TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {data.examName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data?.dateFrom?.slice(0, 10)}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data?.dateTo?.slice(0, 10)}
                        </TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {data.dateFromNepali}
                        </TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {data.dateToNepali}
                        </TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          {data.description}
                        </TableCell>
                        <TableCell style={{ border: "1px solid #ddd", padding: "8px" }}>
                          <span
                            style={{
                              color:
                                status === "Active"
                                  ? "green"
                                  : status === "Running"
                                  ? "orange"
                                  : "gray",
                            }}
                          >
                            {status}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                         
                        >
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            sx={{
                              bgcolor: "#1976d2",
                              color: "white",
                              "&:hover": {
                                bgcolor: "#1565c0",
                              },
                              padding: "6px 12px",
                              borderRadius: 2,
                            }}
                            component={Link}
                            to={`/exam-management/exam-routine?examschedule=${data.id}`}
                            // onClick={() => handleSelected(data.id)}
                          >
                            Add Routine
                          </Button>
                          <Button
                            size="small"
                            color="primary"
                            sx={{
                              padding: "6px 12px",
                              borderRadius: 2,
                            }}
                            onClick={() => handleEditClick(data.id)}
                            variant="outlined"
                            startIcon={<EditNoteIcon />}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth="lg"
      >
        {selectedId && <EditExamSchedule selectedId={selectedId} onClose={handleClose} onUpdate={handleUpdate} />}
      </Dialog>

      
    </>
  );
}
