import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import SettingsMenu from "../../components/appBar/SettingsMenu";
import HomeIcon from "@mui/icons-material/Home";
import defaultTuLogo from "../../assets/defaultLogo.jpeg";
import profile from "../../assets/profile.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FaBuilding,
  FaClipboardList,
  FaCog,
  FaCogs,
  FaTachometerAlt,
  FaUserGraduate,
  FaUsers,
  FaUserShield,
  FaWpforms,
} from "react-icons/fa";

const baseUrl = process.env.REACT_APP_BASE_URL;

const modules = [
  {
    title: "Dashboard",
    icon: <FaTachometerAlt size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/",
    disabledRoles: [],
  },
  {
    title: "Program Setup",
    icon: <FaCogs size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/program-management",
    disabledRoles: ["SuperAdmin", "Admin"],
  },
  {
    title: "Other Setup",
    icon: <FaCog size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/other-setup",
    disabledRoles: ["SuperAdmin", ""],
  },
  {
    title: "Student",
    icon: <FaUserGraduate size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/student-management",
    disabledRoles: ["UniAdmin", ""],
  },
  {
    title: "Employee",
    icon: <FaUsers size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/employee-management",
    disabledRoles: ["UniAdmin", ""],
  },
  {
    title: "Budget",
    icon: <FaUsers size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/budget-management",
    disabledRoles: ["UniAdmin", "CollegeAdmin", "SuperAdmin"],
  },
  {
    title: "University/Campus",
    icon: <FaClipboardList size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/organization-management",
    disabledRoles: ["CollegeAdmin", "Admin", "UniAdmin"],
  },
  {
    title: "Infrastructure",
    icon: <FaBuilding size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/infrastructure-management",
    disabledRoles: ["Admin"],
  },
  {
    title: "Role/User",
    icon: <FaUserShield size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/user-management",
    disabledRoles: ["CollegeAdmin", "Admin"],
  },
  {
    title: "Exam",
    icon: <FaWpforms size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/exam-management",
    disabledRoles: ["Admin"],
  },
];

const reportModules = [
  {
    title: "Summary Report",
    icon: <FaUserShield size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/student-management/other-report",
    disabledRoles: [""],
  },
  {
    title: "Statistical Report",
    icon: <FaWpforms size={40} />,
    bgColor: "#1C375B",
    color: "#1C375B",
    link: "/form-management/statisticalReport",
    disabledRoles: [],
  },
];

function HomeAppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElReports, setAnchorElReports] = React.useState(null);
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;

  let logos;
  let instituteNames;
  let localLevels;
  let districts;

  if (currentUser && currentUser.listUser && currentUser.listUser.length > 0) {
    logos = currentUser?.institution?.logo || defaultTuLogo;
    instituteNames =
      currentUser.institution?.campusName ||
      currentUser.institution?.name ||
      "विश्वविद्यालय अनुदान आयोग";
    localLevels =
      currentUser.institution?.localLevel ||
      currentUser.institution?.palika ||
      "";
    districts = currentUser.institution?.district || "";
  }
  const handleOpenReportsMenu = (event) => {
    setAnchorElReports(event.currentTarget);
  };

  const handleCloseReportsMenu = () => {
    setAnchorElReports(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isDisabled = (module) => {
    return module.disabledRoles.includes(roleName);
  };

  return (
    <Box bgcolor="white">
      <Box
        position="fixed"
        sx={{
          width: "100%",
          top: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#01204E",
          height: "77px",
          bgcolor: "white",
        }}
      >
        <Container maxWidth="xl" sx={{ paddingBottom: "5px" }}>
          {currentUser && (
            <Toolbar disableGutters>
              <Link to="/" variant="body1" nowrap="true" component="a" href="/">
                <img
                  src={
                    currentUser?.institution?.logo
                      ? `${baseUrl}/${logos}`
                      : logos
                  }
                  alt="Logo"
                  style={{ marginRight: "8px", height: "75px" }}
                />
              </Link>

              <Box>
                <Typography color="#2b6eb5" variant="body1" fontWeight="bold">
                  {instituteNames}
                </Typography>
                {localLevels && districts ? (
                  <Typography variant="body2">
                    {localLevels}, {districts}
                  </Typography>
                ) : (
                  <Typography variant="body2" fontWeight="bold" color="#2b6eb5">
                    University Grants Commission
                  </Typography>
                )}
              </Box>
            </Toolbar>
          )}
          {!currentUser && (
            <Toolbar disableGutters>
              <IconButton sx={{ display: { xs: "none", md: "flex" }, mr: 5 }} />
              <Link
                to="/"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: "0 rem",
                  color: "#01204E",
                  textDecoration: "none",
                  marginLeft: "4rem",
                }}
              >
                <img
                  src={defaultTuLogo}
                  alt="Logo"
                  style={{ marginRight: "8px", height: "75px", padding: "5px" }}
                />
              </Link>

              <Box>
                <Typography color="#2b6eb5" variant="body1">
                  {instituteNames}
                </Typography>

                <Typography variant="body1">
                  University Grants Commission
                </Typography>
                <Typography variant="body2">Sanothimi, Bhaktapur</Typography>
              </Box>
            </Toolbar>
          )}
        </Container>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-30%, -50%)",
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
        >
          <Typography variant="h6" color="#2b6eb5" fontWeight="bold">
            Integrated Higher Education Management Information System
          </Typography>
          <Typography
            variant="h6"
            textAlign={"center"}
            color="#2b6eb5"
            fontWeight="bold"
          >
            (HEMIS)
          </Typography>
        </Box>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          top: "76px",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#FFF",
          bgcolor: "#2B6EB5",
          height: "41px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth={"xl"}>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                color="inherit"
                size="small"
                sx={{
                  "&:hover": {
                    bgcolor: "#1976d2",
                  },
                }}
                component={Link}
                to="/home"
              >
                <HomeIcon fontSize="small" />
              </IconButton>
              {currentUser && (
                <>
                  {modules.map((module, index) => {
                    const disabled = isDisabled(module);
                    return (
                      !disabled && (
                        <NavLink
                          key={index}
                          to={module.link}
                          style={({ isActive }) => ({
                            textDecoration: "none",
                            color: isActive ? "white" : "inherit",
                            textTransform: "capitalize",
                            padding: "0 12px",
                          })}
                        >
                          <Button
                            variant="text"
                            size="small"
                            sx={{
                              textTransform: "capitalize",
                              color: "inherit",
                              "&:hover": {
                                bgcolor: "#1976d2",
                              },
                              minWidth: "auto",
                              margin: "0",
                            }}
                          >
                            {module.title}
                          </Button>
                        </NavLink>
                      )
                    );
                  })}
                </>
              )}
              {currentUser && (
                <Button
                  variant="body2"
                  size="small"
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    textTransform: "capitalize",
                    "&:hover": {
                      bgcolor: "#1976d2",
                    },
                  }}
                  onClick={handleOpenReportsMenu}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  Reports
                </Button>
              )}

              <Menu
                anchorEl={anchorElReports}
                open={Boolean(anchorElReports)}
                onClose={handleCloseReportsMenu}
              >
                {currentUser && (
                  <>
                    {reportModules.map((module, index) => {
                      const disabled = isDisabled(module);
                      return (
                        !disabled && (
                          <MenuItem
                            key={index}
                            component={Link}
                            to={module.link}
                            onClick={handleCloseReportsMenu}
                          >
                            {module.title}
                          </MenuItem>
                        )
                      );
                    })}
                  </>
                )}
              </Menu>
            </Box>
            {currentUser && (
              <Box
                sx={{ flexGrow: 0 }}
                justifyContent="right"
                alignItems="right"
              >
                <Button
                  size="small"
                  variant="body2"
                  sx={{
                    mx: 0,
                    textDecoration: "none",
                    color: "inherit",
                    textTransform: "lowercase",
                    "&:hover": {
                      bgcolor: "#1976d2",
                    },
                  }}
                  target="blank"
                >
                  {currentUser?.email || "Email"}
                </Button>
              </Box>
            )}
            <Box sx={{ flexGrow: 0 }} justifyContent="right" alignItems="right">
              {!currentUser && (
                <Button
                  component={Link}
                  to="/login"
                  variant="standard"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    "&:hover": {
                      bgcolor: "#1976d2",
                    },
                  }}
                >
                  Login
                </Button>
              )}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              {currentUser && (
                <Tooltip title="Open settings">
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0 }}
                    size="small"
                  >
                    <Avatar src={profile} alt="Profile" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <SettingsMenu
        anchorElUser={anchorElUser}
        handleCloseUserMenu={handleCloseUserMenu}
      />
    </Box>
  );
}

export default HomeAppBar;
