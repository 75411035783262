import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const HeadTableCell = styled(TableCell)({
  fontSize: "16px",
  backgroundColor: "#2B6EB5",
  color: "white",
});

function UgcTable({ authToken }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) return;
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          `${backendUrl}/Employee/Report/GetEmployeesForAdmin`,
          config
        );
        setData(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [authToken]);

  // Calculate grand totals
  const grandTotals = {
    studentMale: 0,
    studentFemale: 0,
    studentOthers: 0,
    studentTotal: 0,
    teachingMale: 0,
    teachingFemale: 0,
    teachingOthers: 0,
    teachingTotal: 0,
    adminMale: 0,
    adminFemale: 0,
    adminOthers: 0,
    adminTotal: 0,
    totalCampuses: 0, // To store total number of campuses
  };

  data.forEach((institution) => {
    const studentMale =
      institution.students?.find((s) => s.gender === "male")?.count || 0;
    const studentFemale =
      institution.students?.find((s) => s.gender === "female")?.count || 0;
    const studentOthers =
      institution.students?.find((s) => s.gender === "other")?.count || 0;
    const studentTotal = studentMale + studentFemale + studentOthers;

    const teachingMale =
      institution.teachingStaff?.find((s) => s.gender === "male")?.count || 0;
    const teachingFemale =
      institution.teachingStaff?.find((s) => s.gender === "female")?.count || 0;
    const teachingOthers =
      institution.teachingStaff?.find((s) => s.gender === "other")?.count || 0;
    const teachingTotal = teachingMale + teachingFemale + teachingOthers;

    const adminMale =
      institution.nonTeachingStaff?.find((s) => s.gender === "male")?.count ||
      0;
    const adminFemale =
      institution.nonTeachingStaff?.find((s) => s.gender === "female")?.count ||
      0;
    const adminOthers =
      institution.nonTeachingStaff?.find((s) => s.gender === "other")?.count ||
      0;
    const adminTotal = adminMale + adminFemale + adminOthers;

    // Accumulate totals
    grandTotals.studentMale += studentMale;
    grandTotals.studentFemale += studentFemale;
    grandTotals.studentOthers += studentOthers;
    grandTotals.studentTotal += studentTotal;

    grandTotals.teachingMale += teachingMale;
    grandTotals.teachingFemale += teachingFemale;
    grandTotals.teachingOthers += teachingOthers;
    grandTotals.teachingTotal += teachingTotal;

    grandTotals.adminMale += adminMale;
    grandTotals.adminFemale += adminFemale;
    grandTotals.adminOthers += adminOthers;
    grandTotals.adminTotal += adminTotal;

    // Add to total campuses
    grandTotals.totalCampuses += institution.noOfCOllege || 0;
  });

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ minWidth: 650 }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
              rowSpan={2}
            >
              {" "}
              S.No{" "}
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
              rowSpan={2}
            >
              University
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
              rowSpan={2}
            >
              No of Campus
            </HeadTableCell>
            <HeadTableCell
              colSpan={4}
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Students
            </HeadTableCell>
            <HeadTableCell
              colSpan={4}
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Teaching Staff
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
              colSpan={4}
            >
              Non-Teaching Staff
            </HeadTableCell>
          </TableRow>
          <TableRow>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Male
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Female
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Others
            </HeadTableCell>

            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              total
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Male
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Female
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Others
            </HeadTableCell>

            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              total
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Male
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Female
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Others
            </HeadTableCell>

            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              total
            </HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((institution, index) => {
            const studentMale =
              institution.students?.find((s) => s.gender === "male")?.count ||
              0;
            const studentFemale =
              institution.students?.find((s) => s.gender === "female")?.count ||
              0;
            const studentOthers =
              institution.students?.find((s) => s.gender === "other")?.count ||
              0;
            const studentTotal = studentMale + studentFemale + studentOthers;

            const teachingMale =
              institution.teachingStaff?.find((s) => s.gender === "male")
                ?.count || 0;
            const teachingFemale =
              institution.teachingStaff?.find((s) => s.gender === "female")
                ?.count || 0;
            const teachingOthers =
              institution.teachingStaff?.find((s) => s.gender === "other")
                ?.count || 0;
            const teachingTotal =
              teachingMale + teachingFemale + teachingOthers;

            const adminMale =
              institution.nonTeachingStaff?.find((s) => s.gender === "male")
                ?.count || 0;
            const adminFemale =
              institution.nonTeachingStaff?.find((s) => s.gender === "female")
                ?.count || 0;
            const adminOthers =
              institution.nonTeachingStaff?.find((s) => s.gender === "other")
                ?.count || 0;
            const adminTotal = adminMale + adminFemale + adminOthers;

            return (
              <TableRow key={index}>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px" }}>
                  {index + 1}
                </TableCell>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px" }}>
                  {institution.institutionName}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {institution.noOfCOllege}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {studentMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {studentFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {studentOthers}
                </TableCell>

                <TableCell
                  sx={{
                    border: "1px solid #black",
                    padding: "4px",
                    textAlign: "right",
                    backgroundColor:'#c2c2c2',

                  }}
                >
                  {studentTotal}
                </TableCell>

                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {teachingMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {teachingFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {teachingOthers}
                </TableCell>

                <TableCell
                  sx={{
                    border: "1px solid #black",
                    padding: "4px",
                    textAlign: "right",
                    backgroundColor:'#c2c2c2',

                  }}
                >
                  {teachingTotal}
                </TableCell>

                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {adminMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {adminFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {adminOthers}
                </TableCell>

                <TableCell
                  sx={{
                    backgroundColor: "#c2c2c2",
                    border: "1px solid #black",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {adminTotal}
                </TableCell>
              </TableRow>
            );
          })}

          {/* Grand Total Row */}
          <TableRow>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                fontWeight: "bold",
                padding: "4px",
                textAlign: "center",
                backgroundColor: "#c2c2c2",
              }}
            >
              Grand Total
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.totalCampuses}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.studentMale}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.studentFemale}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.studentOthers}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.studentTotal}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.teachingMale}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.teachingFemale}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.teachingOthers}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.teachingTotal}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.adminMale}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.adminFemale}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.adminOthers}
            </TableCell>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                backgroundColor: "#c2c2c2",
              }}
            >
              {grandTotals.adminTotal}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UgcTable;
