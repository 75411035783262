import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import HomeAppBar from "../../../modules/navbar/HomeAppBar";
import EditBudgetMgmt from "./EditBudgetMgmt";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const BudgetMgmt = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    control,
  } = useForm();
  const [budgetData, setBudgetData] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState("");
  const [loading, setLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState(null);

  const fetchBudgetData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${backendUrl}/BudgetDisbursement`,
        config
      );
      setBudgetData(response.data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchDropdownData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const fiscalYearResponse = await axios.get(
        `${backendUrl}/FiscalYear`,
        config
      );
      const universityResponse = await axios.get(
        `${backendUrl}/University/GetAllUniversities`,
        config
      );
      setFiscalYears(fiscalYearResponse.data);
      setUniversities(universityResponse.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchBudgetData();
    fetchDropdownData();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const formData = {
        id: 0,
        universityId: parseInt(data.universityNameId, 10),
        fiscalYearId: parseInt(data.fiscalYearId, 10),
        regularBudget: parseFloat(data.regularBudget),
        developBudget: parseFloat(data.developBudget),
        otherBudget: data.otherBudget ? parseFloat(data.otherBudget) : 0,
        remarks: data.remarks || "",
      };

      const response = await axios.post(
        `${backendUrl}/BudgetDisbursement`,
        formData,
        config
      );

      if (response.status === 200) {
        toast.success("Budget submitted successfully!", {
          autoClose: 2000,
        });
        fetchBudgetData();
        reset();
      }
    } catch (err) {
      toast.error("Failed to submit budget. Please try again.", {
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  const regularBudget = parseFloat(watch("regularBudget") || 0);
  const developBudget = parseFloat(watch("developBudget") || 0);
  const otherBudget = parseFloat(watch("otherBudget") || 0);
  const totalBudget = regularBudget + developBudget + otherBudget;

  // Filter the budget data based on the selected fiscal year (fiscalYearNepali)
  const filteredBudgetData = selectedFiscalYear
    ? budgetData.filter((data) => data.fiscalYearNepali === selectedFiscalYear)
    : budgetData;

  const handleOpenEditDialog = (budget) => {
    setSelectedBudget(budget);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedBudget(null);
  };
  const handleUpdate = () => {
    fetchBudgetData(); // Fetch updated data after edit
  };

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={0}>
          <HomeAppBar />
        </Box>
      </Box>
      <Grid container spacing={2} sx={{ mt: 10 }}>
        <Grid item xs={false} md={1} />
        <Grid item xs={12} md={10}>
          <Paper elevation={5} sx={{ borderRadius: "20px" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Financing for Higher Education
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.universityNameId}
                    >
                      <InputLabel required id="universityName-label">
                        University Name
                      </InputLabel>
                      <Controller
                        name="universityNameId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            id="universityNameId"
                            labelId="universityName-label"
                            label="University Name"
                            defaultValue=""
                          >
                            <MenuItem value="" disabled>
                              Select University
                            </MenuItem>
                            {universities.map((uni) => (
                              <MenuItem key={uni.id} value={uni.id}>
                                {uni.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.fiscalYearId}
                    >
                      <InputLabel required id="fiscalYearId-label">
                        Fiscal Year
                      </InputLabel>
                      <Select
                        {...register("fiscalYearId", { required: true })}
                        id="fiscalYearId"
                        labelId="fiscalYearId-label"
                        defaultValue=""
                        label="Fiscal Year"
                      >
                        <MenuItem value="" disabled>
                          Select Fiscal Year
                        </MenuItem>
                        {fiscalYears.map((fy) => (
                          <MenuItem key={fy.id} value={fy.id}>
                            {fy.yearNepali}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      {...register("regularBudget")}
                      id="regularBudget"
                      size="small"
                      name="regularBudget"
                      type="number"
                      label="Regular Budget (NRS)"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      {...register("developBudget")}
                      id="developBudget"
                      size="small"
                      name="developBudget"
                      type="number"
                      label="Development Budget (NRS)"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      {...register("otherBudget")}
                      id="otherBudget"
                      size="small"
                      name="otherBudget"
                      type="number"
                      label="Other Budget (NRS)"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <TextField
                      id="totalBudget"
                      size="small"
                      name="totalBudget"
                      type="number"
                      label="Total Budget"
                      value={totalBudget.toFixed(2)}
                      fullWidth
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      {...register("remarks")}
                      id="remarks"
                      size="small"
                      name="remarks"
                      label="Remarks"
                      fullWidth
                      rows={2}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#007aff", color: "#inherit" }}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Paper>
         

  <Grid container alignItems="center" mt={4}>
       <Grid
    container
    alignItems="center"
    marginBottom="5px"
    justifyContent="flex-start" // Align the content to the left
    xs={12}
    md={3}
  >
    <FormControl size="small" fullWidth>
      <InputLabel id="fiscalYearFilter-label">Fiscal Year</InputLabel>
      <Select
        id="fiscalYearFilter"
        labelId="fiscalYearFilter-label"
        value={selectedFiscalYear}
        onChange={(e) => setSelectedFiscalYear(e.target.value)}
        label="F.Y"
      >
        <MenuItem value="" disabled>
          Select Fiscal Year
        </MenuItem>
        {fiscalYears.map((fy) => (
          <MenuItem key={fy.id} value={fy.yearNepali}>
            {fy.yearNepali}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Grid>

  <Grid container justifyContent="center" xs={12} md={6}>
    <Typography variant="h5" sx={{ color: "#2A629A" }}>
      Budget List
    </Typography>
  </Grid>
</Grid>

            <TableContainer>
              <Table
                style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
              >
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell
                      style={{ color: "#FFFFFF", border: "1px solid #ddd" }}
                    >
                      S.N
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                      }}
                    >
                      University Name
                    </TableCell>
                    <TableCell
                      style={{ color: "#FFFFFF", border: "1px solid #ddd" }}
                    >
                      Fiscal Year
                    </TableCell>
                    <TableCell
                      style={{ color: "#FFFFFF", border: "1px solid #ddd" }}
                    >
                      Regular Budget (NRS)
                    </TableCell>
                    <TableCell
                      style={{ color: "#FFFFFF", border: "1px solid #ddd" }}
                    >
                      Development Budget (NRS)
                    </TableCell>
                    <TableCell
                      style={{ color: "#FFFFFF", border: "1px solid #ddd" }}
                    >
                      Other Budget (NRS)
                    </TableCell>
                    <TableCell
                      style={{ color: "#FFFFFF", border: "1px solid #ddd" }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      style={{ color: "#FFFFFF", border: "1px solid #ddd" }}
                    >
                      Remarks
                    </TableCell>
                    <TableCell
                      style={{ color: "#FFFFFF", border: "1px solid #ddd" }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredBudgetData.map((data, index) => (
                    <TableRow key={data.id}>
                      <TableCell style={{ border: "1px solid #ddd" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #ddd",
                        }}
                      >
                        {data.universityName}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd" }}>
                        {data.fiscalYearNepali}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd" }}>
                        {data.regularBudget}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd" }}>
                        {data.developBudget}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd" }}>
                        {data.otherBudget}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd" }}>
                        {(
                          parseFloat(data.regularBudget) +
                          parseFloat(data.developBudget) +
                          parseFloat(data.otherBudget)
                        ).toFixed(2)}
                      </TableCell>
                      <TableCell style={{ border: "1px solid #ddd" }}>
                        {data.remarks || ""}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={() => handleOpenEditDialog(data)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      <EditBudgetMgmt
        open={openEditDialog}
        handleClose={handleCloseEditDialog}
        budgetData={selectedBudget}
        fetchBudgetData={fetchBudgetData}
        onUpdate={handleUpdate}
      />
    </>
  );
};

export default BudgetMgmt;
