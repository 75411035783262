import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  styled,
} from "@mui/material";
import { toast } from "react-toastify";
import {  getFiscalYearById, updateFiscalYearById } from "../../../services/services";




const EditArthikBarsa = ({ id, handleClose }) => {
  const [formData, setFormData] = useState({
    yearNepali: '',
    yearEnglish: '',
    activeFiscalYear: false,
    index: 0
  });
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const data = await getFiscalYearById(id);
          setFormData(data);
        } catch (error) {
          console.error('Error fetching team data:', error);
        }
      }
    };
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateFiscalYearById(id, formData);
      toast.success('Fiscal year updated successfully');
    } catch (error) {
      console.error('Error updating Fiscal year:', error);
      toast.error('Failed to update Fiscal year');
    }
    setTimeout(() => {
      handleClose()
    }, 600);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={12}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Edit Fiscal Year
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <TextField
                  required
                  id="yearNepali"
                  size="small"
                  name="yearNepali"
                  value={formData.yearNepali}
                  onChange={handleChange}
                  label="Fiscal Year (B.S.)"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  autoComplete="given-name"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  required
                  id="yearEnglish"
                  size="small"
                  name="yearEnglish"
                  value={formData.yearEnglish}
                  onChange={handleChange}
                  label="Fiscal Year (A.D.)"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item sm='2'>
                <TextField
                  required
                  id="index"
                  size="small"
                  type="number"
                  name="index"
                  InputLabelProps={{
                    shrink: true
                  }}
                  label="Index"
                  fullWidth
                  value={formData.index}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                    size="small"
                    label="Status"
                    name="activeFiscalYear"
                    fullWidth
                    value={formData.activeFiscalYear}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              sx={{ mt: 2 }}
            >
              <Button type='submit' size='small' variant='contained'>
                Edit
              </Button>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditArthikBarsa;
