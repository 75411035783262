import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  CardContent,
  InputLabel,
  FormControl,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const MgmtForm = () => {
  const { register, handleSubmit, reset, watch, control } = useForm();
  const [isPublished, setIsPublished] = useState(false);
  const [activityType, setActivityType] = useState("");

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    reset();
  };

  const handleActivityTypeChange = (event) => {
    setActivityType(event.target.value);
  };

  const handlePublishedChange = (event) => {
    setIsPublished(event.target.checked);
  };

  return (
    <Grid container spacing={2} sx={{ mt: 10 }}>
      <Grid item xs={false} md={2} />
      <Grid item xs={12} md={8}>
        <Paper elevation={5} sx={{ borderRadius: "20px" }}>
          <CardContent>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Research & Publication Management
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="memberName-label" required>Member Name</InputLabel>
                    <Select
                      
                      labelId="memberName-label"
                      {...register("memberName", { required: true })}
                      name="memberName"
                      label = "Member Name"
                  
                    >
                      <MenuItem value="Member1">Member1</MenuItem>
                      <MenuItem value="Member2">Member2</MenuItem>
                      <MenuItem value="Member3">Member3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    required
                    {...register("activityTitle", { required: true })}
                    label="Activity Title"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="activityType-label" required>Activity Type</InputLabel>
                    <Select
                      labelId="activityType-label"
                      {...register("activityType", { required: true })}
                      value={activityType}
                      onChange={handleActivityTypeChange}
                      name="activityType"
                      label = "Activity Type"
                      
                    >
                      <MenuItem value="Research">Research</MenuItem>
                      <MenuItem value="Publication">Publication</MenuItem>
                      <MenuItem value="Fellowship">Fellowship</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    {...register("startDate", { required: true })}
                    label="Start Date"
                    type="datetime-local"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    {...register("endDate", { required: true })}
                    label="End Date"
                    type="datetime-local"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPublished}
                        onChange={handlePublishedChange}
                      />
                    }
                    label="Published"
                  />
                </Grid>
                {isPublished && (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      {...register("publishedDate", { required: isPublished })}
                      label="Published Date"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    {...register("fundedDate", { required: true })}
                    label="Funded Date"
                    type="datetime-local"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    {...register("fundedAmount", { required: true })}
                    label="Funded Amount"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </Grid>
                {activityType === "Publication" && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        {...register("publishedAt")}
                        label="Published At"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="publicationType-label" required>
                          Publication Type
                        </InputLabel>
                        <Select
                          labelId="publicationType-label"
                          {...register("publicationType")}
                          label = "Publication Type"
                          fullWidth
                        >
                          <MenuItem value="Professional">Professional</MenuItem>
                          <MenuItem value="Memorial">Memorial</MenuItem>
                          <MenuItem value="Bulletin">Bulletin</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="publicationFrequency-label" required>
                          Publication Frequency
                        </InputLabel>
                        <Select
                          labelId="publicationFrequency-label"
                          {...register("publicationFrequency")}
                          label = "Publication Frequency"
                          fullWidth
                        >
                          <MenuItem value="Annual">Annual</MenuItem>
                          <MenuItem value="Biannual">Biannual</MenuItem>
                          <MenuItem value="Others">Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {activityType === "Fellowship" && (
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="fellowshipFor-label" required>Fellowship For</InputLabel>
                      <Select
                        labelId="fellowshipFor-label"
                        {...register("fellowshipFor")}
                        fullWidth
                      >
                        <MenuItem value="M. Phil">M. Phil</MenuItem>
                        <MenuItem value="PHD">PHD</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={5}>
                  <TextField
                    {...register("supportingMembers")}
                    label="Supporting Members"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register("remarks")}
                    label="Remarks"
                    fullWidth
                    size = "small"
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="outlined"
                    startIcon={<FileUploadIcon />}
                    fullWidth
                  >
                    Upload Thumbnail/Abstract Image
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="outlined"
                    startIcon={<FileUploadIcon />}
                    fullWidth
                  >
                    Upload PDF
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MgmtForm;
