import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  Chip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const EditExamSchedule = ({ selectedId, onClose, onUpdate }) => {
  const {
    control,
    handleSubmit,

    setValue,
    formState: { errors },
  } = useForm();

  const [programsData, setProgramsData] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };

        // Fetch programs and fiscal years
        const [programsResponse, fiscalYearsResponse] = await Promise.all([
          axios.get(`${backendUrl}/ProgramMgmt/GetCollegePrograms`, config),
          axios.get(`${backendUrl}/FiscalYear`, config),
        ]);

        setProgramsData(programsResponse.data);
        setFiscalYears(fiscalYearsResponse.data);

        // Set active fiscal year by default
        const activeFiscalYear = fiscalYearsResponse.data.find(
          (year) => year.activeFiscalYear === true
        );
        if (activeFiscalYear) {
          setValue("fiscalYearId", activeFiscalYear.id);
        }

        // Fetch existing exam schedule details
        const examResponse = await axios.get(
          `${backendUrl}/ExamSchedule/${selectedId}`,
          config
        );

        const examData = examResponse.data;
        console.log(examData);

        // Populate form fields using `setValue`
        setValue("fiscalYearId", examData.fiscalYearId || "");
        setValue("programId", examData.programMgmtId || "");
        setValue("examType", examData.examType || "");
        setValue("examName", examData.examName || "");
        setValue("dateFrom", examData.dateFrom?.slice(0, 10) || "");
        setValue("dateTo", examData.dateTo?.slice(0, 10) || "");
        setValue("dateFromNepali", examData.dateFromNepali || "");
        setValue("dateToNepali", examData.dateToNepali || "");
        setValue("status", examData.status || false);
        setValue("details", examData.description || "");
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchInitialData();
  }, [selectedId, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };

      const payload = {
        id: selectedId,
        fiscalYearId: data.fiscalYearId,
        programMgmtId: data.programId,
        examType: data.examType,
        examName: data.examName,
        dateFrom: new Date(data.dateFrom).toISOString(),
        dateTo: new Date(data.dateTo).toISOString(),
        dateFromNepali: data.dateFromNepali,
        dateToNepali: data.dateToNepali,
        status: data.status,
        description: data.details,
      };

      await axios.put(
        `${backendUrl}/ExamSchedule/${selectedId}`,
        payload,
        config
      );

      toast.success("Exam schedule updated successfully!", {
        autoClose: 1500,
      });

      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error("Error updating exam schedule:", error);
      toast.error("Failed to update exam schedule.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogContent fullWidth>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", color: "#2A629A" }}
          >
            Edit Exam Schedule
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              {/* Fiscal Year */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel required id="fiscalYearId-label">
                    Fiscal Year
                  </InputLabel>
                  <Controller
                    name="fiscalYearId"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Fiscal Year is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Fiscal Year"
                        labelId="fiscalYearId-label"
                      >
                        {fiscalYears.map((fy) => (
                          <MenuItem key={fy.id} value={fy.id}>
                            {fy.yearNepali}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Program */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel required>Program</InputLabel>
                  <Controller
                    name="programId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select {...field} label="Program">
                        {programsData.map((program) => (
                          <MenuItem key={program.id} value={program.id}>
                            {program.programName}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Exam Type */}
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel required>Exam Type</InputLabel>
                  <Controller
                    name="examType"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select {...field} label="Exam Type">
                        <MenuItem value="internal">Internal Exam</MenuItem>
                        <MenuItem value="board">External Exam</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Exam Name */}
              <Grid item xs={12} sm={6}>
                <Controller
                  name="examName"
                  control={control}
                  defaultValue=""
                  disabled
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Exam Name"
                      fullWidth
                      size="small"
                      error={!!errors.examName}
                      helperText={errors.examName && "Exam name is required"}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )}
                />
              </Grid>

              {/* Dates */}
              <Grid item xs={12} sm={3}>
                <Controller
                  name="dateFrom"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Date From"
                      type="date"
                      fullWidth
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Controller
                  name="dateTo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Date To"
                      type="date"
                      fullWidth
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              {/* Nepali Dates */}
              <Grid item xs={12} sm={3}>
                <Controller
                  name="dateFromNepali"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Date From Nepali"
                      type="date"
                      fullWidth
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Controller
                  name="dateToNepali"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Date To Nepali"
                      type="date"
                      fullWidth
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              {/* Status */}
              <Grid item xs={12} sm={2}>
                <Controller
                  name="status"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel>Status</InputLabel>
                      <Select {...field} label="Status">
                        <MenuItem value={true}>
                          <Chip label="Active" color="success" />
                        </MenuItem>
                        <MenuItem value={false}>
                          <Chip label="Inactive" color="error" />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <Controller
                  name="details"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      fullWidth
                      size="small"
                      multiline
                      rows={2}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* Buttons */}
            <Grid
              container
              justifyContent="center"
              spacing={2}
              style={{ marginTop: "20px" }}
            >
              <Grid item>
                <Button onClick={onClose} variant="outlined">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default EditExamSchedule;
