import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Grid, Paper, Typography } from "@mui/material";
import axios from "axios";  // Import axios
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const StudentByEthnicity = ({ authToken }) => {
  const [studentsData, setStudentsData] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status

  useEffect(() => {
    if (!authToken) return;

    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true when fetching data
        const response = await axios.get(`${backendUrl}/Dashboard/GetStudentByEthnicity`, {
          headers: {
            Authorization: `Bearer ${authToken}`,  // Ensure the token is sent in the header
          },
        });
        setStudentsData(response.data); // Set data to state
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
  }, [authToken]); // Re-run when authToken changes

  const generateChartOption = () => {
    if (!studentsData || studentsData.length === 0) {
      // If data is not available or empty, return an empty chart configuration
      return {};
    }

    // Filter out items where total is zero
    const filteredData = studentsData.filter((item) => item.total > 0);

    const categories = filteredData.map((item) => item.title);
    const seriesData = [
      {
        name: "Total Students",
        type: "bar",
        barWidth: 35,
        data: filteredData.map((item) => item.total || 0),
        itemStyle: { color: "#1976d2" },
        label: { show: true, position: "top", color: "#000" },
      },
    ];

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: seriesData.map((series) => series.name),
        textStyle: { color: "#2E294E" },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: {
        type: "category",
        data: categories,
        axisLabel: { color: "#333", rotate: 15 },
        name: "Ethnicity",
        nameLocation: "middle",
        nameGap: 35,
      },
      yAxis: {
        type: "value",
        axisLine: { show: true },
        name: "No. of Students",
        nameLocation: "middle",
        nameGap: 35,
        axisLabel: { color: "#333" },
      },
      series: seriesData,
    };
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={5} sx={{ padding: "20px" }}>
          <Typography
            variant="body1"
            align="center"
            fontWeight={900}
            style={{ padding: "1rem" }}
          >
            Students by Ethnicity
          </Typography>

          {loading ? (
            <Typography align="center">Loading...</Typography>
          ) : (
            <ReactECharts
              option={generateChartOption()}
              style={{ height: "400px" }}
              opts={{ renderer: "canvas" }}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StudentByEthnicity;
