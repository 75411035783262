import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getStudents() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Student/GetAllStudent`,
      config
    );
    return response.data; // Assuming response.data is an array of students
  } catch (err) {
    console.error("Error fetching students:", err);
    throw err; // Rethrow the error so the component can handle it
  }
}
export async function getVerifiedStudents() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Student/GetAllStudent?isVerified=true`,
      config
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching students:", err);
    throw err; 
  }
}
export async function getUpgradeStudents() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${backendUrl}/StudentUpgrade`, config);
    return response.data;
  } catch (err) {
    console.error("Error fetching students:", err);
    throw err;
  }
}
export async function upgradeStudents(upgradeData) {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.post(
      `${backendUrl}/StudentUpgrade`,
      upgradeData,
      config
    );
    return response.data;
  } catch (err) {
    console.error("Error upgrading students:", err);
    throw err;
  }
}

export async function getStudentByGender(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Student/GetTotalStudents`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getStudentByPrograms(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Student/GetStudentsByProgram`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getStudentByFacultyAndEthnicity(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Student/GetStudentsByFacultyAndEthnicity`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getStudentByEthnicity(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Student/GetStudentsByEthnicity`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getJoiningTypeByEmployeeType(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Employee/GetJoiningTypeByEmployeeType`,
      config
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching data:", err);
    throw err;
  }
}

export async function getEmployeeByType(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Employee/GetNoOfPermanentStaff`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getStudentByFaculty(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Student/GetStudentsByFacultyForDahsboard`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getStudentByLevelDashboard(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Student/GetStudentsByLevelForDahsboard`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getStudentsByEthnicityDashboard() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Dashboard/GetStudentByEthnicity`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getEmployees() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Employee/GetAllEmployeees`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getEmployeeByGender(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Employee/Report/EmployeeType`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getCampusByType(authToken) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Dashboard/GetNoOfCollgeForUniAndAdmin`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
export async function getAdminProfile() {
  try {
    const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = JSON.parse(localStorageData.user);
    const authToken = userState.currentUser.tokenString;
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(
      `${backendUrl}/Student/GetUserDetails`,
      config
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
}
