import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  FileDownload,
  PictureAsPdf,
  InsertDriveFile,
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getStudentOfNonTechnicalFaculty, getStudentOfTechnicalFaculty } from "./reportApi";


const EnrollmentByFacultyType = ({ fiscalId }) => {
  const [techFacultyStudent, setTechFacultyStudent] = useState([])
  const [nonTechFacultyStudent, setNonTechFacultyStudent] = useState([])

  const fetchTechnicalStudents = async (fiscalId) => {
    const response = await getStudentOfTechnicalFaculty(fiscalId)
    response ? setTechFacultyStudent(response) : setTechFacultyStudent([])

  }

  const fetchNonTechnicalStudents = async (fiscalId) => {
    const response = await getStudentOfNonTechnicalFaculty(fiscalId)
    response ? setNonTechFacultyStudent(response) : setNonTechFacultyStudent([])

  }
  useEffect(() => {
    fetchNonTechnicalStudents()
    fetchTechnicalStudents()
  }, [fiscalId])

  const technicalTotals = techFacultyStudent.reduce((acc, curr) => {
    acc.male += curr.male;
    acc.female += curr.female;
    acc.others += curr.others;
    acc.total += curr.total;
    return acc;
  }, { male: 0, female: 0, others: 0, total: 0 })

  const nonTechnicalTotals = nonTechFacultyStudent.reduce((acc, curr) => {
    acc.male += curr.male;
    acc.female += curr.female;
    acc.others += curr.others;
    acc.total += curr.total;
    return acc;
  }, { male: 0, female: 0, others: 0, total: 0 })

  const grandTotal = {
    male: technicalTotals.male + nonTechnicalTotals.male,
    female: technicalTotals.female + nonTechnicalTotals.female,
    others: technicalTotals.others + nonTechnicalTotals.others,
    total: technicalTotals.total + nonTechnicalTotals.total

  }
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet([
      ...techFacultyStudent,
      { title: "Technical Totals", ...technicalTotals },
      ...nonTechFacultyStudent,
      { title: "Non-Technical Totals", ...nonTechnicalTotals },
      { title: "Grand Total", ...grandTotal },
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "EnrollmentData");
    XLSX.writeFile(workbook, "EnrollmentData.xlsx");
  };
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Enrollment by Faculty Type", 14, 10);
  
    doc.autoTable({
      startY: 20,
      head: [["Faculty Type", "Male", "Female", "Others", "Total"]],
      body: [
        ...techFacultyStudent.map((faculty) => [
          faculty.title,
          faculty.male,
          faculty.female,
          faculty.others,
          faculty.total,
        ]),
        ["Technical Total", ...Object.values(technicalTotals)],
        ...nonTechFacultyStudent.map((faculty) => [
          faculty.title,
          faculty.male,
          faculty.female,
          faculty.others,
          faculty.total,
        ]),
        ["Non-Technical Total", ...Object.values(nonTechnicalTotals)],
        ["Grand Total", ...Object.values(grandTotal)],
      ],
      styles: {
        lineColor: "#c2c2c2", 
        lineWidth: 0.2,       
        cellPadding: 1,       
    },
    headStyles: {
       fontSize:8
    },
    bodyStyles: {
       fontSize:7
    },
    });
    doc.save("EnrollmentData.pdf");
  };
  
  return (
    <div>
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: "10px" }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#5FAD41" }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: "10px" }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#272727" }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
      </Grid>
      <Grid sm={12} display='flex' justifyContent='center'>
        <TableContainer sx={{ border: "1px solid #ddd", width: '80%' }}>
          <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
            <TableHead style={{ backgroundColor: "#2A629A" }}>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                  colSpan={6}
                >
                  Technical Faculties
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  S.No.
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Faculty
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Male
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Female
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Others
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  Total
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {techFacultyStudent.map((faculty, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                      width: '7%'
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {faculty.title}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      textAlign: "right",
                    }}
                  >
                    {faculty.male}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      textAlign: "right",
                    }}
                  >
                    {faculty.total.female}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      textAlign: "right",
                    }}
                  >
                    {faculty.others}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {faculty.total}
                  </TableCell>
                </TableRow>
              ))}

              {/* Technical Totals Row */}
              <TableRow style={{ backgroundColor: "#c2c2c2" }}>
                <TableCell
                  colSpan={2}
                  sx={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  Technical Total
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {technicalTotals.male}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {technicalTotals.female}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {technicalTotals.others}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {technicalTotals.total}
                </TableCell>
              </TableRow>
            </TableBody>

            <TableHead style={{ backgroundColor: "#2A629A" }}>
              <TableRow>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                  colSpan={7}
                >
                  Non-technical Faculties
                </TableCell>
              </TableRow>
            </TableHead>


            <TableBody>
              {nonTechFacultyStudent.map((faculty, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {faculty.title}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      textAlign: "right",
                    }}
                  >
                    {faculty.male}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      textAlign: "right",
                    }}
                  >
                    {faculty.female}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      textAlign: "right",
                    }}
                  >
                    {faculty.others}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {faculty.total}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow style={{ backgroundColor: "#c2c2c2" }}>
                <TableCell
                  colSpan={2}
                  sx={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  Non Technical Total
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {nonTechnicalTotals.male}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {nonTechnicalTotals.female}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {nonTechnicalTotals.others}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {nonTechnicalTotals.total}
                </TableCell>
              </TableRow>
              <TableRow style={{ backgroundColor: "#c2c2c2" }}>
                <TableCell
                  colSpan={2}
                  sx={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                >
                  Grand Total
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {grandTotal.male}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {grandTotal.female}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {grandTotal.others}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {grandTotal.total}
                </TableCell>
              </TableRow>
            </TableBody>

          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};

export default EnrollmentByFacultyType;
