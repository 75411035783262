import axios from "axios"
const backendUrl = process.env.REACT_APP_BACKEND_URL; 


export async function getIncomeExpenditureEntry() {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/IncomeExpenditureEntry`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function getFinanceHead() {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/FinanceHead`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }