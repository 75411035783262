import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import logo from '../../assets/logo.jpg';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function PasswordReset() {
    const [formData, setFormData] = useState({
        email: '',
    });
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(
                `${backendUrl}/User/GetCodeUser?email=${encodeURIComponent(formData.email)}`,
                {},
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            if (response.status === 201) {
                setStatus('success');
            } else if (response.status === 404) {
                setStatus('error');
            } else {
                setStatus('error');
            }
        } catch (error) {
            setStatus('error');
        } finally {
            setLoading(false); // Set loading to false once request is completed
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        // Reset status when user starts typing again
        setStatus(null);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={5} sx={{ mt: '5rem', borderRadius: '15px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2rem' }}>
                    <img src={logo} alt='logo' style={{ height: '45px' }} />
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            size='small'
                            label="Please enter a valid email address"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <Typography color='primary' fontSize='12px'>
                            A reset link will be sent to your email if the account is found with the entered address.
                        </Typography>
                        <Button
                            type='submit'
                            variant='contained'
                            sx={{ width: '12rem', mx: 'auto', mt: '.7rem' }}
                            size='small'
                            disabled={loading} // Disable button when loading
                        >
                            {loading ? 'Sending...' : 'Send Reset Link'}
                        </Button>

                        {/* Success or Error Message */}
                        {status === 'success' && (
                            <Box sx={{ mt: '1rem', color: 'green', display: 'flex', alignItems: 'center' }}>
                                <CheckCircleIcon sx={{ mr: 1 }} />
                                <Typography variant="body2">
                                    A reset link has been sent to your email. Please Check your Email.
                                </Typography>
                            </Box>
                        )}
                        {status === 'error' && (
                            <Box sx={{ mt: '1rem', color: 'red', display: 'flex', alignItems: 'center' }}>
                                <ErrorIcon sx={{ mr: 1 }} />
                                <Typography variant="body2">
                                    Failed to send reset link. Please check the email address and try again.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}

export default PasswordReset;
