// 3.9 Report
import {
  Box,
  Button,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { generateProvinceAndMaleFemaleReport } from "./reportApi";

const ProvinceWiseEnrollment = ({ fiscalId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiData, setApiData] = useState([])

  const fetchData = async () => {
    const response = await generateProvinceAndMaleFemaleReport(fiscalId)
    response ? setApiData(response) : setApiData([])
    console.log(response)
  }

  useEffect(() => {
    fetchData()
  }, [fiscalId])

  const rows = apiData.map((item) => (
    {
      province: item.provinceName,
      constituteMale: item.constituent.male,
      constituteFemale: item.constituent.female,
      constituteOther: item.constituent.other,
      constituteTotal: item.constituent.total,

      privateMale: item.private.male,
      privateFemale: item.private.female,
      privateOther: item.private.other,
      privateTotal: item.private.total,

      communityMale: item.community.male,
      communityFemale: item.community.female,
      communityOther: item.community.other,
      communityTotal: item.community.total,
    }
  ))

  const totals = rows.reduce(
    (acc, row) => {
      acc.constituteMale += row.constituteMale;
      acc.constituteFemale += row.constituteFemale;
      acc.constituteOther += row.constituteOther;
      acc.constituteTotal += row.constituteTotal;

      acc.privateMale += row.privateMale;
      acc.privateFemale += row.privateFemale;
      acc.privateOther += row.privateOther;
      acc.privateTotal += row.privateTotal;

      acc.communityMale += row.communityMale;
      acc.communityFemale += row.communityFemale;
      acc.communityOther += row.communityOther;
      acc.communityTotal += row.communityTotal;

      acc.grandTotal +=
        row.constituteTotal + row.privateTotal + row.communityTotal;

      return acc;
    },
    {
      constituteMale: 0,
      constituteFemale: 0,
      constituteOther: 0,
      constituteTotal: 0,
      privateMale: 0,
      privateFemale: 0,
      privateOther: 0,
      privateTotal: 0,
      communityMale: 0,
      communityFemale: 0,
      communityOther: 0,
      communityTotal: 0,
      grandTotal: 0,
    }
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rows);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CampusTypeByFaculty");
    XLSX.writeFile(workbook, "CampusTypeByFaculty.xlsx");
    handleClose();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Campus Type By Faculty", 14, 16);
    const tableData = rows.map((row, index) => [
      index + 1,
      row.province,
      row.constituteMale,
      row.constituteFemale,
      row.constituteTotal,
      row.privateMale,
      row.privateFemale,
      row.privateTotal,
      row.communityMale,
      row.communityFemale,
      row.communityTotal,
      row.constituteTotal+row.privateTotal+ row.communityTotal,
    ]);

    // Add grand totals as the last row
    tableData.push([
      "",
      "Grand Total",
      totals.constituteMale,
      totals.constituteFemale,
      totals.constituteTotal,
      totals.privateMale,
      totals.privateFemale,
      totals.privateTotal,
      totals.communityMale,
      totals.communityFemale,
      totals.communityTotal,
      totals.grandTotal,
    ]);

    doc.autoTable({
      head: [
        [
          "S.No.",
          "Faculty",
          "Con.Male",
          "Con.Female",
          "Con.Total",
          "Pvt.Male",
          "Pvt.Female",
          "Pvt.Total",
          "Com.Male",
          "Com.Female",
          "Com.Total",
          'Total'
        ],
      ],
      body: tableData,
      startY: 30,
      styles: {
        lineColor: "#c2c2c2",
        lineWidth: 0.2,
        cellPadding: 1,
      },
      headStyles: {
        fontSize: 8
      },
      bodyStyles: {
        fontSize: 7
      },
    });
    doc.save("province_wise.pdf");
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;
  return (
    <div>
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: '10px' }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ padding: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: '#5FAD41' }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: '10px' }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#272727' }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
      </Grid>
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={3}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={3}
              >
                Province
              </TableCell>

            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Constituent Campus
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Private Campus
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Community Campus
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                Total
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Other
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>

              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Other
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Other
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "left",
                  }}
                >
                  {row.province}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteOther}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateOther}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityOther}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteTotal + row.privateTotal + +row.communityTotal}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ backgroundColor: "#c2c2c2" }}>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={2}
              >
                Grand Total
              </TableCell>

              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.constituteMale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.constituteFemale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.constituteOther}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.constituteTotal}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.privateMale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.privateFemale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.privateOther}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.privateTotal}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.communityMale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.communityFemale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.communityOther}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.communityTotal}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: 'right'
                }}
              >
                {totals.grandTotal}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProvinceWiseEnrollment;
