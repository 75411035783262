import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  DialogContent,
  IconButton,

} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getCampusById } from "../../../services/services";
import EditCampusInfo, { EditCampusInfoProvider } from "../../../components/campusRegister/campus/EditCampusInfo";
import EditCampusContactDetails, { EditCampusContactProvider } from "../../../components/campusRegister/campus/EditCampusContactDetails";
import EditCampusReviewDetails from "../../../components/campusRegister/campus/EditCampusDetailsReview";

const steps = ["Campus Info", "Estd. & Bank Details", "Review Details"];

const EditCampus = ({ setOpenEditDialog, id, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [campusById, setCampusById] = useState([]);

  const fetchData = async () => {
    try {
      const getUniversityData = await getCampusById(id);
      setCampusById(Array.isArray(getUniversityData) ? getUniversityData : [getUniversityData]);
    } catch (error) {
      console.log('error');
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleClose = () => {
    setOpenEditDialog(false);
  };

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <EditCampusInfoProvider>
      <EditCampusContactProvider>
        <DialogContent sx={{ padding: '0px' }}>
          <Grid container paddingX='10px'>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" mb={1}>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                {campusById && campusById.map((data) => (
                  <Typography key={data.id} variant="h6" textAlign="center" sx={{ color: "#2A629A", mt: '-15px' }}
                  >
                    Edit {data.campusName}
                  </Typography>
                ))}
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>
                        <span
                          style={{
                            fontSize: '0.8rem',
                            color: index <= activeStep ? "#007aff" : "inherit",
                          }}
                        >
                          {label}
                        </span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <Box mt={4}>
                  {activeStep === 0 && (
                    <EditCampusInfo
                      handleNext={handleNext}
                      handleBack={handleBack}
                      id={id}
                    />
                  )}
                  {activeStep === 1 && (
                    <EditCampusContactDetails
                      handleNext={handleNext}
                      handleBack={handleBack}
                      id={id}
                    />
                  )}
                  {activeStep === 2 && (
                    <EditCampusReviewDetails handleBack={handleBack} handleClose={handleClose} onClose={onClose} id={id} />
                  )}
                </Box>

            </Grid>
          </Grid>
        </DialogContent>
      </EditCampusContactProvider>
    </EditCampusInfoProvider>
  );
};

export default EditCampus;
