import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { ToWords } from "to-words";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReceiptNavBar from "../../../modules/navbar/ReceiptNavBar";
import { useSelector } from "react-redux";
const baseUrl = process.env.REACT_APP_BASE_URL;
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ReceiptPdfExport = () => {
  const toWords = new ToWords();
  const [receiptData, setReceiptData] = useState(null);
  const { id } = useParams();
  const { currentUser } = useSelector((state) => state.user);
  const Logo = currentUser?.institution?.logo;
  const campusName = currentUser?.institution?.campusName;
  const localLevel = currentUser?.institution?.localLevel;
  const district = currentUser?.institution?.district;
  const userName = currentUser?.listUser[0]?.roleName;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  useEffect(() => {
    const fetchReceiptData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const response = await axios.get(`${backendUrl}/Receipt/${id}`, config);
        setReceiptData(response.data);
      } catch (error) {
        console.error("Error fetching receipt data:", error);
      }
    };

    fetchReceiptData();
  }, [id]);
  const amountPaid = parseFloat(receiptData?.amountPaid ?? 0); // Default to 0 if undefined

  // Convert amountPaid to words
  let words = "";
  if (!isNaN(amountPaid)) {
    words = toWords.convert(amountPaid, {
      currency: true,
      ignoreDecimal: true,
    });
  } else {
    words = "Invalid amount";
  }

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ReceiptNavBar />
        </Box>
      </Box>
      <div
        style={{
          minHeight: "100vh",
        }}
      >
        <Box sx={{ maxWidth: 1000, margin: "auto" }} ref={componentRef}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                bgcolor: "#1976d2",
                color: "white",
                "&:hover": {
                  bgcolor: "#1565c0",
                },
                borderRadius: 2,
              }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Box>
          <Card
            sx={{
              borderRadius: 4,
              boxShadow: 4,
              backgroundColor: "white",
              minHeight: "600px",
              border: "1px solid #eee",
              padding: 3,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginBottom: 1,
                }}
              >
                <img
                  src={`${baseUrl}/${Logo}`}
                  alt="campus Logo"
                  width="70px"
                  height="70px"
                />
              </Box>
              <Typography variant="body2" align="center">
                Tribhuvan University
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ fontWeight: 700 }}
              >
                {campusName}
                
              </Typography>
              <Typography
                variant="body2"
                align="center"
                sx={{ fontWeight: 400 }}
                gutterBottom
              >
                {localLevel}, {district}
                
              </Typography>

              <Typography align="center" gutterBottom>
                Student Income Receipt
              </Typography>

              <Divider style={{ margin: "20px 0" }} />
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    <strong>Receipt No:</strong> {receiptData?.receiptNo || ""}
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography variant="body2" textAlign={"right"}>
                    <strong>Date:</strong>{" "}
                    {receiptData?.dateOfPayment?.slice(0, 10) || ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left">
                  <Typography variant="body2">
                    <strong>Student Name:</strong> {receiptData?.applicantNameEng || ""}
                  </Typography>

                  <Typography variant="body2">
                   <strong>Roll No:</strong>  {receiptData?.rollNo || ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} align="left"></Grid>
              </Grid>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          width: "8%",
                          fontWeight:'bold'

                        }}
                      >
                        क्र.सं./S.No.
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "center",
                          fontWeight:'bold'
                        }}
                      >
                        विवरण/Particulars
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          width: "15%",
                          fontWeight:'bold'

                        }}
                      >
                        रकम/Amount
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          fontWeight:'bold'

                        }}
                      >
                        कैफियत/Remarks
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        1
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {receiptData?.receiptItem[0].name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "right",
                        }}
                      >
                        {"रू." + receiptData?.receiptItem[0].amount}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {receiptData?.remarks || "-"}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        colSpan={2}
                        align="right"
                        style={{
                          border: "1px solid #fff",
                          padding: "4px",
                          textAlign: "center",
                          backgroundColor: "#c2c2c2",
                        }}
                      >
                        जम्मा / Total:
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid #fff",
                          padding: "4px",
                          textAlign: "right",
                          backgroundColor: "#c2c2c2",
                        }}
                      >
                        {"रू." + receiptData?.amountPaid}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          border: "1px solid #fff",
                          padding: "4px",
                          textAlign: "left",
                          backgroundColor: "#c2c2c2",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Typography variant="body2" pt={2}>
                  Amount In Words: {words}
                </Typography>
              </TableContainer>
            </CardContent>
            <Divider sx={{ margin: "10px 0" }} />

            <Typography variant="body2" sx={{ marginTop: "20px" }}>
              <strong>Note:</strong> Please verify all details before final
              submission.
            </Typography>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginTop: "20px" }}
            >
              <Typography variant="body2">
                Printed by: &nbsp;
                <span style={{ textDecoration: "underline" }}>{userName}</span>
              </Typography>
              <Typography variant="body2">
                Signature: .....................................................
              </Typography>
            </Box>
          </Card>
        </Box>
      </div>
      <style>
        {`
          @media print {
            body {
              padding: 0 !important;
              margin: 0 !important;
            }
            .MuiCard-root {
              border: none;
              box-shadow: none;
              padding: 40px;
            }
            .MuiCardContent-root {
              padding: 0 !important;
            }
            .MuiTypography-body2 {
              font-size: 16px !important;
            }
            .MuiButton-root {
              display: none !important;
            }
            .MuiGrid-container {
              display: block !important;
            }
            .MuiGrid-item {
              display: inline-block !important;
              width: 48% !important;
              margin-right: 2% !important;
            }
            .MuiGrid-item:nth-child(2n) {
              margin-right: 0 !important;
            }
            .MuiTableCell-root {
              padding: 8px !important;
              font-size: 14px !important;
            }
            .MuiTableRow-root {
              border-bottom: 1px solid #ddd;
            }
            .MuiTableContainer-root {
              margin-top: 20px;
              margin-bottom: 20px;
            }
            .MuiTypography-h6, .MuiTypography-body2 {
              font-size: 18px !important;
            }
            .MuiTableHead-root {
              background-color: #f0f0f0 !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default ReceiptPdfExport;
