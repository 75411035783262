import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { Typography, Box } from "@mui/material";
import { blue } from "@mui/material/colors";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const StudentEnrollmentTrend = ({ authToken }) => {
  const [date,setDate] = useState({})
  const [chartData, setChartData] = useState({
    axisData: [],
    totalData: [],
    femaleData: [],
    maleData: [],
    loading: true,
    error: null,
  });

  useEffect(() => {
    if (!authToken) return;

    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };

        const response = await fetch(
          `${backendUrl}/Dashboard/Trend-summary`,
          config
        );
        const resData = await response.json();
        setDate({
          from:resData[0].fiscalYear,
          to:resData[resData.length-1].fiscalYear
        })
         console.log(resData)
        if (Array.isArray(resData)) {
          const axisData = resData.map((item) => item.fiscalYear);
          const maleData = resData.map((item) => item.maleStudents);
          const femaleData = resData.map((item) => item.femaleStudents);
          const totalData = maleData.map(
            (male, index) => male + femaleData[index]
          );
          setChartData({
            axisData,
            totalData,
            maleData,
            femaleData,
            loading: false,
            error: null,
          });
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setChartData((prevState) => ({
          ...prevState,
          loading: false,
          error: error.message,
        }));
      }
    };

    fetchData();
  }, [authToken]);
  
  console.log(date)
  useEffect(() => {
    if (chartData.loading || chartData.axisData.length === 0) {
      return;
    }

    const chartDom = document.getElementById("main");
    const myChart = echarts.init(chartDom);

    const option = {
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Total", "Male", "Female"],
        textStyle: {
          color: blue[700],
        },
      },
      grid: {
       
        containLabel: false,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },

      xAxis: [
        {
          type: "category",
          boundaryGap: false,
          data: chartData.axisData,
          name: "Fiscal Years",
          nameLocation: "middle",
          nameGap: 35,
        },
      ],
      yAxis: {
        type: "value",
        name: "No Of Students",
        nameLocation: "middle",
        nameGap: 35,
      },
      series: [
        {
          name: "Male",
          type: "line",
          data: chartData.maleData,
          color: "#1e88e5",
          symbolSize: 10,
          symbol: "circle",
          label: {
            show: true,
            position: "top",
            color: "#1e88e5",
            fontSize: 12,
          },
        },
        {
          name: "Female",
          type: "line",
          data: chartData.femaleData,
          color: "#e91e63",
          symbolSize: 10,
          symbol: "circle",
          label: {
            show: true,
            position: "top",
            color: "#e91e63",
            fontSize: 12,
          },
        },
        {
          name: "Total",
          type: "line",
          data: chartData.totalData,
          color: "#3e8e41",
          symbolSize: 10,
          symbol: "circle",
          label: {
            show: true,
            position: "top",
            color: "#3e8e41",
            fontSize: 12,
          },
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
  }, [chartData]);

  if (chartData.loading) {
    return (
      <Box>
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          color={blue[700]}
        >
          Loading data, please wait...
        </Typography>
      </Box>
    );
  }

  if (chartData.error) {
    return (
      <Box>
        <Typography variant="body1" align="center" gutterBottom color="error">
          Error: {chartData.error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "100%",
        }}
      >
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          color={blue[700]}
          style={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          Student Enrollment Trend From {date.from} to {date.to}
        </Typography>
        <div id="main" style={{ height: "400px" }}></div>
      </Box>
    </Box>
  );
};

export default StudentEnrollmentTrend;
