import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FileUploader from "../Reusable-component/FileUploader";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const StudentAcademicInfo = ({ studentId }) => {
  const [academicData, setAcademicData] = useState([]);
  const [transcriptFiles, setTranscriptFiles] = useState([]);
  const [provisionFiles, setProvisionFiles] = useState([]);
  const [migrationFiles, setMigrationFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { control, handleSubmit, formState: { errors }, reset, setFocus } = useForm();

  const onSubmit = (formData) => {
    console.log(formData)
    if (editIndex !== null) {
      const updatedAcademicData = academicData.map((data, index) =>
        index === editIndex ? formData : data
      );
      setAcademicData(updatedAcademicData);

      // Clear file inputs after updating the row
      setTranscriptFiles((files) => {
        const updatedFiles = [...files];
        updatedFiles[editIndex] = null;
        return updatedFiles;
      });
      setProvisionFiles((files) => {
        const updatedFiles = [...files];
        updatedFiles[editIndex] = null;
        return updatedFiles;
      });
      setMigrationFiles((files) => {
        const updatedFiles = [...files];
        updatedFiles[editIndex] = null;
        return updatedFiles;
      });

      setEditIndex(null);
    } else {
      setAcademicData([...academicData, formData]);
      setTranscriptFiles([...transcriptFiles, null]);
      setProvisionFiles([...provisionFiles, null]);
      setMigrationFiles([...migrationFiles, null]);
    }
    reset();
    setDialogOpen(true);
  };

  const handleFileChange = (type, file) => {
    // Update the appropriate file state based on the `type`
    if (type === 'transcript') {
      setTranscriptFiles((prevFiles) => [...prevFiles, file]);
    } else if (type === 'provision') {
      setProvisionFiles((prevFiles) => [...prevFiles, file]);
    } else if (type === 'migration') {
      setMigrationFiles((prevFiles) => [...prevFiles, file])

    }

  };

  const handleDialogClose = (addMore) => {
    setDialogOpen(false);
    if (addMore) {
      setFocus("educationLevel");
    }
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    const dataToEdit = academicData[index];
    reset(dataToEdit);
  };

  const handleFinalSubmit = async () => {
    setLoading(true);

    const formData = new FormData();
    academicData.forEach((data, index) => {
      formData.append(`dtos[${index}].studentId`, studentId);
      formData.append(`dtos[${index}].educationLevel`, data.educationLevel);
      formData.append(`dtos[${index}].institutionName`, data.institutionName);
      formData.append(`dtos[${index}].passedYear`, data.passedYear);
      formData.append(`dtos[${index}].fullMark`, data.fullMark);
      formData.append(`dtos[${index}].obtainedMark`, data.obtainedMark);
      formData.append(`dtos[${index}].gpa`, data.gpa);
      formData.append(`dtos[${index}].grade`, data.grade);
      formData.append(`dtos[${index}].regdNo`, data.regdNo);
      formData.append(`dtos[${index}].boardOfUniversity`, data.boardOfUniversity);
      if (transcriptFiles[index]) {
        formData.append(`transcriptFiles[${index}]`, transcriptFiles[index]);
      }
      if (provisionFiles[index]) {
        formData.append(`provFiles[${index}]`, provisionFiles[index]);
      }
    });

    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      await axios.post(
        `${backendUrl}/StuEdu`,
        formData, config,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      navigate('/student-management/verified-students')
      toast.success('Student Successfully registered.', { autoClose: 1500 });
      setAcademicData([]);
      setTranscriptFiles([]);
      setProvisionFiles([]);
      setMigrationFiles([]);
      reset();
    } catch (error) {
      console.error("Error posting data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid component="form" onSubmit={handleSubmit(onSubmit)} >
      <Grid container mt='.7rem' spacing={1} >
        <Grid item xs={12} sm={3}>
          <Controller
            name="educationLevel"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                id="educationLevel"
                size="small"
                name="educationLevel"
                label="Education Level"
                fullWidth
                error={!!errors.educationLevel}
                helperText={errors.educationLevel ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Controller
            name="institutionName"
            control={control}
            defaultValue=""
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                id="institutionName"
                size="small"
                name="institutionName"
                label="Institution Name"
                fullWidth
                error={!!errors.institutionName}
                helperText={errors.institutionName ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="passedYear"
            control={control}
            defaultValue=""
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                id="passedYear"
                size="small"
                type="number"
                name="passedYear"
                label="Passed Year"
                fullWidth
                error={!!errors.passedYear}
                helperText={errors.passedYear ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="fullMark"
            control={control}
            defaultValue=""
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                id="fullMark"
                size="small"
                name="fullMark"
                label="Full Mark"
                fullWidth
                InputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  },
                }}
                error={!!errors.fullMark}
                helperText={errors.fullMark ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="obtainedMark"
            control={control}
            defaultValue=""
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                id="obtainedMark"
                size="small"
                type="number"
                name="obtainedMark"
                label="Obtained Mark"
                fullWidth
                error={!!errors.obtainedMark}
                helperText={errors.obtainedMark ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="gpa"
            control={control}
            defaultValue=""
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="gpa"
                size="small"
                name="gpa"
                label="GPA"
                fullWidth
                error={!!errors.gpa}
                helperText={errors.gpa ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="grade"
            control={control}
            defaultValue=""
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="grade"
                size="small"
                name="grade"
                label="Grade/Division"
                fullWidth
                error={!!errors.grade}
                helperText={errors.grade ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="regdNo"
            control={control}
            defaultValue=""
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                id="regdNo"
                size="small"
                name="regdNo"
                label="Registration Number"
                fullWidth
                error={!!errors.regdNo}
                helperText={errors.regdNo ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="boardOfUniversity"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                required
                label="Board of University"
                id="boardOfUniversity"
                size="small"
                name="boardOfUniversity"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3} sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}>
          <FormControl>
            <FileUploader
              onFileChange={(file) => handleFileChange('transcript', file)}
              name="transcript"
              placeHolder="Transcript file (pdf)"
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}>
          <FormControl>
            <FileUploader
              onFileChange={(file) => handleFileChange('provision', file)}
              name="provision"
              placeHolder="Provision certificate image"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}>
          <FormControl>
            <FileUploader
              onFileChange={(file) => handleFileChange('migration', file)}
              name="migration"
              placeHolder="migration certificate image"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleSubmit(onSubmit)()}
            sx={{
              marginBottom: "10px",
            }}
          >
            {editIndex !== null ? "Update" : "Add"}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableHead style={{ backgroundColor: "#2A629A" }}>
              <TableRow>
                <TableCell style={tableCellStyle}>S.No.</TableCell>
                <TableCell style={tableCellStyle}>Education Level</TableCell>
                <TableCell style={tableCellStyle}>Institution Name</TableCell>
                <TableCell style={tableCellStyle}>Passed Year</TableCell>
                <TableCell style={tableCellStyle}>Full Mark</TableCell>
                <TableCell style={tableCellStyle}>Obtained Mark</TableCell>
                <TableCell style={tableCellStyle}>GPA</TableCell>
                <TableCell style={tableCellStyle}>Grade</TableCell>
                <TableCell style={tableCellStyle}>Registration Number</TableCell>
                <TableCell style={tableCellStyle}>Board of University</TableCell>
                <TableCell style={tableCellStyle}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ backgroundColor: "whitesmoke" }}>
              {academicData.length > 0 && academicData.map((data, index) => (
                <TableRow key={index}>
                  <TableCell style={tableBodyCellStyle}>{index + 1}</TableCell>
                  <TableCell style={tableBodyCellStyle}>{data.educationLevel}</TableCell>
                  <TableCell style={tableBodyCellStyle}>{data.institutionName}</TableCell>
                  <TableCell style={tableBodyCellStyle}>{data.passedYear}</TableCell>
                  <TableCell style={tableBodyCellStyle}>{data.fullMark}</TableCell>
                  <TableCell style={tableBodyCellStyle}>{data.obtainedMark}</TableCell>
                  <TableCell style={tableBodyCellStyle}>{data.gpa}</TableCell>
                  <TableCell style={tableBodyCellStyle}>{data.grade}</TableCell>
                  <TableCell style={tableBodyCellStyle}>{data.regdNo}</TableCell>
                  <TableCell style={tableBodyCellStyle}>{data.boardOfUniversity}</TableCell>
                  <TableCell style={tableBodyCellStyle}>
                    <Button onClick={() => handleEditClick(index)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container direction="row" alignItems="flex-start">
        <Grid item xs={12}>
          {academicData.length > 0 && (
            <Button
              type="button"
              variant="contained"
              size="small"
              sx={{
                bgcolor: "#2A629A",
                marginBottom: "10px",
                marginLeft: "10px",
                mt: '15px',
                position: 'relative', // To position the loader correctly
                // Disable the button while loading
                pointerEvents: loading ? 'none' : 'auto',
                opacity: loading ? 0.6 : 1,
              }}
              onClick={handleFinalSubmit}
              endIcon={loading ? <CircularProgress size={24} color="inherit" /> : ''}

            >
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          )}
        </Grid>
      </Grid>
      <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>Do you wish to add other information?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(true)} color="primary">
            Yes
          </Button>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const tableBodyCellStyle = {
  color: "black",
  border: "1px solid #c2c2c2",
  padding: "8px",
  height: "24px",
  textAlign: "center",
};

const tableCellStyle = {
  color: "whiteSmoke",
  border: "1px solid #c2c2c2",
  padding: "8px",
  height: "24px",
  textAlign: "center",
};

export default StudentAcademicInfo;