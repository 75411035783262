import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Box,
    Typography,
    Divider,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Grid,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { getFiscalYear } from "../../../services/services";
import HomeAppBar from "../../../modules/navbar/HomeAppBar";
import ProgramByEcobelts from "../CampusReport/ProgramByEcobelts";
import { useSelector } from "react-redux";
import TeachersByQualification from "./TeachersByQualification";
import DetailEnrollmentInProgram from "./DetailEnrollmentInProgram";
import GraduatedStdByLevelFaculty from "./GraduatedStdByLevelFaculty";
import CampusReportByProvince from "./CampusReportByProvince";
import TeachersByPosition from "./TeachersByPosition";
import StudentPassRateByProgram from "./StudentPassRateByProgram";
import TeachersByFacultyInCollege from "./TeachersByFacultyInCollege";
import AnnualStudentEnrollment from "./annual-reports/AnnualStudentEnrollment";

export default function CampusReportAccordion() {
    const { currentUser } = useSelector((state) => state.user);
    const [campusExpanded1, setCampusExpanded1] = React.useState(false);
    const [teacherExpanded, setTeacherExpanded] = React.useState(false);
    const [annualExpanded, setAnnualExpanded] = React.useState(false);
    // const [fiscalYearDetails, setFiscalYearDetails] = useState({})

    const [data, setData] = React.useState([]);
    const [selectedYear, setSelectedYear] = React.useState("");
    const [activeFiscalYear, setActiveFiscalYear] = useState({});
    const [selectedFiscalYearId, setSelectedFiscalYearId] = React.useState(0);

    const handleCampusExpanded = (panel) => (event, isExpanded) => {
        setCampusExpanded1(isExpanded ? panel : false);
    };
    const handleTeacherExpanded = (panel) => (event, isExpanded) => {
        setTeacherExpanded(isExpanded ? panel : false);
    };
    const handleAnnualExpanded = (panel) => (event, isExpanded) => {
        setAnnualExpanded(isExpanded ? panel : false);
    };
    const handleYearChange = (event) => {
        const selectedYearValue = event.target.value;
        const selectedYearItem = data.find(
            (item) => item.yearNepali === selectedYearValue
        );
        setSelectedYear(selectedYearValue);
        setSelectedFiscalYearId(selectedYearItem ? selectedYearItem.id : "");
    };
    React.useEffect(() => {
        const response = async () => {
            try {
                const fiscalYear = await getFiscalYear();
                const activeFiscalYear = fiscalYear.find(
                    (item) => item.activeFiscalYear === true
                );
                setData(fiscalYear);
                if (activeFiscalYear) {
                    setSelectedYear(activeFiscalYear.yearNepali);
                    setSelectedFiscalYearId(activeFiscalYear.id);
                    setActiveFiscalYear(activeFiscalYear);
                } else if (fiscalYear.length > 0) {
                    setSelectedYear(fiscalYear[0].yearNepali);
                    setSelectedFiscalYearId(fiscalYear[0].id);
                }
            } catch (err) {
                console.log(err);
            }
        };
        response();
    }, []);

    return (
        <>
            <Box
                sx={{
                    margin: 0,
                    padding: 0,
                    height: "100%",
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <Box mt={8}>
                    <HomeAppBar />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    minHeight: "100vh",
                    padding: 2,
                }}
            >
                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    sx={{ marginTop: 2, color: blue[700] }}
                >
                    Summary Report of {currentUser?.institution.campusName} (F.Y.
                    {selectedYear ? selectedYear : "Select Year"})
                </Typography>
                <Divider sx={{ marginBottom: 2, width: "100%" }} />
                <Grid container justifyContent="left" marginBottom={1}>
                    <FormControl fullWidth sx={{ maxWidth: "20%" }} size="small">
                        <InputLabel>Select Fiscal Year</InputLabel>
                        <Select
                            size="small"
                            label="Select Fiscal Year"
                            value={selectedYear}
                            onChange={handleYearChange}
                        >
                            <MenuItem disabled value="">
                                Select Fiscal Year
                            </MenuItem>
                            {data.map((item) => (
                                <MenuItem key={item.id} value={item.yearNepali}>
                                    {item.yearNepali}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid container>
                    <Grid border="3px solid #2b6eb5" item sm={12}>
                        <Typography
                            bgcolor="#2B6EB5"
                            fontSize="18px"
                            padding="10px"
                            textAlign="center"
                            color="white"
                        >
                            Summary Report for students
                        </Typography>
                        {[
                            {
                                title:
                                    "Summary of student enrollment  (Annex-5) - completed ",
                                component: (
                                    <DetailEnrollmentInProgram fiscalId={selectedFiscalYearId} />

                                ),
                            },
                            // {
                            //     title:
                            //         "Summary of student enrollment EthnicityWise  (Annex-5).",
                            //     component: (
                            //         <DetailEnrollmentWithEthnic fiscalId={selectedFiscalYearId} />
                            //         //  Dashboard/College/GetStudentByProgramLevelAndEthnicity
                            //     ),
                            // },
                            {
                                title:
                                    "Gender-wise student enrollment in different programs  based on ecological belt - completed",
                                component: (
                                    <ProgramByEcobelts fiscalId={selectedFiscalYearId} />
                                ),
                            },
                            {
                                title:
                                    "Students pass rate by Program - (completed with Demo Data)",
                                component: (
                                    <StudentPassRateByProgram fiscalId={selectedFiscalYearId} />
                                ),
                            },
                            {
                                title:
                                    "Number of graduated student by Faculty and level (completed with Demo Data)",
                                component: (
                                    <GraduatedStdByLevelFaculty fiscalId={selectedFiscalYearId} />
                                ),
                            },
                            {
                                title:
                                    "Student enrollment According to Province - completed",
                                component: (
                                    <CampusReportByProvince fiscalId={selectedFiscalYearId} />
                                ),
                            },

                        ].map((item, index) => (
                            <Accordion
                                key={index}
                                expanded={campusExpanded1 === `panel${index}`}
                                onChange={handleCampusExpanded(`panel${index}`)}
                                sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        "&:hover": { backgroundColor: "#e0e0e0" },
                                        borderRadius: 1,
                                        padding: "5px 12px",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: 500, color: blue[700] }}
                                    >
                                        {index + 1}.{item.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{ padding: 1, backgroundColor: "#fafafa" }}
                                >
                                    {item.component}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid border="3px solid #2b6eb5" item sm={12}>
                        <Typography
                            bgcolor="#2B6EB5"
                            fontSize="18px"
                            padding="10px"
                            textAlign="center"
                            color="white"
                        >
                            Summary Report for Teachers
                        </Typography>
                        {[
                            {
                                title:
                                    "Summary of Teaching staffs according to their qualification - completed",
                                component: (
                                    <TeachersByQualification fiscalId={selectedFiscalYearId} />
                                ),
                            },
                            {
                                title:
                                    "Summary of Teaching staffs by their Teaching faculty in the college - completed",
                                component: (
                                    <TeachersByFacultyInCollege />
                                ),
                            },
                            {
                                title:
                                    "Summary of Teaching staffs according to their position- completed",
                                component: (
                                    <TeachersByPosition fiscalId={selectedFiscalYearId} />
                                ),
                            }
                        ].map((item, index) => (
                            <Accordion
                                key={index}
                                expanded={teacherExpanded === `panel${index}`}
                                onChange={handleTeacherExpanded(`panel${index}`)}
                                sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
                                    aria-controls={`panel${index}-content`}
                                    id={`panel${index}-header`}
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        "&:hover": { backgroundColor: "#e0e0e0" },
                                        borderRadius: 1,
                                        padding: "5px 12px",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        sx={{ fontWeight: 500, color: blue[700] }}
                                    >
                                        {index + 1}.{item.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{ padding: 1, backgroundColor: "#fafafa" }}
                                >
                                    {item.component}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
