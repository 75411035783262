import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Typography,
  Divider,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";
import InstitutionsStatus from "./InstitutionsStatus";
import CampusInFaculty from "./CampusInFaculty";
import ProvinceWiseCampus from "./ProvinceWiseCampus";
import EnrollmentByFacultyType from "./EnrollmentByFacultyType";
import GenderWiseEnrollment from "./GenderWiseEnrollment";
import ProgramsByLevels from "./ProgramsByLevels";
import CampusTypeByFaculty from "./CampusTypeByFaculty";
import TeacherByCampusType from "./Staffs&Employees/TeacherByCampusType";
import UniversityEnrollmentByYear from "./UniversityEnrollmentByYear";
import CampusTypeEnrollmentByYear from "./CampusTypeEnrollmentByYear";
import { blue } from "@mui/material/colors";
import DetailEnrollmentByCampus from "./DetailEnrollmentByCampus";
import RegionWiseStudentCampus from "./RegionWiseStudentCampus";
import { getFiscalYear } from "../../services/services";
import ProvinceWiseStudents from "./ProvinceWiseStudents";
import UniLevelByEcobelts from "./EcobeltWise/UniLevelByEcobelts";
import HomeAppBar from "../../modules/navbar/HomeAppBar";
import TypeProvinceByEcobelts from "./EcobeltWise/TypeProvinceByEcobelts";
import TypeEcobelt from "./EcobeltWise/TypeEcobelt";
import GenderWiseCampType from "./EcobeltWise/GenderWiseCampType";
import ProvinceWiseEnrollment from "./ProvinceWiseEnrollment";
import ProvinceLevel from "./ProvinceLevel";
import StudentInFaculty from "./StudentInFaculty";
import StudentInLevel from "./StudentInLevel";
import StudentInPByF from "./StudentInPByF";
import TeacherByContract from "./Staffs&Employees/TeacherByContract";
import NonTeachingByPost from "./NonTeachingByPost";
import NumberCampusType from "../setup/graduationModule/NumberCampusType";
import BudgetReport from "../setup/budgetDisburshment/BudgetReport";
import ProgramByEcobelts from "./CampusReport/ProgramByEcobelts";

export default function AccordionUsage() {
  const [expanded, setExpanded] = React.useState(false);
  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(false);
  const [expanded4, setExpanded4] = React.useState(false);
  const [expanded5, setExpanded5] = React.useState(false);
  const [campusExpanded1, setCampusExpanded1] = React.useState(false);


  const [data, setData] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState("");
  const [activeFiscalYear, setActiveFiscalYear] = useState({});
  const [selectedFiscalYearId, setSelectedFiscalYearId] = React.useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded1(isExpanded ? panel : false);
  };
  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };
  const handleChange3 = (panel) => (event, isExpanded) => {
    setExpanded3(isExpanded ? panel : false);
  };
  const handleChange4 = (panel) => (event, isExpanded) => {
    setExpanded4(isExpanded ? panel : false);
  };
  const handleChange5 = (panel) => (event, isExpanded) => {
    setExpanded5(isExpanded ? panel : false);
  };
  const handleCampusExpanded = (panel) => (event, isExpanded) => {
    setCampusExpanded1(isExpanded ? panel : false);
  };
  const handleYearChange = (event) => {
    const selectedYearValue = event.target.value;
    const selectedYearItem = data.find(
      (item) => item.yearNepali === selectedYearValue
    );
    setSelectedYear(selectedYearValue);
    setSelectedFiscalYearId(selectedYearItem ? selectedYearItem.id : "");
  };
  React.useEffect(() => {
    const response = async () => {
      try {
        const fiscalYear = await getFiscalYear();
        const activeFiscalYear = fiscalYear.find(
          (item) => item.activeFiscalYear === true
        );
        setData(fiscalYear);
        if (activeFiscalYear) {
          setSelectedYear(activeFiscalYear.yearNepali);
          setSelectedFiscalYearId(activeFiscalYear.id);
          setActiveFiscalYear(activeFiscalYear);
        } else if (fiscalYear.length > 0) {
          setSelectedYear(fiscalYear[0].yearNepali);
          setSelectedFiscalYearId(fiscalYear[0].id);
        }
      } catch (err) {
        console.log(err);
      }
    };
    response();
  }, []);

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <HomeAppBar />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          minHeight: "100vh",
          padding: 2,
        }}
      >
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ marginTop: 2, color: blue[700] }}
        >
          Higher Education Data in Nepal (Fiscal Year{" "}
          {selectedYear ? selectedYear : "Select Year"})
        </Typography>
        <Divider sx={{ marginBottom: 2, width: "100%" }} />
        <Grid container justifyContent="left" marginBottom={1}>
          <FormControl fullWidth sx={{ maxWidth: "20%" }} size="small">
            <InputLabel>Select Fiscal Year</InputLabel>
            <Select
              size="small"
              label="Select Fiscal Year"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <MenuItem disabled value="">
                Select Fiscal Year
              </MenuItem>
              {data.map((item) => (
                <MenuItem key={item.id} value={item.yearNepali}>
                  {item.yearNepali}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid container>
          <Grid border="3px solid #2b6eb5" item sm={12}>
            <Typography
              bgcolor="#2B6EB5"
              fontSize="18px"
              padding="10px"
              textAlign="center"
              color="white"
            >
              Summary Report for Universities & Campuses
            </Typography>
            {[
              {
                title: `Status of higher education (institution, student & teacher in Nepal)  - F.Y. ${selectedYear} `,
                component: (
                  <InstitutionsStatus
                    selectedFiscalYearId={selectedFiscalYearId}
                  />
                ),
              },
              {
                title:
                  "Number of campuses by university with level and ecological belt (Table-2.2) ",
                component: (
                  <UniLevelByEcobelts fiscalId={selectedFiscalYearId} />
                ),
              },
              {
                title:
                  "Number of Campuses with Faculties by Provinces. (Table-2.3)",
                component: <CampusInFaculty />,
              },
              {
                title:
                  "Number of Campuses in each university by Provinces (Table - 2.6)",
                component: <ProvinceWiseCampus />,
              },
            ].map((item, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor: "#ffffff",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                    borderRadius: 1,
                    padding: "5px 12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, color: blue[700] }}
                  >
                    {index + 1}.{item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ padding: 1, backgroundColor: "#fafafa" }}
                >
                  {item.component}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>

        <Grid container>
          <Grid border="3px solid #2b6eb5" item sm={12}>
            <Typography
              bgcolor="#2B6EB5"
              fontSize="18px"
              padding="10px"
              textAlign="center"
              color="white"
            >
              Summary Report of Enrollment in Higher Education
            </Typography>
            {[
              {
                title:
                  "Number of students enrolled in Technical & Non-technical faculties (Table-3.4) ",
                component: (
                  <EnrollmentByFacultyType fiscalId={selectedFiscalYearId} />
                ),
              },
              // {
              //   title:
              //     "University-Wise total Enrollment and their share (%) in different Years. - no api",
              //   component: <UniversityEnrollmentByYear fiscalId={selectedFiscalYearId} />,
              // },
              {
                title:
                  "Student enrollment in different type of campus in different level (Table- 3.5 + 3.6)",
                component: (
                  <GenderWiseCampType fiscalId={selectedFiscalYearId} />
                ),
              },
              {
                title:
                  "Number of Students Enrolled in different level by University.(Table 3.7) ",
                component: <StudentInLevel fiscalId={selectedFiscalYearId} />,
              },
              {
                title: `Number of Students Enrolled in different faculties by Universities. (Table-3.8) -F.Y.${selectedYear}`,
                component: <StudentInFaculty fiscalId={selectedFiscalYearId} />,
              },
              {
                // no others
                title:
                  "Student enrollment in different faculties by types of Campus (Table-3.9)",
                component: (
                  <CampusTypeByFaculty fiscalId={selectedFiscalYearId} />
                ),
              },
              {
                title: `Number of students in each province by campus type and ecological belts (Table 2.5 + 2.9) - F.Y. ${selectedYear}`,
                component: (
                  <TypeProvinceByEcobelts fiscalId={selectedFiscalYearId} />
                ),
              },
              {
                // incorrect
                title:
                  "Number of students in each province by campus type and gender (Table-3.10)",
                component: (
                  <ProvinceWiseEnrollment fiscalId={selectedFiscalYearId} />
                ),
              },
              {
                title:
                  "Gender-Wise student enrollment in different campus in different levels (Table-3.5 + 3.6).",
                component: (
                  <GenderWiseCampType fiscalId={selectedFiscalYearId} />
                ),
              },
              {
                title:
                  "Gender-wise student enrollment in different types of campus based on ecological belt (Table- 3.2 + 3.3)",
                component: <TypeEcobelt fiscalId={selectedFiscalYearId} />,
              },
              {
                title:
                  "No. of Student enrolled in each Province in each faculty (Table-3.11)",
                component: <StudentInPByF fiscalId={selectedFiscalYearId} />,
              },
              {
                title:
                  "Student enrollment in different level in each province (Table-3.12)",
                component: <ProvinceLevel fiscalId={selectedFiscalYearId} />,
              },
              {
                title:
                  "Student enrollment in each province by university (Table-3.13) ",
                component: (
                  <ProvinceWiseStudents fiscalId={selectedFiscalYearId} />
                ),
              },
              // {
              //   title: "Number of campuses and Students in the ecological Belts. - completed",
              //   component: <RegionWiseStudentCampus fiscalId={selectedFiscalYearId} />,
              // },
            ].map((item, index) => (
              <Accordion
                key={index}
                expanded={expanded1 === `panel${index}`}
                onChange={handleChange1(`panel${index}`)}
                sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor: "#ffffff",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                    borderRadius: 1,
                    padding: "5px 12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, color: blue[700] }}
                  >
                    {index + 1}.{item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ padding: 1, backgroundColor: "#fafafa" }}
                >
                  {item.component}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
        <Grid container>
          <Grid border="3px solid #2b6eb5" item sm={12}>
            <Typography
              bgcolor="#2B6EB5"
              fontSize="18px"
              padding="10px"
              textAlign="center"
              color="white"
            >
              Summary Report for Teaching and Non-teaching staffs
            </Typography>
            {[
              {
                title:
                  "Number of different Level of teacher in different Types of Campus. (Table 9.1+10.1)  ",
                component: (
                  <TeacherByCampusType fiscalId={selectedFiscalYearId} />
                ),
              },
              {
                title:
                  "No of Teaching staffs in each university By Joining type",
                component: (
                  <TeacherByContract fiscalId={selectedFiscalYearId} />
                ),
              },
              {
                title:
                  "No of Non-teaching staff in each university By position Type ",
                component: (
                  <NonTeachingByPost fiscalId={selectedFiscalYearId} />
                ),
              },
            ].map((item, index) => (
              <Accordion
                key={index}
                expanded={expanded3 === `panel${index}`}
                onChange={handleChange3(`panel${index}`)}
                sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor: "#ffffff",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                    borderRadius: 1,
                    padding: "5px 12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, color: blue[700] }}
                  >
                    {index + 1}.{item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ padding: 1, backgroundColor: "#fafafa" }}
                >
                  {item.component}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>

        {[].map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                backgroundColor: "#ffffff",
                "&:hover": { backgroundColor: "#e0e0e0" },
                borderRadius: 1,
                padding: "5px 12px",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, color: blue[700] }}
              >
                {index + 1}.{item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 1, backgroundColor: "#fafafa" }}>
              {item.component}
            </AccordionDetails>
          </Accordion>
        ))}
        <Grid container>
          <Grid border="3px solid #2b6eb5" item sm={12}>
            <Typography
              bgcolor="#2B6EB5"
              fontSize="18px"
              padding="10px"
              textAlign="center"
              color="white"
            >
              Financing For the Higher Education
            </Typography>
            {[
              {
                title:
                  "Budget distributed to Universities In the Year 2081/082",
                component: <BudgetReport fiscalId={selectedFiscalYearId} />,
              },
              // {
              //   title:
              //     "Enrollment and share of students in different types of campuses in different years.",
              //   component: <CampusTypeEnrollmentByYear fiscalId={selectedFiscalYearId} />,
              // },
            ].map((item, index) => (
              <Accordion
                key={index}
                expanded={expanded5 === `panel${index}`}
                onChange={handleChange5(`panel${index}`)}
                sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor: "#ffffff",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                    borderRadius: 1,
                    padding: "5px 12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, color: blue[700] }}
                  >
                    {index + 1}.{item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ padding: 1, backgroundColor: "#fafafa" }}
                >
                  {item.component}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
        <Grid container>
          <Grid border="3px solid #2b6eb5" item sm={12}>
            <Typography
              bgcolor="#2B6EB5"
              fontSize="18px"
              padding="10px"
              textAlign="center"
              color="white"
            >
              Number Of Campuses In the Given Ranges.
            </Typography>
            {[
              {
                title: "Number Of Campuses In the Given Ranges.",
                component: <NumberCampusType fiscalId={selectedFiscalYearId} />,
              },
              // {
              //   title:
              //     "Enrollment and share of students in different types of campuses in different years.",
              //   component: <CampusTypeEnrollmentByYear fiscalId={selectedFiscalYearId} />,
              // },
            ].map((item, index) => (
              <Accordion
                key={index}
                expanded={expanded4 === `panel${index}`}
                onChange={handleChange4(`panel${index}`)}
                sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor: "#ffffff",
                    "&:hover": { backgroundColor: "#e0e0e0" },
                    borderRadius: 1,
                    padding: "5px 12px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 500, color: blue[700] }}
                  >
                    {index + 1}.{item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ padding: 1, backgroundColor: "#fafafa" }}
                >
                  {item.component}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>

          <Grid container>
            <Grid border="3px solid #2b6eb5" item sm={12}>
              <Typography
                bgcolor="#2B6EB5"
                fontSize="18px"
                padding="10px"
                textAlign="center"
                color="white"
              >
                Detail Enrollment Inormation Information in each campuses by
                university
              </Typography>
              {[
                {
                  title:
                    "Summary of student enrollment in each campuses (Annex-5).",
                  component: (
                    <DetailEnrollmentByCampus fiscalId={selectedFiscalYearId} />
                  ),
                },
                // {
                //   title:
                //     "Enrollment and share of students in different types of campuses in different years.",
                //   component: <CampusTypeEnrollmentByYear fiscalId={selectedFiscalYearId} />,
                // },
              ].map((item, index) => (
                <Accordion
                  key={index}
                  expanded={expanded2 === `panel${index}`}
                  onChange={handleChange2(`panel${index}`)}
                  sx={{ marginBottom: 1.5, width: "100%", borderRadius: 1 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: blue[700] }} />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      backgroundColor: "#ffffff",
                      "&:hover": { backgroundColor: "#e0e0e0" },
                      borderRadius: 1,
                      padding: "5px 12px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 500, color: blue[700] }}
                    >
                      {index + 1}.{item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ padding: 1, backgroundColor: "#fafafa" }}
                  >
                    {item.component}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
