import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CircularProgress, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/defaultLogo.jpeg";
import {
  signInFailure,
  signInStart,
  signInSuccess,
} from "../../redux/user/userSlice";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AdminLogin = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const { loading, currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath =
    new URLSearchParams(location.search).get("redirect") || "/";

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const cleanedFormData = {
      email: formData.email.trim(), 
      password: formData.password.trim(),
    };

    if (!cleanedFormData.email || !cleanedFormData.password) {
      toast.error("Email and password are required.", { autoClose: 1500 });
      return;
    }

    dispatch(signInStart());

    try {
      const response = await axios.post(`${backendUrl}/User/Login`, {
        ...cleanedFormData,
        rememberMe,
      });

      if (response.data) {
        const { name, value } = response.data;
        
        // If verificationToken is returned, navigate to reset password page
        if (name === "verificationToken" && value) {
          navigate(`/reset-password/${cleanedFormData.email}/${value}`);
        } else {
          dispatch(signInSuccess({ user: response.data, rememberMe }));
          navigate(redirectPath);
        }

        toast.success("Successfully Logged In!", { autoClose: 1500 });
      } else {
        throw new Error("Unexpected response format.");
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) {
          toast.error("Wrong Credentials. Email or Password is Wrong.");
        } else {
          toast.error("An error occurred. Please try again later.", {
            autoClose: 1500,
          });
        }
        dispatch(signInFailure(err.message));
      } else {
        toast.error("An unexpected error occurred.", { autoClose: 1500 });
        dispatch(signInFailure("An unexpected error occurred."));
      }
    } finally {
      // Ensure that if no user is found after sign-in, failure is dispatched
      if (!currentUser) {
        dispatch(signInFailure("User not found after sign-in attempt."));
      }
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={5} sx={{ mt: "1rem", borderRadius: "15px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <img src={logo} alt="logo" style={{ height: "45px" }} />
          <Typography component="h1" variant="h5">
            Admin Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              size="small"
              id="password"
              autoComplete="current-password"
              value={formData.password}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  color="primary"
                />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              size="small"
              variant="contained"
              sx={{ mt: 2, mb: 1 }}
            >
              Sign In as Admin
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  to="/forgot-password"
                  variant="body2"
                  style={{ textDecoration: "none" }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default AdminLogin;
