import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Paper, Typography, Grid } from "@mui/material";
import { getJoiningTypeByEmployeeType } from "../dashboard/services/service";

const TeachingStaffPieChart = ({ authToken }) => {
  const [teachingData, setTeachingData] = useState({});
  const [loading, setLoading] = useState(true); // State to manage loading indicator

  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) return;
      try {
        const data = await getJoiningTypeByEmployeeType(authToken);
        // Filter data for 'Teaching' category
        const teaching = data.find((item) => item.title === "Teaching");
        if (teaching) {
          setTeachingData(teaching);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data fetch
      }
    };
    fetchData();
  }, [authToken]);

  // Prepare data for the chart
  const chartData = [
    { value: teachingData.permanent || 0, name: "Permanent" },
    { value: teachingData.temporary || 0, name: "Temporary" },
    { value: teachingData.contract || 0, name: "Contract" },
    { value: teachingData.partTime || 0, name: "Part-Time" },
  ].filter((item) => item.value > 0); // Filter out items with 0 value

  const totalValue = chartData.reduce((acc, item) => acc + item.value, 0);
  const colors = ["#005884", "#FB5645", "#1976d2", "#5e5e5e"]; // Add your color array here

  const option =
    totalValue > 0
      ? {
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "5%",
            left: "center",
          },
          title: {
            text: totalValue.toString(), // Display total value
            subtext: "Total",
            left: "center",
            top: "center",
            textStyle: {
              fontSize: 30,
              fontWeight: "bold",
              color: "#333", // Color for total value
            },
            subtextStyle: {
              fontSize: 16,
              color: "#666", // Color for subtitle
            },
          },
          series: [
            {
              name: "Employee Type",
              type: "pie",
              radius: ["45%", "60%"],
              avoidLabelOverlap: false,
              emphasis: {
                label: {
                  show: true,
                  fontSize: 24,
                  fontWeight: "bold",
                },
              },
              label: {
                show: true, // Enable label display
                position: "inside", // Position labels inside the slices
                formatter: "{c}", // Show only the value
                textStyle: {
                  fontSize: 14,
                  color: "#fff", // Label text color (white for better contrast)
                },
              },
              labelLine: {
                show: false, // Disable label lines since labels are inside
              },
              data: chartData.map((item, index) => ({
                ...item,
                itemStyle: { color: colors[index % colors.length] }, // Assign colors to each item
              })), // Use the filtered chart data with colors
            },
          ],
        }
      : null;

  return (
    <Grid item xs={12} md={12}>
      <Paper elevation={5} sx={{ padding: "20px" }}>
        <Typography
          variant="body1"
          align="center"
          fontWeight={600}
          style={{ padding: "1rem" }}
        >
          Teaching Staff By Type
        </Typography>
        {loading ? (
          <Typography variant="body1" align="center">
            Loading...
          </Typography>
        ) : option ? (
          <ReactECharts
            key={totalValue}
            option={option}
            style={{ height: "400px" }}
          />
        ) : (
          <Typography variant="body1" align="center" color="textSecondary">
            No data available
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};

export default TeachingStaffPieChart;
