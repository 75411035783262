import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Paper, Typography, Grid } from "@mui/material";
import { getJoiningTypeByEmployeeType } from "../dashboard/services/service";

const NonTechingStaffPieChart = ({ authToken }) => {
  const [technical, setTechnical] = useState({});
  const [administrative, setAdministrative] = useState({});

  const [loading, setLoading] = useState(true); // State to manage loading indicator

  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) return;
      try {
        const data = await getJoiningTypeByEmployeeType(authToken);
        // Filter data for 'Technical' category
        const administrative =
          data?.find((item) => item.title === "Administrative") || {};
        const technicalData =
          data?.find((item) => item.title === "Technical") || {};
        setTechnical(technicalData);
        setAdministrative(administrative);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authToken]);

  // Prepare data for the chart and filter out zero values
  const chartData = [
    { value: technical.permanent || 0, name: "Permanent" },
    { value: technical.temporary || 0, name: "Temporary" },
    { value: technical.contract || 0, name: "Contract" },
    { value: technical.partTime || 0, name: "Part-Time" },
  ].filter((item) => item.value > 0); // Filter out items with 0 value
  const chartData1 = [
    { value: administrative.permanent || 0, name: "Permanent" },
    { value: administrative.temporary || 0, name: "Temporary" },
    { value: administrative.contract || 0, name: "Contract" },
    { value: administrative.partTime || 0, name: "Part-Time" },
  ].filter((item) => item.value > 0); // Filter out items with 0 value

  const totalValue = chartData.reduce((acc, item) => acc + item.value, 0);
  const totalValue1 = chartData1.reduce((acc, item) => acc + item.value, 0);

  // Define color scheme
  const colors = ["#005884", "#FB5645", "#1976d2", "#5e5e5e"]; // Add your color array here

  const option =
    totalValue > 0
      ? {
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "5%",
            left: "center",
          },
          series: [
            {
              name: "Employee Type",
              type: "pie",
              radius: ["45%", "60%"],
              avoidLabelOverlap: false,
              emphasis: {
                label: {
                  show: true,
                  fontSize: 24,
                  fontWeight: "bold",
                },
              },
              label: {
                show: true, // Enable label display
                position: "inside", // Position labels inside the slices
                formatter: "{c}", // Show only the value
                textStyle: {
                  fontSize: 14,
                  color: "#fff", // Label text color (white for better contrast)
                },
              },
              labelLine: {
                show: false, // Disable label lines since labels are inside
              },
              data: chartData.map((item, index) => ({
                ...item,
                itemStyle: { color: colors[index % colors.length] }, // Assign colors to each item
              })), // Use the filtered chart data with colors
            },
          ],
          title: {
            text: totalValue.toString(), // Display total value
            subtext: "Total",
            left: "center",
            top: "center",
            textStyle: {
              fontSize: 30,
              fontWeight: "bold",
              color: "#333", // Color for total value
            },
            subtextStyle: {
              fontSize: 16,
              color: "#666", // Color for subtitle
            },
          },
        }
      : {
          title: {
            text: "No Technical staff data",
            left: "center",
            top: "center",
          },
        };
  const option1 =
    totalValue1 > 0
      ? {
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "5%",
            left: "center",
          },
          series: [
            {
              name: "Employee Type",
              type: "pie",
              radius: ["45%", "60%"],
              avoidLabelOverlap: false,
              emphasis: {
                label: {
                  show: true,
                  fontSize: 24,
                  fontWeight: "bold",
                },
              },
              label: {
                show: true,
                position: "inside",
                formatter: "{c}",
                textStyle: {
                  fontSize: 14,
                  color: "#fff",
                },
              },
              labelLine: {
                show: false,
              },
              data: chartData1.map((item, index) => ({
                ...item,
                itemStyle: { color: colors[index % colors.length] },
              })),
            },
          ],
          title: {
            text: totalValue1.toString(),
            subtext: "Total",
            left: "center",
            top: "center",
            textStyle: {
              fontSize: 30,
              fontWeight: "bold",
              color: "#333",
            },
            subtextStyle: {
              fontSize: 16,
              color: "#666",
            },
          },
        }
      : {
          title: {
            text: "No Administrative staff data",
            left: "center",
            top: "center",
          },
        };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Typography variant="body1">Loading...</Typography>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={6}>
        <Paper elevation={5} sx={{ padding: "20px" }}>
          <Typography
            variant="body1"
            align="center"
            fontWeight={600}
            style={{ padding: "1rem" }}
          >
            Staff By Recruitment Type(Technical)
          </Typography>
          <ReactECharts option={option} style={{ height: "400px" }} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={5} sx={{ padding: "20px" }}>
          <Typography
            variant="body1"
            align="center"
            fontWeight={600}
            style={{ padding: "1rem" }}
          >
            Staff By Recruiment Type (Administrative)
          </Typography>
          <ReactECharts option={option1} style={{ height: "400px" }} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NonTechingStaffPieChart;
