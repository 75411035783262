import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  Select,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import ImageUploader from "../Reusable-component/ImageUploader";
import { styled } from "@mui/material/styles";
import { createContext, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import {
  getCampus,
  getEmployeePosition,
  getFiscalYear,

} from "../../services/services";

import DateInputField from "../DateField/DateInputField";
import { useSelector } from "react-redux";
import { getFacultyNamesForTeaching, getProGramByCollegeId } from "../report/CampusReport/CampusServices";

const WorkInfoContext = createContext();

const WorkInfoProvider = ({ children }) => {
  const methods = useForm();
  const [workInfo, setWorkInfo] = useState({
    // group: "",
    employeePositionId: 0,
    position: "",
    joiningType: "",
    joiningDate: 0,
    fiscalYear: 0,
    reference: null,
    joiningletter: null,
    otherletter: null,
    logo: "",
  });
  // Update the contactDetails state as the form data changes
  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setWorkInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
      console.log(value);
    } else {
      setWorkInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <WorkInfoContext.Provider value={{ ...methods, workInfo, onChange }}>
      {children}
    </WorkInfoContext.Provider>
  );
};
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },

  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const WorkGeneralInfo = ({ handleNext, handleBack, employeeType }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useContext(WorkInfoContext);
  const { onChange } = useContext(WorkInfoContext);
  const [fiscalYear, setFiscalYear] = useState([]);
  const [defaultFiscal, setDefaultFiscal] = useState("");
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser.listUser[0].roleName;
  const [position, setPosition] = useState([]);
  const [teachingFaculty, setTeachingFaculty] = useState([]);

  const fetchTeachingFaculties = async () => {
    const response = await getFacultyNamesForTeaching()
    //  const response = await getProGramByCollegeId(currentUser.institution.id)
    //  console.log(response)
    const faculties = [...new Set(response.map(item => item.universityName))];
    setTeachingFaculty(faculties)
  }
  useEffect(() => {
    fetchTeachingFaculties()
  }, [])


  const onSubmit = (data) => {
    handleSubmit((formData) => {
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      handleNext();
    })(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCampus();
        const fiscalYear = await getFiscalYear();
        const empPosition = await getEmployeePosition();
        setPosition(empPosition);
        setFiscalYear(fiscalYear);
        const activeFiscalYear = fiscalYear.find(
          (data) => data && data.activeFiscalYear === true
        );
        if (activeFiscalYear) {
          setDefaultFiscal(activeFiscalYear.id);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const onBack = (data) => {
    console.log(data);
    handleBack();
  };
  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid container spacing={1}>
        {employeeType !== "teaching" ? (
          <>
            <Grid item xs={12} sm={3.5}>
              <FormControl fullWidth size="small">
                <InputLabel required={roleName !== "Admin"}>Post</InputLabel>
                <Controller
                  name="employeePositionId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      required={roleName !== "Admin"}
                      id="employeePositionId"
                      size="small"
                      name="employeePositionId"
                      label="Employee Post"
                      fullWidth
                    >
                      {position
                        .filter((item) => item.category === "Nonteaching")
                        .map((item, index) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.postName}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="position"
                control={control}
                render={({ field }) => (
                  <ValidationTextField
                    {...field}
                    required={roleName !== "Admin"}
                    id="position"
                    size="small"
                    name="position"
                    label="Position"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth size="small">
              <InputLabel required={roleName !== "Admin"}>Employee position</InputLabel>
              <Controller
                name="employeePositionId"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    required={roleName !== "Admin"}
                    id="employeePositionId"
                    size="small"
                    name="employeePositionId"
                    label="Employee position"
                    fullWidth
                  >
                    {position
                      .filter((item) => item.category === "Teaching")
                      .map((item, index) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.postName}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={2.5}>
          <FormControl fullWidth size="small">
            <InputLabel required={roleName !== "Admin"}>Joining Type</InputLabel>
            <Controller
              name="joiningType"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  required={roleName !== "Admin"}
                  id="joiningType"
                  size="small"
                  name="joiningType"
                  label="Joining Type"
                  fullWidth
                  error={!!errors.joiningType}
                  helperText={errors.joiningType ? "Required" : ""}
                >
                  <MenuItem value={""} disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="permanent">Permament</MenuItem>
                  <MenuItem value="temporary">Temporary</MenuItem>
                  <MenuItem value="partTime">Part Time</MenuItem>
                  <MenuItem value="contract">Contract</MenuItem>
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>
        {
          employeeType === 'teaching' && (
            <Grid item xs={12} sm={2.5}>
              <FormControl fullWidth size="small">
                <InputLabel required={roleName !== "Admin"}>Teaching Faculty</InputLabel>
                <Controller
                  name="teachingFacultyName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <ValidationSelect
                      {...field}
                      required={roleName !== "Admin"}
                      id="teachingFacultyName"
                      size="small"
                      name="teachingFacultyName"
                      label="Teaching Faculty"
                      fullWidth
                      error={!!errors.teachingFacultyName}
                      helperText={errors.teachingFacultyName ? "Required" : ""}
                    >
                      <MenuItem value={""} disabled>
                        Select
                      </MenuItem>
                      {
                        teachingFaculty.map((item, index) => (
                          <MenuItem key={index} value={item}>{item}</MenuItem>
                        ))
                      }
                    </ValidationSelect>
                  )}
                />
              </FormControl>
            </Grid>
          )
        }

        <Grid item xs={12} sm={3}>
          <Controller
            name="joiningDate"
            control={control}
            defaultValue={null} // Set your default value here
            render={({ field }) => (
              <DateInputField
                {...field}
                label="Joining Date"
                name="joiningDate"
                value={field.value}
                format="YYYY/MM/DD"
                onChange={(newValue) => {
                  field.onChange(newValue);
                  onChange("joiningDate", newValue); // Update the context state
                }}
              />
            )}
          />
          {/* <Controller
            name="joiningDate"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <NepaliDatePicker
                {...field}
                label="Joining Date"
                name="joiningDate"
                width="100%"
                onDateChange={(name, value) => field.onChange(value)} // Update the field's value
                placeholder="YYYY/MM/DD"
                variant="outlined"
                shrink={true}
              />
            )}
          /> */}
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth size="small">
            <InputLabel id="fiscalYear">Fiscal Year</InputLabel>
            <Controller
              name="fiscalYear"
              control={control}
              defaultValue={defaultFiscal} // Set defaultValue here for initial form render
              render={({ field }) => (
                <Select
                  {...field}
                  required
                  id="fiscalYear"
                  size="small"
                  label="Fiscal Year"
                  fullWidth
                  value={field.value || defaultFiscal} // Ensure the value is managed by state
                  autoComplete="given-name"
                  error={!!errors.fiscalYear}
                  helperText={
                    errors.fiscalYear ? "Fiscal year is required" : ""
                  }
                >
                  {fiscalYear.map((data) => (
                    <MenuItem value={data.id} key={data.id}>
                      {data.yearNepali}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl>
            <ImageUploader
              allowMultiple={false}
              onImagesChange={(newImages) => {
                if (newImages.length > 0) {
                  onChange(null, "joiningletter", newImages[0]);
                } else {
                  onChange(null, "joiningletter", null);
                }
              }}
              name="joiningletter"
              placeholder="Upload joining letter"
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl>
            <ImageUploader
              allowMultiple={false}
              onImagesChange={(newImages) => {
                if (newImages.length > 0) {
                  onChange(null, "otherletter", newImages[0]);
                } else {
                  onChange(null, "otherletter", null);
                }
              }}
              name="otherletter"
              placeholder="Upload other letter"
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl>
            <ImageUploader
              allowMultiple={false}
              onImagesChange={(newImages) => {
                if (newImages.length > 0) {
                  onChange(null, "reference", newImages[0]);
                } else {
                  onChange(null, "reference", null);
                }
              }}
              name="reference"
              placeholder="upload reference letter"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="column" alignItems="flex-start">
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={onBack}
            sx={{}}
            startIcon={<ChevronLeftRoundedIcon />}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            size="small"
            type="submit"
            sx={{
              marginLeft: "10px",
            }}
            endIcon={<ChevronRightRoundedIcon />}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export { WorkInfoProvider, WorkInfoContext };
export default WorkGeneralInfo;
