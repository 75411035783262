import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Paper, Typography, Grid } from "@mui/material";
import {
  getStudentByFaculty,
  getStudentByLevelDashboard,
} from "../dashboard/services/service";

const FacultyLevelChart = ({ authToken }) => {
  const [studentsByFaculty, setStudentsByFaculty] = useState([]);
  const [studentsByPrograms, setStudentsByPrograms] = useState([]);

  useEffect(() => {
    if (!authToken) return;

    const fetchData = async () => {
      try {
        const byPrograms = await getStudentByLevelDashboard(authToken);
        setStudentsByPrograms(byPrograms);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [authToken]);

  console.log(studentsByPrograms);
  
  useEffect(() => {
    if (!authToken) return;

    const fetchData = async () => {
      try {
        const byFaculty = await getStudentByFaculty(authToken);
        setStudentsByFaculty(byFaculty);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [authToken]);

  // Generate chart option for Faculty data
 // Generate chart option for Faculty data
const generateChartOption = () => {
  const categories = ["Male", "Female", "Others", "Total"];
  const colors = ["#1976d2", "#FB5645", "#5e5e5e", "#005884"];

  // Filter out faculties with only zero values in all categories
  const filteredFaculties = studentsByFaculty.filter((faculty) =>
    categories.some((category) => {
      switch (category) {
        case "Male":
          return faculty.male > 0;
        case "Female":
          return faculty.female > 0;
        case "Others":
          return faculty.other > 0;
        case "Total":
          return faculty.total > 0;
        default:
          return false;
      }
    })
  );

  // Map the series data based on filtered faculties, only adding non-zero categories
  const seriesData = categories
    .map((category) => {
      const data = filteredFaculties.map((faculty) => {
        switch (category) {
          case "Male":
            return faculty.male;
          case "Female":
            return faculty.female;
          case "Others":
            return faculty.other;
          case "Total":
            return faculty.total;
          default:
            return 0;
        }
      });

      // Only create a series if there's any non-zero value
      if (data.some(value => value > 0)) {
        return {
          name: category,
          type: "bar",
          barWidth: 35,
          itemStyle: {
            color: colors[categories.indexOf(category)],
          },
          label: {
            show: true,
            position: "top",
            color: "#000",
          },
          data: data,
        };
      }

      return null;
    })
    .filter((series) => series !== null); // Remove null series (categories with all zero values)

  const xAxisData = filteredFaculties.map((faculty) => faculty.title);

  return {
    tooltip: { trigger: "axis" },
    legend: { data: seriesData.map((s) => s.name) },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: "category",
        data: xAxisData,
        axisLabel: { rotate: 15 },
        name: "Faculties",
        nameLocation: "middle",
        nameGap: 35,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
        },
        name: "No. of Students",
        nameLocation: "middle",
        nameGap: 35,
      },
    ],
    series: seriesData,
  };
};

// Generate chart option for Programs data
const generateChartOption2 = () => {
  const categories = ["Male", "Female", "Other", "Total"];
  const colors = ["#1976d2", "#FB5645", "#5e5e5e", "#005884"];
  const programs = studentsByPrograms;

  // Filter out programs with only zero values in all categories
  const filteredPrograms = programs.filter((item) =>
    categories.some((category) => {
      switch (category) {
        case "Male":
          return item.male > 0;
        case "Female":
          return item.female > 0;
        case "Other":
          return item.other > 0;
        case "Total":
          return item.total > 0;
        default:
          return false;
      }
    })
  );

  // Map the series data based on filtered programs, only adding non-zero categories
  const seriesData = categories
    .map((category) => {
      const data = filteredPrograms.map((item) => {
        switch (category) {
          case "Male":
            return item.male;
          case "Female":
            return item.female;
          case "Other":
            return item.other;
          case "Total":
            return item.total;
          default:
            return 0;
        }
      });

      // Only create a series if there's any non-zero value
      if (data.some(value => value > 0)) {
        return {
          name: category,
          type: "bar",
          barWidth: 35,
          itemStyle: {
            color: colors[categories.indexOf(category)],
          },
          label: {
            show: true,
            position: "top",
            color: "#000",
          },
          data: data,
        };
      }

      return null;
    })
    .filter((series) => series !== null); // Remove null series (categories with all zero values)

  const xAxisData = filteredPrograms.map((item) => item.title);

  return {
    tooltip: { trigger: "axis" },
    legend: { data: seriesData.map((s) => s.name) },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: true },
      },
    },
    xAxis: [
      {
        type: "category",
        data: xAxisData,
        axisLabel: { rotate: 15 },
        name: "Levels",
        nameLocation: "middle",
        nameGap: 35,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: true,
        },
        name: "No. of Students",
        nameLocation: "middle",
        nameGap: 35,
      },
    ],
    grid: { containLabel: false },
    series: seriesData,
  };
};


  return (
    <Grid
      container
      justifyContent="center"
      paddingLeft="10px"
      alignItems="flex-start"
      spacing={1}
    >
      <Paper elevation={5} sx={{ width: "100%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              align="center"
              fontWeight={900}
              style={{ padding: "1rem" }}
            >
              Students on the basis of Faculty
            </Typography>
            <ReactECharts
              option={generateChartOption()}
              style={{ height: "400px" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              align="center"
              fontWeight={900}
              style={{ padding: "1rem" }}
            >
              Students on the basis of Levels
            </Typography>
            <ReactECharts
              option={generateChartOption2()}
              style={{ height: "400px" }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default FacultyLevelChart;
