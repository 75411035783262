import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { getStudentById } from '../../services/employeeService';
import domToPdf from 'dom-to-pdf';
import { useSelector } from 'react-redux';
import { Grid, Typography, Box, Stack, Table, TableRow, TableCell } from '@mui/material';
import { styled } from "@mui/material/styles";
const backendUrl = process.env.REACT_APP_BASE_URL;


const DottedSpan = styled('span')(({ theme }) => ({
    margin: '0px 7px',
    borderBottom: '1px dotted black',
    padding: '0px 5px'
}));
const SignatureField = styled('div')(({ theme }) => ({
    display: 'inline-block',
    marginTop: '2rem',
    borderTop: '1px solid black',
    padding: '.2rem 1rem'
}));

const ExportStudentInfo = forwardRef(({ id }, ref) => {
    const { currentUser } = useSelector((state) => state.user);
    const [stdInfo, setStdInfo] = useState({});
    const contentRef = useRef();
    const fetchData = async () => {
        if (id) {
            const response = await getStudentById(id);
            setStdInfo(response);
        }
        else {
            setStdInfo({})
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);
    const handleExport = async () => {
        if (!stdInfo || Object.keys(stdInfo).length === 0) {
            await fetchData(); // Fetch data if not already available
        }
        const content = contentRef.current;
        const options = {
            filename: 'student_info.pdf',
            jsPDF: {
                orientation: 'potrait',
                unit: 'mm',
                format: 'A4',
                margin: [10, 10, 10, 10],
            },
        };
        domToPdf(content, options, () => {
            console.log('PDF generated successfully!');
        });
    };
    useImperativeHandle(ref, () => ({
        handleExport
    }));
   
    return (
        <Grid my='5rem' padding='0 2rem' container ref={contentRef}> {/* Use ref here */}
            <Stack width='100%' direction='column' rowGap='2rem'>
                {/* heading */}
                <Grid display='flex' item sm={12}>
                    {/* logo */}
                    <Box display='flex' alignItems='center' justifyContent='center' width='20%'>
                        <img style={{ height: '100px', borderRadius: '50%' }} src='https://media.licdn.com/dms/image/v2/C561BAQFW9-f0UfBIUQ/company-background_1536_768/company-background_1536_768/0/1583917731517?e=2147483647&v=beta&t=Zd4Mq0WzMOsQViO-1v5hCYKHMmXg-gHX36qMRTvZPrg' alt='campus logo' />
                    </Box>
                    {/* heading */}
                    <Box width='60%'>
                        <Stack textAlign='center' direction='column'>
                            <Typography fontSize="24px">
                                TRIBHUVAN UNIVERSITY
                            </Typography>
                            <Typography fontSize="24px" textTransform='uppercase'>
                                {currentUser.institution?.campusName}
                            </Typography>
                            <Typography fontSize="24px" textTransform='uppercase'>
                                {`${currentUser?.institution.district}, ${currentUser?.institution?.province}`}
                            </Typography>
                            <Typography fontSize="24px">
                                Application form for the Registration
                            </Typography>
                            <Typography fontSize="24px">
                                Admission Batch:
                                <DottedSpan >
                                    2909
                                </DottedSpan>
                                <span> - </span>
                                <DottedSpan >
                                    2909
                                </DottedSpan>
                            </Typography>
                        </Stack>
                    </Box>
                    <Box display='flex' justifyContent='center' alignItems='center' width='20%'>
                        {/* ppSizePhoto photo */}
                        <img style={{ height: '100px', width: '100px', objectFit: 'cover' }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJrOMbiewX992x1FlYzd4M4WMA82e4GjxjYA&s' alt='campus logo' />
                    </Box>
                </Grid>
                {/* Registration */}
                <Grid sm={12}>
                    <Box width='60%' border='1px solid black' padding='5px'>
                        <Typography fontSize='20px'>
                            Registration Number :
                        </Typography>
                    </Box>
                    <Box padding='5px' width='100%'>
                        <Typography fontSize='20px'>
                            Faculty / Institute
                            <DottedSpan style={{ fontSize: "20px" }}>
                                {stdInfo.facultyName}
                            </DottedSpan>
                            Level
                            <DottedSpan style={{ fontSize: "20px" }}>
                                {stdInfo.levelName}
                            </DottedSpan>
                            Campus
                            <DottedSpan style={{ fontSize: "20px" }}>
                                {currentUser?.institution.campusName}
                            </DottedSpan>
                        </Typography>
                    </Box>
                </Grid>
                {/* Full name of student */}
                <Grid>
                    <Table >
                        <TableRow >
                            <TableCell sx={{ border: '1px solid gray', width: '25%', padding: '2px 5px' }}>
                                <Typography fontSize='20px'>
                                    Full Name of the student (In Block Letter)
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{ border: '1px solid gray', textAlign: 'center', padding: '1px 2px', width: '10%' }}
                            >
                                <Typography fontSize="20px" textTransform="uppercase">
                                    {stdInfo.gender === 'male' ? 'Mr.' : stdInfo.gender === 'female' ? "Mrs." : " "}
                                </Typography>
                            </TableCell>
                            {`${stdInfo.firstName} ${stdInfo.middleName} ${stdInfo.lastName}`.split('').map((letter, index) => (
                                <TableCell
                                    key={index}
                                    sx={{ border: '1px solid gray', textAlign: 'center', padding: '1px 2px', width: '10px' }}
                                >
                                    <Typography fontSize="20px" textTransform="uppercase">
                                        {letter.trim() === '' ? ' ' : letter}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ border: '1px solid gray', width: '25%', padding: '2px 5px' }}>
                                <Typography fontSize='22px'>
                                    देवनागरीमा
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{ border: '1px solid gray', textAlign: 'center', padding: '1px 2px', width: '10%' }}
                            >
                                <Typography fontSize="22px" textTransform="uppercase">
                                    {stdInfo.gender === 'male' ? 'श्री ' : stdInfo.gender === 'female' ? "श्रीमती" : " "}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{ border: '1px solid gray', textAlign: 'Center', padding: '1px 2px', width: '10px' }}
                                colSpan={`${stdInfo.firstName} ${stdInfo.middleName} ${stdInfo.lastName}`.split('').length}
                            >
                                <Typography fontSize='20px'>
                                  {stdInfo.nepaliName}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </Table>
                </Grid>
                {/* Date of birth */}
                <Grid sm={12}>
                    <Box width='60%'>
                        <Table >
                            <TableRow >
                                <TableCell rowSpan={2} sx={{ border: '1px solid gray', width: '50%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Date of birth according to S.L.C or Secondary
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{ border: '1px solid gray', textAlign: 'center', padding: '1px 2px' }}
                                >
                                    <Typography fontSize='20px'>
                                        Year
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{ border: '1px solid gray', textAlign: 'center', padding: '1px 2px' }}
                                >
                                    <Typography fontSize='20px'>
                                        Month
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    sx={{ border: '1px solid gray', textAlign: 'center', padding: '1px 2px' }}
                                >
                                    <Typography fontSize='20px'>
                                        Day
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    sx={{ border: '1px solid gray', textAlign: 'center' }}
                                    colSpan={3}
                                >
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </Box>
                    <Box padding='5px' width='100%'>
                        <Typography fontSize='20px'>
                            Nationality:
                            <DottedSpan style={{ fontSize: "20px" }}>
                                Nepali
                            </DottedSpan>
                            Religion :
                            <DottedSpan style={{ fontSize: "20px" }}>
                                
                            </DottedSpan>
                        </Typography>
                    </Box>
                </Grid>
                {/* Father's Name */}
                <Grid sm={12}>
                    <Table >
                        <TableRow  >
                            <TableCell sx={{ border: '1px solid gray', width: '30%', padding: '2px 5px' }}>
                                <Typography fontSize='20px'>
                                    Father's Name
                                </Typography>
                            </TableCell>
                            {stdInfo.fatherName?.split('').map((letter, index) => (
                                <TableCell
                                    key={index}
                                    sx={{ border: '1px solid gray', textAlign: 'center', padding: '1px 2px', width: '10px' }}
                                    width='10px'
                                >
                                    <Typography fontSize="20px" textTransform="uppercase">
                                        {letter.trim() === '' ? ' ' : letter}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </Table>
                </Grid>
                {/* Mailing Address */}
                <Grid sm={12}>
                    <Box padding='5px' width='100%'>
                        <Typography fontSize='20px'>
                            Mailing Address (Town/ Village):
                            <DottedSpan style={{ fontSize: '20px', marginRight: '30px' }}>
                                {`${stdInfo.pLocalLevel}, ${stdInfo.pDistrict}`}
                            </DottedSpan>
                            Ward No:
                            <DottedSpan style={{ fontSize: '20px', marginRight: '30px' }}>
                                {stdInfo.pWardNo}
                            </DottedSpan>
                            District :
                            <DottedSpan style={{ fontSize: '20px', marginRight: '30px' }}>
                            {stdInfo.pDistrict}
                            </DottedSpan>
                            Province :
                            <DottedSpan style={{ fontSize: '20px', marginRight: '30px' }}>
                                {stdInfo.pProvince}
                            </DottedSpan>
                        </Typography>
                    </Box>
                </Grid>
                {/* Examination passed */}
                <Grid paddingBottom='1rem' borderBottom='1px solid black' sm={12}>
                    <Typography fontSize='20px' fontWeight='bold'>Examination Passed</Typography>
                    <Box mt='1rem'>
                        <Table >
                            <TableRow >
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Examination passed
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '30%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Board or University
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '10%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Year
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Marks Secured in English
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Roll No. or Symbol No.
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Division
                                    </Typography>
                                </TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        S.L.C. or Secondary
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '30%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '10%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Intermediate or Higher Secondary
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '30%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '10%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Bachelor's
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '30%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '10%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                        Others
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '30%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '10%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>

                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                    </Typography>
                                </TableCell>
                                <TableCell sx={{ border: '1px solid gray', width: '15%', padding: '2px 5px' }}>
                                    <Typography fontSize='20px'>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </Box>
                    <Box mt='1rem'>
                        <Typography fontSize='20px' fontWeight='bold'>I declare that  the particulars given above are correct. If found false any action to be taken against me by the controller of the examination center shall be acceptable</Typography>
                    </Box>
                    <Box mt='3rem' display='flex' justifyContent='space-between'>
                        <SignatureField >
                            <Typography fontSize='20px'>
                                Full signature of parent/guardian
                            </Typography>
                            <Typography fontSize='20px'>
                                Date:
                            </Typography>
                        </SignatureField>
                        <SignatureField >
                            <Typography fontSize='20px'>
                                Full signature of Applicant
                            </Typography>
                            <Typography fontSize='20px'>
                                Date:
                            </Typography>
                        </SignatureField>
                    </Box>
                </Grid>
                {/*  To be filled by campus */}
                <Grid paddingBottom='1rem' borderBottom='1px solid black' item sm={12}>
                    <Typography fontSize='20px' display='inline-block' fontWeight='bold' borderBottom='1px solid black'>TO BE FILLED BY THE CAMPUS OFFICE</Typography>
                    <Typography mt='.2rem' fontSize='20px'>
                        This application is accepted by this campus only after checking the original Certificates and Mark sheets obtained from the student . I certify that the documents produced by the student have been properly verified and the particulars furnished are accurate to the best of myt knowledge
                    </Typography>
                    <Box mt='3rem' display='flex' justifyContent='space-between'>
                        <SignatureField >
                            <Typography fontSize='20px'>
                                Checked By
                            </Typography>
                            <Typography fontSize='20px'>
                                Date:
                            </Typography>
                        </SignatureField>
                        <SignatureField >
                            <Typography fontSize='20px'>
                                Office ceal
                            </Typography>
                            <Typography fontSize='20px'>
                                Date:
                            </Typography>
                        </SignatureField>
                        <SignatureField >
                            <Typography fontSize='20px'>
                                Campus Chief
                            </Typography>
                            <Typography fontSize='20px'>
                                Date:
                            </Typography>
                        </SignatureField>
                    </Box>
                </Grid>

                <Grid item sm={12} paddingBottom='.5rem' borderBottom='1px solid black'>
                    <Typography fontSize='20px' display='inline-block' fontWeight='bold'> IN CASE OF PRIVATE STUDENTS </Typography>
                </Grid>

                <Grid item sm={12}>
                    <Typography fontSize='20px' fontWeight='bold' > I CERTIFY THAT THE ABOVE PARTICULARS ARE CORRECT</Typography>
                    <Box mt='3rem' display='flex' justifyContent='space-between'>
                        <SignatureField >
                            <Typography fontSize='20px'>
                                Office seal
                            </Typography>
                            <Typography fontSize='20px'>
                                Date:
                            </Typography>
                        </SignatureField>
                        <SignatureField >
                            <Typography fontSize='20px'>
                                signature of Gazetted Officer
                            </Typography>
                            <Typography fontSize='20px'>
                                Name<DottedSpan style={{ width: '20rem' }}></DottedSpan>
                            </Typography>
                            <Typography fontSize='20px'>
                                Post<DottedSpan style={{ width: '20rem' }}></DottedSpan>
                            </Typography>
                        </SignatureField>
                    </Box>
                </Grid>

            </Stack>
        </Grid>
    );
});

export default ExportStudentInfo;