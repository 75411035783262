import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getCampusById } from "../../../services/services";
const EditCampusContactContext = createContext();
const EditCampusContactProvider = ({ children }) => {
  const methods = useForm();
  const [contactDetails, setContactDetails] = useState({
    yearOfEstd: "",
    dateOfEstd: "",
    accreditationStatus: "",
    campusChief: "",
    principalNumber: "",
    principalNumber2: "",
    contactEmail1: "",
    focalPersonName: "",
    position: "",
    contactNumber: "",
    contactNumber2: "",
    contactNumber3: "",
    contactEmail2: "",
    bankName: "",
    branchName: "",
    accountNumber: "",
  });

  const onChange = (name, value) => {
    setContactDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <EditCampusContactContext.Provider
      value={{ ...methods, contactDetails, onChange }}
    >
      {children}
    </EditCampusContactContext.Provider>
  );
};

const EditCampusContactDetails = ({ handleNext, handleBack, id }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useContext(EditCampusContactContext);
  const { onChange } = useContext(EditCampusContactContext);

  const fetchData = async () => {
    try {
      const getCampusData = await getCampusById(id);
      setValue("yearOfEstd", getCampusData.yearOfEstd || "");
      setValue("dateOfEstd", getCampusData.dateOfEstd || "");
      setValue("accreditationStatus", getCampusData.accrebiatation || "");
      setValue("focalPersonName", getCampusData.contactName || "");
      setValue("position", getCampusData.contactPersonPosition || "");
      setValue("contactNumber", getCampusData.phoneNo || "");
      setValue("contactEmail1", getCampusData.principalEmail || "");
      setValue("contactEmail2", getCampusData.contactEmail || "");
      setValue("bankName", getCampusData.bankName || "");
      setValue("branchName", getCampusData.branchName || "");
      setValue("accountNumber", getCampusData.accountNumber || "");
      setValue("campusChief", getCampusData.principalName || "");
      setValue("principalNumber", getCampusData.principalPhoneNo || "");
      setValue("principalNumber2", getCampusData.principalPhoneNo1 || "");
      setValue("contactNumber2", getCampusData.contactNumber2 || "");
      setValue("contactNumber3", getCampusData.contactNumber3 || "");
    } catch (error) {
      console.error("Error fetching campus data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, setValue]);

  const onSubmit = (data) => {
    onChange("yearOfEstd", data.yearOfEstd);
    onChange("dateOfEstd", data.dateOfEstd);
    onChange("accreditationStatus", data.accreditationStatus);
    onChange("campusChief", data.campusChief);
    onChange("principalNumber", data.principalPhoneNo);
    onChange("principalNumber2", data.principalPhoneNo1);
    onChange("contactEmail1", data.principalEmail);
    onChange("focalPersonName", data.focalPersonName);
    onChange("position", data.position);
    onChange("contactNumber", data.principalPhoneNo2);
    onChange("contactNumber2", data.contactNo3);
    onChange("contactNumber3", data.contactNo3);
    onChange("contactEmail2", data.contactEmail2);
    onChange("bankName", data.bankName);
    onChange("branchName", data.branchName);
    onChange("accountNumber", data.accountNumber);
    handleNext();
  };

  const onBack = () => {
    handleBack();
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={onBack}
      rowSpacing='8px'
      columnSpacing='5px'
    >

      <Grid item xs={12} sm={4}>
        <Controller
          name="yearOfEstd"
          control={control}
          rules={{
            required: "Estd. year required",
            pattern: {
              value: /^\d{0,4}$/,
              message:
                "Please enter only numeric values with a maximum length of four digits",
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="yearOfEstd"
              size="small"
              type="text"
              label="Year Of Establishment"
              InputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                onInput: (e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                  if (e.target.value.length > 4) {
                    e.target.value = e.target.value.slice(0, 4);
                  }
                },
              }}
              fullWidth
              error={!!errors.yearOfEstd}
              helperText={errors.yearOfEstd ? errors.yearOfEstd.message : ""}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth size="small">
          <Controller
            name="dateOfEstd"
            control={control}
            rules={{ required: "Date is required" }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                size="small"
                label="Date of Establishment"
                error={!!errors.dateOfEstd}
                fullWidth
                helperText={
                  errors.dateOfEstd ? "Date of establishment required" : ""
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth size="small">
          <InputLabel id="accreditationStatus">
            Accreditation Status
          </InputLabel>
          <Controller
            name="accreditationStatus"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                labelId="accreditationStatus"
                id="accreditationStatus"
                size="small"
                label="Accreditation Status"
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            name="campusChief"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                required
                id="campusChief"
                size="small"
                name="campusChief"
                label="Chief/Principal Name"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Controller
            name="principalNumber"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                required
                {...field}
                id="principalNumber"
                size="small"
                name="principalNumber"
                label="Phone Number"
                InputProps={{
                  placeholder: "XXXXXXXX",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  },
                }}
                fullWidth
                error={!!errors.principalNumber}
                helperText={
                  errors.principalNumber
                    ? errors.principalNumber.message
                    : ""
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Controller
            name="principalNumber2"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="principalNumber2"
                size="small"
                name="principalNumber2"
                label="Landline Number"
                InputProps={{
                  placeholder: "XXXXXXXX",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  },
                }}
                fullWidth
                error={!!errors.principalNumber2}
                helperText={
                  errors.principalNumber2
                    ? errors.principalNumber2.message
                    : ""
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="contactEmail1"
            control={control}
            rules={{
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Email format not matched",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                required
                {...field}
                id="contactEmail1"
                size="small"
                name="contactEmail1"
                label="Contact Email"
                fullWidth
                error={!!errors.contactEmail1}
                helperText={
                  errors.contactEmail1 ? errors.contactEmail1.message : ""
                }
              />
            )}
          />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="focalPersonName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              required
              id="focalPersonName"
              size="small"
              type="text"
              name="focalPersonName"
              label="Contact Person Name"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="position"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              required
              id="position"
              size="small"
              name="position"
              label="Position"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="contactNumber"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              required
              {...field}
              id="contactNumber"
              size="small"
              name="contactNumber"
              label="Phone Number"
              InputProps={{
                placeholder: "XXXXXXXX",
                inputMode: "numeric",
                pattern: "[0-9]*",
                onInput: (e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                },
              }}
              fullWidth
              error={!!errors.contactNumber}
              helperText={
                errors.contactNumber ? errors.contactNumber.message : ""
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="contactNumber2"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="contactNumber2"
              size="small"
              name="contactNumber2"
              label="Landline Number 1"
              InputProps={{
                placeholder: "XXXXXXXX",
                inputMode: "numeric",
                pattern: "[0-9]*",
                onInput: (e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                },
              }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="contactNumber3"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="contactNumber3"
              size="small"
              name="contactNumber3"
              label="Landline Number 2"
              InputProps={{
                placeholder: "XXXXXXXX",
                inputMode: "numeric",
                pattern: "[0-9]*",
                onInput: (e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                },
              }}
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="contactEmail2"
          control={control}
          rules={{
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Email format not matched",
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <TextField
              required
              {...field}
              id="contactEmail2"
              size="small"
              name="contactEmail2"
              label="Contact Email"
              fullWidth
              error={!!errors.contactEmail2}
              helperText={
                errors.contactEmail2 ? errors.contactEmail2.message : ""
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="bankName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="bankName"
              size="small"
              name="bankName"
              label="Bank Name"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name="branchName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="branchName"
              name="branchName"
              size="small"
              label="Bank Branch"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="accountNumber"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              id="accountNumber"
              name="accountNumber"
              size="small"
              label="Bank Account Number"
              fullWidth
            />
          )}
        />
      </Grid>

      <Box mt={1} mb={2} display="flex" width='100%' justifyContent="flex-end">
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={onBack}
          sx={{
            marginLeft: "10px",
          }}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          size="small"
          type="submit"
          sx={{
            marginLeft: "10px",
          }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};

export { EditCampusContactProvider, EditCampusContactContext };
export default EditCampusContactDetails;
