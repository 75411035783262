import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  CardContent,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Box,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { getFinanceHead, getIncomeExpenditureEntry } from "./FinanceApi";
import { getFiscalYear } from "../../../services/services";
import OtherSetupAppBar from "../../../modules/navbar/OtherSetupAppBar";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const IncomeExpenditureEntry = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [financeHeadData, setFinanceHeadData] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [incomeExpData, setIncomeExpData] = useState([]);

  const fetchIncomeEntryData = async () => {
    const fiscalYearResponse = await getFiscalYear();
    const financeHeadResponse = await getFinanceHead();
    setFiscalYears(fiscalYearResponse);
    setFinanceHeadData(financeHeadResponse);
  };

  console.log(financeHeadData);
  const fetchIncomeExpenditureEntry = async () => {
    const response = await getIncomeExpenditureEntry();
    setIncomeExpData(response);
  };

  useEffect(() => {
    fetchIncomeEntryData();
    fetchIncomeExpenditureEntry();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const apiEntryData = {
      headNameId: parseInt(data.headNameID) || 0,
      fiscalYearId: data.fiscalYearId,
      dateOfEntry: data.dateOfEntry,
      amount: parseInt(data.amount) || 0,
      evidenceDoc: data.evidenceDocument || "",
      remarks: data.remarks?.trim() || "",
    };
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      await axios.post(
        `${backendUrl}/IncomeExpenditureEntry`,
        apiEntryData,
        config
      );
      toast.success("Data posted successfully", {
        autoClose: 1500,
      });
      reset();
      fetchIncomeEntryData();
      fetchIncomeExpenditureEntry();
    } catch (error) {
      toast.error("Error posting data: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <OtherSetupAppBar />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={false} md={1} />
        <Grid item xs={12} md={10}>
          <Paper elevation={5} sx={{ borderRadius: "20px" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Income & Expenditure Entry Management
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.headNameID}
                    >
                      <InputLabel required id="headNameID-label">
                        Head Name
                      </InputLabel>
                      <Select
                        {...register("headNameID", { required: true })}
                        id="headNameID"
                        name="headNameID"
                        labelId="headNameID-label"
                        label="Header Name ID"
                        defaultValue=""
                      >
                        {financeHeadData.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {" "}
                            {item.headName}
                          </MenuItem>
                        ))}
                      </Select>
                      {/* {errors.headNameID && (
                      <FormHelperText>Head Name ID is required</FormHelperText>
                    )} */}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.fiscalYearId}
                    >
                      <InputLabel required id="fiscalYearId-label">
                        Fiscal Year
                      </InputLabel>
                      <Select
                        {...register("fiscalYearId", { required: true })}
                        id="fiscalYearId"
                        labelId="fiscalYearId-label"
                        defaultValue=""
                        label="Fiscal Year"
                      >
                        {fiscalYears.map((fy) => (
                          <MenuItem key={fy.id} value={fy.id}>
                            {fy.yearNepali}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      {...register("dateOfEntry", { required: true })}
                      id="dateOfEntry"
                      required
                      size="small"
                      name="dateOfEntry"
                      label="Date of Entry"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={!!errors.dateOfEntry}
                      helperText={
                        errors.dateOfEntry ? "Date of Entry is required" : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      {...register("amount", { required: true })}
                      id="amount"
                      required
                      size="small"
                      name="amount"
                      label="Amount"
                      type="number"
                      fullWidth
                      error={!!errors.amount}
                      helperText={errors.amount ? "Amount is required" : ""}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      {...register("evidenceDocument", { required: false })}
                      id="evidenceDocument"
                      size="small"
                      name="evidenceDocument"
                      label="Evidence Document"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.evidenceDocument}
                      helperText={
                        errors.evidenceDocument
                          ? "Evidence Document is required"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      {...register("remarks")}
                      id="remarks"
                      size="small"
                      name="remarks"
                      label="Remarks"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#007aff", color: "#inherit" }}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Paper>
          <Grid margin="10px">
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              Income & Expenditure Entry List
            </Typography>
            <TableContainer>
              <Table
                style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
              >
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Head Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Fiscal Year
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Date of Entry
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Remarks
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                        width: "10%",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "white" }}>
                  {incomeExpData.length > 0 ? (
                    incomeExpData.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data?.headName?.headName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {/* {data.fiscalYearId} */}
                          2081/82
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data?.dateOfEntry?.slice(0, 10)}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.amount}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.remarks}
                        </TableCell>

                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          <Button>
                            {" "}
                            <EditNoteIcon /> Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} style={{ textAlign: "center" }}>
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default IncomeExpenditureEntry;
