import React, { useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Checkbox,
  Chip,
  Grid,
} from '@mui/material';
import { grey, blue, lightBlue } from '@mui/material/colors';
import UserPermissionManagement from './UserPermissionManagement';
import UserAppBar from '../navbar/UserAppBar';

const PermissionManagement = () => {
  // Example data structure for modules with active/inactive status
  const modules = [
    {
      name: 'Student Management',
      status: 'Active',
      remarks: 'Manage student enrollment, registration, and enrollment status.',
    },
    {
      name: 'Program Management',
      status: 'Inactive',
      remarks: 'Manage course creation, enrollment, and assignments.',
    },
    {
      name: 'Employee Management ',
      status: 'Active',
      remarks: 'Manage employee registration.',
    },
  ];

  return (
    <div>
    <Box sx={{
      margin: 0, padding: 0,
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
      <Box mt={8}>
      <UserAppBar/>
      </Box>
     </Box>
      <Box sx={{  maxWidth: 1200, margin: '0 auto' }}>
        <Typography variant="h6" gutterBottom textAlign='center' sx={{ fontWeight: 'semibold', color: blue[700] }}>
          Module Access Management
        </Typography>
        
        {/* Enhanced "Select Admin User" Dropdown */}
        <FormControl fullWidth sx={{ maxWidth: 500, pb:1 }} size="small">
          <InputLabel id="user-select-label" sx={{ fontWeight: 'semibold', color: blue[700] }}>
            Select Admin User
          </InputLabel>
          <Select
            labelId="user-select-label"
            id="user-select"
            size="small"
            label="Select Admin User"
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              borderColor: '#c2c2c2', // Light gray border color
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#c2c2c2', // Border color
                },
                '&:hover fieldset': {
                  borderColor: blue[500], // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: blue[700], // Border color when focused
                  boxShadow: `0 0 0 2px ${blue[100]}`, // Subtle shadow when focused
                },
              },
              '& .MuiInputLabel-root': {
                fontSize: '1rem', // Slightly larger label
              },
              '& .MuiSelect-icon': {
                color: blue[700], // Color for the dropdown arrow
              },
            }}
          >
            <MenuItem value="1">Hari Ram</MenuItem>
            <MenuItem value="2">Sita Ram</MenuItem>
            <MenuItem value="3">Govindam Shyam</MenuItem>
          </Select>
        </FormControl>

        {/* Table for Modules */}
        <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="module-management-table">
            <TableHead sx={{ backgroundColor: blue[600] }}>
              <TableRow sx={{ height: 35 }}> {/* Reduce the height of the table header row */}
                <TableCell
                  sx={{
                    fontWeight: 'semibold',
                    fontSize: '1rem',
                    textAlign: 'center',
                    color: 'white',
                    border: `1px solid #c2c2c2`,  // Right border between columns
                    padding: '6px 10px',  // Reduced padding for header
                  }}
                >
                  Module Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: 'semibold',
                    fontSize: '1rem',
                    color: 'white',
                    border: `1px solid #c2c2c2`,
                    padding: '6px 10px', // Reduced padding for header
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: 'semibold',
                    fontSize: '1rem',
                    color: 'white',
                    textAlign: 'center',
                    border: `1px solid #c2c2c2`,
                    padding: '6px 10px', // Reduced padding for header
                  }}
                >
                  Remarks
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Map over the modules data */}
            {modules.map((module, index) => {
              const isInactive = module.status === 'Inactive';
              return (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: isInactive ? grey[100] : 'white',
                    cursor: isInactive ? 'not-allowed' : 'pointer',
                    opacity: isInactive ? 0.6 : 1,
                    '&:hover': {
                      backgroundColor: isInactive ? grey[100] : lightBlue[50],
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: `1px solid #c2c2c2`, // Apply c2c2c2 vertical borders
                      padding: '6px 10px',  // Reduced padding for body cells
                    }}
                  >
                    <Checkbox disabled={isInactive} sx={{ padding: 0 }} />
                    {module.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      border: `1px solid #c2c2c2`, // Apply c2c2c2 vertical borders
                      padding: '6px 10px',  // Reduced padding for body cells
                    }}
                  >
                    <Chip
                      label={module.status}
                      color={module.status === 'Active' ? 'success' : 'error'}
                      size="small"
                      sx={{ textTransform: 'capitalize' }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: `1px solid #c2c2c2`, // Apply c2c2c2 bottom borders
                      padding: '6px 10px',  // Reduced padding for body cells
                    }}
                  >
                    {module.remarks}
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </TableContainer>

        {/* Button to Assign Permissions */}
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
          >
            Assign Permissions
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default PermissionManagement;
