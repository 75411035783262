import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import EditStudentGeneralInfo, { EditStudentInfoContext } from './EditGeneralInfo';
import { EditStudentAddressContext } from './EditStudentAddressInfo';
import { EditStudentGuardianContext } from './EditGuardianInfo';
// import { EditStudentAcademicContext } from './EditStudentAcademicsInfo';
import { EditStudentRegContext } from './EditStudentRegistration';
import { LoadingOverlay } from '@mantine/core';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getFacultyByFacultyId, getLevelById, getProgramById } from '../../../services/services';
const backendUrl = process.env.REACT_APP_BACKEND_URL;


const EditReviewDetails = ({ handleBack, id, handleClose, onUpdate, handleEditDialogClose, onRegisterSuccess }) => {
  const [AcademicDetails, setAcademicDetails] = useState({});
  const { editStudentInfo } = useContext(EditStudentInfoContext);
  const { editStudentAddress } = useContext(EditStudentAddressContext);
  const { editGuardianInfo } = useContext(EditStudentGuardianContext);
  const [loading, setLoading] = useState(false)
  const { editRegistrationInfo } = useContext(EditStudentRegContext)
  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchAcademicDetails = async () => {
      try {
        const facultyResponse = await getFacultyByFacultyId(editRegistrationInfo.facultyId);
        const levelResponse = await getLevelById(editRegistrationInfo.levelId);
        const programResponse = await getProgramById(editRegistrationInfo.programId);
        setAcademicDetails((prevDetails) => ({
          ...prevDetails,
          facultyName: facultyResponse.facultyName,
          levelName: levelResponse.levelName,
          programName: programResponse.programName,
        }));
      } catch (error) {
        console.error('Error fetching academic details:', error);
      }
    };

    if (editRegistrationInfo) {
      fetchAcademicDetails();
    }
  }, [editRegistrationInfo]);

  let universityId;
  let campusId;

  if (currentUser.type === 'Uni') {
    universityId = currentUser.institution.id;
  } else if (currentUser.type === 'college') {
    campusId = currentUser.institution.id;
  }
  const handleRegister = () => {
    setLoading(true);
    setTimeout(async () => {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('campusId', campusId || 0);
      formData.append('universityId', universityId || 0);
      formData.append('citizenIssueDist', editStudentInfo.issuedDist);
      formData.append('nidno', editStudentInfo.nidNo);
      formData.append('levelId', editRegistrationInfo.levelId);
      formData.append('facultyId', editRegistrationInfo.facultyId);
      formData.append('programId', editRegistrationInfo.programId);
      formData.append('admissionYear', editRegistrationInfo.admissionYear);
      formData.append('complitionYear', editRegistrationInfo.completionYear);
      formData.append('levelid', editStudentInfo.nepaliName);
      formData.append('firstName', editStudentInfo.firstName);
      formData.append('middleName', editStudentInfo.middleName);
      formData.append('lastName', editStudentInfo.lastName);
      formData.append('ppSizePhoto', editStudentInfo.ppSizePhoto);
      formData.append('phoneNumber', editStudentInfo.phone);
      formData.append('doBBS', editStudentInfo.dobBs);
      formData.append('doBAD', editStudentInfo.dobAd);
      formData.append('gender', editStudentInfo.gender);
      formData.append('ethnicity', editStudentInfo.ethnicity);
      formData.append('nationality', editStudentInfo.nationality);
      formData.append('disabilityStatus', editStudentInfo.disabilityStatus);
      formData.append('disabilityType', editStudentInfo.disabilityType);
      formData.append('email', editStudentInfo.email);
      formData.append('citizenshipNo', editStudentInfo.citizenshipNo);
      formData.append('nidNo', editStudentInfo.nidNo);
      formData.append('pProvince', editStudentAddress.pProvince);
      formData.append('pDistrict', editStudentAddress.pDistrict);
      formData.append('pLocalLevel', editStudentAddress.pLocalLevel);
      formData.append('pWardNo', editStudentAddress.pWardNo);
      formData.append('pBlockNo', 0);
      formData.append('pHouseNo', 0);
      formData.append('pLocality', editStudentAddress.pTole);
      formData.append('tProvince', editStudentAddress.tProvince);
      formData.append('tDistrict', editStudentAddress.tDistrict);
      formData.append('tLocalLevel', editStudentAddress.tLocalLevel);
      formData.append('tWardNo', editStudentAddress.tWardNo);
      formData.append('tBlockNo', 0);
      formData.append('tHouseNo', 0);
      formData.append('tLocality', editStudentAddress.tTole);
      formData.append('fatherName', editGuardianInfo.fatherName);
      formData.append('fOccupation', editGuardianInfo.fatherOccupation);
      formData.append('fatherPhoneNo', editGuardianInfo.fatherPhoneNo);
      formData.append('fatherEmail', editGuardianInfo.fatherEmail);
      formData.append('motherName', editGuardianInfo.motherName);
      formData.append('mOccupation', editGuardianInfo.motherOccupation);
      formData.append('motherPhoneNo', editGuardianInfo.motherPhoneNo);
      formData.append('motherEmail', editGuardianInfo.motherEmail);
      formData.append('guardianName', editGuardianInfo.guardianName);
      formData.append('gOccupation', editGuardianInfo.guardianOccupation);
      formData.append('guardianPhone', editGuardianInfo.guardianPhone);
      formData.append('gAddress', editGuardianInfo.address);
      formData.append('gEmail', editGuardianInfo.guardianEmail);
      formData.append('fiscalYearId', editRegistrationInfo.fiscalYearId);
      try {
        const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data'
          }
        };
        const response = await axios.patch(`${backendUrl}/Student/${id}`, formData, config);
        if (response.status === 200) {
          toast.success('student edited successfully')
          handleEditDialogClose()
        } else {
          throw new Error('Failed to update student');
        }
        // onRegisterSuccess(id);
      } catch (err) {
        if (err.response && err.response.status === 409) {
          toast.error("Student already exists!", {
            autoClose: 2000,
          });
        } else {
          toast.error("Failed to add data!", {
            autoClose: 2000,
          });
        }
      } finally {
        setLoading(false);
      }
    }, 1600);
    setTimeout(() => {
     handleEditDialogClose()
    }, 1500);
  };
  const StudentGeneralInfo = editStudentInfo ? [
    { name: 'विद्यार्थीको पुरा नाम देबनगरीमा:', detail: editStudentInfo.nepaliName },
    { name: 'First Name:', detail: editStudentInfo.firstName },
    { name: 'Middle Name:', detail: editStudentInfo.middleName },
    { name: 'Last Name:', detail: editStudentInfo.lastName },
    { name: 'Date Of Birth(BS):', detail: editStudentInfo.dobBs },
    { name: 'Phone Number:', detail: editStudentInfo.phone },
    { name: 'Email:', detail: editStudentInfo.email },
    { name: 'Date Of Birth(AD):', detail: editStudentInfo.dobAd },
    { name: 'Gender:', detail: editStudentInfo.gender },
    { name: 'Ethnicity:', detail: editStudentInfo.ethnicity },
    { name: 'Nationality:', detail: editStudentInfo.nationality },
    { name: 'Disability Status:', detail: editStudentInfo.disabilityStatus },
    { name: 'Disability Type:', detail: editStudentInfo.disabilityType },
    { name: 'Citizenship No:', detail: editStudentInfo.citizenshipNo },
    { name: 'Citizenship issued District:', detail: editStudentInfo.issuedDist },
    { name: 'Guardian Name:', detail: editGuardianInfo.guardianName },
    { name: 'Guardian Contact No.', detail: editGuardianInfo.guardianPhone },
  ] : [];

  const AcademicInfo = editStudentInfo ? [
    { name: 'Program:', detail: AcademicDetails.programName },
    { name: 'Faculty:', detail: AcademicDetails.facultyName },
    { name: 'Level:', detail: AcademicDetails.levelName },
    { name: 'Admission Year:', detail: editRegistrationInfo.admissionYear },
    { name: 'Enrollment Date:', detail: editRegistrationInfo.dateOfEnrollment },
  ] : [];

  return (
    <>
      {
        loading ? (
          <>
            <LoadingOverlay
              visible={loading}
              zIndex={100}
              overlayProps={{ radius: 'sm', blur: 1 }}
              loaderProps={{ color: '#1976d2', type: 'bars' }}
            />
          </>
        ) : (
          <>
            <Grid container spacing={.5}>
              <Grid item xs={12} md={12}>
                <Box border='1px solid #c2c2c2' padding='0px' borderRadius='10px' position='relative' paddingBottom='5px'>
                  <Typography border='1px solid #c2c2c2' borderRadius='10px' fontSize='14px' display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'>
                    General Details
                  </Typography>
                  <Grid marginTop='-15px' container paddingLeft='1rem' justifyContent="flex-start">
                    {StudentGeneralInfo.map((details, index) => (
                      <Grid item xs={3} key={index}>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ width: "100%", mb: 1 }}
                        >
                          <Typography variant="body2" fontSize='14px' color="text.secondary">
                            {details.name}
                          </Typography>
                          <Typography fontSize='12px' color="#2B6EB5">{details.detail || '-'}</Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                  <Stack paddingLeft='1rem' direction='column'>
                    <Typography variant="body2" fontSize='12px' color="#2B6EB5">
                      <span style={{ color: '#6b6b6b', fontSize: '14px' }}>Permanent Address : </span>
                      {editStudentAddress.pProvince}, {editStudentAddress.pDistrict}, {editStudentAddress.pLocalLevel} - {editStudentAddress.pWardNo || ''}
                    </Typography>
                    <Typography variant="body2" fontSize='12px' color="#2B6EB5">
                      <span style={{ color: '#6b6b6b', fontSize: '14px' }}>Current Address : </span>
                      {editStudentAddress.tProvince}, {editStudentAddress.tDistrict}, {editStudentAddress.tLocalLevel} - {editStudentAddress.tWardNo}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid mt='15px' item xs={12} md={12}>
                <Box border='1px solid #c2c2c2' padding='0px' borderRadius='10px' position='relative' paddingBottom='5px'>
                  <Typography border='1px solid #c2c2c2' borderRadius='10px' fontSize='14px' display='inline-block' bgcolor='white' padding='0 5px' position='relative' left='20px' bottom='14px'>
                    Academic info
                  </Typography>
                  <Grid marginTop='-15px' container paddingLeft='1rem' justifyContent="flex-start">
                    {AcademicInfo.map((details, index) => (
                      <Grid item xs={3} key={index}>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ width: "100%", mb: 1 }}
                        >
                          <Typography variant="body2" fontSize='14px' color="text.secondary">
                            {details.name}
                          </Typography>
                          <Typography fontSize='12px' color="#2B6EB5">{details.detail || '-'}</Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Grid display='flex' justifyContent='flex-end' sm={12}>
              <Box mt={1} mb={2} display="flex" justifyContent="space-between">
                <Button onClick={handleBack} variant='outlined' color='error' type="reset" size="small"
                  startIcon={<ChevronLeftRoundedIcon />}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  type="submit"
                  sx={{
                    marginLeft: "10px",
                    position: 'relative',
                    pointerEvents: loading ? 'none' : 'auto',
                    opacity: loading ? 0.6 : 1,
                  }}
                  onClick={handleRegister}
                  endIcon={loading ? <CircularProgress size={24} color="inherit" /> : <ChevronRightRoundedIcon />}
                >
                  {loading ? 'Submitting...' : 'Update & Next'}
                </Button>
              </Box>
            </Grid>
          </>
        )
      }
    </>
  );
};

export default EditReviewDetails;
