import React, { useEffect, useState, createContext, useContext } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getStudentById } from "../../../services/employeeService";
import useAddressData from "../../address/address";
import { getEthnicGroup } from "../../../services/services";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import ImageEditor from "../../Reusable-component/ImageEditor";
const IMAGE_URL = process.env.REACT_APP_BASE_URL

const convertToISODate = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

const EditStudentInfoContext = createContext();

const EditStudentInfoProvider = ({ children }) => {
  const methods = useForm();
  const [editStudentInfo, setEditStudentInfo] = useState({
    nepaliName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    dobBs: "",
    dobAd: "",
    gender: "",
    ethnicity: "",
    nationality: "",
    disabilityStatus: "",
    disabilityType: "",
    email: "",
    citizenshipNo: "",
    issuedDist: "",
    citizenFront: null,
    citizenBack: null,
    nidNo: "",
    nidPic: null,
    ppSizePhoto: null,
  });

  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setEditStudentInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setEditStudentInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <EditStudentInfoContext.Provider value={{ ...methods, editStudentInfo, onChange }}>
      {children}
    </EditStudentInfoContext.Provider>
  );
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000",
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important",
  },
});

const EditStudentGeneralInfo = ({ handleNext, handleBack, id }) => {
  const [fetchedImages, setFetchedImages] = useState({
    digitalSignature: null,
    ppSizePhoto: null,
    citizenshipFront: null,
    citizenshipBack: null,
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useContext(EditStudentInfoContext);

  const { onChange } = useContext(EditStudentInfoContext);
  const disabilityStatus = watch('disabilityStatus');
  const { address } = useAddressData();
  const [ethnicGroup, setEthnicGroup] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const ethnicGroup = await getEthnicGroup()
        setEthnicGroup(ethnicGroup)
        const getStudentData = await getStudentById(id);
        const dobBsFormatted = getStudentData.doBBS?.split('T')[0];
        const dobAdFormatted = getStudentData.doBAD.split('T')[0];
        setValue('nepaliName', getStudentData.nepaliName);
        setValue('firstName', getStudentData.firstName);
        setValue('middleName', getStudentData.middleName);
        setValue('lastName', getStudentData.lastName);
        setValue('phone', getStudentData.phoneNumber);
        setValue('email', getStudentData.email);
        setValue('dobAd', dobAdFormatted);
        setValue('dobBs', dobBsFormatted);
        setValue('gender', getStudentData.gender);
        setValue('ethnicity', getStudentData.ethnicity);
        setValue('edg', getStudentData.edg);
        setValue('nationality', getStudentData.nationality);
        setValue('disabilityStatus', getStudentData.disabilityStatus);
        setValue('disabilityType', getStudentData.disabilityType);
        setValue('citizenshipNo', getStudentData.citizenshipNo);
        setValue('pDistrict', getStudentData.pDistrict);
        setValue('isMuktaKamaiaya', getStudentData.isMuktaKamaiya);
        setValue('isFromMartyrFamily', getStudentData.isFromMartyrFamily)
        setValue('digitalSignature', getStudentData.digitalSignature)
        setValue('ppSizePhoto', getStudentData.ppSizePhoto)
        setValue('citizenshipFront', getStudentData.citizenshipFront)
        setValue('citizenshipBack', getStudentData.citizenshipBack)
        setFetchedImages({
          digitalSignature: getStudentData.digitalSignature,
          ppSizePhoto: getStudentData.ppSizePhoto,
          citizenshipFront: getStudentData.citizenshipFront,
          citizenshipBack: getStudentData.citizenshipBack,
        });
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchData();
  }, [id, setValue]);
  

  const onSubmit = (data) => {
    
    handleSubmit((formData) => {
      console.log("Form Data Submitted:", formData);
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      handleNext();
    })(data);
  };

  const handleImageChange = (name, newImage) => {
    setValue(name, newImage);
    setFetchedImages((prev) => ({
      ...prev,
      [name]: newImage,
    }));
  };
  const handleRemoveFetchedImage = (name) => {
    setValue(name, null);
    setFetchedImages((prev) => ({
      ...prev,
      [name]: null,
    }));
  };
  const onBack = () => {
    handleBack();
  };
  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={onBack}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="nepaliName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                required
                {...field}
                id="nepaliName"
                size="small"
                name="nepaliName"
                label="विद्यार्थीको पुरा नाम देबनगरीमा (unicode)"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "Required" }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="firstName"
                size="small"
                name="firstName"
                label="First Name (English)"
                fullWidth
                inputProps={{ style: { textTransform: "uppercase" } }}
                error={!!errors.firstName}
                helperText={errors.firstName ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="middleName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="middleName"
                size="small"
                name="middleName"
                label="Middle Name"
                fullWidth
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Required" }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="lastName"
                size="small"
                name="lastName"
                label="Last Name"
                fullWidth
                error={!!errors.lastName}
                helperText={errors.lastName ? "Required" : ""}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone Number is required",
              validate: (value) => {
                const isValidPhone = /^[9]\d{9}$/.test(value);
                return isValidPhone || "Invalid phone number";
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="phone"
                type="text"
                name="phone"
                label="Phone Number"
                InputProps={{
                  placeholder: "98XXXXXXXX",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  onInput: (e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9]/g, "")
                      .slice(0, 10); // Limit input to 10 characters
                  },
                }}
                fullWidth
                autoComplete="phone number"
                size="small"
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Email format not matched",
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="email"
                name="email"
                type="email"
                size="small"
                label="Email"
                fullWidth
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="dobAd"
            control={control}
            rules={{ required: "Required" }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="dobAd"
                size="small"
                name="dobAd"
                type="date"
                label="Date of Birth(A.D.)"
                fullWidth
                InputLabelProps={{
                  shrink: true, // Always shrink the label
                }}
                error={!!errors.dobAd}
                helperText={errors.dobAd ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="dobBs"
            control={control}
            rules={{ required: "Required" }}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="dobBs"
                size="small"
                name="dobBs"
                type="date"
                label="Date of Birth(B.S.)"
                fullWidth
                InputLabelProps={{
                  shrink: true, // Always shrink the label
                }}
                error={!!errors.dobBs}
                helperText={errors.dobBs ? "Required" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="gender"
            control={control}
            rules={{ required: "Required" }}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth size="small">
                <InputLabel id="gender-label" required>Gender</InputLabel>
                <ValidationSelect
                  labelId="gender"
                  {...field}
                  label="Gender"
                  defaultValue=""
                  error={!!errors.gender}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </ValidationSelect>
                {errors.gender && (
                  <Typography color="error" variant="caption">
                    {errors.gender.message}
                  </Typography>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
            <InputLabel sx={{ borderColor: "blue" }} id="ethnicity" required>
              Ethnicity
            </InputLabel>
            <Controller
              name="ethnicity"
              control={control}
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  required
                  labelId="ethnicity"
                  id="ethnicity"
                  name="ethnicity"
                  label="Ethnicity"
                  fullWidth
                  error={!!errors.ethnicity}
                  onChange={(e) => field.onChange(e.target.value)} // Ensure field value is updated
                  value={field.value || ""} // Handle value properly
                >
                  <MenuItem value="" disabled>Select Ethnicity</MenuItem>
                  {ethnicGroup && ethnicGroup.map((data) => (
                    <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="nationality"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="nationality"
                size="small"
                name="nationality"
                label="Nationality"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="disabilityStatus"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth size="small">
                <InputLabel id="disability-status-label">Disability Status</InputLabel>
                <ValidationSelect
                  labelId="disability-status-label"
                  {...field}
                  label="Disability Status"
                  defaultValue=""
                >
                  <MenuItem value="able">Able</MenuItem>
                  <MenuItem value="differentlyable">Differently Able</MenuItem>
                </ValidationSelect>
              </FormControl>
            )}
          />
        </Grid>
        {disabilityStatus === "Disabled" && (
          <Grid item xs={12} sm={3}>
            <Controller
              name="disabilityType"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="disabilityType"
                  size="small"
                  name="disabilityType"
                  label="Disability Type"
                  fullWidth
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <Controller
            name="citizenshipNo"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                id="citizenshipNo"
                size="small"
                name="citizenshipNo"
                label="Citizenship Number"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth size="small">
            <InputLabel>
              Issued District
            </InputLabel>
            <Controller
              name="pDistrict"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  id="pDistrict"
                  size="small"
                  name="pDistrict"
                  label="Issued District"
                  fullWidth

                >
                  {address && Array.from(new Set(address.map(data => data.district))).map((district, index) => (
                    <MenuItem key={index} value={district}>{district}</MenuItem>
                  ))}
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid paddingLeft='10px' container sm={12} >
          <Grid item sm={4}>
            <FormGroup >
              <Controller
                name="edg"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Belong to EDG?"
                    sx={{
                      color: "#5a5b5d",
                      "& .MuiFormControlLabel-label": {
                        color: "#5a5b5d",
                      },
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={4}>
            <FormGroup >
              <Controller
                name="isMuktaKamaiya"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value ? true : false}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Is mukta Kamaiya ?"
                    sx={{
                      color: "#5a5b5d",
                      "& .MuiFormControlLabel-label": {
                        color: "#5a5b5d",
                      },
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={4}>
            <FormGroup >
              <Controller
                name="isFromMartyrFamily"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value ? true : false}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Is from martyr/conflict-affected family ?"
                    sx={{
                      color: "#5a5b5d",
                      "& .MuiFormControlLabel-label": {
                        color: "#5a5b5d",
                      },
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          {/* <Grid sm={3}>
            <ImageEditor
              placeholder='Passport size photo'
              name="ppSizePhoto"
              onImageChange={(newImage) => handleImageChange('ppSizePhoto', newImage)}
            />
            {fetchedImages.ppSizePhoto && (
              <div style={{ position: 'relative', marginTop: '5px', width: '50px', height: '40px' }}>
                <img src={`${IMAGE_URL}/${fetchedImages.ppSizePhoto}`} alt="Fetched" style={{ width: '100%', height: '100%', borderRadius: '8px' }} />
                <IconButton
                  size="small"
                  onClick={() => handleRemoveFetchedImage('ppSizePhoto')}
                  style={{
                    position: 'absolute',
                    top: '-4px',
                    right: '-6px',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    color: 'red',
                    padding: '0px'
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            )}
          </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" mb={1}>
            <Button
              variant="outlined"
              color="error"
              size="small"
              startIcon={<ChevronLeftRoundedIcon />}
              onClick={onBack}
              disabled
            >
              Back
            </Button>
            <Button
              sx={{ marginLeft: '10px' }}
              variant="outlined"
              size="small"
              endIcon={<ChevronRightRoundedIcon />}
              type="submit"
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { EditStudentInfoProvider, EditStudentInfoContext };
export default EditStudentGeneralInfo;
