
// 3.9 Report
import {
    Box,
    Button,
    Grid,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    FormControl,
    InputLabel,
    TableHead,
    Select,
    TableRow,
    MenuItem
} from "@mui/material";
import { useEffect } from "react";
import React, { useState } from "react";
import { FileDownload, InsertDriveFile } from "@mui/icons-material";
import * as XLSX from "xlsx";
import "jspdf-autotable";
const testData = [
    {
        level: 'Bachelor',
        faculties: [
            {
                faculty: 'Management',
                programs: [
                    {
                        program: 'BBA',
                        male: 4,
                        female: 2,
                        other: 0,
                        total: 6
                    },
                    {
                        program: 'BIM',
                        male: 0,
                        female: 0,
                        other: 0,
                        total: 0
                    },
                ],
            },
            {
                faculty: 'Science',
                programs: [
                    {
                        program: 'BSc',
                        male: 5,
                        female: 3,
                        other: 0,
                        total: 8
                    },
                    {
                        program: 'BSc in Computer Science',
                        male: 2,
                        female: 4,
                        other: 0,
                        total: 6
                    },
                ],
            },
        ],
    },
    {
        level: 'Masters',
        faculties: [
            {
                faculty: 'Management',
                programs: [
                    {
                        program: 'MBA',
                        male: 3,
                        female: 5,
                        other: 1,
                        total: 9
                    },
                    {
                        program: 'MBS',
                        male: 2,
                        female: 3,
                        other: 0,
                        total: 5
                    },
                ],
            },
            {
                faculty: 'Arts',
                programs: [
                    {
                        program: 'MA',
                        male: 4,
                        female: 2,
                        other: 0,
                        total: 6
                    },
                    {
                        program: 'MA in History',
                        male: 0,
                        female: 0,
                        other: 0,
                        total: 0
                    },
                ],
            },
        ],
    },
    {
        level: 'PGD',
        faculties: [
            {
                faculty: 'Management',
                programs: [
                    {
                        program: 'PGD in Management',
                        male: 1,
                        female: 1,
                        other: 0,
                        total: 2
                    },
                ],
            },
        ],
    },
];

const GraduatedStdByLevelFaculty = ({ fiscalId }) => {
    const [selectedFaculty, setSelectedFaculty] = useState("All");
    const [selectedLevel, setSelectedLevel] = useState("All");
    const [anchorEl, setAnchorEl] = useState(null);
    const [apiData, setApiData] = useState([]);

    useEffect(() => {
        setApiData(testData)
    }, [])
    const rows = apiData.flatMap((item) => {
        return item.faculties.flatMap((faculty) => {
            return faculty.programs.map((program) => ({
                level: item.level,
                faculty: faculty.faculty,
                program: program.program,
                male: program.male,
                female: program.female,
                other: program.other,
                total: program.total
            }));
        });
    });

    const facultyNames = [...new Set(rows.map(item => item.faculty))];
    const levelNames = [...new Set(rows.map(item => item.level))];
    const filteredRows = rows.filter((row) => {
        return (
            (selectedFaculty === "All" || row.faculty === selectedFaculty) &&
            (selectedLevel === "All" || row.level === selectedLevel)
        );
    });

    const totals = filteredRows.reduce(
        (acc, curr) => {
            acc.male += curr.male;
            acc.female += curr.female;
            acc.other += curr.other;
            acc.total += curr.total;
            return acc;
        },
        {
            male: 0,
            female: 0,
            other: 0,
            total: 0,
        }
    );


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const header = [
            [
                "S.No.",
                "Faculty",
                "Mountain-Male",
                "Mountain-Female",
                "Mountain-Other",
                "Mountain-Total",
                "Hill-Male",
                "Hill-Female",
                "Hill-Other",
                "Hill-Total",
                "Terai-Male",
                "Terai-Female",
                "Terai-Other",
                "Terai-Total",
                "Total",
            ],
        ];
        XLSX.utils.sheet_add_aoa(worksheet, header, { origin: "A1" });
        rows.forEach((row, index) => {
            const rowData = [
                index + 1,
                row.campusType,
                row.mountainMale,
                row.mountainFemale,
                row.mountainOther,
                row.mountainTotal,
                row.hillMale,
                row.hillFemale,
                row.hillOther,
                row.hillTotal,
                row.teraiMale,
                row.teraiFemale,
                row.teraiOther,
                row.teraiTotal,
                row.mountainTotal + row.hillTotal + row.teraiTotal,
            ];
            XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${index + 2}` });
        });
        const totalsRow = [
            "",
            "Total",
            totals.mountainMale,
            totals.mountainFemale,
            totals.mountainOther,
            totals.mountainTotal,
            totals.hillMale,
            totals.hillFemale,
            totals.hillOther,
            totals.hillTotal,
            totals.teraiMale,
            totals.teraiFemale,
            totals.teraiOther,
            totals.teraiTotal,
            totals.grandTotal,
        ];
        XLSX.utils.sheet_add_aoa(worksheet, [totalsRow], { origin: `A${rows.length + 2}` });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "CampusTypeByFaculty");
        XLSX.writeFile(workbook, "CampusTypeByFaculty.xlsx");
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? "export-popover" : undefined;
    return (
        <div>
            <Box sx={{ marginBottom: "5px", display: "flex", gap: 2 }}>
                <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
                    <InputLabel>Select Level</InputLabel>
                    <Select
                        size="small"
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: 2,
                        }}
                        value={selectedLevel}
                        onChange={(e) => setSelectedLevel(e.target.value)}
                        label="Select Level"
                    >
                        <MenuItem value="All">All Level</MenuItem>
                        {
                            levelNames.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
                    <InputLabel>Select Faculty</InputLabel>
                    <Select
                        size="small"
                        sx={{
                            backgroundColor: "#fff",
                            borderRadius: 2,
                        }}
                        value={selectedFaculty}
                        onChange={(e) => setSelectedFaculty(e.target.value)}
                        label="Select Faculty"
                    >
                        <MenuItem value="All">All Faculty</MenuItem>
                        {
                            facultyNames.map((item, index) => (
                                <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <Grid container justifyContent="right">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<FileDownload />}
                        onClick={handleClick}
                        style={{ marginBottom: "10px" }}
                    >
                        Export
                    </Button>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <Box sx={{ padding: "10px" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                sx={{ backgroundColor: "#5FAD41" }}
                                startIcon={<InsertDriveFile />}
                                onClick={exportToExcel}
                                fullWidth
                                style={{ marginBottom: "10px" }}
                            >
                                Excel
                            </Button>
                        </Box>
                    </Popover>
                </Grid>
            </Box>
            <TableContainer sx={{ border: "1px solid #ddd" }}>
                <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
                    <TableHead style={{ backgroundColor: "#2A629A" }}>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                rowSpan={2}
                            >
                                S.No.
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                rowSpan={2}
                            >
                                Program
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                colSpan={4}
                            >
                                Gender
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Male
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Female
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Other
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                       textAlign:'left',
                                    }}
                                >
                                    {index + 1}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                       textAlign:'left',
                                    }}
                                >
                                    {item.program}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                       textAlign:'right',
                                    }}
                                >
                                    {item.male}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                       textAlign:'right',
                                    }}
                                >
                                    {item.female}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                       textAlign:'right',
                                    }}
                                >
                                    {item.other}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "1px solid #c2c2c2",
                                        padding: "4px",
                                       textAlign:'right',
                                    }}
                                >
                                    {item.total}
                                </TableCell>

                            </TableRow>

                        ))}
                        <TableRow sx={{ backgroundColor: "#c2c2c2" }}>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "center",
                                }}
                                colSpan={2}
                            >
                                Grand Total
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.male}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.female}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.other}
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    padding: "4px",
                                    textAlign: "right",
                                }}
                            >
                                {totals.total}
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </Table>
            </TableContainer>
        </div >
    );
};

export default GraduatedStdByLevelFaculty;


