// utils/dateUtils.js
export const convertNepaliDateToAD = (nepaliDate) => {
  // Example conversion logic. Replace with actual conversion logic.
  try {
    const [year, month, day] = nepaliDate.split("-").map(Number);
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      throw new Error("Invalid date components");
    }
    // Example conversion; replace with real conversion logic
    const adYear = year - 57; // Placeholder adjustment
    const adDate = new Date(adYear, month - 1, day);
    return adDate;
  } catch (error) {
    console.error("Date conversion error:", error);
    return null;
  }
};

export const getDateOnly = (timestamp) => {
  if (!timestamp) return null; // Handle null or undefined inputs
  return new Date(timestamp).toLocaleDateString("en-CA"); // Canadian format (YYYY-MM-DD)
};

export const capitaliseFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
