import {
  Box,
  Button,
  capitalize,
  Dialog,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExamAppBar from "../navbar/ExamAppBar";
import { blue } from "@mui/material/colors";
import axios from "axios";
import MarksEntry from "./MarksEntry";
import { Link } from "react-router-dom";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default function MarksEntryList() {
  const [moduleData, setModuleName] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);

  const fetchData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${backendUrl}/SubjectExamSchedule`,
        config
      );
      setModuleName(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditClick = (data) => {
    setSelectedExam(data);
    setOpenEditDialog(true);
  };

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ExamAppBar />
        </Box>
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        padding={2}
      >
        <Grid item xs={10} display="flex" justifyContent="center">
          <Typography variant="h6" color={blue[700]} textAlign="center">
            These are the List Exams For Marks Entry
          </Typography>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="flex-end">
          <Button
          component={Link}
          to={'/exam-management/view-result'}
            variant="h6"
            color={blue[700]}
            size="small"
            sx={{
              bgcolor: "#1976d2",
              color: "white",
              "&:hover": {
                bgcolor: "#1565c0",
              },
              padding: "6px 12px",
              borderRadius: 2,
            }}
            startIcon={<LocalLibraryIcon/>}
          >
           View Results
          </Button>
        </Grid>
      </Grid>

      <Grid justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <TableContainer sx={{borderRadius:2}}>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Exam Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Subject Name
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Exam Type
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Exam Date
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Exam Time
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Full Marks
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Pass Marks
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Status
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#FFFFFF",
                      border: "1px solid #ddd",
                      padding: "8px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{bgcolor:'white'}}>
                {moduleData.length > 0 &&
                  moduleData.map((data, index) => {
                    // Get today's date
                    const today = new Date();
                    const startDate = new Date(data.examDate);
                    console.log(startDate);
                    console.log(today);

                    let status;
                    if (today < startDate) {
                      status = "Active";
                    } else if (today === startDate) {
                      status = "Running";
                    } else {
                      status = "Finished";
                    }

                    return (
                      <TableRow key={data.id}>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.examSchedule?.examName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.subject?.subjectName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {capitalize(data.examType)}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.examDate.slice(0, 10)}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.examTime}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.fullMark}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.passMark}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {data.description}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          <span
                            style={{
                              color:
                                status === "Active"
                                  ? "green"
                                  : status === "Running"
                                  ? "orange"
                                  : "gray",
                            }}
                          >
                            {status}
                          </span>
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => handleEditClick(data)}
                          >
                            Marks Entry
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth
        maxWidth="xl"
      >
        <MarksEntry
          id={selectedExam}
          onClose={() => setOpenEditDialog(false)}
          selectedExam={selectedExam}
        />
      </Dialog>
    </>
  );
}
