// 3.9 Report
import {
  Box,
  Button,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fetchEnrollmentIncampusBylevel } from "../reportApi";

const GenderWiseCampType = ({fiscalId}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiData, setApiData] = useState([])

  const fetchData = async () => {
    const response = await fetchEnrollmentIncampusBylevel(fiscalId)
    if(response){
      setApiData(response)
    }else{
      setApiData([])
    }
  }

  useEffect(() => {
   fetchData()
  }, [fiscalId])
  
  const calculateTotals = (category) => {
    const { male, female, other } = category;
    return male + female + other;
  };
  
  const rows = apiData.map((item, index) => ({
    id: index + 1,
    level: item.level.trim(), // Trim spaces if necessary
    constituteMale: item.constituent.male,
    constituteFemale: item.constituent.female,
    constituteOther: item.constituent.other,
    constituteTotal: calculateTotals(item.constituent),
    privateMale: item.private.male,
    privateFemale: item.private.female,
    privateOther: item.private.other,
    privateTotal: calculateTotals(item.private),
    communityMale: item.community.male,
    communityFemale: item.community.female,
    communityOther: item.community.other,
    communityTotal: calculateTotals(item.community),
  }));

  // Calculate the totals for each column and the overall total
  const totals = rows.reduce(
    (acc, row) => {
      acc.constituteMale += row.constituteMale;
      acc.constituteFemale += row.constituteFemale;
      acc.constituteOther += row.constituteOther;
      acc.constituteTotal += row.constituteTotal;

      acc.privateMale += row.privateMale;
      acc.privateFemale += row.privateFemale;
      acc.privateOther += row.privateOther;
      acc.privateTotal += row.privateTotal;

      acc.communityMale += row.communityMale;
      acc.communityFemale += row.communityFemale;
      acc.communityOther += row.communityOther;
      acc.communityTotal += row.communityTotal;

      acc.grandTotal +=
        row.constituteTotal + row.privateTotal + row.communityTotal;

      return acc;
    },
    {
      constituteMale: 0,
      constituteFemale: 0,
      constituteOther: 0,
      constituteTotal: 0,
      privateMale: 0,
      privateFemale: 0,
      privateOther: 0,
      privateTotal: 0,
      communityMale: 0,
      communityFemale: 0,
      communityOther: 0,
      communityTotal: 0,
      grandTotal: 0,
    }
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const exportToExcel = () => {
    if (!apiData || apiData.length === 0) {
      console.error("No data available for export.");
      return;
    }
    const headerRow = [
      "Level",
      "Constitute Male",
      "Constitute Female",
      "Constitute Other",
      "Constitute Total",
      "Private Male",
      "Private Female",
      "Private Other",
      "Private Total",
      "Community Male",
      "Community Female",
      "Community Other",
      "Community Total",
      "Total",
    ];
  
    const data = apiData.map((item) => {
      const constituent = item.constituent || { male: 0, female: 0, other: 0 };
      const privateData = item.private || { male: 0, female: 0, other: 0 };
      const community = item.community || { male: 0, female: 0, other: 0 };
      const calculateTotals = (category = { male: 0, female: 0, other: 0 }) => {
        const { male = 0, female = 0, other = 0 } = category;
        return male + female + other;
      };
      return [
        item.level.trim(),
        constituent.male,
        constituent.female,
        constituent.other,
        calculateTotals(constituent),
        privateData.male,
        privateData.female,
        privateData.other,
        calculateTotals(privateData),
        community.male,
        community.female,
        community.other,
        calculateTotals(community),
        calculateTotals(constituent) +
          calculateTotals(privateData) +
          calculateTotals(community),
      ];
    });
    const totals = data.reduce(
      (acc, row) => {
        acc.constituteMale += row[1];
        acc.constituteFemale += row[2];
        acc.constituteOther += row[3];
        acc.constituteTotal += row[4];
  
        acc.privateMale += row[5];
        acc.privateFemale += row[6];
        acc.privateOther += row[7];
        acc.privateTotal += row[8];
  
        acc.communityMale += row[9];
        acc.communityFemale += row[10];
        acc.communityOther += row[11];
        acc.communityTotal += row[12];
  
        acc.grandTotal += row[13];
        return acc;
      },
      {
        constituteMale: 0,
        constituteFemale: 0,
        constituteOther: 0,
        constituteTotal: 0,
        privateMale: 0,
        privateFemale: 0,
        privateOther: 0,
        privateTotal: 0,
        communityMale: 0,
        communityFemale: 0,
        communityOther: 0,
        communityTotal: 0,
        grandTotal: 0,
      }
    );
    const totalsRow = [
      "Grand Total",
      totals.constituteMale,
      totals.constituteFemale,
      totals.constituteOther,
      totals.constituteTotal,
      totals.privateMale,
      totals.privateFemale,
      totals.privateOther,
      totals.privateTotal,
      totals.communityMale,
      totals.communityFemale,
      totals.communityOther,
      totals.communityTotal,
      totals.grandTotal,
    ];
    data.push(totalsRow);
    const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enrollment Data");
    XLSX.writeFile(workbook, "EnrollmentData.xlsx");
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;


  return (
    <div>
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: '10px' }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box sx={{ padding: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: '#5FAD41' }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
            >
              Excel
            </Button>
          </Box>
        </Popover>
      </Grid>
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={3}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={3}
              >
                Level
              </TableCell>

            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Constitute Campus
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Private Campus
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={4}
              >
                Community Campus
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                Total
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Other
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>

              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Other
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Other
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "left",
                  }}
                >
                  {row.level}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteOther}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateOther}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.privateTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityOther}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.communityTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {row.constituteTotal + row.privateTotal + +row.communityTotal}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ backgroundColor: "#c2c2c2" }}>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={2}
              >
                Grand Total
              </TableCell>

              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.constituteMale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.constituteFemale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.constituteOther}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.constituteTotal}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.privateMale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.privateFemale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.privateOther}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.privateTotal}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.communityMale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.communityFemale}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.communityOther}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.communityTotal}
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  padding: "4px",
                  textAlign:'right'
                }}
              >
                {totals.grandTotal}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GenderWiseCampType;
