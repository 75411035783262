import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogContent,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const EditLandMgmt = ({ id, onClose, onUpdate }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLandDataById = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };

        const response = await axios.get(`${backendUrl}/Lands/${id}`, config);
        const landData = response.data;

        // Set default values for form fields
        setValue("areaCoveredRooms", landData.totalArea || 0);
        setValue("areaUnit", landData.unit || 0);
        setValue("sheetNo", landData.sheetNo || 0);
        setValue("kittaNo", landData.kittaNo || 0);
        setValue("ownership", landData.ownerShip ? "yes" : "no");
        setValue("remarks", landData.remarks || "");
      } catch (error) {
        console.error("Error fetching land data:", error);
      }
    };

    fetchLandDataById();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    const apiData = {
        id:id,
      totalArea: parseFloat(data.areaCoveredRooms) || 0,
      unit: data.areaUnit || 0,
      sheetNo: data.sheetNo || 0,
      kittaNo: data.kittaNo || 0,
      ownerShip: data.ownership === "yes",
      remarks: data.remarks || "",
    };

    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };

      await axios.put(`${backendUrl}/Lands/${id}`, apiData, config);

      toast.success("Data updated successfully", {
        autoClose: 1500,
      });
      onUpdate();
      onClose();
    } catch (error) {
      toast.error("Error updating data: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogContent fullWidth>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ textAlign: "center", color: "#2A629A", padding: "10px" }}
      >
        Edit Land Details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <Controller
              name="areaCoveredRooms"
              control={control}
              defaultValue=""
              rules={{
                required: "Total Area is required",
                pattern: {
                  value: /^[0-9]*\.?[0-9]+$/,
                  message: "Please enter a valid number",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  size="small"
                  label="Total Area (sq.ft)"
                  fullWidth
                  error={!!errors.areaCoveredRooms}
                  helperText={errors.areaCoveredRooms?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl fullWidth size="small" error={!!errors.areaUnit}>
              <InputLabel id="area-unit-label">Area Unit</InputLabel>
              <Controller
                name="areaUnit"
                control={control}
                defaultValue=""
                rules={{ required: "Area Unit is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="area-unit-label"
                    label="Area Unit"
                  >
                    <MenuItem value="hector">Hector</MenuItem>
                    <MenuItem value="ropani">Ropani</MenuItem>
                    <MenuItem value="kattha">Kattha</MenuItem>
                    <MenuItem value="barga meter">barga meter</MenuItem>
                    <MenuItem value="barga feet">barga feet</MenuItem>
                  </Select>
                )}
              />
              {errors.areaUnit && (
                <Typography variant="body2" color="error">
                  {errors.areaUnit.message}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              name="sheetNo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Sheet No"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              name="kittaNo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Kitta No"
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl fullWidth size="small" error={!!errors.ownership}>
              <InputLabel id="ownership-label">Ownership</InputLabel>
              <Controller
                name="ownership"
                control={control}
                defaultValue=""
                rules={{ required: "Ownership is required" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="ownership-label"
                    label="Ownership"
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                )}
              />
              {errors.ownership && (
                <Typography variant="body2" color="error">
                  {errors.ownership.message}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="remarks"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Remarks"
                  fullWidth
                  multiline
                  rows={4}
                />
              )}
            />
          </Grid>

          <Grid container justifyContent="center" spacing={2} mt={2}>
            <Grid item>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </DialogContent>
  );
};

export default EditLandMgmt;
