import {
  Box,
  Button,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  FileDownload,
  PictureAsPdf,
  InsertDriveFile,
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fetchEnrollmentByFaculty } from "./reportApi";

const StudentInFaculty = ({ fiscalId }) => {
  const [apiData, setApiData] = useState([]);
  const fetchData = async () => {
    const response = await fetchEnrollmentByFaculty(fiscalId)
    if (response) {
      setApiData(response)
    } else {
      setApiData([])
    }
    setApiData(response);
  };
  useEffect(() => {
    fetchData();
  }, [fiscalId]);

  const headers =
    apiData.length > 0 ? Object.keys(apiData[0].facultyCampusCounts) : [];
  const facultyTotals = headers.reduce((acc, header) => {
    acc[header] = apiData.reduce(
      (sum, uni) => sum + uni.facultyCampusCounts[header],
      0
    );
    return acc;
  }, {});
  const universityTotals = apiData.map((uni) =>
    Object.values(uni.facultyCampusCounts).reduce((a, b) => a + b, 0)
  );
  const grandTotalOfUniversities = universityTotals.reduce((a, b) => a + b, 0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  const exportToExcel = () => {
    const header = ["S.No.", "University", ...headers, "Total"];

    const data = apiData.map((uni, index) => [
      index + 1,
      uni.universityName,
      ...headers.map((header) => uni.facultyCampusCounts[header]),
      universityTotals[index],
    ]);
    const grandTotalRow = [
      "",
      "Grand Total",
      ...headers.map((header) => facultyTotals[header]),
      grandTotalOfUniversities,
    ];
    data.push(grandTotalRow)
    const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Faculty Campus");
    XLSX.writeFile(wb, "faculty_campus.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);

    const columns = ["S.No.", "University", ...headers, "Total"];
    const data = apiData.map((uni, index) => [
      index + 1,
      uni.universityName,
      ...headers.map((header) => uni.facultyCampusCounts[header]),
      universityTotals[index],
    ]);
    const grandTotalRow = [
      "",
      "Grand Total",
      ...headers.map((header) => facultyTotals[header]),
      grandTotalOfUniversities,
    ];
    data.push(grandTotalRow)

    doc.autoTable({
      head: [columns],
      body: data,
      styles: {
        lineColor: "#c2c2c2",
        lineWidth: 0.2,
        cellPadding: 1,
      },
      headStyles: {
        fontSize: 6
      },
      bodyStyles: {
        fontSize: 6
      },
    });

    doc.save("faculty_campus.pdf");
  };
  return (
    <div>
      {/* Export Button with Popover */}
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: "10px" }}
        >
          Export
        </Button>

        {/* Popover for Excel and PDF options */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#5FAD41" }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: "10px" }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#272727" }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
      </Grid>

      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                University
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
                colSpan={headers.length + 1}
              >
                Student Enrollment in different faculties
              </TableCell>
            </TableRow>

            <TableRow>
              {headers.map((item, index) => (
                <TableCell
                  key={index}
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {item}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {apiData.map((uni, index) => (
              <TableRow key={uni.universityName}>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "left",
                  }}
                >
                  {uni.universityName}
                </TableCell>
                {headers.map((header) => (
                  <TableCell
                    key={header}
                    sx={{
                      border: "1px solid #ddd",
                      textAlign: "right",
                    }}
                  >
                    {uni.facultyCampusCounts[header]}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {universityTotals[index]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            {Object.entries(facultyTotals).map(([item, value], index) => (
              <TableCell
                key={index}
                sx={{
                  border: "1px solid #ddd",
                  textAlign: "right",
                }}
              >
                {value}
              </TableCell>
            ))}

            <TableCell
              sx={{
                border: "1px solid #ddd",
                textAlign: "right",
              }}
            >
              {grandTotalOfUniversities}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StudentInFaculty;
