import React from "react";
import { Routes, Route } from "react-router-dom";
import { FacultyMgmt, LevelMgmt, SubjectMgmt } from "./components";
import UniversityList from "./pages/university/UniversityList.jsx";
import UniversityRegister from "./pages/university/UniversityRegister.jsx";
import Login from "./pages/auth/login.jsx";
import Register from "./pages/auth/register.jsx";
import CampusRegister from "./pages/campus/CampusRegister.jsx";
import StudentStepper from "./components/studentRegistration/StudentStepper.jsx";
import CampusList from "./pages/campus/CampusList.jsx";
import ArthikBarsa from "./components/setup/arthikBarsa/ArthikBarsa.jsx";
import NotFound from "./pages/error/Noroute.jsx";
import { Box } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./pages/profile/Profile.jsx";
import StudentInfoDetail from "./components/report/university/StudentInfoDetail.jsx";
import EmployeeRegister from "./components/employeeRegister/EmployeeRegister.jsx";
import Footer from "./components/footer/Footer.jsx";
import TeacherDetails from "./components/report/university/TeacherDetails.jsx";
import NonTeachingDetails from "./components/report/university/NonTeachingDetails.jsx";
import StudentList from "./pages/students/StudentList.jsx";
import StudentAcademicInfo from "./components/studentRegistration/StudentAcademicInfo.jsx";
import EmployeeList from "./pages/employee/EmployeeList.jsx";
import PrivateRoute from "./components/privateRoute/PrivateRoute.jsx";
import StudentSummary from "./components/report/StudentSummary.jsx";
import StudentByDistrict from "./components/report/StudentByDistrict.jsx";
import TeachingStaffSummary from "./components/report/TeachingStaffSummary.jsx";
import NonTeachingStaffSummary from "./components/report/NonTeachingStaffSummary.jsx";
import "./app.css";
import TeachingStaff from "./pages/employee/TeachingStaff.jsx";
import TechnicalStaff from "./pages/employee/TechnicalStaff.jsx";
import NonTechnicalStaff from "./pages/employee/NonTechnicalStaff.jsx";
import ConstituentCampus from "./pages/campus/ConstituentCampus.jsx";
import CommunityCampus from "./pages/campus/CommunityCampus.jsx";
import PrivateCampus from "./pages/campus/PrivateCampus.jsx";
import Main from "./esewa/Main.js";
import Success from "./esewa/Success.js";
import Failure from "./esewa/Failure.js";
import EnrollmentForm from "./components/setup/enrollment/EnrollmentForm.jsx";
import FeeSetup from "./components/setup/feeSetup/FeeSetup.jsx";
import EnrollCourse from "./components/setup/enrollment/EnrollCourse.jsx";
import { useSelector } from "react-redux";
import AddFaculties from "./components/addingComponents/AddingFaculties.jsx";
import StudentsDetails from "./pages/students/ForVerificationStudents.jsx";
import VerifiedStudentList from "./pages/students/VerifiedStudentList.jsx";
import BatchMgmt from "./components/setup/batch/CreateBatch.jsx";
import UpgradeAcademics from "./pages/students/UpgradeAcademics.jsx";
import EthnicGroup from "./components/setup/ethnicGroup/EthnicsGroup.jsx";
import FeeType from "./components/setup/feeSetup/FeeType.jsx";
import RegisterUser from "./components/settings/RegisterUser.jsx";
import LandMgmt from "./components/setup/land/LandMgmt.jsx";
import BuildingMgmt from "./components/setup/Building/BuildingMgmt.jsx";
import LabMgmt from "./components/setup/lab/LabMgmt.jsx";
import HostelMgmt from "./components/setup/Hostel/HostelMgmt.jsx";
import FacilityMgmt from "./components/setup/RegisterFacility/RegisterMgmt.jsx";
import LibraryMgmt from "./components/setup/library/LibraryMgmt.jsx";
import EquipMgmt from "./components/setup/Equipment/EquipmentMgmt.jsx";
import PasswordReset from "./pages/auth/PasswordReset.jsx";
import NewPassword from "./pages/auth/NewPassword.jsx";
import StudentHome from "./components/student/StudentHome.jsx";
import NewDashboard from "./pages/dashboard/newDashboard.jsx";
import HomePage from "./pages/home/HomePage.jsx";
import StudentManagementHome from "./modules/studentManagement/studentManagementHome.jsx";
import EmployeeManagementHome from "./modules/employeeManagement/employeeManagementHome.jsx";
import InfrastructureManagement from "./modules/infrastructureManagement/infrastructureManagementHome.jsx";
import UserManagementHome from "./modules/userManagement/UserManagementHome.jsx";
import HomeAppBar from "./modules/navbar/HomeAppBar.jsx";
import PermissionManagement from "./modules/userManagement/PermissionManagement.jsx";
import ProgramSetupHome from "./modules/programSetupManagement/ProgramSetupHome.jsx";
import OtherSetupHome from "./modules/otherSetupManagement/OtherSetupHome.jsx";
import AddSections from "./components/setup/program/SectionAdd.jsx";
import AttendanceManagementHome from "./modules/attendanceManagement/AttendanceManagementHome.jsx";
import ImageUploader from "./components/Reusable-component/ImageUploader.jsx";
import OrganizationManagementHome from "./modules/organizationManagement/OrganizationManagementHome.jsx";
import EmployeeAttendanceManagement from "./modules/attendanceManagement/EmployeeAttendanceManagement.jsx";
import AccordionUsage from "./components/report/ReportAccordions.jsx";
import PostSetup from "./components/setup/post/PostSetup.jsx";
import HolidayMgmt from "./components/setup/holidaySetup/HolidayMgmt.jsx";
import BudgetMgmt from "./components/setup/budgetDisburshment/BudgetMgmt.jsx";
import StudentAgeMgmt from "./components/setup/budgetDisburshment/StudentAgeMgmt.jsx";
import GraduationModule from "./components/setup/graduationModule/GraduationModule.jsx";
import StatisticalReport from "./components/report/PrabhatReport/StatisticalReport.jsx";
import ExamSetupHome from "./modules/examManagement/ExamSetupHome.jsx";
import ExamAppear from "./modules/examManagement/ExamAppear.jsx";
import ExamSchedule from "./modules/examManagement/ExamSchedule.jsx";
import ExamType from "./modules/examManagement/ExamType.jsx";
import RoutineSchedule from "./modules/examManagement/RoutineSchedule.jsx";
import ReceiptMgmt from "./components/setup/graduationModule/ReceiptMgmt.jsx";
import NumberCampusType from "./components/setup/graduationModule/NumberCampusType.jsx";
import GraduationModuleTable from "./components/setup/graduationModule/GraduationModuleTable.jsx";
import CharacterProveApplication from "./components/setup/graduationModule/CharacterProveAppication.jsx";
import IncomeExpenseHead from "./components/setup/FinanceData/IncomeExpenseHead.jsx";
import IncomeExpenditureEntry from "./components/setup/FinanceData/IncomeExpenditureEntry.jsx";
import ExportStudentInfo from "./pages/students/ExportStudentInfo.js";
import UgcEmployee from "./pages/employee/UgcEmployee.jsx";
import DepartmentAdd from "./components/setup/program/DepartmentAdd.jsx";
import ExamScheduleList from "./modules/examManagement/ExamScheduleList.jsx";
import RoutineList from "./modules/examManagement/RoutineList.jsx";
import MarksEntryList from "./modules/examManagement/MarksEntryList.jsx";
import CampusReportAccordion from "./components/report/CampusReport/CampusReportAccordion.jsx";
import ReceiptPdfExport from "./components/setup/graduationModule/ReceiptPdfExport.jsx";
import ViewResults from "./modules/examManagement/ViewResults.jsx";
import GraduationForm from "./components/setup/graduationModule/GraduationForm.jsx";
import ReceiptHome from "./modules/receipt/ReceiptHome.jsx";
import ReceiptTable from "./components/setup/graduationModule/ReceiptTable.jsx";
import CollegeStatReportAccordion from "./components/report/CampusReport/statisticalReport/CollegeStatReportAccordion.jsx";
import ReceiptStudentList from "./components/setup/graduationModule/StudentListForReceipt.jsx";
import GraduationModuleHome from "./components/setup/graduationModule/GraduationModuleHome.jsx";
import GraduationList from "./components/setup/graduationModule/GraduationList.jsx";
import CharacterCertificate from "./components/setup/graduationModule/CharacterCertificate.jsx";
function AdminRoute() {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  const adminRole = currentUser ? currentUser.type : "";
  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box mt={4}>
        <HomeAppBar />
      </Box>
      <Box flexGrow={2} margin="30px 20px 20px 20px">
        <Routes>
          <Route element={<PrivateRoute />}>
            {roleName === "Student" ? (
              <Route path="/" element={<StudentHome />}>
                <Route path="student-home" element={<StudentHome />} />
              </Route>
            ) : (
              <>
                <Route path="/home" element={<HomePage />} />
                <Route path="/" element={<NewDashboard />} />
                <Route
                  path="/student-management"
                  element={<StudentManagementHome />}
                />
                <Route
                  path="/employee-management"
                  element={<EmployeeManagementHome />}
                />
                <Route
                  path="/infrastructure-management"
                  element={<InfrastructureManagement />}
                />
                <Route
                  path="/user-management"
                  element={<UserManagementHome />}
                />
                <Route path="/profile" element={<Profile />} />
                <Route
                  path="/student-management/student-list"
                  element={<StudentList />}
                />
                <Route
                  path="/user-management/add-user"
                  element={<RegisterUser />}
                />
                <Route
                  path="/employee-management/employee-list"
                  element={<EmployeeList />}
                />
                <Route
                  path="/employee-management/teaching-staff"
                  element={<TeachingStaff />}
                />
                <Route path="/image" element={<ImageUploader />} />
                <Route
                  path="/employee-management/technical-staff"
                  element={<TechnicalStaff />}
                />
                <Route
                  path="/employee-management/non-technical-staff"
                  element={<NonTechnicalStaff />}
                />
                <Route
                  path="/employee-management/ugc-employee"
                  element={<UgcEmployee />}
                />
                <Route
                  path="/academic-info/:id"
                  element={<StudentAcademicInfo />}
                />
                <Route
                  path="/infrastructure-management/land-management"
                  element={<LandMgmt />}
                />
                <Route
                  path="/infrastructure-management/building-management"
                  element={<BuildingMgmt />}
                />
                <Route
                  path="/infrastructure-management/lab-management"
                  element={<LabMgmt />}
                />
                <Route
                  path="/infrastructure-management/hostel-management"
                  element={<HostelMgmt />}
                />
                <Route
                  path="/infrastructure-management/land-management"
                  element={<LandMgmt />}
                />
                <Route
                  path="/infrastructure-management/facility-register"
                  element={<FacilityMgmt />}
                />
                <Route
                  path="/infrastructure-management/library-management"
                  element={<LibraryMgmt />}
                />
                <Route
                  path="/infrastructure-management/land-management"
                  element={<LandMgmt />}
                />
                <Route
                  path="/infrastructure-management/equipment-management"
                  element={<EquipMgmt />}
                />
                <Route
                  path="/student-management/student-information-detail"
                  element={<StudentInfoDetail />}
                />
                <Route
                  path="/student-management/student-summary-detail"
                  element={<StudentSummary />}
                />
                <Route
                  path="/student-management/student-by-district"
                  element={<StudentByDistrict />}
                />
                <Route
                  path="/employee-management/teaching-staff-summary"
                  element={<TeachingStaffSummary />}
                />
                <Route
                  path="/student-management/other-report"
                  element={
                    adminRole === "college" ? (
                      <CampusReportAccordion />
                    ) : (
                      <AccordionUsage />
                    )
                  }
                />
                <Route
                  path="/employee-management/non-teaching-staff-summary"
                  element={<NonTeachingStaffSummary />}
                />
                <Route
                  path="/employee-management/teacher-detail"
                  element={<TeacherDetails />}
                />
                <Route
                  path="/employee-management/non-teaching-details"
                  element={<NonTeachingDetails />}
                />
                <Route
                  path="/other-setup/faculty-management"
                  element={<FacultyMgmt />}
                />
                <Route path="/holiday-setups" element={<HolidayMgmt />} />
                <Route
                  path="/character-application"
                  element={<CharacterProveApplication />}
                />

                <Route path="/holiday-setups" element={<HolidayMgmt />} />
                <Route path="/budgetmgmt-setups" element={<BudgetMgmt />} />
                <Route
                  path="/studentagemgmt-setups"
                  element={<StudentAgeMgmt />}
                />
                <Route
                  path="/graduation-management/graduation-form/:id"
                  element={<GraduationModule />}
                />
                <Route path="/graduationForm" element={<GraduationForm />} />

                <Route
                  path="/student-management/graduationtable-setups"
                  element={<GraduationModuleTable />}
                />
                <Route
                  path="/receipt-management/receipt-form/:id"
                  element={<ReceiptMgmt />}
                />
                <Route
                  path="/receipt-management/students"
                  element={<ReceiptStudentList />}
                />
                <Route
                  path="/number-campustype"
                  element={<NumberCampusType />}
                />
                <Route
                  path="/other-setup/level-management"
                  element={<LevelMgmt />}
                />
                {/* <Route path="/program-management" element={<ProgramMgmt />} /> */}
                <Route
                  path="/program-management/subject-management"
                  element={<SubjectMgmt />}
                />
                <Route
                  path="/other-setup/batch-management"
                  element={<BatchMgmt />}
                />
                <Route
                  path="/other-setup/ethnic-group-management"
                  element={<EthnicGroup />}
                />
                <Route
                  path="/other-setup/post-management"
                  element={<PostSetup />}
                />
                <Route
                  path="/organization-management/university-register"
                  element={<UniversityRegister />}
                />
                <Route
                  path="/organization-management/campus-register"
                  element={<CampusRegister />}
                />
                <Route
                  path="/form-management/statisticalReport"
                  element={<CollegeStatReportAccordion />}
                />
                <Route
                  path="/organization-management/university-list"
                  element={<UniversityList />}
                />
                <Route
                  path="/organization-management/central-departments"
                  element={<CampusList />}
                />
                <Route
                  path="/organization-management/constituent-campus"
                  element={<ConstituentCampus />}
                />
                <Route
                  path="/organization-management/community-campus"
                  element={<CommunityCampus />}
                />
                <Route
                  path="/organization-management/private-campus"
                  element={<PrivateCampus />}
                />
                <Route
                  path="/student-management/student-register"
                  element={<StudentStepper />}
                />
                <Route
                  path="/employee-management/employee-register"
                  element={<EmployeeRegister />}
                />
                <Route path="/enrollment/:id" element={<EnrollmentForm />} />
                <Route path="/other-setup/fee-setup" element={<FeeSetup />} />
                <Route
                  path="/receipt-management/fee-type"
                  element={<FeeType />}
                />
                <Route
                  path="/student-management/upgrade-academics"
                  element={<UpgradeAcademics />}
                />

                <Route
                  path="/program-management/college-programs"
                  element={<AddFaculties />}
                />
                <Route
                  path="/student-management/verified-students"
                  element={<VerifiedStudentList />}
                />
                <Route
                  path="/student-management/exportToPdf"
                  element={<ExportStudentInfo />}
                />
                <Route
                  path="/student-management/student-verification/:id"
                  element={<StudentsDetails />}
                />
                <Route
                  path="/organization-management"
                  element={<OrganizationManagementHome />}
                />
                <Route
                  path="/user-management/permission-management"
                  element={<PermissionManagement />}
                />

                <Route
                  path="/form-management/statisticalReport"
                  element={<StatisticalReport />}
                />
                <Route
                  path="/program-management"
                  element={<ProgramSetupHome />}
                />
                <Route path="/other-setup" element={<OtherSetupHome />} />
                <Route
                  path="/program-management/add-section"
                  element={<AddSections />}
                />
                <Route
                  path="/program-management/add-department"
                  element={<DepartmentAdd />}
                />
                <Route
                  path="/student-management/attendance"
                  element={<AttendanceManagementHome />}
                />
                <Route
                  path="/employee-management/attendance"
                  element={<EmployeeAttendanceManagement />}
                />
                <Route path="/exam-management" element={<ExamSetupHome />} />
                <Route
                  path="/exam-management/exam-appear"
                  element={<ExamAppear />}
                />
                <Route
                  path="/exam-management/exam-list"
                  element={<ExamScheduleList />}
                />
                <Route
                  path="/exam-management/exam-schedule"
                  element={<ExamSchedule />}
                />
                <Route
                  path="/exam-management/exam-type"
                  element={<ExamType />}
                />
                <Route
                  path="/exam-management/exam-routine"
                  element={<RoutineSchedule />}
                />

                <Route
                  path="/exam-management/routine-list"
                  element={<RoutineList />}
                />
                <Route
                  path="/exam-management/marks-entry"
                  element={<MarksEntryList />}
                />
                <Route
                  path="/exam-management/view-result"
                  element={<ViewResults />}
                />

                <Route
                  path="/income-expense-head"
                  element={<IncomeExpenseHead />}
                />
                <Route
                  path="/income-expenditure-entry"
                  element={<IncomeExpenditureEntry />}
                />
                <Route path="/budget-management" element={<BudgetMgmt />} />
                <Route
                  path="/graduation-management/receipt-details/:id"
                  element={<ReceiptPdfExport />}
                />
                <Route path="/receipt-management" element={<ReceiptHome />} />
                <Route
                  path="/receipt-management/receipt-list"
                  element={<ReceiptTable />}
                />
                {/* For Finance Head */}
                <Route
                  path="/other-setup/incomeexpensehead"
                  element={<IncomeExpenseHead />}
                />
                <Route
                  path="/other-setup/incomeexpense"
                  element={<IncomeExpenditureEntry />}
                />
                <Route
                  path="/exam-management/marks-entry"
                  element={<MarksEntryList />}
                />
                <Route
                  path="/graduation-management"
                  element={<GraduationModuleHome />}
                />
                <Route
                  path="/graduation-management/graduation-list"
                  element={<GraduationList />}
                />
                <Route
                  path="character-certificate/:id"
                  element={<CharacterCertificate />}
                />
                <Route path="/budget-management" element={<BudgetMgmt />} />
              </>
            )}
          </Route>
          <Route path="/forgot-password" element={<PasswordReset />} />
          <Route
            path="/reset-password/:email/:token"
            element={<NewPassword />}
          />
          <Route path="/register" element={<Register />} />
          <Route path="/open-enroll" element={<EnrollCourse />} />
          <Route path="/other-setup/arthikBarsa" element={<ArthikBarsa />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<Login />} />
          <Route exact path="/esewa" element={<Main />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failed" element={<Failure />} />
        </Routes>
        <ToastContainer />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Footer />
      </Box>
    </Box>
  );
}

export default AdminRoute;
