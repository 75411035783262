import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Dialog,
  TablePagination,
  Typography,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { SaveAlt as ExportIcon } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditReceiptMgmt from "./EditReceiptMgmt";
import ReceiptNavBar from "../../../modules/navbar/ReceiptNavBar";
import { blue } from "@mui/material/colors";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ReceiptTable = () => {
  const [receiptData, setReceiptData] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState("");
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const navigate = useNavigate();
  const [selectedReceiptId, setSelectedReceiptId] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  useEffect(() => {
    fetchReceiptData();
  }, []);

  const fetchReceiptData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const fiscalYearResponse = await axios.get(
        `${backendUrl}/FiscalYear`,
        config
      );
      const response = await axios.get(`${backendUrl}/Receipt`, config);

      setFiscalYears(fiscalYearResponse.data);
      setReceiptData(response.data);
      
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };
  console.log(receiptData);

  const handleFiscalYearChange = (event) => {
    setSelectedFiscalYear(event.target.value);
  };

  const filteredReceiptData = selectedFiscalYear
    ? receiptData.filter((data) => data.fiscalYear === selectedFiscalYear)
    : receiptData;

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExport = (id) => {
    navigate(`/graduation-management/receipt-details/${id}`);
  };

  const handleEditClick = (receiptId) => {
    setSelectedReceiptId(receiptId);
    setOpenEditDialog(true);
  };

  const handleUpdate = () => {
    fetchReceiptData();
  };

  const paginatedData = filteredReceiptData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ReceiptNavBar />
        </Box>
      </Box>

      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={2} mt={1} mb={1}>
          <FormControl fullWidth size="small">
            <InputLabel id="fiscalYearId-label">Fiscal Year</InputLabel>
            <Select
              id="fiscalYearId"
              labelId="fiscalYearId-label"
              value={selectedFiscalYear}
              onChange={handleFiscalYearChange}
              label="Fiscal Year"
              sx={{
                borderRadius: 2,
                backgroundColor: "white",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {fiscalYears.map((fy) => (
                <MenuItem key={fy.id} value={fy.id}>
                  {fy.yearNepali}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6} mt={1} mb={1}>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              color: blue[700],
            }}
          >
            Payment List
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <TableContainer sx={{ borderRadius: 2 }}>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                    }}
                  >
                    S.N
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                    }}
                  >
                    Receipt No.
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                    }}
                  >
                    Applicant Name
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                    }}
                  >
                    Program
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                      width: "12%",
                    }}
                  >
                    Semester
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                      width: "12%",
                    }}
                  >
                    Date of Payment
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                    }}
                  >
                    Remarks
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{ backgroundColor: "white" }}>
                {paginatedData.length > 0 ? (
                  paginatedData.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {index + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data?.receiptNo}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data?.studentName}
                      </TableCell>

                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data?.receiptItem[0]?.name}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "right",
                        }}
                      >
                        {"RS. " + data?.receiptItem[0]?.amount || 0}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data?.dateOfPayment?.slice(0, 10)}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data.remarks || "-"}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          width: "10%",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <Button
                            onClick={() => handleEditClick(data.id)}
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{
                              borderRadius: 2,
                            }}
                          >
                            <EditNoteIcon /> Edit
                          </Button>
                          <Button
                            onClick={() => handleExport(data.id)}
                            variant="contained"
                            size="small"
                            startIcon={<ExportIcon />}
                            sx={{
                              bgcolor: "#1976d2",
                              color: "white",
                              "&:hover": {
                                bgcolor: "#1565c0",
                              },
                              padding: "6px 12px",
                              borderRadius: 2,
                            }}
                          >
                            Generate
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={10} style={{ textAlign: "center" }}>
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredReceiptData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[25, 50]}
          />
        </Grid>
        <Dialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          maxWidth="md"
        >
          <EditReceiptMgmt
            id={selectedReceiptId}
            onClose={() => setOpenEditDialog(false)}
            onUpdate={handleUpdate}
          />
        </Dialog>
      </Grid>
    </>
  );
};

export default ReceiptTable;
