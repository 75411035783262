import React from "react";
import OrganizationAppBar from "../navbar/OrganizationAppBar";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import UniversityList from "../../pages/university/UniversityList";
import ConstituentCampus from "../../pages/campus/ConstituentCampus";

const OrganizationManagementHome = () => {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  return (
    <div>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <OrganizationAppBar />
        </Box>
        <Box mt={-8}>
          {roleName === "SuperAdmin" && <UniversityList />}
          {roleName === "UniAdmin" && <ConstituentCampus />}
        </Box>
      </Box>
    </div>
  );
};

export default OrganizationManagementHome;
