import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Button,
  Popover,
  Grid
} from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import { getDynamicTeacherData } from "../reportApi";


const TeacherByCampusType = (fiscalId) => {
  const [rows, setRows] = useState([])
  const [header, setHeader] = useState([])
  const [totals, setTotals] = useState({})

  const fetchData = async () => {
    const response = await getDynamicTeacherData();
    if (response) {
      const headers = [
        ...Object.keys(response.reduce((acc, item) => ({ ...acc, ...item.categoryCounts }), {}))
      ];
      setHeader(headers);
      const transformedData = response.map((item) => ({
        university: item.university,
        ratio: item.totalStudents !== 0 ? (item.totalTeachers / item.totalStudents).toFixed(2) : "0.00",
        ...headers.reduce((acc, category) => {
          acc[category.toLowerCase()] = item.categoryCounts[category] || 0;
          return acc;
        }, {}),
        totalStudents: item.totalStudents || 0,
        totalTeachers: item.totalTeachers || 0,

      }));
      const totals = headers.reduce((acc, category) => {
        acc[category.toLowerCase()] = response.reduce(
          (sum, item) => sum + (item.categoryCounts[category] || 0), 0
        );
        return acc;
      }, {});
      totals.totalTeachers = headers.reduce((sum, category) => sum + (totals[category.toLowerCase()] || 0), 0);

      totals.totalStudents = response.reduce((sum, item) => sum + item.totalStudents, 0);
      setTotals(totals)
      setRows(transformedData);

    } else {
      setRows([])
      setHeader([])
      setTotals([])
    }
  };

  const totalRatio = totals.totalTeachers / totals.totalStudents
  useEffect(() => {
    fetchData()
  }, [])

  const [filteredCampusType, setFilteredCampusType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const filteredRows = filteredCampusType
    ? rows.filter((row) => row.campusType === filteredCampusType)
    : rows;


  const exportToExcel = () => {
    const excelData = [
      // Header row
      [
        "S.No.",
        "University",
        ...header,
        "Total Teachers",
        "Total Students",
        "Ratio (teacher/students)"
      ],
      // Data rows
      ...filteredRows.map((row, index) => [
        index + 1,
        row.university,
        ...header.map((category) => row[category.toLowerCase()] || 0),
        row.totalTeachers,
        row.totalStudents,
        row.ratio
      ]),
      [
        "Grand Total",
        "",
        ...header.map((category) => totals[category.toLowerCase()] || 0),
        totals.totalTeachers,
        totals.totalStudents,
        (totals.totalTeachers / totals.totalStudents).toFixed(2)
      ]
    ];

    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Teacher Data");
    XLSX.writeFile(wb, "teacher_data.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableHead = [
      [
        "S.No.",
        "University",
        ...header,
        "Total Teachers",
        "Total Students",
        "Ratio (teacher/students)"
      ]
    ];
    const tableBody = filteredRows.map((row, index) => [
      index + 1,
      row.university,
      ...header.map((category) => row[category.toLowerCase()] || 0),
      row.totalTeachers,
      row.totalStudents,
      row.ratio
    ]);
    const totalsRow = [
      "Grand Total",
      "",
      ...header.map((category) => totals[category.toLowerCase()] || 0),
      totals.totalTeachers,
      totals.totalStudents,
      (totals.totalTeachers / totals.totalStudents).toFixed(2)
    ];

    doc.autoTable({
      head: tableHead,
      body: [...tableBody, totalsRow],
      styles: {
        lineColor: "#c2c2c2",
        lineWidth: 0.2,
        cellPadding: 1,
      },
      headStyles: {
        fontSize: 8
      },
      bodyStyles: {
        fontSize: 7
      },
    });
    doc.save("teacher_data.pdf");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;
  return (
    <div>
      {/* Filter by Campus Type */}
      <Box sx={{ marginBottom: "0px", display: "flex", gap: 2 }}>
        {/* Export Button */}
        <Grid container justifyContent="right">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileDownload />}
            onClick={handleClick}
            style={{ marginBottom: '10px' }}
          >
            Export
          </Button>

          {/* Popover for Excel and PDF options */}
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{ padding: '10px' }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ backgroundColor: '#5FAD41' }}
                startIcon={<InsertDriveFile />}
                onClick={exportToExcel}
                fullWidth
                style={{ marginBottom: '10px' }}
              >
                Excel
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#272727' }}
                startIcon={<PictureAsPdf />}
                onClick={exportToPDF}
                fullWidth
              >
                PDF
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Box>


      {/* Table */}
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                S.No.
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                University
              </TableCell>
              {header.map((item, index) => (
                <TableCell
                  key={index}
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {item}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total Teachers
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total students
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Ratio (student/teacher)
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredRows.map((row, index) => {
              const totalTeachers =
                header.reduce((sum, category) => sum + (row[category.toLowerCase()] || 0), 0); // Dynamically calculate total teachers
              return (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.university}
                  </TableCell>
                  {header.map((category, idx) => (
                    <TableCell
                      key={idx}
                      sx={{
                        border: "1px solid #c2c2c2",
                        padding: "4px",
                        textAlign: "right",
                      }}
                    >
                      {row[category.toLowerCase()] || 0}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.totalTeachers}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.totalStudents}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.ratio}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          {/* Grand Total */}
          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={2}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            {
              Object.entries(totals).map(([key, value], index) => (
                <TableCell
                  key={index}
                  sx={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {value}
                </TableCell>
              ))
            }
            <TableCell
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
              }}
            >
              {totalRatio.toFixed(2)}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TeacherByCampusType;