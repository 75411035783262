import React, { useState, useEffect } from "react";
import {
    Grid,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Popover,
    Box,
} from "@mui/material";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fetchNonTeacherReportByPost } from "./reportApi";

function NonTeachingByPost({ fiscalId }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [apiData, setApiData] = useState([]);
    const [grandTotals, setGrandTotals] = useState({});

    const fetchData = async () => {
        const response = await fetchNonTeacherReportByPost(fiscalId);
        if (response) {
            const transformedData = response.reports.map((item) => ({
                university: item.university,
                positions: item.positions,
                total: item.total,
            }));
            setApiData(transformedData);
            setGrandTotals(response.grandTotals);
        }
    };

    useEffect(() => {
        fetchData();
    }, [fiscalId]);

    // Export to Excel
    const exportToExcel = () => {
        const header = [["S.No.", "University", "Technical", "Administrative", "Others", "Total"]];
        const data = apiData.map((item, index) => [
            index + 1,
            item.university,
            item.positions.Technical,
            item.positions.Administrative,
            item.positions.Other,
            item.total,
        ]);
        const grandTotalRow = [
            "Grand Total",
            "",
            grandTotals.Technical,
            grandTotals.Administrative,
            grandTotals.Other,
            grandTotals.Technical + grandTotals.Administrative + grandTotals.Other,
        ];

        const ws = XLSX.utils.aoa_to_sheet([...header, ...data, grandTotalRow]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Teachers By Contract");
        XLSX.writeFile(wb, "teachers_by_contract.xlsx");
    };

    // Export to PDF
    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        const columns = ["S.No.", "University", "Technical", "Administrative", "Others", "Total"];
        const data = apiData.map((item, index) => [
            index + 1,
            item.university,
            item.positions.Technical,
            item.positions.Administrative,
            item.positions.Other,
            item.total,
        ]);
        const grandTotalRow = [
            "Grand Total",
            "",
            grandTotals.Technical,
            grandTotals.Administrative,
            grandTotals.Other,
            grandTotals.Technical + grandTotals.Administrative + grandTotals.Other,
        ];
        data.push(grandTotalRow);
        doc.autoTable({
            head: [columns],
            body: [...data],
            styles: {
                lineColor: "#c2c2c2", 
                lineWidth: 0.2,       
                cellPadding: 1,       
            },
            headStyles: {
               fontSize:8
            },
            bodyStyles: {
               fontSize:7
            },
        });
        doc.save("teachers_by_contract.pdf");
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "export-popover" : undefined;

    return (
        <div>
            <Grid container justifyContent="right">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<FileDownload />}
                    onClick={handleClick}
                    style={{ marginBottom: "10px" }}
                >
                    Export
                </Button>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <Box sx={{ padding: "10px" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ backgroundColor: "#5FAD41" }}
                            startIcon={<InsertDriveFile />}
                            onClick={exportToExcel}
                            fullWidth
                            style={{ marginBottom: "10px" }}
                        >
                            Excel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "#272727" }}
                            startIcon={<PictureAsPdf />}
                            onClick={exportToPDF}
                            fullWidth
                        >
                            PDF
                        </Button>
                    </Box>
                </Popover>
            </Grid>
            <TableContainer sx={{ border: "1px solid #ddd" }}>
                <Table>
                    <TableHead style={{ backgroundColor: "#2A629A" }}>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    textAlign: "center",
                                }}
                            >
                                S.No.
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    textAlign: "center",
                                }}
                            >
                                University
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    textAlign: "center",
                                }}
                            >
                                Technical
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    textAlign: "center",
                                }}
                            >
                                Administrative
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    textAlign: "center",
                                }}
                            >
                                Others
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiData.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ textAlign: "center" }}>{index + 1}</TableCell>
                                <TableCell sx={{ textAlign: "left" }}>{item.university}</TableCell>
                                <TableCell sx={{ textAlign: "right" }}>{item.positions.Technical}</TableCell>
                                <TableCell sx={{ textAlign: "right" }}>{item.positions.Administrative}</TableCell>
                                <TableCell sx={{ textAlign: "right" }}>{item.positions.Other}</TableCell>
                                <TableCell sx={{ textAlign: "right" }}>{item.total}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                            <TableCell colSpan={2} sx={{ textAlign: "right", fontWeight: "bold" }}>
                                Grand Total
                            </TableCell>
                            <TableCell sx={{ textAlign: "right" }}>{grandTotals.Technical}</TableCell>
                            <TableCell sx={{ textAlign: "right" }}>{grandTotals.Administrative}</TableCell>
                            <TableCell sx={{ textAlign: "right" }}>{grandTotals.Other}</TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                                {grandTotals.Technical + grandTotals.Administrative + grandTotals.Other}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default NonTeachingByPost;
