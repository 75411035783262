import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { blue } from "@mui/material/colors";
import toast from "react-hot-toast";
import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const MarksEntry = ({ selectedExam }) => {
  const { control, handleSubmit, watch } = useForm();
  const [examAppearData, setExamAppearData] = useState([]);
  const subjectExamScheduleId = selectedExam?.id;

  const onSubmit = async (data) => {
    const requestData = data.subjects.map((subject, index) => ({
      subjectExamScheduleId: subjectExamScheduleId,
      achieveScored: (subject.theoryMarks || 0) + (subject.practicalMarks || 0),
      studentID: examAppearData[index]?.student?.id,
      theoreticalMarks: subject.theoreticalMarks,
      practicalMarks: subject.practicalMarks,
      remarks: subject.remarks || "",
    }));
  
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
  
      // Directly pass headers in fetch call
      const response = await fetch(`${backendUrl}/MarksEntry`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData), // Correctly stringify requestData
      });
  
      const result = await response.json();
      if (response.ok) {
        toast.success("Marks entered successfully!");
      } else {
        toast.error(result.message || "An error occurred while submitting marks.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting marks");
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          `${backendUrl}/ExamAppearManagement/SubjectExamSchedule/${subjectExamScheduleId}`,
          config
        );
        setExamAppearData(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [subjectExamScheduleId]);
  return (
    <DialogContent fullWidth>
      <Box sx={{ padding: 0 }}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                color={blue[700]}
                textAlign={"center"}
              >
                Enter Marks for subject: {selectedExam?.subject?.subjectName}{" "}
                Exam Name:{selectedExam?.examSchedule?.examName} in Date:{" "}
                {selectedExam?.examDate?.slice(0, 10)}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TableContainer sx={{ marginTop: 2 }}>
                  <Table size="small">
                    <TableHead style={{ backgroundColor: "#2A629A" }}>
                      <TableRow>
                        <TableCell
                          style={{
                            color: "#FFFFFF",
                            border: "1px solid #ddd",
                            padding: "4px",
                          }}
                          rowSpan={2}
                        >
                          S.No.
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#FFFFFF",
                            border: "1px solid #ddd",
                            padding: "4px",
                          }}
                          rowSpan={2}
                        >
                          Student Name
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#FFFFFF",
                            border: "1px solid #ddd",
                            padding: "4px",
                          }}
                          rowSpan={2}
                        >
                          Subject
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#FFFFFF",
                            border: "1px solid #ddd",
                            padding: "4px",
                            textAlign: "center",
                          }}
                          colSpan={3}
                        >
                          Theory
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#FFFFFF",
                            border: "1px solid #ddd",
                            padding: "4px",
                            textAlign: "center",
                          }}
                          colSpan={3}
                        >
                          Practical
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#FFFFFF",
                            border: "1px solid #ddd",
                            padding: "4px",
                            textAlign: "center",
                          }}
                          rowSpan={2}
                        >
                          Total Obtained
                        </TableCell>
                        <TableCell
                          style={{
                            color: "#FFFFFF",
                            border: "1px solid #ddd",
                            padding: "4px",
                            textAlign: "center",
                          }}
                          rowSpan={2}
                        >
                          Remarks
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ color: "#ffffff", border: "1px solid #ddd" }}
                        >
                          Full Marks
                        </TableCell>
                        <TableCell
                          sx={{ color: "#ffffff", border: "1px solid #ddd" }}
                        >
                          Pass Marks
                        </TableCell>
                        <TableCell
                          sx={{ color: "#ffffff", border: "1px solid #ddd" }}
                        >
                          Marks Obtained
                        </TableCell>
                        <TableCell
                          sx={{ color: "#ffffff", border: "1px solid #ddd" }}
                        >
                          Full Marks
                        </TableCell>
                        <TableCell
                          sx={{ color: "#ffffff", border: "1px solid #ddd" }}
                        >
                          Pass Marks
                        </TableCell>
                        <TableCell
                          sx={{ color: "#ffffff", border: "1px solid #ddd" }}
                        >
                          Marks Obtained
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {examAppearData.length > 0 &&
                        examAppearData.map((data, index) => (
                          <TableRow key={data?.id}>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              {index+1}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              {data?.student?.firstName}{" "}
                              {data?.student?.middleName}{" "}
                              {data?.student?.lastName}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              {selectedExam?.subject?.subjectName}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              {data?.subjectExamSchedule?.fullMark}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              {data?.subjectExamSchedule?.passMark}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              <Controller
                                name={`subjects[${index}].theoreticalMarks`}
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              {data?.subjectExamSchedule?.fullMark}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              {data?.subjectExamSchedule?.passMark}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              <Controller
                                name={`subjects[${index}].practicalMarks`}
                                control={control}
                                defaultValue={0}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ min: 0 }}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              {(watch(`subjects[${index}].theoreticalMarks`) || 0) +
                                (watch(`subjects[${index}].practicalMarks`) || 0)}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                border: "1px solid #ddd",
                              }}
                            >
                              <Controller
                                name={`subjects[${index}].remarks`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box
                  sx={{ display: "flex", justifyContent: "center", padding: 2 }}
                >
                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </DialogContent>
  );
};

export default MarksEntry;
