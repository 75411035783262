import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const GraduationFormForOld = ({ value }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [fiscalYears, setFiscalYears] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const collegeId = currentUser.institution?.id;

  const onSubmit = async (data) => {
    const apiGraduationData = {
      fiscalYearID: data.fiscalYearId,
      campusId: collegeId,
      applicantNameEng: data.applicantNameEng,
      applicantNameNep: data.applicantNameNep,
      doBNepali: data.dobNep,
      doBEng: data.dobEng,
      email: data.email,
      universityIssueNo: data.universityIssueNo,
      studentRegNo: data.studentRegNo,
      symbolNoUniversity: data.symbolNo,
      campusRolNo: data.campusRollNo,
      enrolledYear: data.enrolledYear,
      passedYear: data.passedYear,
      division: data.division,
      gpa: data.gpa,
      fatherName: data.fatherName,
      motherName: data.motherName,
      contactNo: data.contactNumber,
      uploadSignature: data.uploadSignature,
      uploadPPSizePhoto: data.uploadPhoto,
      uploadReceipt: data.uploadReceipt,
      uploadTranscript: data.uploadTranscript,
      uploadOtherDoc: data.uploadOtherDocs,
      remarks: data.remarks,
    };
    try {
      setIsLoading(true);
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      await axios.post(
        `${backendUrl}/Graduation/GenerateGraduationApplication`,
        apiGraduationData,
        config
      );
      toast.success("Data posted successfully", {
        autoClose: 1500,
      });
      reset();
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchGraduationData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const fiscalYearResponse = await axios.get(
        `${backendUrl}/FiscalYear`,

        config
      );
      setFiscalYears(fiscalYearResponse.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchGraduationData();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} mx="auto">
          <Paper elevation={3} sx={{ borderRadius: "10px" }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "#2A629A", mb: 3 }}
              >
                Graduation Management
              </Typography>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel required id="fiscalYearId-label">
                        Fiscal Year
                      </InputLabel>

                      <Select
                        {...register("fiscalYearId", { required: true })}
                        id="fiscalYearId"
                        labelId="fiscalYearId-label"
                        defaultValue=""
                        label="Fiscal Year"
                      >
                        {fiscalYears.map((fy) => (
                          <MenuItem key={fy.id} value={fy.id}>
                            {fy.yearNepali}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {value === 1 && (
                    <>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          fullWidth
                          required
                          name="applicantNameNep"
                          size="small"
                          label="Applicant Name (Nepali)"
                          type="text"
                          {...register("applicantNameNep", { required: true })}
                          error={!!errors.applicantNameNep}
                          helperText={
                            errors.applicantNameNep &&
                            "applicant Name (Nepali) is required"
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <TextField
                          fullWidth
                          required
                          name="applicantNameEng"
                          size="small"
                          label="Applicant Name (English)"
                          {...register("applicantNameEng", { required: true })}
                          error={!!errors.applicantNameEng}
                          helperText={
                            errors.applicantNameEng &&
                            "Applicant Name is required"
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      name="dobEng"
                      size="small"
                      label="DOB (English)"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      {...register("dobEng", { required: true })}
                      error={!!errors.dobEng}
                      helperText={errors.dobEng && "Date of Birth is required"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      name="dobNep"
                      size="small"
                      label="DOB (Nepali)"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      {...register("dobNep", { required: true })}
                      error={!!errors.dobNep}
                      helperText={errors.dobNep && "Date of Birth is required"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      fullWidth
                      required
                      name="email"
                      size="small"
                      label="Email"
                      type="email"
                      {...register("email", { required: true })}
                      error={!!errors.email}
                      helperText={errors.email && "Email is required"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="universityIssueNo"
                      label="University Issue No."
                      {...register("universityIssueNo", { required: true })}
                      error={!!errors.universityIssueNo}
                      helperText={
                        errors.universityIssueNo &&
                        "University Issue No. is required"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="studentRegNo"
                      label="Student Registration Number"
                      {...register("studentRegNo", { required: true })}
                      error={!!errors.studentRegNo}
                      helperText={
                        errors.studentRegNo &&
                        "Student Registration Number is required"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="symbolNo"
                      label="Symbol Number"
                      {...register("symbolNo", { required: true })}
                      error={!!errors.symbolNo}
                      helperText={
                        errors.symbolNo && "Symbol Number is required"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="campusRollNo"
                      label="Campus Roll No."
                      {...register("campusRollNo", { required: true })}
                      error={!!errors.campusRollNo}
                      helperText={
                        errors.campusRollNo && "Campus Roll No. is required"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="enrolledYear"
                      label="Enrolled Year"
                      type="number"
                      {...register("enrolledYear", { required: true })}
                      error={!!errors.enrolledYear}
                      helperText={
                        errors.enrolledYear && "Enrolled Year is required"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Passed Year"
                      name="passedYear"
                      type="number"
                      {...register("passedYear", { required: true })}
                      error={!!errors.passedYear}
                      helperText={
                        errors.passedYear && "Passed Year is required"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register("division", { required: true })}
                      label="Grade/Division"
                      name="division"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="gpa"
                      label="GPA/Percentage"
                      step="0.01"
                      {...register("gpa", {
                        required: true,
                        min: 0,
                        max: 9,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      name="fatherName"
                      size="small"
                      label="Father Name"
                      {...register("fatherName", { required: true })}
                      error={!!errors.fatherName}
                      helperText={
                        errors.fatherName && "Father's name is required"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="motherName"
                      label="Mother Name"
                      {...register("motherName", { required: true })}
                      error={!!errors.motherName}
                      helperText={
                        errors.motherName && "Mother's name is required"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="contactNumber"
                      label="Contact Number"
                      type="tel"
                      {...register("contactNumber", { required: true })}
                      error={!!errors.contactNumber}
                      helperText={
                        errors.contactNumber && "Contact Number is required"
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      {...register("uploadTranscript", { required: false })}
                      id="uploadTranscript"
                      required
                      size="small"
                      name="uploadTranscript"
                      label="Upload Transcript"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.uploadTranscript}
                      helperText={
                        errors.uploadTranscript
                          ? "Upload Transcript is required"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      {...register("uploadPhoto", { required: false })}
                      id="uploadPhoto"
                      size="small"
                      name="uploadPhoto"
                      label="Upload Photo"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.uploadPhoto}
                      helperText={
                        errors.uploadPhoto ? "Upload Photo is required" : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      {...register("uploadReceipt", { required: false })}
                      id="uploadReceipt"
                      size="small"
                      name="uploadReceipt"
                      label="Upload Receipt"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.uploadReceipt}
                      helperText={
                        errors.uploadReceipt ? "Upload Receipt is required" : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      {...register("uploadOtherDocs", { required: false })}
                      id="uploadOtherDocs"
                      size="small"
                      name="uploadOtherDocs"
                      label="Upload OtherDocs"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.uploadOtherDocs}
                      helperText={
                        errors.uploadOtherDocs
                          ? "Upload Other Docs is required"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      name="remarks"
                      label="Remarks"
                      {...register("remarks")}
                    />
                  </Grid>

                  <Grid container justifyContent="center" sx={{ mt: 3 }}>
                    <Button type="submit" variant="contained" color="primary">
                      {isLoading ? "submiting" : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GraduationFormForOld;
