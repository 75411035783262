import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Checkbox,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { blue } from "@mui/material/colors";
import { getUpgradeStudents } from "../../components/dashboard/services/service";
import ExamAppBar from "../navbar/ExamAppBar";
import { useLocation } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ExamAppear = () => {
  const location = useLocation();
  const { control } = useForm();
  const [students, setStudents] = useState([]);
  const [examData, setExamData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const queryParams = new URLSearchParams(location.search);
  const Id = queryParams.get("examroutine");

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const studentData = await getUpgradeStudents();
        console.log(studentData);
        setStudents(studentData);
      } catch (error) {
        console.error("Error fetching student data", error);
      }
    };
    fetchStudentData();
  }, []);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const examData = await axios.get(
          `${backendUrl}/SubjectExamSchedule/${Id}`,
          config
        );
        setExamData(examData.data);
      } catch (error) {
        console.error("Error fetching student data", error);
      }
    };

    fetchStudentData();
  }, [Id]);

  const onSubmit = async (data) => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const attendanceData = students
        .filter((student) => student.attendance)
        .map((student) => ({
          subjectExamScheduleId: examData?.id,
          subjectID: examData?.subjectID,
          studentID: student?.student?.id,
          doesAppeared: student.attendance,
          remarks: student.remarks || "",
        }));

      if (attendanceData.length === 0) {
        toast.error("No attendance records to submit.");
        return;
      }
      const response = await axios.post(
        `${backendUrl}/ExamAppearManagement`,
        attendanceData,
        config
      );
      if (response.status === 200) {
        toast.success("Attendance submitted successfully!");
      } else {
        toast.error("Error submitting attendance.");
      }
    } catch (error) {
      console.error("Error submitting attendance:", error);
      toast.error(
        "There was an error submitting the attendance. Please try again."
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };
  const handleSelectAllClick = (event) => {
    const checked = event.target.checked;
    
    // Update all student attendance based on select all checkbox
    const updatedStudents = students.map((student) => ({
      ...student,
      attendance: checked,
    }));
    
    setStudents(updatedStudents);
  };
  

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ExamAppBar />
        </Box>
      </Box>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h5" align="center" gutterBottom color={blue[700]}>
          Exam Appeared Attendance Sheet
        </Typography>

        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={10}>
            <Box sx={{ padding: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Program Name</InputLabel>
                    <Select
                      {...control.register("programName", {
                        required: "Program Name is required",
                      })}
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: 2,
                        borderColor: "#c2c2c2",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#c2c2c2",
                          },
                          "&:hover fieldset": {
                            borderColor: blue[500],
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: blue[700],
                            boxShadow: `0 0 0 2px ${blue[100]}`,
                          },
                        },
                        "& .MuiInputLabel-root": { fontSize: "1rem" },
                        "& .MuiSelect-icon": { color: blue[700] },
                      }}
                      label="Program Name"
                      value={examData.subject?.program?.programName || ""}
                    >
                      <MenuItem
                        key={examData.subject?.program?.programName || ""}
                        value={examData.subject?.program?.programName || ""}
                      >
                        {examData.subject?.program?.programName || ""}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        label="Subject Name"
                        InputLabelProps={{ shrink: true }}
                        value={examData?.subject?.subjectName || ""}
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: 2,
                          borderColor: "#c2c2c2",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c2c2c2",
                            },
                            "&:hover fieldset": {
                              borderColor: blue[500],
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: blue[700],
                              boxShadow: `0 0 0 2px ${blue[100]}`,
                            },
                          },
                          "& .MuiInputLabel-root": { fontSize: "1rem" },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name="semester"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        label="Semester Name"
                        InputLabelProps={{ shrink: true }}
                        value={examData?.subject?.semester || "First"}
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: 2,
                          borderColor: "#c2c2c2",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c2c2c2",
                            },
                            "&:hover fieldset": {
                              borderColor: blue[500],
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: blue[700],
                              boxShadow: `0 0 0 2px ${blue[100]}`,
                            },
                          },
                          "& .MuiInputLabel-root": { fontSize: "1rem" },
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        label="Exam Date"
                        InputLabelProps={{ shrink: true }}
                        value={examData?.examDate?.slice(0, 10) || ""}
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: 2,
                          borderColor: "#c2c2c2",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c2c2c2",
                            },
                            "&:hover fieldset": {
                              borderColor: blue[500],
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: blue[700],
                              boxShadow: `0 0 0 2px ${blue[100]}`,
                            },
                          },
                          "& .MuiInputLabel-root": { fontSize: "1rem" },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        label="Exam Time"
                        InputLabelProps={{ shrink: true }}
                        value={examData?.examTime || ""}
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: 2,
                          borderColor: "#c2c2c2",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c2c2c2",
                            },
                            "&:hover fieldset": {
                              borderColor: blue[500],
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: blue[700],
                              boxShadow: `0 0 0 2px ${blue[100]}`,
                            },
                          },
                          "& .MuiInputLabel-root": { fontSize: "1rem" },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={10}>
            <Box component={Paper} sx={{ padding: 3 }}>
              <Typography
                variant="h6"
                gutterBottom
                textAlign={"center"}
                color={blue[700]}
              >
                Student Exam Attendance for{" "}
                {examData.examSchedule?.examName || ""} exam Subject{" "}
                {examData.subject?.subjectName || ""} on{" "}
                {examData?.examDate?.slice(0, 10) || ""}
              </Typography>
              <TableContainer sx={{ marginTop: 2 }}>
                <Table
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid #ddd",
                  }}
                >
                  <TableHead style={{ backgroundColor: "#2A629A" }}>
                    <TableRow>
                      <TableCell
                        style={{
                          color: "#FFFFFF",
                          border: "1px solid #ddd",
                          padding: "4px",
                        }}
                      >
                        S.No.
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#FFFFFF",
                          border: "1px solid #ddd",
                          padding: "4px",
                        }}
                      >
                        Student Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#FFFFFF",
                          border: "1px solid #ddd",
                          padding: "4px",
                        }}
                      >
                        Symbol No
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#FFFFFF",
                          border: "1px solid #ddd",
                          padding: "4px",
                        }}
                      >
                        Program Name
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#FFFFFF",
                          border: "1px solid #ddd",
                          padding: "4px",
                        }}
                      >
                        Semester
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#FFFFFF",
                          border: "1px solid #ddd",
                          padding: "4px",
                        }}
                      >
                        Subject
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#FFFFFF",
                          border: "1px solid #ddd",
                          padding: "4px",
                        }}
                      >
                        <Checkbox
                          sx={{ color: "whitesmoke" }}
                          onChange={handleSelectAllClick}
                          checked={students.every(
                            (student) => student.attendance
                          )} 
                        />
                        Attendance
                      </TableCell>

                      <TableCell
                        style={{
                          color: "#FFFFFF",
                          border: "1px solid #ddd",
                          padding: "4px",
                        }}
                      >
                        Remarks
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.map((student, index) => (
                      <TableRow key={student.id}>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {student.student.firstName}{" "}
                          {student.student.middleName}{" "}
                          {student.student.lastName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {student.student.rollNo}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {student.programName}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {student.year
                            ? `${student.year} year`
                            : `${student.semester} semester`}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          {examData.subject?.subjectName || ""}
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "4px" }}
                        >
                          <Checkbox
                            checked={student.attendance || false} // Default to false if undefined
                            onChange={(e) => {
                              const updatedStudents = [...students];
                              const studentIndex = updatedStudents.findIndex(
                                (s) => s.student?.id === student?.student?.id // Use student id here to match the checkbox state
                              );
                              if (studentIndex !== -1) {
                                updatedStudents[studentIndex].attendance =
                                  e.target.checked;
                                setStudents(updatedStudents);
                              } else {
                                console.error("Student not found");
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          style={{ border: "1px solid #ddd", padding: "0px" }}
                        >
                          <TextField
                            fullWidth
                            size="small"
                            id="remarks"
                            sx={{
                              padding: 0,
                              "& fieldset": { border: "none" },
                            }}
                            value={student.remarks || ""} // Default to empty string if undefined
                            onChange={(e) => {
                              const updatedStudents = [...students];
                              const studentIndex = updatedStudents.findIndex(
                                (s) => s.id === student.id
                              );
                              if (studentIndex !== -1) {
                                updatedStudents[studentIndex].remarks =
                                  e.target.value;
                                setStudents(updatedStudents);
                              } else {
                                console.error("Student not found");
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[15, 25, 100]}
                  component="div"
                  count={students.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>

              <Box sx={{ textAlign: "center", marginTop: 2 }}>
                <Button variant="contained" color="primary" onClick={onSubmit}>
                  Submit Attendance
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ExamAppear;
