import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const HeadTableCell = styled(TableCell)({
  fontSize: "16px",
  backgroundColor: "#2B6EB5",
  color: " white",
});

function UnivesityTable({ authToken }) {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser.listUser[0].roleName;

  const [data, setData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          `${backendUrl}/Employee/Report/GetEmployeesForUni`,
          config
        );
        setData(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [authToken]);
  
  console.log("Employeee datas are:", data);

  // Initialize total counters
  let totalStudentMale = 0;
  let totalStudentFemale = 0;
  let totalStudentTotal = 0;
  let totalTeachingMale = 0;
  let totalTeachingFemale = 0;
  let totalTeachingTotal = 0;
  let totalAdminMale = 0;
  let totalAdminFemale = 0;
  let totalAdminTotal = 0;

  return (
    <TableContainer component={Paper}>
      <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
        <TableHead style={{ backgroundColor: "#2A629A" }}>
          <TableRow>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
              rowSpan={2}
            >
              {" "}
              S.No{" "}
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
              rowSpan={2}
            >
              Institution
            </HeadTableCell>
            <HeadTableCell
              colSpan={3}
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Students
            </HeadTableCell>
            <HeadTableCell
              colSpan={3}
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Teaching Staff 
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
              colSpan={3}
            >
              Administrative Staff
            </HeadTableCell>
          </TableRow>
          <TableRow>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Male
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Female
            </HeadTableCell>

            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Total
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Male
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Female
            </HeadTableCell>

            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Total
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Male
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Female
            </HeadTableCell>
            <HeadTableCell
              sx={{
                border: "1px solid #ddd",
                color: "#ffffff",
                padding: "4px",
                textAlign: "center",
              }}
            >
              Total
            </HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((institution, index) => {
            const studentMale =
              institution.students.find((s) => s.gender === "male")?.count || 0;
            const studentFemale =
              institution.students.find((s) => s.gender === "female")?.count ||
              0;
            const studentTotal = studentMale + studentFemale;

            const teachingMale =
              institution.teachingStaff.find((s) => s.gender === "male")
                ?.count || 0;
            const teachingFemale =
              institution.teachingStaff.find((s) => s.gender === "female")
                ?.count || 0;
            const teachingTotal = teachingMale + teachingFemale;

            const adminMale =
              institution.administrativeStaff.find((s) => s.gender === "male")
                ?.count || 0;
            const adminFemale =
              institution.administrativeStaff.find((s) => s.gender === "female")
                ?.count || 0;
            const adminTotal = adminMale + adminFemale;

            // Update grand totals
            totalStudentMale += studentMale;
            totalStudentFemale += studentFemale;
            totalStudentTotal += studentTotal;
            totalTeachingMale += teachingMale;
            totalTeachingFemale += teachingFemale;
            totalTeachingTotal += teachingTotal;
            totalAdminMale += adminMale;
            totalAdminFemale += adminFemale;
            totalAdminTotal += adminTotal;

            return (
              <TableRow key={index}>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px" }}>
                  {index + 1}
                </TableCell>
                <TableCell sx={{ border: "1px solid #c2c2c2", padding: "4px" }}>
                  {institution.institutionName}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {studentMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {studentFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #fff",
                    padding: "4px",
                    textAlign: "right",
                    backgroundColor:'#c2c2c2',
                  }}
                >
                  {studentTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {teachingMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {teachingFemale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #fff",
                    padding: "4px",
                    textAlign: "right",
                    backgroundColor:'#c2c2c2',
                  }}
                >
                  {teachingTotal}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {adminMale}
                </TableCell>
                <TableCell
                  sx={{
                    border: "1px solid #c2c2c2",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {adminFemale}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor:'#c2c2c2',
                    border: "1px solid #fff",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {adminTotal}
                </TableCell>
              </TableRow>
            );
          })}

          {/* Grand Total Row */}
          <TableRow>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "center" }} colSpan={2}>Grand Total</TableCell>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "right" }}>
              {totalStudentMale}
            </TableCell>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "right" }}>
              {totalStudentFemale}
            </TableCell>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "right" }}>
              {totalStudentTotal}
            </TableCell>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "right" }}>
              {totalTeachingMale}
            </TableCell>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "right" }}>
              {totalTeachingFemale}
            </TableCell>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "right" }}>
              {totalTeachingTotal}
            </TableCell>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "right" }}>
              {totalAdminMale}
            </TableCell>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "right" }}>
              {totalAdminFemale}
            </TableCell>
            <TableCell sx={{ border: "1px solid #fff",backgroundColor:'#c2c2c2', padding: "4px", textAlign: "right" }}>
              {totalAdminTotal}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UnivesityTable;
