import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import { blue } from "@mui/material/colors";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const EditSubjectDialog = ({
  open,
  onClose,
  subjectId,
  programData,
  onUpdate,
  year,
  semester,
}) => {
  const [subjectData, setSubjectData] = useState(null);
  const [selectedProgramType, setSelectedProgramType] = useState("");
  const [selectedSemester, setSelectedSemester] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  // Make sure programData is available before setting initial state
  useEffect(() => {
    if (programData.length > 0) {
      setSelectedProgramType(programData[0].programType);
    }
  }, [programData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (open && subjectId) {
      axios
        .get(`${backendUrl}/Subject/${subjectId}`)
        .then((response) => {
          const data = response.data;
          setSubjectData(data);
        })
        .catch((err) => console.error("Error fetching subject data:", err));
    }
  }, [open, subjectId]);

  useEffect(() => {
    if (subjectData) {
      // Reset form and set new data from subjectData
      reset({
        programId: subjectData.programId,
        subjectName: subjectData.subjectName,
        subjectType: subjectData.subjectType,
        shortName: subjectData.shortName,
        semester: subjectData.semester,
        year: subjectData.year,
        code: subjectData.code,
        alias: subjectData.alias || "",
        detail: subjectData.remarks || "",
      });
      setSelectedSemester(subjectData.semester);
      setSelectedYear(subjectData.year);
    }
  }, [subjectData, reset]);

  const onSubmit = async (data) => {
    try {
      const formattedData = {
        id: subjectId,
        programId: data.programId,
        subjectName: data.subjectName,
        subjectType: data.subjectType,
        shortName: data.shortName,
        semester: data.semester || 0,
        year: data.year || 0,
        code: data.code,
        alias: data.alias || null,
        remarks: data.detail || null,
      };
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      await axios.patch(
        `${backendUrl}/Subject/${subjectId}`,
        formattedData,
        config
      );
      onUpdate();
      onClose();
    } catch (err) {
      console.log("Error updating subject:", err);
    }
  };

  const handleProgramChange = (e) => {
    const selectedProgram = programData.find(
      (program) => program.id === e.target.value
    );
    setSelectedProgramType(selectedProgram?.programType || "");

    // Update semester and year based on the selected program
    setSelectedSemester("");
    setSelectedYear("");
  };

  // Don't render the dialog until subjectData is available
  if (!subjectData) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isFullScreen}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <Typography
          variant="h6"
          color={blue[700]}
          textAlign={"center"}
          padding={2}
        >
          Edit Subject
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="programId" required>
                  Program Name
                </InputLabel>
                <Select
                  {...register("programId", { required: true })}
                  id="programId"
                  size="small"
                  name="programId"
                  fullWidth
                  onChange={handleProgramChange}
                  label="Program Name"
                  value={subjectData?.programId || ""}
                >
                  {programData.length > 0 &&
                    programData.map((data) => (
                      <MenuItem key={data.id} value={data.id}>
                        {data.programName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                required
                {...register("subjectName", { required: true })}
                id="subjectName"
                size="small"
                name="subjectName"
                label="Subject Name"
                fullWidth
                error={!!errors.subjectName}
                helpertext={errors.subjectName ? "Subject name required" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControl fullWidth size="small">
                <InputLabel required={selectedProgramType === "annual"}>
                  Year
                </InputLabel>
                <Select
                  required={selectedProgramType === "annual"}
                  {...register("year")}
                  id="year"
                  size="small"
                  name="year"
                  label="Year"
                  disabled={selectedProgramType !== "annual"}
                  fullWidth
                  value={selectedYear || ""}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <MenuItem disabled value="">
                    Select Year
                  </MenuItem>
                  {year &&
                    year.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControl fullWidth size="small">
                <InputLabel required={selectedProgramType === "semester"}>
                  Semester
                </InputLabel>
                <Select
                  required={selectedProgramType === "semester"}
                  {...register("semester")}
                  id="semester"
                  size="small"
                  name="semester"
                  label="Semester"
                  fullWidth
                  disabled={selectedProgramType !== "semester"}
                  value={selectedSemester || ""}
                  onChange={(e) => setSelectedSemester(e.target.value)}
                >
                  <MenuItem disabled value="">
                    Select Semester
                  </MenuItem>
                  {semester &&
                    semester.map((sem) => (
                      <MenuItem key={sem} value={sem}>
                        {sem}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                required
                {...register("shortName", { required: true })}
                id="shortName"
                size="small"
                name="shortName"
                label="Short Name"
                fullWidth
                error={!!errors.shortName}
                helpertext={errors.shortName ? "Short name required" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <TextField
                required
                {...register("code", { required: true })}
                id="code"
                size="small"
                name="code"
                label="Code"
                fullWidth
                error={!!errors.code}
                helpertext={errors.code ? "Code is required" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="subjectType" required>
                  Subject Type
                </InputLabel>
                <Select
                  required
                  {...register("subjectType", { required: true })}
                  id="subjectType"
                  size="small"
                  name="subjectType"
                  label="Subject Type"
                  fullWidth
                  value={subjectData?.subjectType || ""}
                  
                >
                  <MenuItem value="regular">Regular</MenuItem>
                  <MenuItem value="optional">Optional</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                {...register("alias")}
                id="alias"
                name="alias"
                size="small"
                label="Alias"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register("detail")}
                id="detail"
                name="detail"
                size="small"
                label="Details"
                fullWidth
              />
            </Grid>
          </Grid>

          <DialogActions>
            <Button onClick={onClose} color="error">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Update
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditSubjectDialog;
