import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid,
  InputAdornment,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogContent,
  Box,
  capitalize,
  Chip,
  Menu,
  IconButton,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExportStudentInfo from "./ExportStudentInfo";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { getVerifiedStudents } from "../../components/dashboard/services/service";
import EditStudentRegister from "../../components/studentRegistration/editStudent/EditStudentStepper";
import { useSelector } from "react-redux";
import StudentAppBar from "../../modules/navbar/studentAppBar";
import { styled, alpha } from "@mui/material/styles";
import DescriptionIcon from "@mui/icons-material/Description";
import { blue } from "@mui/material/colors";


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

const VerifiedStudentList = () => {
  const [menuDialog, setMenuDialog] = useState(null);
  const [studentData, setStudentData] = useState([]);
  const [filteredStudentData, setFilteredStudentData] = useState([]);
  const [programType, setProgramType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [levels, setLevels] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYearId] = useState("");
  const [years, setYears] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [semesters, setSemesters] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const exportRef = useRef();

  const menuOpen = Boolean(menuDialog);
  const handleMenuDialogClick = (event, id) => {
    setMenuDialog(event.currentTarget);
    setSelectedStudentId(id);
  };

  const handleExportButtonClicked = async () => {
    // await new Promise((resolve) => setTimeout(resolve, 0));
    exportRef.current?.handleExport();
    setMenuDialog(null);
  };
  const menuDialogClose = () => {
    setMenuDialog(null);
  };
  const rowsPerPage = 25; // Number of rows per page

  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  const isButtonDisabled =
    roleName === "UniAdmin" ||
    roleName === "SuperAdmin" ||
    roleName === "Admin";
  const fetchData = async () => {
    try {
      const verifiedStudents = await getVerifiedStudents();
      setStudentData(verifiedStudents);
      setFilteredStudentData(verifiedStudents);

      const uniqueLevels = [
        ...new Set(
          verifiedStudents.map((student) => student.levelName).filter(Boolean)
        ),
      ];
      const uniqueFaculties = [
        ...new Set(
          verifiedStudents.map((student) => student.facultyName).filter(Boolean)
        ),
      ];
      const uniquePrograms = [
        ...new Set(
          verifiedStudents.map((student) => student.programName).filter(Boolean)
        ),
      ];
      const uniqueFiscalYear = [
        ...new Set(
          verifiedStudents.map((student) => student?.fiscalYear).filter(Boolean)
        ),
      ];
      const uniqueSemesters = [
        ...new Set(
          verifiedStudents.map((student) => student.semester).filter(Boolean)
        ),
      ];
      const uniqueYears = [
        ...new Set(
          verifiedStudents.map((student) => student.year).filter(Boolean)
        ),
      ];
      setFiscalYears(uniqueFiscalYear);
      setLevels(uniqueLevels);
      setFaculties(uniqueFaculties);
      setPrograms(uniquePrograms);
      setSemesters(uniqueSemesters);
      setYears(uniqueYears);
    } catch (err) {
      console.error("Error fetching student data:", err);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const filteredData = studentData.filter((student) => {
      const firstName = student.firstName
        ? student.firstName.toLowerCase()
        : "";
      const lastName = student.lastName ? student.lastName.toLowerCase() : "";
      const rollNo = student.rollNo ? student.rollNo.toLowerCase() : "";
      const gender = student.gender ? student.gender.toLowerCase() : "";
      const registeredYear = student.admissionYear ? student.admissionYear : "";
      const semester = student.semester ? student.semester : "";
      const year = student.year ? student.year : "";
      const faculty = student.facultyName.toLowerCase();
      const program = student.programName.toLowerCase();
      const district = student.district ? student.district.toLowerCase() : "";
      const searchTermLower = searchTerm.toLowerCase();

      return (
        (firstName.includes(searchTermLower) ||
          lastName.includes(searchTermLower) ||
          rollNo.includes(searchTermLower) ||
          gender.includes(searchTermLower) ||
          registeredYear.includes(searchTermLower) ||
          semester.includes(searchTermLower) ||
          year.includes(searchTermLower) ||
          faculty.includes(searchTermLower) ||
          program.includes(searchTermLower) ||
          district.includes(searchTermLower)) &&
        (selectedLevel === "" || student.levelName === selectedLevel) &&
        (selectedFaculty === "" || student.facultyName === selectedFaculty) &&
        (selectedProgram === "" || student.programName === selectedProgram) &&
        (selectedFiscalYear === "" ||
          student?.fiscalYear === selectedFiscalYear) &&
        (selectedSemester === "" || student.semester === selectedSemester) &&
        (selectedYear === "" || student.year === selectedYear)
      );
    });

    setFilteredStudentData(filteredData);
  }, [
    searchTerm,
    selectedLevel,
    selectedFaculty,
    selectedProgram,
    studentData,
    selectedFiscalYear,
    selectedSemester,
    selectedYear,
  ]);
  useEffect(() => {
    const relevantLevels = [
      ...new Set(
        studentData
          .filter((student) => student.facultyName === selectedFaculty)
          .map((student) => student.levelName)
          .filter(Boolean)
      ),
    ];

    setLevels(relevantLevels);

    setSelectedLevel("");
    setSelectedProgram("");
  }, [selectedFaculty, studentData]);

  useEffect(() => {
    const relevantPrograms = [
      ...new Set(
        studentData
          .filter(
            (student) =>
              student.levelName === selectedLevel &&
              student.facultyName === selectedFaculty
          )
          .map((student) => student.programName)
          .filter(Boolean)
      ),
    ];

    setPrograms(relevantPrograms);

    setSelectedProgram("");
  }, [selectedLevel, selectedFaculty, studentData]);

  // Calculate pagination range
  const indexOfLastStudent = page * rowsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - rowsPerPage;
  const currentStudents = filteredStudentData.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handleUpdate = () => {
    fetchData();
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false)
  }

  const handleEditClick = () => {
    setOpenEditDialog(true);
    setMenuDialog(null);
  };

  // Close the export popover
  const handleClose = () => {
    getVerifiedStudents();
  };

  const handleProgramChange = (event) => {
    const selectedProgramName = event.target.value;
    setSelectedProgram(selectedProgramName);
    const selectedProgram = studentData.find(
      (student) => student.programName === selectedProgramName
    );

    if (selectedProgram) {
      setProgramType(selectedProgram.programType);
    }
  };
  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <StudentAppBar />
        </Box>
      </Box>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "15px" }}
        >
          <Grid item xs={12} sm={6} md={2.3}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search by Students..."
              value={searchTerm}
              sx={{ bgcolor: "whitesmoke" }}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "#2b6eb5" }} />
                  </InputAdornment>
                ),
                style: {
                  height: "36px",
                  padding: "0 10px",
                  fontSize: "13px",
                },
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" style={{ color: blue[700] }}>
              Successfully Enrolled Students
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} style={{ textAlign: "right" }}>
            <Button
              component={Link}
              to="/student-management/student-register"
              variant="contained"
              color="primary"
              size="small"
              sx={{
                bgcolor: "#1976d2",
                color: "white",
                "&:hover": {
                  bgcolor: "#1565c0",
                },
                padding: "6px 12px",
                borderRadius: 2,
              }}
              disabled={isButtonDisabled}
            >
              Add Student
            </Button>
          </Grid>
        </Grid>
        <TableContainer sx={{borderRadius:2}}>
          <Table>
            <TableHead style={{ backgroundColor: "#2A629A" }}>
              <TableRow>
                <TableCell colSpan={13} style={{ padding: 0 }}>
                  <Grid container spacing={2} padding={1}>
                    <Grid item xs={12} sm={4} md={1.5}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedFiscalYear}
                          onChange={(e) =>
                            setSelectedFiscalYearId(e.target.value)
                          }
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Fiscal Year</MenuItem>
                          {fiscalYears.map((years, index) => (
                            <MenuItem key={index} value={years}>
                              {years}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={1.5}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedFaculty}
                          onChange={(e) => setSelectedFaculty(e.target.value)}
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Faculty</MenuItem>
                          {faculties.map((faculty, index) => (
                            <MenuItem key={index} value={faculty}>
                              {faculty}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={1.5}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedLevel}
                          onChange={(e) => setSelectedLevel(e.target.value)}
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Level</MenuItem>
                          {levels.map((level, index) => (
                            <MenuItem key={index} value={level}>
                              {level}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} md={1.5}>
                      <FormControl size="small" fullWidth>
                        <Select
                          value={selectedProgram}
                          onChange={handleProgramChange}
                          displayEmpty
                          sx={{
                            backgroundColor: "whitesmoke",
                            borderColor: "lightgray",
                            borderRadius: 1,
                            width: "150px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "lightgray",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "blue",
                            },
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                              fontSize: "0.75rem",
                            },
                            "& .MuiSelect-icon": {
                              fontSize: "1rem",
                            },
                          }}
                        >
                          <MenuItem value="">Select Program</MenuItem>
                          {programs.map((program, index) => (
                            <MenuItem key={index} value={program}>
                              {program}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {programType === "semester" && (
                      <Grid item xs={12} sm={4} md={2}>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={selectedSemester}
                            onChange={(e) =>
                              setSelectedSemester(e.target.value)
                            }
                            displayEmpty
                            sx={{
                              backgroundColor: "whitesmoke",
                              borderColor: "lightgray",
                              borderRadius: 1,
                              width: "150px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "lightgray",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                fontSize: "0.75rem",
                              },
                              "& .MuiSelect-icon": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            <MenuItem value="">Select Semester</MenuItem>
                            {semesters.map((semester, index) => (
                              <MenuItem key={index} value={semester}>
                                {semester} Semester
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {programType === "annual" && (
                      <Grid item xs={12} sm={4} md={2}>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                            displayEmpty
                            sx={{
                              backgroundColor: "whitesmoke",
                              borderColor: "lightgray",
                              borderRadius: 1,
                              width: "150px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "lightgray",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                fontSize: "0.75rem",
                              },
                              "& .MuiSelect-icon": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            <MenuItem value="">Select Year</MenuItem>
                            {years.map((year, index) => (
                              <MenuItem key={index} value={year}>
                                {year} Year
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                {[
                  "S.No.",
                  "Full Name",
                  "Gender",
                  "Fiscal Year",
                  "Batch Year",
                  "Semester/Year",
                  "Roll No",
                  "Level",
                  "Faculty",
                  "Program",
                  "Status",
                  "Actions",
                ].map((header, index) => (
                  <TableCell
                    key={index}
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                      width:
                        header === "S.No."
                          ? "2%"
                          : header === "Actions"
                          ? "5%"
                          : "auto",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{bgcolor:'white'}}>
              {currentStudents.map((data, index) => (
                <TableRow key={data.id}>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {indexOfFirstStudent + index + 1}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {`${data.firstName ? data.firstName : ""} ${
                      data.middleName ? data.middleName : ""
                    } ${data.lastName ? data.lastName : ""}`}
                  </TableCell>

                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.gender ? capitalize(data.gender) : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.fiscalYear ? data.fiscalYear : ""}
                  </TableCell>

                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.admissionYear ? data.admissionYear : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.year
                      ? `${data.year} year`
                      : `${data.semester} semester`}
                  </TableCell>

                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.rollNo}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.levelName}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.facultyName}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                      <Tooltip title={data.programName}>

                    {data.programName}
                    </Tooltip>

                  </TableCell>

                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {data.isVerified ? (
                      <Chip label="Verified" color="success" size="small" />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <IconButton
                        onClick={(event) =>
                          handleMenuDialogClick(event, data.id)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <StyledMenu
                        anchorEl={menuDialog}
                        open={menuOpen}
                        onClose={menuDialogClose}
                      >
                        <MenuItem
                          onClick={() => handleEditClick()}
                          disableRipple
                        >
                          <EditIcon />
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleExportButtonClicked()}
                          disableRipple
                        >
                          <ArchiveIcon />
                          Download form
                        </MenuItem>
                        <MenuItem disableRipple>
                          <DescriptionIcon />
                          View Documents
                        </MenuItem>
                      </StyledMenu>
                    </div>
                    {selectedStudentId && (
                      <div style={{ display: "none" }}>
                        <ExportStudentInfo
                          ref={exportRef}
                          id={selectedStudentId}
                        />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
          <Pagination
            count={Math.ceil(filteredStudentData.length / rowsPerPage)}
            page={page}
            shape="rounded"
            onChange={handlePageChange}
          />
        </Grid>
        <Dialog
          sx={{ padding: "0px" }}
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          maxWidth="lg"
        >
          <DialogContent sx={{ padding: "0px" }}>
            <EditStudentRegister
              id={selectedStudentId}
              setOpenEditDialog={setOpenEditDialog}
              handleEditDialogClose={handleEditDialogClose}
              onClose={() => setOpenEditDialog(false)}
              onUpdate={handleUpdate}
              handleClose={handleClose}
            />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default VerifiedStudentList;
