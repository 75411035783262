import React, { useEffect } from "react";
import * as echarts from "echarts";
import { Typography, Box } from "@mui/material";
import { blue } from "@mui/material/colors";

const StudentGraduationTrend = () => {
  useEffect(() => {
    var chartDom = document.getElementById("main1");
    var myChart = echarts.init(chartDom);
    var option1;

    const axisData = ["2020", "2021", "2022", "2023", "2024"]; // Years
    const maleData = axisData.map(() => Math.round(Math.random() * 500)); // Random males
    const femaleData = axisData.map(() => Math.round(Math.random() * 500)); // Random females
    const totalData = maleData.map((male, i) => male + femaleData[i]); // Total students

    const links = totalData.map((item, i) => ({
      source: i,
      target: i + 1,
    }));
    links.pop(); // Remove the last link to avoid out-of-bound errors

    option1 = {
      // title: {
      //   text: "Student Graduation Trend From 2020 to 2024",
      //   left: "center", // Title center alignment
      // },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Total", "Male", "Female", "Other"],
      },
      grid: {
        containLabel: false,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["2077/078", "2078/079", "2079/080", "2080/081", "2081/082"],
        name: "Fiscal Years",
        nameLocation: "middle",
        nameGap: 35,
      },
      yAxis: {
        type: "value",
        name: "No Of Students",
        nameLocation: "middle",
        nameGap: 35,
      },
      series: [
        {
          name: "Total",
          type: "line",
          stack: "Total",
          data: [120, 132, 101, 134, 90, 230, 210],
        },
        {
          name: "Male",
          type: "line",
          stack: "Total",
          data: [220, 182, 191, 234, 290, 330, 310],
        },
        {
          name: "Female",
          type: "line",
          stack: "Total",
          data: [150, 232, 201, 154, 190, 330, 410],
        },
        {
          name: "Others",
          type: "line",
          stack: "Total",
          data: [0, 0, 0, 0, 0, 0, 0],
        },
      ],
    };

    myChart.setOption(option1);

    return () => {
      if (myChart) {
        myChart.dispose();
      }
    };
  }, []);

  return (
    <Box>
      <Box
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          width: "100%",
        }}
      >
        <Typography
          variant="body1"
          align="center" // Align the title to the center
          gutterBottom
          color={blue[700]}
          style={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          Student Graduation Trend From 2020 to 2024
        </Typography>
        <div id="main1" style={{ height: "400px" }}></div>
      </Box>
    </Box>
  );
};

export default StudentGraduationTrend;
