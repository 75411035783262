import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import DashboardCard from "../../components/newDashboard/DashboardCard.jsx";
import FacultyLevelChart from "../../components/newDashboard/FacultyLevelChart.jsx";
import StudentByEthnicity from "../../components/newDashboard/StudentByEthnicity.jsx";
import StudentsByCampus from "../../components/newDashboard/StudentsByCampus.jsx";
import UgcTable from "../../components/dashboard/Tables/UgcTable.jsx";
import UnivesityTable from "../../components/dashboard/Tables/UnivesityTable.jsx";
import TeachingStaffPieChart from "../../components/newDashboard/TeachingStaffPieChart.jsx";
import NonTeachingStaffPieChart from "../../components/newDashboard/NonTeachingStaffPieChart.jsx";
import CampusProgram from "../../components/dashboard/CampusProgram.jsx";
import HomeAppBar from "../../modules/navbar/HomeAppBar.jsx";
import StudentEnrollmentTrend from "../../components/newDashboard/StudentEnrollmentTrend.jsx";
import StudentGraduationTrend from "../../components/newDashboard/StudentGraduation.jsx";

const NewDashboard = () => {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;

  if (!currentUser) {
    return <Typography>Loading...</Typography>;
  }
  return (
    <div>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={10}>
          <Grid item xs={12}>
            {/* <DashboardAppBar /> */}
            <HomeAppBar />
          </Grid>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DashboardCard
            authToken={currentUser?.tokenString}
            roleName={roleName}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <StudentEnrollmentTrend authToken={currentUser?.tokenString} />
        </Grid>
        <Grid item xs={12} md={6}>
          <StudentGraduationTrend authToken={currentUser?.tokenString} />
        </Grid>
        <Grid item xs={12} md={12}>
          <FacultyLevelChart authToken={currentUser?.tokenString} />
        </Grid>
        {roleName === "CollegeAdmin" && (
          <Grid item xs={12} md={6}>
            <CampusProgram authToken={currentUser?.tokenString} />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <StudentByEthnicity authToken={currentUser?.tokenString} />
        </Grid>
        {roleName !== "CollegeAdmin" && (
          <Grid item xs={6}>
            <StudentsByCampus authToken={currentUser?.tokenString} />
          </Grid>
        )}
        {(roleName === "SuperAdmin" || roleName === "Admin") && (
          <Grid item xs={12}>
            <UgcTable authToken={currentUser?.tokenString} />
          </Grid>
        )}
        {roleName === "UniAdmin" && (
          <Grid item xs={12}>
            <UnivesityTable authToken={currentUser?.tokenString} />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <TeachingStaffPieChart authToken={currentUser?.tokenString} />
        </Grid>
        <Grid item xs={12} md={8}>
          <NonTeachingStaffPieChart authToken={currentUser?.tokenString} />
        </Grid>
      </Grid>
    </div>
  );
};

export default NewDashboard;
