import React, { useEffect, useState, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, capitalize, Typography } from "@mui/material";
import axios from "axios";
import StudentAppBar from "../../../modules/navbar/studentAppBar";

const columns = [
  { name: "no", label: "S.No.", options: { sort: false, filter: false } },
  {
    name: "fullName",
    label: "Full Name",
    options: { filter: false, sort: false },
  },
  {
    name: "collegeName",
    label: "Campus Name",
    options: { filter: true, sort: false },
  },
  {
    name: "phoneNumber",
    label: "Phone No.",
    options: { filter: false, sort: false },
  },
  { name: "gender", label: "Gender", options: { filter: false, sort: false } },
  {
    name: "pProvince",
    label: "Province",
    options: { filter: false, sort: false },
  },
  {
    name: "pDistrict",
    label: "District",
    options: { filter: false, sort: false },
  },
  {
    name: "localLevel",
    label: "Palika-ward",
    options: { filter: false, sort: false },
  },
  {
    name: "facultyName",
    label: "Faculty",
    options: { filter: true, sort: false },
  },
  { name: "levelName", label: "Level", options: { filter: true, sort: false } },
  {
    name: "programName",
    label: "Program",
    options: { filter: true, sort: false },
  },
  {
    name: "fiscalYear",
    label: "Fiscal Year",
    options: { filter: true, sort: false },
  },
];

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: "#2b6eb5",
            color: "whitesmoke",
            textTransform: "capitalize",
            padding: "6px",
            fontSize: "14px",
            border: "2px solid #C2C2C2",
          },
          body: {
            fontSize: "14px",
            padding: "6px",
            border: "1px solid #C2C2C2",
          },
        },
      },
    },
  });

const CustomHeader = () => {
  return (
    <thead>
      <tr style={{ backgroundColor: "#2b6eb5", color: "whitesmoke" }}>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          S.No.
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Full Name
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Campus Name
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Phone No.
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Gender
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Province
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          District
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Palika-ward
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Faculty
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Level
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Program
        </th>
        <th
          style={{
            padding: "8px",
            border: "1px solid #c2c2c2",
            backgroundColor: "#2b6eb5",
            color: "#FFFFFF",
            fontSize: "14px",
          }}
        >
          Fiscal Year
        </th>
      </tr>
    </thead>
  );
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const StudentInfoDetail = () => {
  const [data, setData] = useState([]);
  const [fiscalYear, setFiscalYear] = useState("All");
  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    elevation: 0,
    pagination: true,
    search: true,
    searchPlaceholder: "Search...",
    rowsPerPage: 15,
    header: true,
    print: true,
    download: true,
    viewColumns: false,
    title: "Student Information Details",
    onTableChange: (action, tableState) => {
      if (action === "filterChange") {
        const fiscalYearColumnIndex = columns.findIndex(
          (col) => col.name === "fiscalYear"
        );
        if (fiscalYearColumnIndex !== -1) {
          const fiscalYearFilter = tableState.filterList[fiscalYearColumnIndex];
          setFiscalYear(
            fiscalYearFilter.length > 0 ? fiscalYearFilter[0] : "All"
          );
        }
      }
    },
  };

  const fetchData = useCallback(async (fiscalYear) => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${backendUrl}/Student/GetStudentByFilter`,
        {
          ...config,
          params: { fiscalYear },
        }
      );

      const updatedData = response.data.map((student, index) => {
        const modifiedStudent = {
          ...student,
          fullName: `${student.firstName || ""} ${student.middleName || ""} ${
            student.lastName || ""
          }`,
          no: `${index + 1}`,
          levelName: student.levelName,
          facultyName: student.facultyName,
          programName: student.programName,
          localLevel: `${student.pLocalLevel}-${student.pWardNo}`,
          phoneNumber: student.phoneNumber || "",
          gender: capitalize(student.gender) || "",
          pProvince: student.pProvince || "",
          pDistrict: student.pDistrict || "",
          collegeName: student.collegeName || "",
        };

        // Conditionally show Campus Name and Affiliated University

        return modifiedStudent;
      });

      setData(updatedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(fiscalYear);
  }, [fiscalYear, fetchData]);

  // Dynamically modify the columns array based on the user role

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <StudentAppBar />
        </Box>
      </Box>
      <ThemeProvider theme={getMuiTheme()}>
        <div>
          <Typography variant="subtitle1">
            Students in the current F.Y.: {fiscalYear}
          </Typography>
          <MUIDataTable
            title={
              <Typography
                style={{
                  color: "#2b6eb5",
                  fontSize: "20px",
                  padding: "5px",
                  textAlign: "right",
                }}
              >
                Student Information Details
              </Typography>
            }
            data={data}
            columns={columns}
            options={options}
            components={{
              TableHead: CustomHeader,
            }}
          />
        </div>
      </ThemeProvider>
    </>
  );
};

export default StudentInfoDetail;
