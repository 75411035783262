import React, { useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  TextField,
  Chip,
  Button,
  Paper,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import ProgramAppBar from "../../../modules/navbar/ProgramSetupAppBar";
import axios from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DepartmentAdd = () => {

  const [data, setData] = useState({
    departmentName: "",
    status: true,
    remarks: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
     
      const response = await axios.post(
        "http://apiheims.dibugsoft.com/Management/AddDepartment",
        data,
        config
      );

      setData({
        sectionName: "",
        status: true,
        remarks: "",
      });
    } catch (err) {
      console.error("Error during API call:", err);
    }
  };

  return (
    <div>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ProgramAppBar />
        </Box>
      </Box>

      <Paper
        elevation={3}
        sx={{
          padding: "20px",
          borderRadius: "10px",
          margin: "30px auto",
          width: "70%",
          boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
        }}
      >
        <Typography
          variant="h6"
          component="p"
          style={{
            color: blue[700],
            textAlign: "center",
            padding: "10px",
            textTransform: "uppercase",
          }}
        >
          Add New Department
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            sx={{
              marginBottom: "20px",
            }}
          >
            {/* Section Name TextField */}
            <Grid item xs={12} md={4}>
              <TextField
                required
                id="departmentName"
                size="small"
                name="departmentName"
                label="Section Name"
                fullWidth
                value={data.departmentName}
                onChange={(e) => setData({ ...data, departmentName: e.target.value })}
                autoComplete="given-name"
                variant="outlined"
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: blue[500],
                    },
                    "&:hover fieldset": {
                      borderColor: blue[700],
                    },
                  },
                }}
              />
            </Grid>

            {/* Status Select */}
            <Grid item xs={12} md={2}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  required
                  id="status"
                  size="small"
                  name="status"
                  label="Status"
                  value={data.status}
                  onChange={(e) => setData({ ...data, status: e.target.value })}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                >
                  <MenuItem value={true}>
                    <Chip label="Active" color="success" size="small" />
                  </MenuItem>
                  <MenuItem value={false}>
                    <Chip label="Inactive" color="error" size="small" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Remarks TextField */}
            <Grid item xs={12} md={6}>
              <TextField
                id="remarks"
                size="small"
                name="remarks"
                label="Remarks"
                fullWidth
                value={data.remarks}
                onChange={(e) => setData({ ...data, remarks: e.target.value })}
                autoComplete="given-name"
                variant="outlined"
                sx={{
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: blue[500],
                    },
                    "&:hover fieldset": {
                      borderColor: blue[700],
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* Submit Button */}
          <Grid container justifyContent="center" marginTop={2}>
            <Grid item xs={12} md={3}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: blue[700],
                  color: "white",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: blue[800],
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default DepartmentAdd;
