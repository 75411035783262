import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Typography,
  Box,
  Dialog,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { SaveAlt as ExportIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { blue } from "@mui/material/colors";
import EditGraduationModule from "./EditGraduationModule";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const GraduationTableOldStudent = () => {
  const [graduationData, setGraduationData] = useState([]);
  const [filteredGraduationData, setFilteredGraduationData] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [selectedGraduationId, setSelectedGraduationId] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const fetchGraduationData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const fiscalYearResponse = await axios.get(
        `${backendUrl}/FiscalYear`,
        config
      );
      const response = await axios.get(`${backendUrl}/Graduation`, config);
      setGraduationData(response.data);
      setFiscalYears(fiscalYearResponse.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchGraduationData();
  }, []);

  const handleFiscalYearChange = (event) => {
    const fiscalYearId = event.target.value;
    setSelectedFiscalYear(fiscalYearId);
    fetchGraduationData(fiscalYearId);
  };

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    const filteredData = graduationData.filter((data) => {
      const isFiscalYearMatch =
        !selectedFiscalYear || data.fiscalYearID === selectedFiscalYear;
      const isSearchMatch =
        !searchTerm ||
        data.applicantNameEng
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        data.applicantNameNep.toLowerCase().includes(searchTerm.toLowerCase());
      return isFiscalYearMatch && isSearchMatch;
    });
    setFilteredGraduationData(filteredData);
  }, [graduationData, selectedFiscalYear, searchTerm]);

  const handleExportGraduation = (graduationId) => {
    navigate(`/graduationForm?type=old&&id=${graduationId}`);
  };

  const handleEditClick = (graduationId) => {
    setSelectedGraduationId(graduationId);
    setOpenEditDialog(true);
  };

  const handleUpdate = () => {
    fetchGraduationData();
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant={"h6"} textAlign={"center"} color={blue[700]}>
            Graduated old Student List
          </Typography>

          {/* Filters Section */}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth size="small">
                <InputLabel id="fiscalYearId-label">Fiscal Year</InputLabel>
                <Select
                  id="fiscalYearId"
                  labelId="fiscalYearId-label"
                  value={selectedFiscalYear}
                  onChange={handleFiscalYearChange}
                  label="Fiscal Year"
                  sx={{ borderRadius: 2, backgroundColor: "white" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {fiscalYears.map((fy) => (
                    <MenuItem key={fy.id} value={fy.id}>
                      {fy.yearNepali}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Search by Name"
                variant="outlined"
                size="small"
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ borderRadius: 2, backgroundColor: "white" }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Table Section */}
        <Grid item xs={12}>
          <TableContainer sx={{ borderRadius: 2 }}>
            <Table
              style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
            >
              <TableHead style={{ backgroundColor: "#2A629A" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    S.N
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Applicant Name (English)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Applicant Name (Nepali)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    DOB (English)
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    University Issue No
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Student Registration No.
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Symbol No.
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Campus Roll No.
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody sx={{ bgcolor: "white" }}>
                {filteredGraduationData.length > 0 ? (
                  filteredGraduationData.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data?.applicantNameEng}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data?.applicantNameNep}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data?.doBNepali?.slice(0, 10)}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data?.email}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data.universityIssueNo}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data.studentRegNo}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data.symbolNoUniversity}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        {data?.campusRolNo}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "left",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          {/* Edit Button */}
                          <Button
                            onClick={() => handleEditClick(data.id)}
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{
                              borderRadius: 2,
                            }}
                          >
                            <EditNoteIcon /> Edit
                          </Button>

                          {/* Details Button */}
                          <Button
                            onClick={() => handleExportGraduation(data.id)} // Pass the selected graduation ID
                            variant="contained"
                            startIcon={<ExportIcon />}
                            size="small"
                            sx={{
                              bgcolor: "#1976d2",
                              color: "white",
                              "&:hover": {
                                bgcolor: "#1565c0",
                              },
                              padding: "6px 12px",
                              borderRadius: 2,
                            }}
                          >
                            Details
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      <Typography variant="h6" color="textSecondary">
                        No Data Available
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Dialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          maxWidth="md"
        >
          <EditGraduationModule
            id={selectedGraduationId}
            onClose={() => setOpenEditDialog(false)}
            onUpdate={handleUpdate}
          />
        </Dialog>
      </Grid>
    </>
  );
};

export default GraduationTableOldStudent;
