import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogActions,
  capitalize,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getUpgradeStudents,
  upgradeStudents,
} from "../../components/dashboard/services/service";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import StudentAppBar from "../../modules/navbar/studentAppBar";
import { blue } from "@mui/material/colors";
import toast from "react-hot-toast";

const UpgradeAcademics = () => {
  const [studentData, setStudentData] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedSemester, setSelectedSemester] = useState("");
  const [selectedFiscalYear, setSelectedFiscalYearId] = useState("");
  const [levels, setLevels] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [years, setYears] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [filteredStudentData, setFilteredStudentData] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedYear, setSelectedYear] = useState("");

  const [programType, setProgramType] = useState("");

  const rowsPerPage = 25;
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  const isButtonDisabled = roleName === "UniAdmin" || roleName === "SuperAdmin";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const verifiedStudents = await getUpgradeStudents();
        setStudentData(verifiedStudents);
        setFilteredStudentData(verifiedStudents);

        const uniqueLevels = [
          ...new Set(
            verifiedStudents.map((student) => student.levelName).filter(Boolean)
          ),
        ];
        const uniqueFaculties = [
          ...new Set(
            verifiedStudents
              .map((student) => student.facultyName)
              .filter(Boolean)
          ),
        ];
        const uniquePrograms = [
          ...new Set(
            verifiedStudents
              .map((student) => student.programName)
              .filter(Boolean)
          ),
        ];
        const uniqueFiscalYear = [
          ...new Set(
            verifiedStudents
              .map((student) => student?.fiscalYear)
              .filter(Boolean)
          ),
        ];
        const uniqueSemesters = [
          ...new Set(
            verifiedStudents.map((student) => student.semester).filter(Boolean)
          ),
        ];
        const uniqueYears = [
          ...new Set(
            verifiedStudents.map((student) => student.year).filter(Boolean)
          ),
        ];
        setFiscalYears(uniqueFiscalYear);
        setLevels(uniqueLevels);
        setFaculties(uniqueFaculties);
        setPrograms(uniquePrograms);
        setSemesters(uniqueSemesters);
        setYears(uniqueYears);
      } catch (err) {
        console.error("Error fetching student data:", err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredData =
      studentData &&
      studentData.filter((student) => {
        return (
          (selectedLevel === "" || student.levelName === selectedLevel) &&
          (selectedFaculty === "" || student.facultyName === selectedFaculty) &&
          (selectedProgram === "" || student.programName === selectedProgram) &&
          (selectedFiscalYear === "" ||
            student?.fiscalYear === selectedFiscalYear) &&
          (selectedSemester === "" || student.semester === selectedSemester) &&
          (selectedYear === "" || student.year === selectedYear)
        );
      });

    setFilteredStudentData(filteredData);
  }, [
    selectedLevel,
    selectedFaculty,
    selectedProgram,
    studentData,
    selectedFiscalYear,
    selectedSemester,
    selectedYear,
  ]);

  useEffect(() => {
    const relevantLevels = [
      ...new Set(
        studentData
          .filter((student) => student.facultyName === selectedFaculty)
          .map((student) => student.levelName)
          .filter(Boolean)
      ),
    ];

    setLevels(relevantLevels);

    setSelectedLevel("");
    setSelectedProgram("");
  }, [selectedFaculty, studentData]);

  useEffect(() => {
    const relevantPrograms = [
      ...new Set(
        studentData
          .filter(
            (student) =>
              student.levelName === selectedLevel &&
              student.facultyName === selectedFaculty
          )
          .map((student) => student.programName)
          .filter(Boolean)
      ),
    ];

    setPrograms(relevantPrograms);

    setSelectedProgram("");
  }, [selectedLevel, selectedFaculty, studentData]);

  const indexOfLastStudent = page * rowsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - rowsPerPage;
  const currentStudents = filteredStudentData.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCheckboxChange = (event, studentId) => {
    if (event.target.checked) {
      setSelectedStudents([...selectedStudents, studentId]);
    } else {
      setSelectedStudents(selectedStudents.filter((id) => id !== studentId));
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedStudents(
        filteredStudentData.map((student) => student.studentId)
      );
    } else {
      setSelectedStudents([]);
    }
  };

  const ProgramType = {
    SEMESTER: "semester",
    ANNUAL: "annual",
  };

  const getNextSemesterOrYear = (programType, currentSemester, currentYear) => {
    if (!currentSemester && !currentYear) {
      console.error("Both semester and year are missing.");
      return { semester: "", year: "" }; // Or return an appropriate default value
    }

    const normalizedSemester = selectedSemester;
    const normalizedYear = selectedYear;

    if (programType === ProgramType.SEMESTER) {
      if (normalizedSemester === "First") {
        return { semester: "Second", year: "" };
      } else if (normalizedSemester === "Second") {
        return { semester: "Third", year: "" };
      } else if (normalizedSemester === "Third") {
        return { semester: "Fourth", year: "" };
      } else if (normalizedSemester === "Fourth") {
        return { semester: "Fifth", year: "" };
      } else if (normalizedSemester === "Fifth") {
        return { semester: "Sixth", year: "" };
      } else if (normalizedSemester === "Sixth") {
        return { semester: "Seventh", year: "" };
      } else if (normalizedSemester === "Seventh") {
        return { semester: "Eighth", year: "" };
      } else {
        return { semester: "", year: "" };
      }
    } else if (programType === ProgramType.ANNUAL) {
      if (normalizedYear === "First") {
        return { semester: "", year: "Second" };
      } else if (normalizedYear === "Second") {
        return { semester: "", year: "Third" };
      } else if (normalizedYear === "Third") {
        return { semester: "", year: "Fourth" };
      } else {
        return { semester: "", year: "" };
      }
    } else {
      return { semester: "", year: "" };
    }
  };

  const handleUpgrade = async () => {
    try {
      if (!Array.isArray(selectedStudents) || !Array.isArray(studentData)) {
        throw new Error("Invalid input data");
      }

      const upgradeData = selectedStudents
        .map((studentId) => {
          const student = studentData.find(
            (student) => student.studentId === studentId
          );

          if (student) {
            const programType = student.programType;
            const currentSemester = student.semester;
            const currentYear = student.year;

            const { semester, year } = getNextSemesterOrYear(
              programType,
              currentSemester,
              currentYear
            );

            return {
              programId: student.programId,
              studentId: studentId,
              year: year,
              semester: semester,
              batchId: student.batchId,
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      if (typeof upgradeStudents !== "function") {
        throw new Error("upgradeStudents is not a function");
      }

      await upgradeStudents(upgradeData);
      toast.success("Student Upgraded Successfully!!!");
      setOpen(false);
    } catch (error) {
      console.error("Error upgrading students:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleProgramChange = (event) => {
    const selectedProgramName = event.target.value;
    setSelectedProgram(selectedProgramName);

    const selectedProgram = studentData.find(
      (student) => student.programName === selectedProgramName
    );

    if (selectedProgram) {
      setProgramType(selectedProgram.programType); // Set program type (annual or semester)
    }
  };

  const hasSelectedRows = selectedStudents.length > 0;

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <StudentAppBar />
        </Box>
      </Box>
      <div>
        <Box>
          <Grid item xs={12} sm={6} md={4} padding={2}>
            <Typography
              variant="h6"
              style={{ color: "#2b6eb5", textAlign: "center" }}
            >
              Student List For Upgrading Semester/Year
            </Typography>
          </Grid>
        </Box>

        {hasSelectedRows && (
          <Typography variant="body2" color="#1976d2" pb={1}>
            No of Selected Students: {selectedStudents.length}
          </Typography>
        )}

        <TableContainer sx={{ borderRadius: 2 }}>
          <Table>
            <TableHead style={{ backgroundColor: "#2A629A" }}>
              <TableRow>
                <TableCell colSpan={13} style={{ padding: 0 }}>
                  <Grid
                    container
                    spacing={2}
                    padding={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs={12} sm={8} md={9} container spacing={2}>
                      <Grid item xs={12} sm={4} md={2}>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={selectedFiscalYear}
                            onChange={(e) =>
                              setSelectedFiscalYearId(e.target.value)
                            }
                            displayEmpty
                            sx={{
                              backgroundColor: "whitesmoke",
                              borderColor: "lightgray",
                              borderRadius: 1,
                              width: "150px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "lightgray",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                fontSize: "0.75rem",
                              },
                              "& .MuiSelect-icon": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            <MenuItem value="">Select Fiscal Year</MenuItem>
                            {fiscalYears.map((year, index) => (
                              <MenuItem key={index} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={2}>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={selectedFaculty}
                            onChange={(e) => setSelectedFaculty(e.target.value)}
                            displayEmpty
                            sx={{
                              backgroundColor: "whitesmoke",
                              borderColor: "lightgray",
                              borderRadius: 1,
                              width: "150px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "lightgray",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                fontSize: "0.75rem",
                              },
                              "& .MuiSelect-icon": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            <MenuItem value="">Select Faculty</MenuItem>
                            {faculties.map((faculty, index) => (
                              <MenuItem key={index} value={faculty}>
                                {faculty}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4} md={2}>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={selectedLevel}
                            onChange={(e) => setSelectedLevel(e.target.value)}
                            displayEmpty
                            sx={{
                              backgroundColor: "whitesmoke",
                              borderColor: "lightgray",
                              borderRadius: 1,
                              width: "150px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "lightgray",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                fontSize: "0.75rem",
                              },
                              "& .MuiSelect-icon": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            <MenuItem value="">Select Level</MenuItem>
                            {levels.map((level, index) => (
                              <MenuItem key={index} value={level}>
                                {level}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} md={2}>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={selectedProgram}
                            onChange={handleProgramChange}
                            displayEmpty
                            sx={{
                              backgroundColor: "whitesmoke",
                              borderColor: "lightgray",
                              borderRadius: 1,
                              width: "150px",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "lightgray",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "blue",
                              },
                              "& .MuiSelect-select": {
                                padding: "4px 8px",
                                fontSize: "0.75rem",
                              },
                              "& .MuiSelect-icon": {
                                fontSize: "1rem",
                              },
                            }}
                          >
                            <MenuItem value="">Select Program</MenuItem>
                            {programs.map((program, index) => (
                              <MenuItem key={index} value={program}>
                                {program}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* Conditionally render the semester or year field */}
                      {programType === "semester" && (
                        <Grid item xs={12} sm={4} md={2}>
                          <FormControl size="small" fullWidth>
                            <Select
                              value={selectedSemester}
                              onChange={(e) =>
                                setSelectedSemester(e.target.value)
                              }
                              displayEmpty
                              sx={{
                                backgroundColor: "whitesmoke",
                                borderColor: "lightgray",
                                borderRadius: 1,
                                width: "150px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "lightgray",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "blue",
                                },
                                "& .MuiSelect-select": {
                                  padding: "4px 8px",
                                  fontSize: "0.75rem",
                                },
                                "& .MuiSelect-icon": {
                                  fontSize: "1rem",
                                },
                              }}
                            >
                              <MenuItem value="">Select Semester</MenuItem>
                              {semesters.map((semester, index) => (
                                <MenuItem key={index} value={semester}>
                                  {semester} Semester
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      {programType === "annual" && (
                        <Grid item xs={12} sm={4} md={2}>
                          <FormControl size="small" fullWidth>
                            <Select
                              value={selectedYear}
                              onChange={(e) => setSelectedYear(e.target.value)}
                              displayEmpty
                              sx={{
                                backgroundColor: "whitesmoke",
                                borderColor: "lightgray",
                                borderRadius: 1,
                                width: "150px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "lightgray",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "blue",
                                },
                                "& .MuiSelect-select": {
                                  padding: "4px 8px",
                                  fontSize: "0.75rem",
                                },
                                "& .MuiSelect-icon": {
                                  fontSize: "1rem",
                                },
                              }}
                            >
                              <MenuItem value="">Select Year</MenuItem>
                              {years.map((year, index) => (
                                <MenuItem key={index} value={year}>
                                  {year} Year
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      container
                      justifyContent="flex-end"
                    >
                      <Button
                        size="small"
                        variant="standard"
                        onClick={handleClickOpen}
                        disabled={selectedStudents.length === 0}
                        display="none"
                        sx={{
                          bgcolor: "white",
                          color: "#3498db",
                          borderRadius: 2,
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: "#f5f5f5",
                            color: "#3498db",
                            opacity: 0.8,
                          },
                        }}
                      >
                        Upgrade selected
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                {(selectedSemester || selectedYear) && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      sx={{ color: "white" }}
                      color="default"
                      onChange={handleSelectAllClick}
                      checked={
                        selectedStudents.length === filteredStudentData.length
                      }
                      disabled={isButtonDisabled}
                    />
                  </TableCell>
                )}
                {[
                  "S.No.",
                  "Full Name",
                  "Gender",
                  "Batch Year",
                  "Semester/Year",
                  "Roll No",
                  "Level",
                  "Faculty",
                  "Program",
                  "District",
                  "Status",
                ].map((header, index) => (
                  <TableCell
                    key={header}
                    style={{
                      color: "#ffffff",
                      border: "1px solid #ddd",
                      padding: "4px",
                      height: "24px",
                      textAlign: "center",
                      width: header === "S.No." ? "2%" : "auto",
                    }}
                  >
                    {header}
                  </TableCell>
                ))}

                <TableCell
                  style={{
                    color: "#FFFFFF",
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "24px",
                    textAlign: "center",
                    width: "5%",
                  }}
                  disabled={selectedSemester}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "whitesmoke" }}>
              {currentStudents.map((student, index) => (
                <TableRow key={student.srudentId}>
                  {(selectedSemester || selectedYear) && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedStudents.includes(student.studentId)}
                        onChange={(event) =>
                          handleCheckboxChange(event, student.studentId)
                        }
                        disabled={isButtonDisabled}
                      />
                    </TableCell>
                  )}
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {indexOfFirstStudent + index + 1}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {`${student.student.firstName || ""} ${
                      student.student.middleName || ""
                    } ${student.student.lastName || ""}`}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {capitalize(student.student.gender) || ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {student.student.admissionYear || ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {student.year
                      ? `${student.year} year`
                      : `${student.semester} semester`}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {student.student.rollNo}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {student.levelName}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {student.facultyName}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {student.programName}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {student.student.pDistrict || ""}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    {student.student.isVerified ? (
                      <Chip label="Verified" color="success" size="small" />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      component={Link}
                      variant="contained"
                      size="small"
                      onClick={handleClickOpen}
                      style={{
                        fontSize: "10px",
                        textTransform: "capitalize",
                      }}
                      disabled={isButtonDisabled || selectedProgram}
                    >
                      Upgrade
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
          <Pagination
            count={Math.ceil(filteredStudentData.length / rowsPerPage)}
            page={page}
            shape="rounded"
            onChange={handlePageChange}
          />
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="sm"
          fullWidth
          sx={{
            borderRadius: "12px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgba(255, 255, 255, 0.6)",
            backdropFilter: "blur(8px)",
          }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "1rem",
              bgcolor: blue[700],
              color: "white",
              py: 1.5,
              mb: 1.5,
            }}
          >
            Confirm Upgrade
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {programType === "semester" ? (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#444",
                      lineHeight: 1.7,
                      fontSize: "0.875rem",
                      fontWeight: 400,
                      textAlign: "center",
                      mb: 2,
                    }}
                  >
                    You are currently in the{" "}
                    <span color="primary">{selectedSemester}</span> Semester. We
                    are upgrading you to the Next Semester.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#444",
                      lineHeight: 1.7,
                      fontSize: "0.875rem",
                      fontWeight: 400,
                      textAlign: "center",
                      mb: 2,
                    }}
                  >
                    You are currently in the {selectedYear} Year. We are
                    upgrading you to the Next Year.
                  </Typography>
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              p: 1,
              pb: 2,
              borderTop: "1px solid #ddd",
            }}
          >
            <Button
              onClick={handleClose}
              color="error"
              variant="outlined"
              size="small"
              sx={{
                padding: "6px 12px",
                fontSize: "0.75rem",
                borderRadius: 2,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleUpgrade();
              }}
              color="success"
              variant="contained"
              size="small"
              sx={{
                bgcolor: "#1976d2",
                color: "white",
                "&:hover": {
                  bgcolor: "#1565c0",
                },
                padding: "6px 12px",
                borderRadius: 2,
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default UpgradeAcademics;
