import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Popover,
  Button,
  Grid,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  FileDownload,
  InsertDriveFile,
} from "@mui/icons-material";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import { getStudentsForAnnexReport } from "./reportApi";
import useAddressData from "../address/address";
import { useSelector } from "react-redux";

const DetailEnrollmentByCampus = () => {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  const {
    uniqueProvinces,
    uniqueDistricts,
    selectedProvince,
    setSelectedProvince,
    selectedDistrict,
    setSelectedDistrict,
  } = useAddressData();

  const [selectedUniversity, setSelectedUniversity] = useState("All");
  const [selectedCampusType, setSelectedCampusType] = useState("All");
  const [anchorEl, setAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);

  const fetchData = async () => {
    const response = await getStudentsForAnnexReport();
    if (response) {
      const reStructuredResponse = response.map((item) => ({
        universityName:item.universityName,
        province: item.province,
        district: item.district,
        localLevel: item.localLevel,
        campus: item.campus,
        faculty: item.faculty,
        level: item.level,
        program: item.program,
        male: item.male,
        female: item.female,
        others: item.others,
        total: item.total,
        campusType: item.campusType,
        universityType: item.universityType,
        ...item,
        ethnicGroup: Object.keys(item.ethinicGroup).reduce((acc, key) => {
          acc[key.trim()] = item.ethinicGroup[key];
          return acc;
        }, {}),
        ethnicTotalInCampus: Object.values(item.ethinicGroup).reduce(
          (total, value) => total + value,
          0
        ),
      }));
      setRows(reStructuredResponse);
    } else {
      setRows([])
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  
  const filteredRows = rows.filter((row) => {
    return (
      (selectedUniversity === "All" || row.universityName === selectedUniversity) &&
      (selectedCampusType === "All" || row.campusType === selectedCampusType) &&
      (!selectedProvince || row.province === selectedProvince) &&
      (!selectedDistrict || row.district === selectedDistrict)
    );
  });
 const universityNames = [...new Set(rows.map(item => item.universityName))];
 console.log(universityNames)
  const totals = filteredRows.reduce(
    (acc, row) => {
      acc.male += row.male;
      acc.female += row.female;
      acc.others += row.others;
      acc.total += row.male + row.female + row.others;
      acc.Chhetri += row.ethnicGroup.Chhetri;
      acc.Brahman += row.ethnicGroup.Brahman;
      acc.Madhesi += row.ethnicGroup.Madhesi;
      acc.Dalit += row.ethnicGroup.Dalit;
      acc.Muslim += row.ethnicGroup.Muslim;
      acc.Tharu += row.ethnicGroup.Tharu;
      acc.Janajati += row.ethnicGroup.Janajati;
      acc.Others += row.ethnicGroup.Others;
      acc.ethnicTotalInCampus += row.ethnicTotalInCampus;
      return acc;
    },
    {
      male: 0,
      female: 0,
      others: 0,
      total: 0,
      Chhetri: 0,
      Brahman: 0,
      Madhesi: 0,
      Dalit: 0,
      Muslim: 0,
      Tharu: 0,
      Janajati: 0,
      Others: 0,
      ethnicTotalInCampus: 0
    }
  );

  // Export to Excel
  const exportToExcel = () => {
    const headers = [
      ["S.No.", "University", "Campus Name", "District", "Province", "Campus Type", "Faculty", "Level", "Program Name", "Gender", "", "", "", "Ethnicity", "", "", "", "", "", "", "", "Total"],
      ["", "", "", "", "", "", "", "", "Male", "Female", "Others", "Total", "Chhetri", "Brahman", "Madhesi", "Dalit", "Muslim", "Tharu", "Janajati", "Others", ""]
    ];
    const rows = filteredRows.map((row, index) => [
      index + 1,
      row.universityName,
      row.campus,
      row.district,
      row.province,
      row.campusType,
      row.faculty,
      row.level,
      row.program,
      row.male,
      row.female,
      row.others,
      row.male + row.female,
      row.ethnicGroup.Chhetri || 0,
      row.ethnicGroup.Brahman || 0,
      row.ethnicGroup.Madhesi || 0,
      row.ethnicGroup.Dalit || 0,
      row.ethnicGroup.Muslim || 0,
      row.ethnicGroup.Tharu || 0,
      row.ethnicGroup.Janajati || 0,
      row.ethnicGroup.Others || 0,
      row.ethnicTotalInCampus,
    ]);
    const grandTotal = [
      "Grand Total","", "", "", "", "", "", "", "", totals.male, totals.female, totals.others, totals.male + totals.female,
      totals.Chhetri, totals.Brahman, totals.Madhesi, totals.Dalit, totals.Muslim, totals.Tharu, totals.Janajati, totals.Others, totals.ethnicTotalInCampus
    ];
    const wsData = [...headers, ...rows, grandTotal];
    
    // Create sheet
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Enrollment Data");
    XLSX.writeFile(wb, "enrollment_data.xlsx");
  };
  // Handle Export Button Popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;
  return (
    <div>
      <Box sx={{ marginBottom: "5px", display: "flex", gap: 2 }}>
        {
          roleName !== 'UniAdmin' && (
          <>
           
            <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
              <InputLabel>University</InputLabel>
              <Select
                size="small"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 2,
                }}
                labelId="university-select-label"
                id="university-select"
                value={selectedUniversity}
                onChange={(e) => setSelectedUniversity(e.target.value)}
                label="University"
              >
                <MenuItem value="All">All University</MenuItem>
                {
                  universityNames.map((item, index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </>
          )
        }

        {/* Campus Type Dropdown */}
        <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
          <InputLabel>Campus Type</InputLabel>
          <Select
            size="small"
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
            }}
            labelId="campus-type-select-label"
            id="campus-type-select"
            value={selectedCampusType}
            onChange={(e) => setSelectedCampusType(e.target.value)}
            label="Campus Type"
          >
            <MenuItem value="All">All Campuses</MenuItem>
            <MenuItem value="constituent">Constituent</MenuItem>
            <MenuItem value="community">Community</MenuItem>
            <MenuItem value="private">Private</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
          <InputLabel>Province</InputLabel>
          <Select
            size="small"
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
            }}
            defaultValue="none"
            value={selectedProvince}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedProvince(value === "none" ? "" : value); // Reset or set selected province
            }}
            label="Province"
          >
            <MenuItem value="none">All province</MenuItem>
            {uniqueProvinces.map((province) => {
              return (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ maxWidth: "30%" }} size="small">
          <InputLabel>District</InputLabel>
          <Select
            size="small"
            defaultValue={selectedDistrict || ""}
            sx={{
              backgroundColor: "#fff",
              borderRadius: 2,
              borderColor: "#c2c2c2",
            }}
            value={selectedDistrict}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedDistrict(value === "none" ? "" : value); // Reset or set selected province
            }}
            disabled={!selectedProvince}
            label="District"
          >
            <MenuItem value="none">All Districts</MenuItem>
            {uniqueDistricts.map((district) => {
              return (
                <MenuItem key={district} value={district}>
                  {district}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Grid container justifyContent="right">
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<FileDownload />}
            onClick={handleClick}
            style={{ marginBottom: "10px" }}
          >
            Export
          </Button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box sx={{ padding: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ backgroundColor: "#5FAD41" }}
                startIcon={<InsertDriveFile />}
                onClick={exportToExcel}
                fullWidth
                style={{ marginBottom: "10px" }}
              >
                Excel
              </Button>
            </Box>
          </Popover>
        </Grid>
      </Box>

      <TableContainer sx={{ border: "1px solid #ddd" , overflowX:'scroll'}}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                S.No.
              </TableCell>
             
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Campus Name
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                University
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  textAlign: "center",
                }}
              >
                District
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Province
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Campus Type
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Faculty
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Level
              </TableCell>
              <TableCell
                rowSpan={2}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Program Name
              </TableCell>
              <TableCell
                colSpan={4}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Gender
              </TableCell>
              <TableCell
                colSpan={9}
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Ethnicity
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Male
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Female
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                others
              </TableCell>
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>

              {[
                "Chhetri",
                "Brahman",
                "Madhesi",
                "Dalit",
                "Muslim",
                "Tharu",
                "Janajati",
                "Others",
              ].map((item, index) => (
                <TableCell
                  key={index}
                  sx={{
                    border: "1px solid #ddd",
                    color: "#ffffff",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {item}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  border: "1px solid #ddd",
                  color: "#ffffff",
                  padding: "4px",
                  textAlign: "center",
                }}
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredRows.map((row, index) => {
              const totalStudents = row.male + row.female;
              return (
                <TableRow key={row.id}>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.campus}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.universityName}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "2px",
                      textAlign: "left",
                    }}
                  >
                    {row.district}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.province}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.campusType}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.faculty}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.level}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "left",
                    }}
                  >
                    {row.program}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.male}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.female}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.others}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {totalStudents}
                  </TableCell>
                  {Object.entries(row.ethnicGroup).map(
                    ([key, value], index) => (
                      <TableCell
                        key={index}
                        sx={{
                          border: "1px solid #c2c2c2",
                          padding: "4px",
                          textAlign: "right",
                        }}
                      >
                        {row.ethnicGroup[key]}
                      </TableCell>
                    )
                  )}
                  <TableCell
                    sx={{
                      border: "1px solid #c2c2c2",
                      padding: "4px",
                      textAlign: "right",
                    }}
                  >
                    {row.ethnicTotalInCampus}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableRow style={{ backgroundColor: "#c2c2c2" }}>
            <TableCell
              colSpan={9}
              sx={{
                border: "1px solid #ddd",
                padding: "4px",
                textAlign: "right",
                fontWeight: "bold",
              }}
            >
              Grand Total
            </TableCell>
            {
              Object.entries(totals).map(([key, value], index) => (
                <TableCell
                  key={index}
                  sx={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    textAlign: "right",
                  }}
                >
                  {value}
                </TableCell>
              ))
            }
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DetailEnrollmentByCampus;
