import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Popover,
  Grid,
} from "@mui/material";
import axios from "axios";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  FileDownload,
  InsertDriveFile,
  PictureAsPdf,
} from "@mui/icons-material";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const BudgetReport = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fiscalYear, setFiscalYear] = useState(""); // Track selected fiscal year

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          `${backendUrl}/BudgetDisbursement`,
          config
        );
        const rankWiseData = response.data;

        // Filter data based on fiscal year if provided
        if (fiscalYear) {
          const filtered = rankWiseData.filter(
            (item) => item.fiscalYearNepali === fiscalYear
          );
          setFilteredData(filtered);
        } else {
          setFilteredData(rankWiseData); // No filter, show all data
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [fiscalYear]); // Re-fetch data if fiscalYear changes

  const exportToExcel = () => {
    const header = [
      [
        "S.No.",
        "University",
        "Regular Budget",
        "Development Budgets",
        "Other Budgets",
        "Total Budgets",
      ],
    ];

    const data = filteredData.map((item, index) => [
      index + 1,
      item.universityName,
      item.regularBudget,
      item.developBudget,
      item.otherBudget,
      item.totalBudget,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([...header, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    XLSX.writeFile(workbook, "budget_report.xlsx");
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text("Budget Report", 14, 16);

    const columns = [
      "S.No.",
      "University",
      "Regular Budget",
      "Development Budgets",
      "Other Budgets",
      "Total Budgets",
    ];

    const data = filteredData.map((item, index) => [
      index + 1,
      item.universityName,
      item.regularBudget,
      item.developBudget,
      item.otherBudget,
      item.totalBudget,
    ]);

    doc.autoTable(columns, data);
    doc.save("budget_report.pdf");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  return (
    <Box sx={{ padding: "5px" }}>
      <Grid container justifyContent="right">
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<FileDownload />}
          onClick={handleClick}
          style={{ marginBottom: "10px" }}
        >
          Export
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#5FAD41" }}
              startIcon={<InsertDriveFile />}
              onClick={exportToExcel}
              fullWidth
              style={{ marginBottom: "10px" }}
            >
              Excel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#272727" }}
              startIcon={<PictureAsPdf />}
              onClick={exportToPDF}
              fullWidth
            >
              PDF
            </Button>
          </Box>
        </Popover>
      </Grid>
      <TableContainer sx={{ border: "1px solid #ddd" }}>
        <Table>
          {/* Header */}
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow style={{ color: "white" }}>
              <TableCell
                align="center"
                sx={{
                  fontSize: "0.9rem",
                  color: "#ffffff",
                  border: "1px solid #c2c2c2",
                }}
              >
                University
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "#ffffff",
                  fontSize: "0.9rem",
                  border: "1px solid #c2c2c2",
                }}
              >
                Regular Budget
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "#ffffff",
                  fontSize: "0.9rem",
                  border: "1px solid #c2c2c2",
                }}
              >
                Development Budget
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "#ffffff",
                  fontSize: "0.9rem",
                  border: "1px solid #c2c2c2",
                }}
              >
                Other Budget
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "#ffffff",
                  fontSize: "0.9rem",
                  border: "1px solid #c2c2c2",
                }}
              >
                Total Budget(NPR)
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            {filteredData.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell
                  sx={{
                    fontSize: "0.9rem",
                    border: "1px solid #5E686D",
                  }}
                >
                  {item.universityName}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "0.9rem",
                    border: "1px solid #5E686D",
                  }}
                  align="center"
                >
                  {item.regularBudget}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "0.9rem",
                    border: "1px solid #5E686D",
                  }}
                  align="center"
                >
                  {item.developBudget}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "0.9rem",
                    border: "1px solid #5E686D",
                  }}
                  align="center"
                >
                  {item.otherBudget}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "0.9rem",
                    border: "1px solid #5E686D",
                  }}
                  align="center"
                >
                  {(
                    parseFloat(item.regularBudget) +
                    parseFloat(item.developBudget) +
                    parseFloat(item.otherBudget)
                  ).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BudgetReport;
