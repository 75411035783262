import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import {
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";
import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const StudentsByCampus = ({ authToken }) => {
  const [studentsData, setStudentsData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state

  // Fetch data only if the token is loaded and available
  useEffect(() => {
    if (authToken) {
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };

      setLoading(true); // Set loading to true when fetching data
      axios
        .get(`${backendUrl}/Dashboard/GetCollgeStudentForUniAndAdmin`, config)
        .then((response) => {
          // Filter out campuses where all data points are zero
          const filteredData = response.data.filter(
            (item) => item.male > 0 || item.female > 0 || item.others > 0 || item.total > 0
          );

          const data = filteredData.map((item) => ({
            campus: item.title,
            male: item.male,
            female: item.female,
            other: item.others,
            total: item.total,
          }));

          setStudentsData(data);
          setLoading(false);
        })
        .catch((err) => {
          setError("Failed to load data. Please try again later.");
          setLoading(false);
        });
    }
  }, [authToken]);

  // Generate chart options if data is available
  const generateChartOption = () => {
    if (studentsData.length === 0) return {}; // Return empty if no data

    // Capitalizing the first letter of each word in the campus title
    const campuses = studentsData.map((item) =>
      item.campus
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    );

    // Initialize seriesData
    let seriesData = [
      {
        name: "Male",
        type: "bar",
        barWidth: 35,
        data: studentsData.map((item) => item.male),
        itemStyle: { color: "#1976d2" },
        label: { show: true, position: "top", color: "#000" },
      },
      {
        name: "Female",
        type: "bar",
        barWidth: 35,
        data: studentsData.map((item) => item.female),
        itemStyle: { color: "#FB5645" },
        label: { show: true, position: "top", color: "#000" },
      },
    ];

    // Only include the "Other" series if there are non-zero values for "Other"
    if (studentsData.some((item) => item.other > 0)) {
      seriesData.push({
        name: "Other",
        type: "bar",
        barWidth: 35,
        data: studentsData.map((item) => item.other),
        itemStyle: { color: "#8E44AD" },
        label: { show: true, position: "top", color: "#000" },
      });
    }

    // Always include "Total" series
    seriesData.push({
      name: "Total",
      type: "bar",
      barWidth: 35,
      data: studentsData.map((item) => item.total),
      itemStyle: { color: "#005884" },
      label: { show: true, position: "top", color: "#000" },
    });

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: seriesData.map((series) => series.name),
        textStyle: { color: "#2E294E" },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: {
        type: "category",
        data: campuses, // Use transformed campus names
        axisLabel: { color: "#333", textTransform: "capitalize" },
        name: "Campus Type",
        nameLocation: "middle",
        nameGap: 35,
      },
      yAxis: {
        type: "value",
        axisLine: { show: true },
        name: "No. of Students",
        nameLocation: "middle",
        nameGap: 35,
        axisLabel: { color: "#333" },
      },
      series: seriesData,
    };
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={5} sx={{ padding: "20px" }}>
          <Typography
            variant="body1"
            align="center"
            fontWeight={900}
            style={{ padding: "1rem" }}
          >
            Students by Campus Type
          </Typography>

          {loading && (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          )}

          {error && (
            <Alert severity="error" sx={{ marginBottom: "16px" }}>
              {error}
            </Alert>
          )}

          {!loading && !error && studentsData.length > 0 && (
            <ReactECharts
              option={generateChartOption()}
              style={{ height: "400px" }}
              opts={{ renderer: "canvas" }}
            />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StudentsByCampus;
