import React from "react";
import ReceiptNavBar from "../navbar/ReceiptNavBar";
import { Box } from "@mui/material";
import ReceiptStudentList from "../../components/setup/graduationModule/StudentListForReceipt";

export default function ReceiptHome() {
  return (
    <div>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ReceiptNavBar />
          <ReceiptStudentList />
        </Box>
      </Box>
    </div>
  );
}
