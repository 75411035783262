import axios from "axios"
const backendUrl = process.env.REACT_APP_BACKEND_URL; 


export async function fetchReportLevelWiseUni (fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateUniversityReportLevelWise?fiscalYearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchCampusTypeByEcoBelts (fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateProvinceAndEcologicalBeltReport`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchReportByFacultyLevel (fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateFacultyEnrollmentReport?fiscalYearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function generateProvinceAndMaleFemaleReport () {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateProvinceAndMaleFemaleReport`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchEnrollmentIncampusBylevel (fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateUniversityReportLevelWiseWithCampusType?fiscalYearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchEnrollmentInEcoBelts (fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateStudentByBeltReport?fiscalYearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchEnrollmentReportByCampusType (fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateGenderCampusTypeReport?fiscalYearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchCampusReportByProvince () {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateCampusByProvinceReport`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }


  export async function fetchEnrollmentByFaculty (fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateStudentReportFacultyWise?fiscalYearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchCampusReportByFaculty() {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateUniReportFacultyWise`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }


  export async function fetchEnrollmentByProvince(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateStudentByProvinceReport?fiscalyearid=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchEnrollmentByFacultyInProvince(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateStudentByFacultyProvinceReport?fiscalyearid=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchTeacherReportByJoiningType(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateTeacherReport?fiscalyearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function fetchNonTeacherReportByPost(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateNonTeacherReport?fiscalyearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }


  export async function fetchStudentsByCollegeType(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GetStudentByCollegeType?fiscalyearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function getStudentByLevelProvinceWise(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateStudentByLevelProvinceReport?fiscalyearId=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function getStudentOfTechnicalFaculty(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GetStudentByTechnicalFaculty`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }
  export async function getStudentOfNonTechnicalFaculty(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GetStudentByNonTechnicalFaculty`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function getStudentsForAnnexReport(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GetStudentsFilterableReport?fiscalyearid=${fiscalId}`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function getDynamicTeacherData() {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateDynamicTeacherReport`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function getCampusInUniByEcoBelts(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/UniversityEcologicalReport`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

  export async function getStudentByEcobelts(fiscalId) {
    try {
      const localStorageData = JSON.parse(localStorage.getItem('persist:root'));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
        const config = {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.get(`${backendUrl}/Dashboard/GenerateUniversityAndLevelReport`, config)
      return response.data
    } catch (err) {
      console.log(err)
    }
  }

