import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  TableBody,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  InputAdornment,
  TablePagination,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { toast } from "react-toastify";
import axios from "axios";
import ReceiptNavBar from "../../../modules/navbar/ReceiptNavBar";
import EditFeeType from "./EditFeeType";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const FeeType = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [getBatchData, setGetBatchData] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedFeeId, setSelectedFeeId] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${backendUrl}/FeeType`);
      setGetBatchData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = {
        id: 0,
        name: data.name,
        amount: data.amount,
      };

      await axios.post(`${backendUrl}/FeeType`, formData);
      toast.success("Data added successfully!", {
        autoClose: 2000,
      });
      fetchData();
      reset();
    } catch (err) {
      toast.error("Failed to add data!");
    }
  };

  const handleEditClick = (Id) => {
    setSelectedFeeId(Id);
    setOpenEditDialog(true);
  };

  const handleUpdate = () => {
    fetchData();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page change
  };

  const paginatedData = getBatchData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <ReceiptNavBar />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={8}>
          <Paper elevation={5} sx={{ borderRadius: "20px" }}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Fee Type Setup
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Batch</InputLabel>
                      <Select
                        required
                        {...register("batch", {
                          required: "batch is required",
                        })}
                        size="small"
                        type="text"
                        label="Batch"
                        fullWidth
                      ></Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Programs</InputLabel>

                      <Select
                        required
                        {...register("program", {
                          required: "Program is required",
                        })}
                        size="small"
                        type="text"
                        label="Program"
                        fullWidth
                      ></Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Semester</InputLabel>
                      <Select
                        required
                        {...register("semester", {
                          required: "Semester is required",
                        })}
                        size="small"
                        type="text"
                        label="Semester"
                        fullWidth
                      ></Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Year</InputLabel>
                      <Select
                        required
                        {...register("year", { required: "Year is required" })}
                        size="small"
                        type="text"
                        label="Year"
                        fullWidth
                      ></Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      {...register("name", { required: "Name is required" })}
                      size="small"
                      type="text"
                      label="Fee Type Name"
                      fullWidth
                      autoComplete="given-name"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      {...register("amount", {
                        required: "Amount is required",
                      })}
                      size="small"
                      type="number"
                      label="Fee Amount(NPR)"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">RS.</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Status</InputLabel>
                      <Select
                        {...register("isActive")}
                        size="small"
                        label="Status"
                        fullWidth
                        defaultValue={true}
                      >
                        <MenuItem value={true}>
                          <span style={{ color: "green" }}>Active</span>
                        </MenuItem>
                        <MenuItem value={false}>
                          <span style={{ color: "red" }}>Inactive</span>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    style={{ backgroundColor: "#007aff", color: "#inherit" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Paper>
          <Grid margin="10px">
            <Typography
              variant="h6"
              gutterBottom
              sx={{ textAlign: "center", color: "#2A629A" }}
            >
              List of Fee Types
            </Typography>
            <TableContainer>
              <Table
                style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}
              >
                <TableHead style={{ backgroundColor: "#2A629A" }}>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                        width: "5%",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Fee Type Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Fee Amount(NPR.)
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#FFFFFF",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: "white" }}>
                  {paginatedData.map((data, index) => (
                    <TableRow key={data.id}>
                      <TableCell
                        style={{ border: "1px solid #ddd", padding: "4px" }}
                      >
                        {index + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid #ddd", padding: "4px" }}
                      >
                        {data.name}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid #ddd", padding: "4px" }}
                      >
                        {"RS." + data.amount}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid #ddd", padding: "4px" }}
                      >
                        {data.isActive ? (
                          <span style={{ color: "green" }}>Active</span>
                        ) : (
                          <span style={{ color: "red" }}>Inactive</span>
                        )}
                      </TableCell>
                      <TableCell
                        style={{ border: "1px solid #ddd", padding: "4px" }}
                      >
                        <Button onClick={() => handleEditClick(data.id)}>
                          <EditNoteIcon />
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={getBatchData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        <Dialog
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          maxWidth="md"
        >
          <EditFeeType
            feeTypeId={selectedFeeId}
            onUpdate={handleUpdate}
            onClose={() => setOpenEditDialog(false)}
          />
        </Dialog>
      </Grid>
    </>
  );
};

export default FeeType;
