
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  Divider
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getEmployeeById } from '../../../services/employeeService'
import { getEthnicGroup } from "../../../services/services";
import useAddressData from "../../address/address";
import DateInputField from "../../DateField/DateInputField";

const convertToISODate = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};
const EditEmployeeInfoContext = createContext();

const EditEmployeeInfoProvider = ({ children }) => {
  const methods = useForm();
  const [employeeInfo, setEmployeeInfo] = useState({
    employeeType: "",
    ethnicity: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    gender: "",
    citizenshipNo: "",
    dob: "",
    dobAD: '',
    citizenIssueDist: "",
    nidNo: "",
    citizenFront: "",
    citizenBack: "",
    nidPic: "",
    pPsizePhoto: "",
  });
  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setEmployeeInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setEmployeeInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  return (
    <EditEmployeeInfoContext.Provider value={{ ...methods, employeeInfo, onChange }}>
      {children}
    </EditEmployeeInfoContext.Provider>
  );
};
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },

  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const EditEmployeeGeneralInfo = ({ handleNext, handleBack, id }) => {
  
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useContext(EditEmployeeInfoContext);
  const [ethnicGroup, setEthnicGroup] = useState([])

  const { onChange } = useContext(EditEmployeeInfoContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const ethnicGroup = await getEthnicGroup()
        setEthnicGroup(ethnicGroup)
        const getEmployeeData = await getEmployeeById(id)
        const dobBsFormatted = convertToISODate(getEmployeeData.dateOFBirth);
        const dobAdFormatted = convertToISODate(getEmployeeData.dateOFBirthAd);
        setValue('employeeType', getEmployeeData.employeeType)
        setValue('firstName', getEmployeeData.firstName)
        setValue('middleName', getEmployeeData.middleName)
        setValue('lastName', getEmployeeData.lastName)
        setValue('ethnicity', getEmployeeData.ethnicity)
        setValue('email', getEmployeeData.email)
        setValue('phone', getEmployeeData.phoneNumber)
        setValue('gender', getEmployeeData.gender)
        setValue('citizenshipNo', getEmployeeData.citizenshipNo)
        setValue('dob', dobBsFormatted)
        setValue('dobAD', dobAdFormatted)
        setValue('citizenIssueDist', getEmployeeData.citizenIssueDist)
        setValue('nidNo', getEmployeeData.nidNo)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
  }, [id])

  const { address } = useAddressData();

  const onSubmit = (data) => {
    handleSubmit((formData) => {
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      handleNext();
    })(data);
  };

  const onBack = (data) => {
    handleBack(); // Call handleNext only if form submission is successful

  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="firstName"
                  size="small"
                  name="firstName"
                  label="First Name"
                  fullWidth
                  error={!!errors.firstName}
                  helperText={errors.firstName ? "Required" : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="middleName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="middleName"
                  size="small"
                  name="middleName"
                  label="Middle Name"
                  fullWidth

                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="lastName"
                  size="small"
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  error={!!errors.lastName}
                  helperText={errors.lastName ? "Required" : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="phone"
              control={control}
              rules={{
                required: "Phone Number is required",
                validate: (value) => {
                  const isValidPhone = /^[9]\d{9}$/.test(value);
                  return isValidPhone || "invalid phone umber";
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="phone"
                  type="text"
                  name="phone"
                  label="Phone Number"
                  InputProps={{
                    placeholder: "98XXXXXXXX",
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    onInput: (e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10); // Limit input to 10 characters
                    },
                  }}
                  fullWidth
                  autoComplete="phone number"
                  size="small"
                  error={!!errors.phone}
                  helperText={errors.phone ? errors.phone.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Email format not matched",
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="email"
                  name="email"
                  type="email"
                  size="small"
                  label="Email"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message} // Check if errors.email exists before accessing its message property
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel sx={{ borderColor: "blue" }} id="type">
                Gender
              </InputLabel>
              <Controller
                name="gender"
                control={control}
                defaultValue="" // Set the default value here
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    labelId="gender"
                    id="gender"
                    name="gender"
                    label="Gender"
                    fullWidth
                    error={!!errors.gender}
                    helperText={errors.gender ? 'Required' : ''}
                  >
                    <MenuItem value="">
                      {" "}
                    </MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel sx={{ borderColor: "blue" }} id="ethnicity" required>
                Ethnicity
              </InputLabel>
              <Controller
                name="ethnicity"
                control={control}
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    required
                    labelId="ethnicity"
                    id="ethnicity"
                    name="ethnicity"
                    label="Ethnicity"
                    fullWidth
                    error={!!errors.ethnicity}
                    onChange={(e) => field.onChange(e.target.value)} // Ensure field value is updated
                    value={field.value || ""} // Handle value properly
                  >
                    <MenuItem value="" disabled>Select Ethnicity</MenuItem>
                    {ethnicGroup && ethnicGroup.map((data) => (
                      <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              name="citizenshipNo"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="citizenshipNo"
                  size="small"
                  name="citizenshipNo"
                  label="Citizenship No"
                  fullWidth
                  error={!!errors.citizenshipNo}
                  helperText={errors.citizenshipNo ? "Required" : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
          <Controller
            name="dob"
            control={control}
            defaultValue={null} // Ensure a valid default value
            render={({ field }) => (
              <DateInputField
                {...field}
                label="Date of Birth (B.S)"
                name="dob"
                value={field.value}
                format='YYYY/MM/DD'
                onChange={(newValue) => {
                  field.onChange(newValue);
                  onChange("dob", newValue); // Update the context state
                }}
              />
            )}
          />
          </Grid>
          <Grid item xs={12} sm={3}>
          <Controller
            name="dobAD"
            control={control}
            defaultValue={null} 
            render={({ field }) => (
              <DateInputField
                {...field}
                label="Date of Birth (A.D)"
                name="dobAD"
                value={field.value}
                format='DD/MM/YYYY'
                onChange={(newValue) => {
                  field.onChange(newValue);
                  onChange("dobAD", newValue); 
                }}
              />
            )}
          />
          </Grid>

          <Grid item xs={12} sm={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Issued District</InputLabel>
              <Controller
                name="citizenIssueDist"
                control={control}
                // rules={{ required: "Required" }}
                defaultValue=""
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    id="citizenIssueDist"
                    size="small"
                    name="citizenIssueDist"
                    label="Issued District"
                    fullWidth

                  >
                    <MenuItem value='' disabled>
                      Select District
                    </MenuItem>
                    {address &&
                      Array.from(
                        new Set(address.map((data) => data.district))
                      ).map((district, index) => (
                        <MenuItem key={index} value={district}>
                          {district}
                        </MenuItem>
                      ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Controller
              name="nidNo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="nidNo"
                  size="small"
                  name="nidNo"
                  label="Nid No"
                  fullWidth

                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex"  justifyContent="flex-end" mt={1} mb={2}>
            <Button
              variant="outlined"
              color="error"
              size="small"
              disabled
              sx={{
                marginLeft: "10px",
              }}
              startIcon={<ChevronLeftRoundedIcon />}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              size="small"
              type="submit"
              sx={{
                marginLeft: "10px",
              }}
              endIcon={<ChevronRightRoundedIcon />}
            >
              Next
            </Button>
          </Box>
        </Grid>
    </Grid>
  );
};

export { EditEmployeeInfoProvider, EditEmployeeInfoContext };
export default EditEmployeeGeneralInfo;



