import React, { useState, useEffect } from 'react'
import { FormLabel, Stack, Dialog, DialogContent, DialogActions, DialogTitle, Grid, Button, IconButton, TextField, Radio, RadioGroup, FormControl, FormControlLabel, Select, MenuItem, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { getEmployeePositionById, updatetEmployeePosition } from '../../../services/services';
import { toast } from 'react-toastify';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    height: 'auto',
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
function EditPost({ handleClose, open, id }) {
    const [formData, setFormData] = useState({
        postName: '',
        category: '',
        type: '',
        status: null,
        index: null,


    });
    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                try {
                    const data = await getEmployeePositionById(id);
                    setFormData(data);
                } catch (error) {
                    console.error('Error fetching team data:', error);
                }
            }
        };
        fetchData();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updatetEmployeePosition(id, formData);
            toast.success('position updated successfully');
            handleClose();
        } catch (error) {
            console.error('Error updating position:', error);
            toast.error('Failed to update position');
        }
    };
    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogTitle sx={{ m: 'auto auto', p: 1 }}>
                Edit Position
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Stack direction='column'>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1} width='35rem' >
                            <Grid item sm={6}>
                                <FormControl size='small' fullWidth>
                                    <InputLabel size='small'>Category</InputLabel>
                                    <Select
                                        variant='standard'
                                        id="demo-simple-select"
                                        size='small'
                                        label='Category'
                                        name='category'
                                        value={formData.category}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value='Teaching'>
                                            Teaching
                                        </MenuItem>
                                        <MenuItem value='Administrative'>
                                            Non-teaching
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                                <FormControl size='small' fullWidth>
                                    <InputLabel size='small'>Type</InputLabel>
                                    <Select
                                        variant='standard'
                                        id="demo-simple-select"
                                        size='small'
                                        label='Type'
                                        name='type'
                                        disabled={formData.category === 'Teaching'}
                                        value={formData.type }
                                        onChange={handleChange}
                                    >
                                        <MenuItem value='Technical' disabled>
                                           Technical
                                        </MenuItem>
                                        <MenuItem value='NonTechnical'>
                                            Non-technical
                                        </MenuItem>
                                        <MenuItem value='Administrative'>
                                            Administrative
                                        </MenuItem>
                                        <MenuItem value='Other'>
                                            Others
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    variant='standard'
                                    label='Title'
                                    name='postName'
                                    value={formData.postName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <TextField
                                    fullWidth
                                    InputLabelProps={{
                                        shrink:true
                                    }}
                                    size='small'
                                    variant='standard'
                                    type='number'
                                    label='index'
                                    name='index'
                                    value={formData.index}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid mt={1.5} item sm={12}>
                            <FormControl size='small' fullWidth>
                                <InputLabel size='small'>Status</InputLabel>
                                <Select
                                    variant='standard'
                                    id="demo-simple-select"
                                    size='small'
                                    label='Status'
                                    name='status'
                                    value={formData.status}
                                    InputLabelProps={{
                                        shrink:true
                                    }}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={true}>
                                        Active
                                    </MenuItem>
                                    <MenuItem value={false}>
                                        Inactive
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <DialogActions>
                            <Button type='submit' size='small' variant='contained'>
                                Edit
                            </Button>
                        </DialogActions>
                    </form>
                </Stack>
            </DialogContent>
        </BootstrapDialog>
    )
}

export default EditPost