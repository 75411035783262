import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import GraduationNavBar from "../../../modules/navbar/GraduationNavBar";
import { useNavigate, useParams } from "react-router-dom";
const backendUrl = process.env.REACT_APP_BACKEND_URL;
const GraduationModule = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const navigate = useNavigate();
  const [students, setStudents] = useState({});
  const [fiscalYears, setFiscalYears] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [responseId, setResponseId] = useState(0);
  const { currentUser } = useSelector((state) => state.user);
  const studentId = id;
  const collegeId = currentUser.institution?.id;
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const verifiedStudents = await axios.get(
          `${backendUrl}/Student/${id}`,
          config
        );

        setStudents(verifiedStudents.data);
      } catch (err) {
        console.error("Error fetching students:", err);

        toast.error("Failed to load students");
      }
    };

    fetchStudents();
  }, [id]);

  const onSubmit = async (data) => {
    const apiGraduationData = {
      fiscalYearID: data.fiscalYearId,
      campusId: collegeId,
      studentId: studentId,
      universityIssueNo: data.universityIssueNo,
      studentRegNo: data.studentRegNo,
      symbolNoUniversity: data.symbolNo,
      campusRolNo: data.campusRollNo,
      enrolledYear: data.enrolledYear,
      passedYear: data.passedYear,
      division: data.division,
      gpa: data.gpa,
      uploadSignature: data.uploadSignature,
      uploadPPSizePhoto: data.uploadPhoto,
      uploadReceipt: data.uploadReceipt,
      uploadTranscript: data.uploadTranscript,
      uploadOtherDoc: data.uploadOtherDocs,
      remarks: data.remarks,
    };
    try {
      setIsloading(true);
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${backendUrl}/Graduation/GenerateGraduationApplicationForStudent`,
        apiGraduationData,
        config
      );
      const id = response.data.id;
      setResponseId(id);
      if (response.status === 201) {
        navigate(`/graduationForm?type=new&&id=${id}`);
      }
      toast.success("Data posted successfully", {
        autoClose: 1500,
      });
      reset();
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };
  const fetchGraduationData = async () => {
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const fiscalYearResponse = await axios.get(
        `${backendUrl}/FiscalYear`,

        config
      );
      setFiscalYears(fiscalYearResponse.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsloading(false);
    }
  };
  useEffect(() => {
    fetchGraduationData();
  }, []);

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <GraduationNavBar />
        </Box>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} mx="auto">
          <Paper elevation={3} sx={{ padding: 3, borderRadius: "10px" }}>
            <CardContent>
              <Typography
                variant="h6"
                sx={{ textAlign: "center", color: "#2A629A" }}
              >
                Graduation Management
              </Typography>
              <Box sx={{ padding: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body1">Name (English):</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students?.firstName} {students.middleName}{" "}
                          {students.lastName}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body1">Program:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students.programName}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body1">Roll Number:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students.rollNo}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body1">Email:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students.email}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body1">Phone:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students.phoneNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Typography variant="body1">Name (Nepali):</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students.nepaliName}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1">Guardian Name:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students.guardianName}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body1">Guardian Phone:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students.guardianPhone}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body1">
                          Permanent Address:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students.pProvince}, {students.pDistrict}
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <Typography variant="body1">Admission Year:</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {students.admissionYear}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel required id="fiscalYearId-label">
                        Fiscal Year
                      </InputLabel>

                      <Select
                        {...register("fiscalYearId", { required: true })}
                        id="fiscalYearId"
                        labelId="fiscalYearId-label"
                        defaultValue=""
                        label="Fiscal Year"
                      >
                        {fiscalYears.map((fy) => (
                          <MenuItem key={fy.id} value={fy.id}>
                            {fy.yearNepali}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="universityIssueNo"
                      label="University Issue No."
                      {...register("universityIssueNo", { required: true })}
                      error={!!errors.universityIssueNo}
                      helperText={
                        errors.universityIssueNo &&
                        "University Issue No. is required"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="studentRegNo"
                      label="Student Registration Number"
                      {...register("studentRegNo", { required: true })}
                      error={!!errors.studentRegNo}
                      helperText={
                        errors.studentRegNo &&
                        "Student Registration Number is required"
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="symbolNo"
                      label="Symbol Number"
                      {...register("symbolNo", { required: true })}
                      error={!!errors.symbolNo}
                      helperText={
                        errors.symbolNo && "Symbol Number is required"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Passed Year"
                      name="passedYear"
                      type="number"
                      {...register("passedYear", { required: true })}
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      {...register("division", { required: true })}
                      label="Grade/Division"
                      name="division"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      name="gpa"
                      label="GPA/Percentage"
                      step="0.01"
                      {...register("gpa", {
                        required: true,
                        min: 0,
                        max: 9,
                        valueAsNumber: true,
                      })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      {...register("uploadTranscript", { required: false })}
                      required
                      id="uploadTranscript"
                      size="small"
                      name="uploadTranscript"
                      label="Upload Transcript"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.uploadTranscript}
                      helperText={
                        errors.uploadTranscript
                          ? "Upload Transcript is required"
                          : ""
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <TextField
                      {...register("uploadSignature", { required: false })}
                      id="uploadSignature"
                      size="small"
                      name="uploadSignature"
                      label="Upload Signature"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.uploadSignature}
                      helperText={
                        errors.uploadSignature
                          ? "Upload Signature is required"
                          : ""
                      }
                    />
                  </Grid> */}

                  <Grid item xs={4}>
                    <TextField
                      {...register("uploadPhoto", { required: false })}
                      id="uploadPhoto"
                      size="small"
                      name="uploadPhoto"
                      label="Upload Photo"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.uploadPhoto}
                      helperText={
                        errors.uploadPhoto ? "Upload Photo is required" : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      {...register("uploadReceipt", { required: false })}
                      id="uploadReceipt"
                      size="small"
                      name="uploadReceipt"
                      label="Upload Receipt"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.uploadReceipt}
                      helperText={
                        errors.uploadReceipt ? "Upload Receipt is required" : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      {...register("uploadOtherDocs", { required: false })}
                      id="uploadOtherDocs"
                      size="small"
                      name="uploadOtherDocs"
                      label="Upload OtherDocs"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.uploadOtherDocs}
                      helperText={
                        errors.uploadOtherDocs
                          ? "Upload Other Docs is required"
                          : ""
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      name="remarks"
                      label="Remarks"
                      {...register("remarks")}
                    />
                  </Grid>

                  <Grid container justifyContent="center" sx={{ mt: 3 }}>
                    <Button type="submit" variant="contained" color="primary">
                      {isLoading ? "Submitting..." : "Submit"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GraduationModule;
