import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  TextField,
  CardContent,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogContent,
} from "@mui/material";
import {
  getFacultyByFacultyId,
  patchFaculty,
} from "../../../services/services.js";
import { toast } from "react-toastify";

const EditFacultyMgmt = ({ id, handleClose }) => {
  const [formData, setFormData] = useState({
    universityId: 0,
    university: 0,
    campusId: null,
    campus: null,
    facultyName: "",
    facultyType: "",
    shortName: "",
    code: "",
    alias: null,
    detail: null,
    status: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFacultyByFacultyId(id);
        setFormData(response);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const cleanedFormData = {
      ...formData,
      facultyName: formData.facultyName.trim(),
      shortName: formData.shortName.trim(), // Ensure shortName is trimmed
      code: formData.code.trim(),
    };
    try {
      await patchFaculty(id, cleanedFormData);
      toast.success("Faculty updated successfully");
    } catch (error) {
      console.error("Error updating Faculty :", error);
      toast.error("Failed to update Faculty");
    }
    setTimeout(() => {
      handleClose();
    }, 600);
  };

  return (
    <>
      <DialogContent fullWidth>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: "center", color: "#2A629A" }}
                padding="10px"
              >
                Update Faculty Management
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      size="small"
                      label="Faculty Name"
                      fullWidth
                      autoComplete="given-name"
                      name="facultyName"
                      value={formData.facultyName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="facilityType" required>
                        Faculty Type
                      </InputLabel>
                      <Select
                        name="facultyType"
                        value={formData.facultyType}
                        size="small"
                        onChange={handleChange}
                        label="Faculty Type"
                        fullWidth
                      >
                        <MenuItem value={"general"}> General</MenuItem>
                        <MenuItem value={"technical"}> Technical</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <TextField
                      size="small"
                      label="Short Name"
                      name="shortName"
                      value={formData.shortName}
                      onChange={handleChange}
                      fullWidth
                      autoComplete="short-name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Status</InputLabel>
                      <Select
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        size="small"
                        label="Status"
                        fullWidth
                      >
                        <MenuItem value={true}>
                          <span style={{ color: "green" }}>Active</span>
                        </MenuItem>
                        <MenuItem value={false}>
                          <span style={{ color: "red" }}>InActive</span>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      name="code"
                      size="small"
                      label="Code"
                      onChange={handleChange}
                      value={formData.code}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      name="alias"
                      value={formData.alias}
                      onChange={handleChange}
                      size="small"
                      label="Alias"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name="detail"
                      size="small"
                      label="Details"
                      fullWidth
                      onChange={handleChange}
                      value={formData.detail}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{ backgroundColor: "#007aff", color: "#inherit" }}
                  >
                    Update
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default EditFacultyMgmt;
