import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import SettingsMenu from "../../components/appBar/SettingsMenu";
import HomeIcon from "@mui/icons-material/Home";
import defaultTuLogo from "../../assets/defaultLogo.jpeg";
import profile from "../../assets/profile.png";

const baseUrl = process.env.REACT_APP_BASE_URL;

function GraduationNavBar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { currentUser } = useSelector((state) => state.user);

  let logos;
  let instituteNames;
  let localLevels;
  let districts;

  if (currentUser && currentUser.listUser && currentUser.listUser.length > 0) {
    logos = currentUser?.institution?.logo || defaultTuLogo;
    instituteNames =
      currentUser.institution?.campusName ||
      currentUser.institution?.name ||
      "विश्वविद्यालय अनुदान आयोग";
    localLevels =
      currentUser.institution?.localLevel ||
      currentUser.institution?.palika ||
      "";
    districts = currentUser.institution?.district || "";
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box bgcolor="white">
      {/* Top Bar */}
      <Box
        position="fixed"
        sx={{
          width: "100%",
          top: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#01204E",
          height: "77px",
          bgcolor: "white",
        }}
      >
        <Container maxWidth="xl" sx={{ paddingBottom: "5px" }}>
          <Toolbar disableGutters>
            {/* Logo and Institution Name */}
            <Link to="/" variant="body1" nowrap="true" component="a" href="/">
              <img
                src={
                  currentUser?.institution?.logo ? `${baseUrl}/${logos}` : logos
                }
                alt="Logo"
                style={{ marginRight: "8px", height: "75px" }}
              />
            </Link>

            <Box>
              <Typography color="#2b6eb5" variant="body1" fontWeight="bold">
                {instituteNames}
              </Typography>
              {localLevels && districts ? (
                <Typography variant="body2">
                  {localLevels}, {districts}
                </Typography>
              ) : (
                <Typography variant="body2" fontWeight="bold" color="#2b6eb5">
                  University Grants Commission
                </Typography>
              )}
            </Box>
          </Toolbar>
        </Container>

        {/* Main Title */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-30%, -50%)",
            zIndex: (theme) => theme.zIndex.drawer + 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" color="#2b6eb5" fontWeight="bold">
            Integrated Higher Education Management Information System
          </Typography>
          <Typography variant="h6" color="#2b6eb5" fontWeight="bold">
            (HEMIS)
          </Typography>
        </Box>
      </Box>

      {/* Navbar AppBar */}
      <AppBar
        position="fixed"
        sx={{
          top: "76px",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: "#FFF",
          bgcolor: "#2B6EB5",
          height: "41px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Home Button */}
              <IconButton
                color="inherit"
                size="small"
                component={Link}
                to="/"
                sx={{
                  "&:hover": {
                    bgcolor: "#1976d2", // Hover effect
                  },
                }}
              >
                <HomeIcon fontSize="small" />
              </IconButton>

              {/* Navigation Buttons */}
              {currentUser && (
                <>
                  <Button
                    component={Link}
                    to="/home"
                    variant="body2"
                    size="small"
                    sx={{
                      mx: 0,
                      textDecoration: "none",
                      color: "inherit",
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: "#1976d2",
                      },
                    }}
                  >
                    Home
                  </Button>

                  <NavLink
                    to="/graduation-management"
                    end // This ensures it only matches the exact path
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "white" : "inherit",
                      backgroundColor: isActive ? "#1976d2" : "transparent",
                      borderRadius: "4px",
                    })}
                  >
                    <Button
                      variant="body2"
                      size="small"
                      sx={{
                        mx: 0,
                        textDecoration: "none",
                        color: "inherit",
                        textTransform: "capitalize",
                        "&:hover": {
                          bgcolor: "#1976d2",
                        },
                        backgroundColor: "transparent", // Ensure background doesn't overlap
                      }}
                    >
                      Graduation
                    </Button>
                  </NavLink>

                  <NavLink
                    to="/graduation-management/graduation-list"
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      color: isActive ? "white" : "inherit",
                      backgroundColor: isActive ? "#1976d2" : "transparent",
                      borderRadius: "4px",
                    })}
                  >
                    <Button
                      variant="body2"
                      size="small"
                      sx={{
                        mx: 0,
                        textDecoration: "none",
                        color: "inherit",
                        textTransform: "capitalize",
                        "&:hover": {
                          bgcolor: "#1976d2",
                        },
                        backgroundColor: "transparent", // Ensure background doesn't overlap
                      }}
                    >
                      Graduation List
                    </Button>
                  </NavLink>
                </>
              )}
            </Box>

            {/* User Info and Profile */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {currentUser && (
                <Button
                  size="small"
                  variant="body2"
                  sx={{
                    mx: 0,
                    textDecoration: "none",
                    color: "inherit",
                    textTransform: "lowercase",
                    "&:hover": {
                      bgcolor: "#1976d2",
                    },
                  }}
                  target="blank"
                >
                  {currentUser.email}
                </Button>
              )}

              {/* Profile Icon */}
              <Box sx={{ flexGrow: 0 }}>
                {currentUser && (
                  <Tooltip title="Open settings">
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{ p: 0 }}
                      size="small"
                    >
                      <Avatar src={profile} alt="Profile" />
                    </IconButton>
                  </Tooltip>
                )}

                {/* Login Button (if not logged in) */}
                {!currentUser && (
                  <Button
                    component={Link}
                    to="/login"
                    variant="standard"
                    size="small"
                    sx={{
                      textTransform: "capitalize",
                      "&:hover": {
                        bgcolor: "#1976d2", // Hover effect
                      },
                    }}
                  >
                    Login
                  </Button>
                )}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <SettingsMenu
        anchorElUser={anchorElUser}
        handleCloseUserMenu={handleCloseUserMenu}
      />
    </Box>
  );
}

export default GraduationNavBar;
