import React, { useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ImageUploader from "../Reusable-component/ImageUploader";
import { createContext, useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from "@mui/x-date-pickers";
import useAddressData from "../address/address";
import { getEthnicGroup } from "../../services/services";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const StudentInfoContext = createContext();

const StudentInfoProvider = ({ children }) => {
  const methods = useForm();
  const [studentInfo, setStudentInfo] = useState({
    nepaliName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: 0,
    dobBs: "",
    dobAd: "",
    gender: "",
    ethnicity: "",
    edg: false,
    nationality: "",
    disabilityStatus: "",
    disabilityType: "",
    email: "",
    citizenshipNo: "",
    issuedDist: "",
    citizenFront: null,
    citizenBack: null,
    nidNo: "",
    nidPic: null,
    ppSizePhoto: null,
    isMuktaKamaiya: '',
    isFromMartyrFamily:'',
    digitalSignature:null,
  });

  const getLogoURL = () => {
    return studentInfo.ppSizePhoto ? URL.createObjectURL(studentInfo.ppSizePhoto) : null;
  };

  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setStudentInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setStudentInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <StudentInfoContext.Provider value={{ ...methods, studentInfo, onChange, getLogoURL }}>
      {children}
    </StudentInfoContext.Provider>
  );
};
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});
const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
  datePicker: {
    "& .MuiTextField-root": {
      width: 20,
    },
  }
});
const StudentGeneralInfo = ({ handleNext, handleBack }) => {
  const {
    address
  } = useAddressData();
  const [ethnicGroup, setEthnicGroup] = useState([])
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useContext(StudentInfoContext);
  const { onChange } = useContext(StudentInfoContext);
  const disabilityStatus = watch("disabilityStatus"); // Watch the value of disabilityStatus

  const onSubmit = (data) => {
    handleSubmit((formData) => {
      console.log(formData)
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      console.log(data)
      handleNext();
    })(data);
  };

  const onBack = (data) => {
    console.log(data);
    handleBack(); // Call handleNext only if form submission is successful
  };
  const fetchData = async () => {
    try {
      const response = await getEthnicGroup()
      setEthnicGroup(response)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={() => {
        onBack();
      }}
    >
      <Grid container>
        <Grid mt='.5rem' container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Controller
              name="nepaliName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  required
                  {...field}
                  id="nepaliName"
                  size="small"
                  name="nepaliName"
                  label="विद्यार्थीको पुरा नाम देवनागरीमा (unicode)"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  required
                  {...field}
                  id="firstName"
                  size="small"
                  name="firstName"
                  label="First Name (Eng)"
                  fullWidth
                  inputProps={{ style: { textTransform: "uppercase" } }}

                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="middleName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="middleName"
                  size="small"
                  name="middleName"
                  label="Middle Name"
                  fullWidth
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="lastName"
                  size="small"
                  name="lastName"
                  label="Last Name (Eng)"
                  fullWidth
                  inputProps={{ style: { textTransform: "uppercase" } }}

                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="phone"
              control={control}
              rules={{
                validate: (value) => {
                  const isValidPhone = /^[9]\d{9}$/.test(value);
                  return isValidPhone || "invalid phone umber";
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="phone"
                  type="text"
                  name="phone"
                  label="Phone Number"
                  InputProps={{
                    placeholder: "98XXXXXXXX",
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    onInput: (e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10);
                    },
                  }}
                  fullWidth
                  autoComplete="phone number"
                  size="small"
                  error={!!errors.phone}
                  helperText={errors.phone ? errors.phone.message : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Email format not matched",
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  required
                  id="email"
                  name="email"
                  type="email"
                  size="small"
                  label="Email"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={['DatePicker', 'DateField']} sx={{ mt: '-8px', width: '100%' }}>
                <Controller
                  name="dobBs"
                  control={control}
                  defaultValue={null} // Set your default value here
                  render={({ field }) => (
                    <DateField
                      {...field}
                      fullWidth
                      required
                      label='Date of Birth (B.S.)'
                      enableAccessibleFieldDOMStructure
                      slotProps={{ textField: { size: 'small' } }}
                      format="YYYY/MM/DD"
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={['DateField']} sx={{ mt: '-8px', overflow: 'hidden', width: '100%' }}>
                <Controller
                  name="dobAd"
                  control={control}
                  defaultValue={null} // Set your default value here
                  render={({ field }) => (
                    <DateField
                      {...field}
                      required
                      label='Date of Birth (A.D.)'
                      enableAccessibleFieldDOMStructure
                      slotProps={{ textField: { size: 'small' } }}
                      format="YYYY/MM/DD"
                      inputFormat="YYYY/MM/DD"
                      disableFormatting
                      fullWidth
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel sx={{ borderColor: "blue" }} id="gender" required>
                Gender
              </InputLabel>
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    required
                    labelId="gender"
                    id="gender"
                    name="gender"
                    label="Gender"
                    fullWidth
                  >
                    <MenuItem value="" disabled>Select Gender</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel sx={{ borderColor: "blue" }} id="type">
                Nationality
              </InputLabel>
              <Controller
                name="nationality"
                control={control}
                rules={{ required: "Required" }}
                defaultValue="nepali" // Set the default value here
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    labelId="nationality"
                    id="nationality"
                    name="nationality"
                    label="Nationality"
                    fullWidth
                    error={!!errors.nationality}
                    helperText={
                      errors.nationality ? errors.nationality.message : ""
                    }
                  >
                    <MenuItem value="" disabled>Select Nationality </MenuItem>
                    <MenuItem value="nepali">Nepali</MenuItem>

                    <MenuItem value="other">Other</MenuItem>
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel sx={{ borderColor: "blue" }} id="ethnicity" required>
                Ethnicity
              </InputLabel>
              <Controller
                name="ethnicity"
                control={control}
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    required
                    labelId="ethnicity"
                    id="ethnicity"
                    name="ethnicity"
                    label="Ethnicity"
                    fullWidth
                    error={!!errors.ethnicity}
                    onChange={(e) => field.onChange(e.target.value)} // Ensure field value is updated
                    value={field.value || ""} // Handle value properly
                  >
                    <MenuItem value="" disabled>Select Ethnicity</MenuItem>
                    {ethnicGroup && ethnicGroup.map((data) => (
                      <MenuItem key={data.id} value={data.name}>{data.name}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>


          <Grid item xs={12} sm={3}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel
                sx={{ borderColor: "blue" }}
                id="disabilityStatusLabel"
                required
              >
                Disability Status
              </InputLabel>
              <Controller
                name="disabilityStatus"
                control={control}
                defaultValue="" // Set the default value here
                render={({ field }) => (
                  <Select
                    {...field}
                    required
                    labelId="disabilityStatusLabel"
                    id="disabilityStatus"
                    fullWidth
                    label="Dissability Status"

                  >
                    <MenuItem value="" disabled>Select Disability Status </MenuItem>
                    <MenuItem value="able">Able</MenuItem>
                    <MenuItem value="differentlyable">
                      Differently-Able
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl sx={{ borderColor: "blue" }} size="small" fullWidth>
              <InputLabel
                sx={{ borderColor: "blue" }}
                id="disabilityType"
                required
              >
                Disability Type
              </InputLabel>
              <Controller
                name="disabilityType"
                control={control}
                defaultValue=""
                disabled={disabilityStatus === "able"}
                render={({ field }) => (
                  <Select
                    {...field}
                    required
                    labelId="disabilityType"
                    id="disabilityType"
                    fullWidth
                    label="Dissability Type"
                  >
                    <MenuItem value="" disabled>अपाङ्गता प्रकार </MenuItem>
                    <MenuItem value="A">क</MenuItem>
                    <MenuItem value="B">ख</MenuItem>
                    <MenuItem value="C">ग</MenuItem>
                    <MenuItem value="D">घ</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="citizenshipNo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}

                  id="citizenshipNo"
                  size="small"
                  name="citizenshipNo"
                  label="Citizenship No"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth size="small">
              <InputLabel>
                Issued District
              </InputLabel>
              <Controller
                name="issuedDist"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <ValidationSelect
                    {...field}
                    id="issuedDist"
                    size="small"
                    name="issuedDist"
                    label="Issued District"
                    fullWidth

                  >
                    {/* Filter unique districts using a Set */}
                    <MenuItem value="" disabled>Select Issued District </MenuItem>

                    {address && Array.from(new Set(address.map(data => data.district))).map((district, index) => (
                      <MenuItem key={index} value={district}>{district}</MenuItem>
                    ))}
                  </ValidationSelect>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              name="nidNo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <ValidationTextField
                  {...field}
                  id="nidNo"
                  size="small"
                  name="nidNo"
                  label="NID No."
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <FormGroup >
              <Controller
                name="edg"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Belong to EDG?"
                    sx={{
                      color: "#5a5b5d",
                      "& .MuiFormControlLabel-label": {
                        color: "#5a5b5d",
                      },
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={4}>
            <FormGroup >
              <Controller
                name="isMuktaKamaiya"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Belong To Mukta Kamaiya?"
                    sx={{
                      color: "#5a5b5d",
                      "& .MuiFormControlLabel-label": {
                        color: "#5a5b5d",
                      },
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item sm={4}>
            <FormGroup >
              <Controller
                name="isFromMartyrFamily"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label="Belong to Martyr's/conflict affetcted Family?"
                    sx={{
                      color: "#5a5b5d",
                      "& .MuiFormControlLabel-label": {
                        color: "#5a5b5d",
                      },
                    }}
                  />
                )}
              />
            </FormGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <ImageUploader
                allowMultiple={false}
                onImagesChange={(newImages) => {
                  if (newImages.length > 0) {
                    onChange(null, "ppSizePhoto", newImages[0]);
                  } else {
                    onChange(null, "ppSizePhoto", null);
                  }
                }}
                name="ppSizePhoto"
                placeholder="Passport size photo"
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <ImageUploader
                allowMultiple={false}
                onImagesChange={(newImages) => {
                  if (newImages.length > 0) {
                    onChange(null, "citizenFront", newImages[0]);
                  } else {
                    onChange(null, "citizenFront", null);
                  }
                }}
                name="citizenFront"
                placeholder="Citizenship Front (optional)"
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <ImageUploader
                allowMultiple={false}
                onImagesChange={(newImages) => {
                  if (newImages.length > 0) {
                    onChange(null, "citizenBack", newImages[0]);
                  } else {
                    onChange(null, "citizenBack", null);
                  }
                }}
                name="citizenBack"
                placeholder="Citizenship Back (optional) "
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <FormControl>
              <ImageUploader
                allowMultiple={false}
                onImagesChange={(newImages) => {
                  if (newImages.length > 0) {
                    onChange(null, "digitalSignature", newImages[0]);
                  } else {
                    onChange(null, "digitalSignature", null);
                  }
                }}
                name="digitalSignature"
                placeholder="Digital Signature"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            color="error"
            size="small"
            type="submit"
            disabled
            startIcon={<ChevronLeftRoundedIcon />}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            size="small"
            type="submit"
            sx={{
              marginLeft: "10px",
            }}
            endIcon={<ChevronRightRoundedIcon />}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
export { StudentInfoProvider, StudentInfoContext };
export default StudentGeneralInfo;
