import { Box, Button, Grid } from "@mui/material";
import React from "react";
import GraduationNavBar from "../../../modules/navbar/GraduationNavBar";

const CharacterCertificate = () => {
  return (
    <div>
      {" "}
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box mt={8}>
          <GraduationNavBar />
        </Box>
      </Box>
      <Grid>
        {/* <DropzoneArea onChange={(files) => console.log("Files:", files)} style={{colors:'red'}}/> */}

        <input type="file" accept="image/*" style={{ display: "none" }} />
        <Button variant="contained">
          Upload the Application For Character Certificate
        </Button>
      </Grid>
    </div>
  );
};

export default CharacterCertificate;
