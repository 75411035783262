import React from "react";
import OtherSetupAppBar from "../navbar/OtherSetupAppBar";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { blue, grey } from "@mui/material/colors";
import Image from "../../assets/undraw_scrum-board_uqku.png"

const OtherSetupHome = () => {
  const { currentUser } = useSelector((state) => state.user);
  const roleName = currentUser?.listUser?.[0]?.roleName || currentUser?.role;
  return (
    <div>
      <Box
        sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: grey[100],
              }}
      >
        <Box mt={8}>
          <OtherSetupAppBar />
        </Box>
        <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={3} alignItems="center">
          {/* Left Side: Image */}
          <Grid item xs={12} md={6}>
            <img
              src={Image}
              alt="Online Test"
              style={{
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Grid>

          {/* Right Side: Text and Button */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                marginBottom: 2,
                color: blue[700],
                textAlign: "left",
              }}
            >
              Welcome to the Other Setup Module
            </Typography>
            <Typography
              variant="h6"
              sx={{
                marginBottom: 4,
                color: grey[700],
                textAlign: "left",
              }}
            >
              Get ready to manage your Setups! Prepare yourself with the tools
              and environment you need to succeed.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      </Box>
    </div>
  );
};

export default OtherSetupHome;
