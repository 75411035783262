import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ProgramAdd = ({ collegeId }) => {
  const [programData, setProgramData] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState({});
  const { currentUser } = useSelector((state) => state.user);

  const universityId =
    currentUser.type === "Uni" ? currentUser.institution.id : 0;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const localStorageData = JSON.parse(
          localStorage.getItem("persist:root")
        );
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        console.log(collegeId);
        const [programResponse, collegeResponse] = await Promise.all([
          axios.get(
            `${backendUrl}/ProgramMgmt/GetAllPrograms/${universityId}`,
            config
          ),
          axios.get(
            `${backendUrl}/ProgramMgmt/GetProgramByCollegeId?collegeid=${collegeId}`,
            config
          ),
        ]);
        setProgramData(programResponse.data);
        console.log(programResponse.data);
        const collegeProgramIds = new Set(
          collegeResponse.data.map((program) => program.id)
        );
        const initialSelectedPrograms = programResponse.data.reduce(
          (acc, program) => {
            acc[program.id] = {
              isSelected: collegeProgramIds.has(program.id),
              isDisabled: collegeProgramIds.has(program.id),
            };
            return acc;
          },
          {}
        );
        setSelectedPrograms(initialSelectedPrograms);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [universityId, collegeId]);

  const handleCheckboxChange = (programId, isChecked) => {
    if (!selectedPrograms[programId]?.isDisabled) {
      setSelectedPrograms((prev) => ({
        ...prev,
        [programId]: {
          ...prev[programId],
          isSelected: isChecked,
        },
      }));
    }
  };

  const handleSave = async () => {
    const selectedProgramIds = Object.keys(selectedPrograms)
      .filter((programId) => selectedPrograms[programId]?.isSelected)
      .map((programId) => parseInt(programId, 10));

    if (selectedProgramIds.length === 0) {
      toast.error("No programs selected");
      return;
    }
    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const dataToSend = {
        collegeId,
        programId: selectedProgramIds,
        isActive: true,
      };

      const response = await axios.post(
        `${backendUrl}/ProgramMgmt/AddProgramToCollegeByUni`,
        dataToSend,
        config
      );
      console.log("Save successful:", response.data);
      toast.success("Programs Saved Successfully");
    } catch (error) {
      console.error("Error saving programs:", error);
      toast.error("Failed to add programs!");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TableContainer style={{ maxHeight: 300, width: "100%" }}>
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead style={{ backgroundColor: "#2A629A" }}>
            <TableRow>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                S.No:
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Level
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Faculty
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Programs
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Add
              </TableCell>
              <TableCell
                style={{
                  color: "#FFFFFF",
                  border: "1px solid #ddd",
                  padding: "8px",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {programData.map((program, index) => (
              <TableRow key={program.id} style={{ border: "1px solid #ddd" }}>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {program.levelName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {program.facultyName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  {program.shortName}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    padding: "4px",
                    height: "20px",
                  }}
                >
                  <Checkbox
                    color="success"
                    checked={!!selectedPrograms[program.id]?.isSelected}
                    onChange={(e) =>
                      handleCheckboxChange(program.id, e.target.checked)
                    }
                    disabled={!!selectedPrograms[program.id]?.isDisabled}
                    sx={{
                      "&.Mui-disabled": {
                        color: "gren",
                      },
                      "&.Mui-checked.Mui-disabled": {
                        color: "green",
                      },
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    padding: "8px",
                    color: "green",
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "none",
                  }}
                >
                  <FiberManualRecordIcon
                    sx={{ fontSize: 12, color: "green", marginRight: "4px" }}
                  />
                  <span>Active</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ marginTop: "16px" }}
      >
        Save
      </Button>
    </div>
  );
};

export default ProgramAdd;
