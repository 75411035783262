import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  DialogContent,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const EditExpenseHead = ({ id, onClose, onUpdate }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useForm();
  
  const [loading, setLoading] = useState(false);
  const headType = watch("headType"); 
 
 

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
        const userState = JSON.parse(localStorageData.user);
        const authToken = userState.currentUser.tokenString;

        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };

        // Fetch details by ID
        const expenseResponse = await axios.get(`${backendUrl}/FinanceHead/${id}`, config);
        const expenseData = expenseResponse.data;

        // Populate form fields with fetched data
        setValue("headCode", expenseData.headCode|| 0);
        setValue("headType", expenseData.headType || "");
        if (expenseData.headType === "Expenditure head") {
            setValue("headType", expenseData.expenditureType || ""); // Set headCode if it's an expenditure head
          } else {
            setValue("headType", null); 
          }
        setValue("headName", expenseData.headName || ""); 
        setValue("remarks", expenseData.remarks || "");

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchInitialData();
  }, [id, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("id", id);
    formData.append("headCode", data.headCode);
    formData.append("headType", data.headType);
    formData.append(
        "expenditureType",
        data.headType === "Expenditure head" ? data.expenditureType : null
      );
      
    formData.append("headName", data.headName);
    formData.append("remarks", data.remarks || "");

    try {
      const localStorageData = JSON.parse(localStorage.getItem("persist:root"));
      const userState = JSON.parse(localStorageData.user);
      const authToken = userState.currentUser.tokenString;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };

      await axios.put(`${backendUrl}/FinanceHead/${id}`, formData, config);

      toast.success("Expense Data updated successfully", {
        autoClose: 1500,
      });
      onUpdate();
      onClose();
    } catch (error) {
      toast.error("Error updating receipt: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogContent>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ textAlign: "center", color: "#2A629A", padding: "10px" }}
      >
        Edit Income/Expense Details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
           {/* Head Code */}
            <Grid item xs={12} sm={4}>
            <Controller
                name="headCode"
                control={control}
                defaultValue=""
                rules={{ required: "Head Code is required" }}
                render={({ field }) => (
                <TextField
                    {...field}
                    required
                    fullWidth
                    size="small"
                    id="headCode"
                    label="Head Code"
                    type="number"
                    error={!!errors.headCode}
                    helperText={errors.headCode?.message}
                />
                )}
            />
            </Grid>

            {/* Head Type */}
            <Grid item xs={12} sm={4}>
            <Controller
                name="headType"
                control={control}
                defaultValue=""
                rules={{ required: "Head Type is required" }}
                render={({ field }) => (
                <FormControl fullWidth size="small" error={!!errors.headType}>
                    <InputLabel required id="headType-label">
                    Head Type
                    </InputLabel>
                    <Select
                    {...field}
                    label="Head Type"
                    id="headType"
                    labelId="headType-label"
                    >
                    <MenuItem value="Income head">Income Head</MenuItem>
                    <MenuItem value="Expenditure head">Expenditure Head</MenuItem>
                    </Select>
                </FormControl>
                )}
            />
            </Grid>

            {/* Expenditure Type (conditionally displayed) */}
            {headType === "Expenditure head" && (
            <Grid item xs={12} sm={4}>
                <Controller
                name="expenditureType"
                control={control}
                defaultValue=""
                rules={{ required: "Expenditure Type is required" }}
                render={({ field }) => (
                    <FormControl fullWidth size="small" error={!!errors.expenditureType}>
                    <InputLabel required id="expenditureType-label">
                        Expenditure Type
                    </InputLabel>
                    <Select
                        {...field}
                        label="Expenditure Type"
                        id="expenditureType"
                        labelId="expenditureType-label"
                    >
                        <MenuItem value="Operating Expenditure">Operating Expenditure</MenuItem>
                        <MenuItem value="Capital Expenditure">Capital Expenditure</MenuItem>
                    </Select>
                    </FormControl>
                )}
                />
            </Grid>
            )}

            {/* Head Name */}
            <Grid item xs={12} sm={4}>
            <Controller
                name="headName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="Head Name"
                    id="headName"
                    error={!!errors.headName}
                    helperText={errors.headName?.message}
                />
                )}
            />
            </Grid>


          <Grid item xs={12}>
            <Controller
             name= "remarks"
             control={control}
             defaultValue=""
             rules={{ required: "Remarks is required" }}
             render={({ field }) => (
            <TextField
            {...field}
              id="remarks"
              label="Remarks"
              fullWidth
              multiline
              rows={1}
              size="small"
            />
             )}
             />
          </Grid>

          <Grid container justifyContent="center" spacing={2} mt={2}>
            <Grid item>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </DialogContent>
  );
};

export default EditExpenseHead;
