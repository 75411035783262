import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Popover,
    Box,
    Grid,
} from "@mui/material";
import { FileDownload, InsertDriveFile, PictureAsPdf } from "@mui/icons-material";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { fetchCampusTypeByEcoBelts } from "../reportApi";


const backendUrl = process.env.REACT_APP_BACKEND_URL;

function TypeProvinceByEcobelts({ fiscalId }) {
    const [rows, setRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [apiData, setApiData] = useState([])

    const fetchData = async () => {
        const reportData = await fetchCampusTypeByEcoBelts(fiscalId)
        if(reportData){
            const transformedData = reportData.map(item => ({
                province: item.provinceName,
                constituent: {
                    hill: item.constituent.hill,
                    mountain: item.constituent.mountain,
                    terai: item.constituent.tarai,
                    total: item.constituent.total
                },
                community: {
                    hill: item.community.hill,
                    mountain: item.community.mountain,
                    terai: item.community.tarai,
                    total: item.community.total
                },
                private: {
                    hill: item.private.hill,
                    mountain: item.private.mountain,
                    terai: item.private.tarai,
                    total: item.private.total
                },
                total: {
                    hill: item.total.hill,
                    mountain: item.total.mountain,
                    terai: item.total.tarai,
                    total: item.total.total
                },
            }));
            setApiData(transformedData)
        }else {
        setApiData([])
        }
    }
    useEffect(() => {
        fetchData()
    }, [fiscalId])

    const transformDataForTable = (data) => {
        return data.map((item, index) => ({
            sNo: index + 1,
            province: item.province,
            constituent: [item.constituent.hill, item.constituent.mountain, item.constituent.terai, item.constituent.total],
            community: [item.community.hill, item.community.mountain, item.community.terai, item.community.total],
            private: [item.private.hill, item.private.mountain, item.private.terai, item.private.total],
            total: [item.total.hill, item.total.mountain, item.total.terai, item.total.total],
        }));
    };
    
    const tableData = transformDataForTable(apiData);
    const totals = apiData.reduce(
        (acc, row) => {
            acc.constituent.hill += row.constituent.hill;
            acc.constituent.mountain += row.constituent.mountain;
            acc.constituent.terai += row.constituent.terai;
            acc.constituent.total += row.constituent.total;

            acc.community.hill += row.community.hill;
            acc.community.mountain += row.community.mountain;
            acc.community.terai += row.community.terai;
            acc.community.total += row.community.total;

            acc.private.hill += row.private.hill;
            acc.private.mountain += row.private.mountain;
            acc.private.terai += row.private.terai;
            acc.private.total += row.private.total;

            acc.total.total += row.total.total;
            return acc;
        },
        {
            constituent: { hill: 0, mountain: 0, terai: 0, total: 0 },
            community: { hill: 0, mountain: 0, terai: 0, total: 0 },
            private: { hill: 0, mountain: 0, terai: 0, total: 0 },
            total:{total:0}
        }
    );
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "export-popover" : undefined;

    const exportToExcel = () => {
        const header = [
            [
                "S.No.",
                "province",
                "constituent",
                "community",
                "private",
                "Total",
            ],
        ];

        const data = rows.map((row, index) => [
            index + 1,
            row.province,
            row.constituent,
            row.community,
            row.private,
            row.constituent + row.community + row.private,
        ]);

        const ws = XLSX.utils.aoa_to_sheet([...header, ...data]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Program Levels");
        XLSX.writeFile(wb, "program_levels.xlsx");
    };

    // Export to PDF
    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        const columns = [
            "S.No.",
            "province",
            "constituent",
            "community",
            "private",
            "Total",
        ];
        const data = rows.map((row, index) => [
            index + 1,
            row.province,
            row.constituent,
            row.community,
            row.private,
            row.constituent + row.community + row.private,
        ]);
        doc.autoTable(columns, data);
        doc.save("program_levels.pdf");
    };
    return (
        <div>
            <Grid container justifyContent="right">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<FileDownload />}
                    onClick={handleClick}
                    style={{ marginBottom: '10px' }}
                >
                    Export
                </Button>

                {/* Popover for Excel and PDF options */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ padding: '10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ backgroundColor: '#5FAD41' }}
                            startIcon={<InsertDriveFile />}
                            onClick={exportToExcel}
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        >
                            Excel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: '#272727' }}
                            startIcon={<PictureAsPdf />}
                            onClick={exportToPDF}
                            fullWidth
                        >
                            PDF
                        </Button>
                    </Box>
                </Popover>
            </Grid>

            <TableContainer sx={{ border: "1px solid #ddd" }}>
                <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
                    <TableHead style={{ backgroundColor: "#2A629A" }}>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",
                                    textAlign: "center",
                                    width: '1rem'
                                }}
                                rowSpan={2}
                            >
                                S.No.
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                                rowSpan={2}
                            >
                                Province
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                                colSpan={4}
                            >
                                Constituent
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                                colSpan={4}
                            >
                                Community
                            </TableCell>
                          
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                                colSpan={4}
                            >
                                Private
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                               rowSpan={2}
                            >
                                Total
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Hill
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Mountain
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Terai
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Hill
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Mountain
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Terai
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Hill
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Mountain
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Terai
                            </TableCell>
                            <TableCell
                                sx={{
                                    border: "1px solid #ddd",
                                    color: "#ffffff",

                                    textAlign: "center",
                                }}
                            >
                                Total
                            </TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tableData.map((row) => (
                            <TableRow key={row.sNo}>
                                {/* Serial Number */}
                                <TableCell sx={{ border: "1px solid #c2c2c2", textAlign: "center" }}>{row.sNo}</TableCell>

                                {/* province */}
                                <TableCell sx={{ border: "1px solid #c2c2c2", textAlign: "left" }}>{row.province}</TableCell>

                                {/* constituent */}
                                {row.constituent.map((val, i) => (
                                    <TableCell key={`constituent-${i}`} sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}>{val}</TableCell>
                                ))}

                                {/* community */}
                                {row.community.map((val, i) => (
                                    <TableCell key={`community-${i}`} sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}>{val}</TableCell>
                                ))}

                                {/* private */}
                                {row.private.map((val, i) => (
                                    <TableCell key={`private-${i}`} sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}>{val}</TableCell>
                                ))}
                                    <TableCell sx={{ border: "1px solid #c2c2c2", textAlign: "right" }}>{row.total[3]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    <TableRow style={{ backgroundColor: "#c2c2c2" }}>
                        <TableCell
                            colSpan={2}
                            sx={{
                                border: "1px solid #ddd",

                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            Grand Total
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",

                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.constituent.hill}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",

                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.constituent.mountain}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",

                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.constituent.terai}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",

                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.constituent.total}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",
                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.community.hill}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",
                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.community.mountain}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",
                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.community.terai}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",
                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.community.total}
                        </TableCell>

                        {/* Private Totals */}
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",
                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.private.hill}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",
                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.private.mountain}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",
                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.private.terai}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",
                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.private.total}
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "1px solid #ddd",
                                textAlign: "right",
                                fontWeight: "bold",
                            }}
                        >
                            {totals.total.total}
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        </div>
    )
}

export default TypeProvinceByEcobelts