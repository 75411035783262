import { Grid, Typography, TextField, Button, Box } from "@mui/material";
import React, { createContext, useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { getEmployeeById } from "../../../services/employeeService";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const ValidationSelect = styled(Select)({
  "& select:valid + fieldset": {
    borderColor: "#3572EF",
    borderWidth: 1,
  },
  "& select:invalid + fieldset": {
    borderColor: "#ff0000", // Custom invalid border color
    borderWidth: 1,
  },
  "& select:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
  datePicker: {
    "& .MuiTextField-root": {
      width: 20,
    },
  },
});
const EditAcademyContext = createContext();

const EditAcademyInfoProvider = ({ children }) => {
  const methods = useForm();
  const [academyInfo, setAcademyInfo] = React.useState({
    graduatedfrom: "",
    facultyName: "",
    levelName: "",
    enrolledYear: "",
    PassedYear: "",
    certificateCopy: "",
    transcriptCopy: "",
    marksheetCopy: "",
    otherDoc: "",
  });

  const onChange = (event, name, value) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      setAcademyInfo((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      setAcademyInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  return (
    <EditAcademyContext.Provider value={{ ...methods, academyInfo, onChange }}>
      {children}
    </EditAcademyContext.Provider>
  );
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "#c2c2c2",
    borderWidth: 1,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 4,
    padding: "4px !important", // override inline-style
  },
});

const EditAcademyGeneralInfo = ({ handleNext, handleBack, id }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    onChange,
  } = useContext(EditAcademyContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeData = await getEmployeeById(id);

        // Log the data to ensure it's coming correctly from the API
        console.log("Fetched Employee Data:", employeeData);

        setValue("graduatedfrom", employeeData.graduatedFrom || "");
        setValue("facultyName", employeeData.facultyName || "");
        setValue("levelName", employeeData.levelName || "");
        setValue("enrolledYear", employeeData.enrolledYear || "");
        setValue("PassedYear", employeeData.passedYear || "");
        setValue("certificateCopy", employeeData.certificateCopy || "");
        setValue("transcriptCopy", employeeData.transcriptCopy || "");
        setValue("marksheetCopy", employeeData.marksheetCopy || "");
        setValue("otherDoc", employeeData.otherDoc || "");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, setValue]);

  const onSubmit = (data) => {
    handleSubmit((formData) => {
      Object.keys(formData).forEach((key) => {
        onChange(null, key, formData[key]);
      });
      handleNext();
    })(data);
  };

  const onBack = () => {
    handleBack(); // Call handleBack only if form submission is successful
  };

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onReset={onBack}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <Controller
            name="graduatedfrom"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                size="small"
                label="Graduated From"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                error={!!errors.graduatedfrom}
                helperText={errors.graduatedfrom ? "Required" : ""}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl size="small" fullWidth>
            <InputLabel id="levelName" required>
              Level of Education
            </InputLabel>
            <Controller
              name="levelName"
              control={control}
              rules={{ required: "Required" }}
              defaultValue=""
              render={({ field }) => (
                <ValidationSelect
                  {...field}
                  size="small"
                  name="levelName"
                  label="Level of Education"
                  fullWidth
                  error={!!errors.levelName}
                  helperText={errors.levelName ? "Required" : ""}
                >
                  <MenuItem value="" disabled>
                    Select levelName
                  </MenuItem>
                  <MenuItem value="Literate">Literate</MenuItem>
                  <MenuItem value="DLE">DLE</MenuItem>
                  <MenuItem value="SLC">SLC</MenuItem>
                  <MenuItem value="TSLC">TSLC</MenuItem>
                  <MenuItem value="12">12</MenuItem>
                  <MenuItem value="PCL">PCL</MenuItem>
                  <MenuItem value="Diploma">Diploma</MenuItem>
                  <MenuItem value="Bachelor">Bachelor</MenuItem>
                  <MenuItem value="Master">Master</MenuItem>
                  <MenuItem value="MPhil.">MPhil.</MenuItem>
                  <MenuItem value="Ph.D.">Ph.D.</MenuItem>
                  <MenuItem value="N/A">N/A</MenuItem>
                </ValidationSelect>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="facultyName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <ValidationTextField
                {...field}
                name="facultyName"
                size="small"
                label="Faculty"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            name="enrolledYear"
            control={control}
            render={({ field }) => (
              <ValidationTextField
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 1950 } }}
                {...field}
                size="small"
                label="Enrolled Year (AD)"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="PassedYear"
            control={control}
            render={({ field }) => (
              <ValidationTextField
                {...field}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 1950 } }}
                size="small"
                label="Passed Year (AD)"
                fullWidth
              />
            )}
          />
        </Grid>
        {/* Include other fields as necessary */}
      </Grid>
      <Box mt={1} mb={2} display="flex" width="100%" justifyContent="flex-end">
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={onBack}
          sx={{ marginLeft: "10px" }}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{ marginLeft: "10px" }}
          endIcon={<ChevronRightRoundedIcon />}
        >
          Next
        </Button>
      </Box>
    </Grid>
  );
};

export { EditAcademyInfoProvider, EditAcademyContext };
export default EditAcademyGeneralInfo;
