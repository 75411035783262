import { Divider, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import React from "react";
import { Link } from "react-router-dom";

export default function StudentReportsMenu({
  anchorElReport,
  handleCloseReportMenu,
  roleName,
}) {
  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElReport}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElReport)}
      onClose={handleCloseReportMenu}
    >
      <Grid>
        <Divider style={{ color: blue[700] }}>General</Divider>
      </Grid>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/student-management/student-information-detail"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left",
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          fullWidth
          size="small"
          onClick={handleCloseReportMenu}
        >
          Student Detail
        </Typography>
      </MenuItem>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/student-management/student-by-district"
          color="inherit"
          size="small"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left",
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          onClick={handleCloseReportMenu}
        >
          Student By District
        </Typography>
      </MenuItem>
      <Grid>
        <Divider>
          <span style={{ color: blue[700] }}>Summary</span>
        </Divider>
      </Grid>
      <MenuItem sx={{ margin: "0px", padding: "0px" }}>
        <Typography
          component={Link}
          to="/student-management/other-report"
          color="inherit"
          size="small"
          variant="body2"
          sx={{
            width: "100%",
            textDecoration: "none",
            borderRadius: 0,
            border: "none",
            color: "inherit",
            fontSize: "14px",
            padding: "7px 7px",
            textAlign: "left",
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: "#1976d2",
              color: "whitesmoke",
            },
          }}
          onClick={handleCloseReportMenu}
        >
          Summary Report
        </Typography>
      </MenuItem>


      {roleName === "SuperAdmin" && (
        <>
          <Grid>
            <Divider>
              <span style={{ color: blue[700] }}>Other</span>
            </Divider>
          </Grid>
          <MenuItem sx={{ margin: "0px", padding: "0px" }}>
            <Typography
              component={Link}
              to="/student-management/other-report"
              color="inherit"
              size="small"
              variant="body2"
              sx={{
                width: "100%",
                textDecoration: "none",
                borderRadius: 0,
                border: "none",
                color: "inherit",
                fontSize: "14px",
                padding: "7px 7px",
                textAlign: "left",
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#1976d2",
                  color: "whitesmoke",
                },
              }}
              onClick={handleCloseReportMenu}
            >
              Other Reports
            </Typography>
          </MenuItem>
        </>
      )}
    </Menu>
  );
}
