import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import GraduationNavBar from "../../../modules/navbar/GraduationNavBar";
import GraduationModuleTable from "./GraduationModuleTable";
import GraduationTableOldStudent from "./GraduationTableOldStudent";

export default function GraduationList() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box mt={8}>
        <GraduationNavBar />
      </Box>

      <Box sx={{ width: "80%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Graduation Modules"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "4px 16px",
          }}
        >
          <Tab
            label="Listed Students"
            sx={{
              fontWeight: "bold",
              fontSize: "16px",
              textTransform: "none",
              color: value === 0 ? "#1976d2" : "#616161",
              "&.Mui-selected": {
                color: "#1976d2",
              },
            }}
          />
          <Tab
            label="Old Students"
            sx={{
              fontWeight: "bold",
              fontSize: "16px",
              textTransform: "none",
              color: value === 1 ? "#1976d2" : "#616161",
              "&.Mui-selected": {
                color: "#1976d2",
              },
            }}
          />
        </Tabs>
      </Box>

      {/* Tab Content */}
      <Box sx={{ flexGrow: 1 }}>
        {value === 0 && <GraduationModuleTable value={value} />}
        {value === 1 && <GraduationTableOldStudent value={value} />}
      </Box>
    </Box>
  );
}
